import client from "src/lib/apollo/client-provider";
import type {
        ApolloQueryResult, ObservableQuery, WatchQueryOptions, MutationOptions, SubscriptionOptions
      } from "@apollo/client";
import { readable } from "svelte/store";
import type { Readable } from "svelte/store";
import gql from "graphql-tag"
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _text: string;
  bigint: number;
  date: string;
  json: object;
  smallint: number;
  timestamptz: string;
  uuid: string;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CheckInviteStatusOutput = {
  __typename?: 'CheckInviteStatusOutput';
  id: Scalars['String'];
  status: InviteState;
};

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput';
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type InviteNewUserOutput = {
  __typename?: 'InviteNewUserOutput';
  token: Scalars['String'];
  user_invite_id: Scalars['String'];
};

export enum InviteState {
  Accepted = 'accepted',
  Pending = 'pending',
  Refused = 'refused'
}

export type InviteUserToTeamOutput = {
  __typename?: 'InviteUserToTeamOutput';
  invite_id: Scalars['String'];
  team_id: Scalars['String'];
  user_id: Scalars['String'];
};

export type RespondTeamInvitationOutput = {
  __typename?: 'RespondTeamInvitationOutput';
  status: Scalars['String'];
  team_id: Scalars['String'];
  user_id: Scalars['String'];
};

export enum RolesEnum {
  Admin = 'admin',
  Anonymous = 'anonymous',
  Editor = 'editor',
  Manager = 'manager',
  Mentor = 'mentor',
  User = 'user'
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type UpdateUserRoleOutput = {
  __typename?: 'UpdateUserRoleOutput';
  role?: Maybe<RolesEnum>;
  user_id?: Maybe<Scalars['String']>;
};

export type UploadOutput = {
  __typename?: 'UploadOutput';
  file_id: Scalars['String'];
  file_path: Scalars['String'];
};

export type UploadProfilePictureOutput = {
  __typename?: 'UploadProfilePictureOutput';
  id: Scalars['String'];
  path: Scalars['String'];
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "contents" */
export type Contents = {
  __typename?: 'contents';
  description: Scalars['String'];
  /** An array relationship */
  groups: Array<Groups_Contents>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Contents_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  repositories: Array<Repositories>;
  /** An aggregate relationship */
  repositories_aggregate: Repositories_Aggregate;
  /** An array relationship */
  sections: Array<Contents_Sections>;
  /** An aggregate relationship */
  sections_aggregate: Contents_Sections_Aggregate;
  /** An array relationship */
  video_repositories: Array<Video_Repositories>;
  /** An aggregate relationship */
  video_repositories_aggregate: Video_Repositories_Aggregate;
};


/** columns and relationships of "contents" */
export type ContentsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsRepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsRepositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsSectionsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsSections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsVideo_RepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsVideo_Repositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};

/** aggregated selection of "contents" */
export type Contents_Aggregate = {
  __typename?: 'contents_aggregate';
  aggregate?: Maybe<Contents_Aggregate_Fields>;
  nodes: Array<Contents>;
};

/** aggregate fields of "contents" */
export type Contents_Aggregate_Fields = {
  __typename?: 'contents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contents_Max_Fields>;
  min?: Maybe<Contents_Min_Fields>;
};


/** aggregate fields of "contents" */
export type Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contents". All fields are combined with a logical 'AND'. */
export type Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Contents_Bool_Exp>>;
  _not?: InputMaybe<Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Contents_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  groups?: InputMaybe<Groups_Contents_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  repositories?: InputMaybe<Repositories_Bool_Exp>;
  sections?: InputMaybe<Contents_Sections_Bool_Exp>;
  video_repositories?: InputMaybe<Video_Repositories_Bool_Exp>;
};

/** unique or primary key constraints on table "contents" */
export enum Contents_Constraint {
  /** unique or primary key constraint */
  ContentsPkey = 'contents_pkey'
}

/** input type for inserting data into table "contents" */
export type Contents_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Groups_Contents_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  repositories?: InputMaybe<Repositories_Arr_Rel_Insert_Input>;
  sections?: InputMaybe<Contents_Sections_Arr_Rel_Insert_Input>;
  video_repositories?: InputMaybe<Video_Repositories_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Contents_Max_Fields = {
  __typename?: 'contents_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Contents_Min_Fields = {
  __typename?: 'contents_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "contents" */
export type Contents_Mutation_Response = {
  __typename?: 'contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contents>;
};

/** input type for inserting object relation for remote table "contents" */
export type Contents_Obj_Rel_Insert_Input = {
  data: Contents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};

/** on_conflict condition type for table "contents" */
export type Contents_On_Conflict = {
  constraint: Contents_Constraint;
  update_columns?: Array<Contents_Update_Column>;
  where?: InputMaybe<Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "contents". */
export type Contents_Order_By = {
  description?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Groups_Contents_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  repositories_aggregate?: InputMaybe<Repositories_Aggregate_Order_By>;
  sections_aggregate?: InputMaybe<Contents_Sections_Aggregate_Order_By>;
  video_repositories_aggregate?: InputMaybe<Video_Repositories_Aggregate_Order_By>;
};

/** primary key columns input for table: contents */
export type Contents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "contents_sections" */
export type Contents_Sections = {
  __typename?: 'contents_sections';
  /** An object relationship */
  content: Contents;
  content_id: Scalars['uuid'];
  /** An object relationship */
  section: Sections;
  section_id: Scalars['uuid'];
};

/** aggregated selection of "contents_sections" */
export type Contents_Sections_Aggregate = {
  __typename?: 'contents_sections_aggregate';
  aggregate?: Maybe<Contents_Sections_Aggregate_Fields>;
  nodes: Array<Contents_Sections>;
};

/** aggregate fields of "contents_sections" */
export type Contents_Sections_Aggregate_Fields = {
  __typename?: 'contents_sections_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contents_Sections_Max_Fields>;
  min?: Maybe<Contents_Sections_Min_Fields>;
};


/** aggregate fields of "contents_sections" */
export type Contents_Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contents_sections" */
export type Contents_Sections_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contents_Sections_Max_Order_By>;
  min?: InputMaybe<Contents_Sections_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contents_sections" */
export type Contents_Sections_Arr_Rel_Insert_Input = {
  data: Array<Contents_Sections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contents_Sections_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contents_sections". All fields are combined with a logical 'AND'. */
export type Contents_Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Contents_Sections_Bool_Exp>>;
  _not?: InputMaybe<Contents_Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Contents_Sections_Bool_Exp>>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Uuid_Comparison_Exp>;
  section?: InputMaybe<Sections_Bool_Exp>;
  section_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "contents_sections" */
export enum Contents_Sections_Constraint {
  /** unique or primary key constraint */
  ContentsSectionsPkey = 'contents_sections_pkey'
}

/** input type for inserting data into table "contents_sections" */
export type Contents_Sections_Insert_Input = {
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['uuid']>;
  section?: InputMaybe<Sections_Obj_Rel_Insert_Input>;
  section_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Contents_Sections_Max_Fields = {
  __typename?: 'contents_sections_max_fields';
  content_id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "contents_sections" */
export type Contents_Sections_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contents_Sections_Min_Fields = {
  __typename?: 'contents_sections_min_fields';
  content_id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "contents_sections" */
export type Contents_Sections_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contents_sections" */
export type Contents_Sections_Mutation_Response = {
  __typename?: 'contents_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contents_Sections>;
};

/** on_conflict condition type for table "contents_sections" */
export type Contents_Sections_On_Conflict = {
  constraint: Contents_Sections_Constraint;
  update_columns?: Array<Contents_Sections_Update_Column>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "contents_sections". */
export type Contents_Sections_Order_By = {
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  section?: InputMaybe<Sections_Order_By>;
  section_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contents_sections */
export type Contents_Sections_Pk_Columns_Input = {
  content_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};

/** select columns of table "contents_sections" */
export enum Contents_Sections_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  SectionId = 'section_id'
}

/** input type for updating data in table "contents_sections" */
export type Contents_Sections_Set_Input = {
  content_id?: InputMaybe<Scalars['uuid']>;
  section_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "contents_sections" */
export enum Contents_Sections_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  SectionId = 'section_id'
}

/** select columns of table "contents" */
export enum Contents_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "contents" */
export type Contents_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "contents" */
export enum Contents_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** fields of action: "createUser" */
export type CreateUser = {
  __typename?: 'createUser';
  /** the time at which this action was created */
  created_at: Scalars['timestamptz'];
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']>;
  /** the unique id of an action */
  id: Scalars['uuid'];
  /** the output fields of this action */
  output?: Maybe<CreateUserOutput>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "diagnostics" */
export type Diagnostics = {
  __typename?: 'diagnostics';
  description: Scalars['String'];
  /** An array relationship */
  groups: Array<Diagnostics_Groups>;
  /** An aggregate relationship */
  groups_aggregate: Diagnostics_Groups_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  tabs: Array<Diagnostics_Tabs>;
  /** An aggregate relationship */
  tabs_aggregate: Diagnostics_Tabs_Aggregate;
};


/** columns and relationships of "diagnostics" */
export type DiagnosticsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


/** columns and relationships of "diagnostics" */
export type DiagnosticsGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


/** columns and relationships of "diagnostics" */
export type DiagnosticsTabsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};


/** columns and relationships of "diagnostics" */
export type DiagnosticsTabs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};

/** aggregated selection of "diagnostics" */
export type Diagnostics_Aggregate = {
  __typename?: 'diagnostics_aggregate';
  aggregate?: Maybe<Diagnostics_Aggregate_Fields>;
  nodes: Array<Diagnostics>;
};

/** aggregate fields of "diagnostics" */
export type Diagnostics_Aggregate_Fields = {
  __typename?: 'diagnostics_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Max_Fields>;
  min?: Maybe<Diagnostics_Min_Fields>;
};


/** aggregate fields of "diagnostics" */
export type Diagnostics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "diagnostics". All fields are combined with a logical 'AND'. */
export type Diagnostics_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  groups?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tabs?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};

/** columns and relationships of "diagnostics_comments" */
export type Diagnostics_Comments = {
  __typename?: 'diagnostics_comments';
  comment?: Maybe<Scalars['String']>;
  /** An object relationship */
  diagnostic_tab: Diagnostics_Tabs;
  diagnostic_tab_id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};

/** aggregated selection of "diagnostics_comments" */
export type Diagnostics_Comments_Aggregate = {
  __typename?: 'diagnostics_comments_aggregate';
  aggregate?: Maybe<Diagnostics_Comments_Aggregate_Fields>;
  nodes: Array<Diagnostics_Comments>;
};

/** aggregate fields of "diagnostics_comments" */
export type Diagnostics_Comments_Aggregate_Fields = {
  __typename?: 'diagnostics_comments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Comments_Max_Fields>;
  min?: Maybe<Diagnostics_Comments_Min_Fields>;
};


/** aggregate fields of "diagnostics_comments" */
export type Diagnostics_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_comments" */
export type Diagnostics_Comments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Comments_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_comments" */
export type Diagnostics_Comments_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "diagnostics_comments". All fields are combined with a logical 'AND'. */
export type Diagnostics_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Comments_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Comments_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  diagnostic_tab?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
  diagnostic_tab_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_comments" */
export enum Diagnostics_Comments_Constraint {
  /** unique or primary key constraint */
  DiagnosticsCommentsPkey = 'diagnostics_comments_pkey'
}

/** input type for inserting data into table "diagnostics_comments" */
export type Diagnostics_Comments_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  diagnostic_tab?: InputMaybe<Diagnostics_Tabs_Obj_Rel_Insert_Input>;
  diagnostic_tab_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Diagnostics_Comments_Max_Fields = {
  __typename?: 'diagnostics_comments_max_fields';
  comment?: Maybe<Scalars['String']>;
  diagnostic_tab_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "diagnostics_comments" */
export type Diagnostics_Comments_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  diagnostic_tab_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Comments_Min_Fields = {
  __typename?: 'diagnostics_comments_min_fields';
  comment?: Maybe<Scalars['String']>;
  diagnostic_tab_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "diagnostics_comments" */
export type Diagnostics_Comments_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  diagnostic_tab_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_comments" */
export type Diagnostics_Comments_Mutation_Response = {
  __typename?: 'diagnostics_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Comments>;
};

/** on_conflict condition type for table "diagnostics_comments" */
export type Diagnostics_Comments_On_Conflict = {
  constraint: Diagnostics_Comments_Constraint;
  update_columns?: Array<Diagnostics_Comments_Update_Column>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_comments". */
export type Diagnostics_Comments_Order_By = {
  comment?: InputMaybe<Order_By>;
  diagnostic_tab?: InputMaybe<Diagnostics_Tabs_Order_By>;
  diagnostic_tab_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_comments */
export type Diagnostics_Comments_Pk_Columns_Input = {
  diagnostic_tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};

/** select columns of table "diagnostics_comments" */
export enum Diagnostics_Comments_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  DiagnosticTabId = 'diagnostic_tab_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "diagnostics_comments" */
export type Diagnostics_Comments_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  diagnostic_tab_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "diagnostics_comments" */
export enum Diagnostics_Comments_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  DiagnosticTabId = 'diagnostic_tab_id',
  /** column name */
  TeamId = 'team_id'
}

/** unique or primary key constraints on table "diagnostics" */
export enum Diagnostics_Constraint {
  /** unique or primary key constraint */
  DiagnosticsPkey = 'diagnostics_pkey'
}

/** columns and relationships of "diagnostics_groups" */
export type Diagnostics_Groups = {
  __typename?: 'diagnostics_groups';
  active: Scalars['Boolean'];
  /** An object relationship */
  diagnostic: Diagnostics;
  diagnostic_id: Scalars['uuid'];
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid'];
};

/** aggregated selection of "diagnostics_groups" */
export type Diagnostics_Groups_Aggregate = {
  __typename?: 'diagnostics_groups_aggregate';
  aggregate?: Maybe<Diagnostics_Groups_Aggregate_Fields>;
  nodes: Array<Diagnostics_Groups>;
};

/** aggregate fields of "diagnostics_groups" */
export type Diagnostics_Groups_Aggregate_Fields = {
  __typename?: 'diagnostics_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Groups_Max_Fields>;
  min?: Maybe<Diagnostics_Groups_Min_Fields>;
};


/** aggregate fields of "diagnostics_groups" */
export type Diagnostics_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_groups" */
export type Diagnostics_Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Groups_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_groups" */
export type Diagnostics_Groups_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "diagnostics_groups". All fields are combined with a logical 'AND'. */
export type Diagnostics_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Groups_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Groups_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  diagnostic?: InputMaybe<Diagnostics_Bool_Exp>;
  diagnostic_id?: InputMaybe<Uuid_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_groups" */
export enum Diagnostics_Groups_Constraint {
  /** unique or primary key constraint */
  DiagnosticsGroupsGroupIdActiveIdx = 'diagnostics_groups_group_id_active_idx',
  /** unique or primary key constraint */
  DiagnosticsGroupsPkey = 'diagnostics_groups_pkey'
}

/** input type for inserting data into table "diagnostics_groups" */
export type Diagnostics_Groups_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  diagnostic?: InputMaybe<Diagnostics_Obj_Rel_Insert_Input>;
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Diagnostics_Groups_Max_Fields = {
  __typename?: 'diagnostics_groups_max_fields';
  diagnostic_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "diagnostics_groups" */
export type Diagnostics_Groups_Max_Order_By = {
  diagnostic_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Groups_Min_Fields = {
  __typename?: 'diagnostics_groups_min_fields';
  diagnostic_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "diagnostics_groups" */
export type Diagnostics_Groups_Min_Order_By = {
  diagnostic_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_groups" */
export type Diagnostics_Groups_Mutation_Response = {
  __typename?: 'diagnostics_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Groups>;
};

/** on_conflict condition type for table "diagnostics_groups" */
export type Diagnostics_Groups_On_Conflict = {
  constraint: Diagnostics_Groups_Constraint;
  update_columns?: Array<Diagnostics_Groups_Update_Column>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_groups". */
export type Diagnostics_Groups_Order_By = {
  active?: InputMaybe<Order_By>;
  diagnostic?: InputMaybe<Diagnostics_Order_By>;
  diagnostic_id?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_groups */
export type Diagnostics_Groups_Pk_Columns_Input = {
  diagnostic_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};

/** select columns of table "diagnostics_groups" */
export enum Diagnostics_Groups_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  DiagnosticId = 'diagnostic_id',
  /** column name */
  GroupId = 'group_id'
}

/** input type for updating data in table "diagnostics_groups" */
export type Diagnostics_Groups_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "diagnostics_groups" */
export enum Diagnostics_Groups_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  DiagnosticId = 'diagnostic_id',
  /** column name */
  GroupId = 'group_id'
}

/** input type for inserting data into table "diagnostics" */
export type Diagnostics_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Diagnostics_Groups_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  tabs?: InputMaybe<Diagnostics_Tabs_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Diagnostics_Max_Fields = {
  __typename?: 'diagnostics_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Diagnostics_Min_Fields = {
  __typename?: 'diagnostics_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "diagnostics" */
export type Diagnostics_Mutation_Response = {
  __typename?: 'diagnostics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics>;
};

/** input type for inserting object relation for remote table "diagnostics" */
export type Diagnostics_Obj_Rel_Insert_Input = {
  data: Diagnostics_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_On_Conflict>;
};

/** on_conflict condition type for table "diagnostics" */
export type Diagnostics_On_Conflict = {
  constraint: Diagnostics_Constraint;
  update_columns?: Array<Diagnostics_Update_Column>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics". */
export type Diagnostics_Order_By = {
  description?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Diagnostics_Groups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  tabs_aggregate?: InputMaybe<Diagnostics_Tabs_Aggregate_Order_By>;
};

/** primary key columns input for table: diagnostics */
export type Diagnostics_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "diagnostics_questions" */
export type Diagnostics_Questions = {
  __typename?: 'diagnostics_questions';
  /** An array relationship */
  answers: Array<Diagnostics_Questions_Answers>;
  /** An aggregate relationship */
  answers_aggregate: Diagnostics_Questions_Answers_Aggregate;
  id: Scalars['uuid'];
  order: Scalars['smallint'];
  question: Scalars['String'];
  /** An object relationship */
  subsection: Diagnostics_Subsections;
  subsection_id: Scalars['uuid'];
};


/** columns and relationships of "diagnostics_questions" */
export type Diagnostics_QuestionsAnswersArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "diagnostics_questions" */
export type Diagnostics_QuestionsAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};

/** aggregated selection of "diagnostics_questions" */
export type Diagnostics_Questions_Aggregate = {
  __typename?: 'diagnostics_questions_aggregate';
  aggregate?: Maybe<Diagnostics_Questions_Aggregate_Fields>;
  nodes: Array<Diagnostics_Questions>;
};

/** aggregate fields of "diagnostics_questions" */
export type Diagnostics_Questions_Aggregate_Fields = {
  __typename?: 'diagnostics_questions_aggregate_fields';
  avg?: Maybe<Diagnostics_Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Questions_Max_Fields>;
  min?: Maybe<Diagnostics_Questions_Min_Fields>;
  stddev?: Maybe<Diagnostics_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Diagnostics_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Diagnostics_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Diagnostics_Questions_Sum_Fields>;
  var_pop?: Maybe<Diagnostics_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Diagnostics_Questions_Var_Samp_Fields>;
  variance?: Maybe<Diagnostics_Questions_Variance_Fields>;
};


/** aggregate fields of "diagnostics_questions" */
export type Diagnostics_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_questions" */
export type Diagnostics_Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Diagnostics_Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Questions_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Questions_Min_Order_By>;
  stddev?: InputMaybe<Diagnostics_Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Diagnostics_Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Diagnostics_Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Diagnostics_Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Diagnostics_Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Diagnostics_Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Diagnostics_Questions_Variance_Order_By>;
};

/** columns and relationships of "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers = {
  __typename?: 'diagnostics_questions_answers';
  answer: Scalars['smallint'];
  id: Scalars['uuid'];
  /** An object relationship */
  question: Diagnostics_Questions;
  question_id: Scalars['uuid'];
  /** An object relationship */
  tab: Diagnostics_Tabs;
  tab_id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};

/** aggregated selection of "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Aggregate = {
  __typename?: 'diagnostics_questions_answers_aggregate';
  aggregate?: Maybe<Diagnostics_Questions_Answers_Aggregate_Fields>;
  nodes: Array<Diagnostics_Questions_Answers>;
};

/** aggregate fields of "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Aggregate_Fields = {
  __typename?: 'diagnostics_questions_answers_aggregate_fields';
  avg?: Maybe<Diagnostics_Questions_Answers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Questions_Answers_Max_Fields>;
  min?: Maybe<Diagnostics_Questions_Answers_Min_Fields>;
  stddev?: Maybe<Diagnostics_Questions_Answers_Stddev_Fields>;
  stddev_pop?: Maybe<Diagnostics_Questions_Answers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Diagnostics_Questions_Answers_Stddev_Samp_Fields>;
  sum?: Maybe<Diagnostics_Questions_Answers_Sum_Fields>;
  var_pop?: Maybe<Diagnostics_Questions_Answers_Var_Pop_Fields>;
  var_samp?: Maybe<Diagnostics_Questions_Answers_Var_Samp_Fields>;
  variance?: Maybe<Diagnostics_Questions_Answers_Variance_Fields>;
};


/** aggregate fields of "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Aggregate_Order_By = {
  avg?: InputMaybe<Diagnostics_Questions_Answers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Questions_Answers_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Questions_Answers_Min_Order_By>;
  stddev?: InputMaybe<Diagnostics_Questions_Answers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Diagnostics_Questions_Answers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Diagnostics_Questions_Answers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Diagnostics_Questions_Answers_Sum_Order_By>;
  var_pop?: InputMaybe<Diagnostics_Questions_Answers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Diagnostics_Questions_Answers_Var_Samp_Order_By>;
  variance?: InputMaybe<Diagnostics_Questions_Answers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Questions_Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Questions_Answers_On_Conflict>;
};

/** aggregate avg on columns */
export type Diagnostics_Questions_Answers_Avg_Fields = {
  __typename?: 'diagnostics_questions_answers_avg_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Avg_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "diagnostics_questions_answers". All fields are combined with a logical 'AND'. */
export type Diagnostics_Questions_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Questions_Answers_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Questions_Answers_Bool_Exp>>;
  answer?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  tab?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
  tab_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_questions_answers" */
export enum Diagnostics_Questions_Answers_Constraint {
  /** unique or primary key constraint */
  DiagnosticsQuestionsAnswersGroupIdQuestionIdTabIdKey = 'diagnostics_questions_answers_group_id_question_id_tab_id_key',
  /** unique or primary key constraint */
  DiagnosticsQuestionsAnswersPkey = 'diagnostics_questions_answers_pkey'
}

/** input type for incrementing numeric columns in table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Inc_Input = {
  answer?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Insert_Input = {
  answer?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Diagnostics_Questions_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['uuid']>;
  tab?: InputMaybe<Diagnostics_Tabs_Obj_Rel_Insert_Input>;
  tab_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Diagnostics_Questions_Answers_Max_Fields = {
  __typename?: 'diagnostics_questions_answers_max_fields';
  answer?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  tab_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Max_Order_By = {
  answer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  tab_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Questions_Answers_Min_Fields = {
  __typename?: 'diagnostics_questions_answers_min_fields';
  answer?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  tab_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Min_Order_By = {
  answer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  tab_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Mutation_Response = {
  __typename?: 'diagnostics_questions_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Questions_Answers>;
};

/** on_conflict condition type for table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_On_Conflict = {
  constraint: Diagnostics_Questions_Answers_Constraint;
  update_columns?: Array<Diagnostics_Questions_Answers_Update_Column>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_questions_answers". */
export type Diagnostics_Questions_Answers_Order_By = {
  answer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Diagnostics_Questions_Order_By>;
  question_id?: InputMaybe<Order_By>;
  tab?: InputMaybe<Diagnostics_Tabs_Order_By>;
  tab_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_questions_answers */
export type Diagnostics_Questions_Answers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "diagnostics_questions_answers" */
export enum Diagnostics_Questions_Answers_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  TabId = 'tab_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Set_Input = {
  answer?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  tab_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Diagnostics_Questions_Answers_Stddev_Fields = {
  __typename?: 'diagnostics_questions_answers_stddev_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Stddev_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Diagnostics_Questions_Answers_Stddev_Pop_Fields = {
  __typename?: 'diagnostics_questions_answers_stddev_pop_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Stddev_Pop_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Diagnostics_Questions_Answers_Stddev_Samp_Fields = {
  __typename?: 'diagnostics_questions_answers_stddev_samp_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Stddev_Samp_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Diagnostics_Questions_Answers_Sum_Fields = {
  __typename?: 'diagnostics_questions_answers_sum_fields';
  answer?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Sum_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics_questions_answers" */
export enum Diagnostics_Questions_Answers_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  TabId = 'tab_id',
  /** column name */
  TeamId = 'team_id'
}

/** aggregate var_pop on columns */
export type Diagnostics_Questions_Answers_Var_Pop_Fields = {
  __typename?: 'diagnostics_questions_answers_var_pop_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Var_Pop_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Diagnostics_Questions_Answers_Var_Samp_Fields = {
  __typename?: 'diagnostics_questions_answers_var_samp_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Var_Samp_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Diagnostics_Questions_Answers_Variance_Fields = {
  __typename?: 'diagnostics_questions_answers_variance_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Variance_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_questions" */
export type Diagnostics_Questions_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Diagnostics_Questions_Avg_Fields = {
  __typename?: 'diagnostics_questions_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "diagnostics_questions". All fields are combined with a logical 'AND'. */
export type Diagnostics_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Questions_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Questions_Bool_Exp>>;
  answers?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  subsection?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
  subsection_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_questions" */
export enum Diagnostics_Questions_Constraint {
  /** unique or primary key constraint */
  DiagnosticsQuestionsPkey = 'diagnostics_questions_pkey',
  /** unique or primary key constraint */
  DiagnosticsQuestionsSubsectionIdOrderKey = 'diagnostics_questions_subsection_id_order_key'
}

/** input type for incrementing numeric columns in table "diagnostics_questions" */
export type Diagnostics_Questions_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "diagnostics_questions" */
export type Diagnostics_Questions_Insert_Input = {
  answers?: InputMaybe<Diagnostics_Questions_Answers_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  question?: InputMaybe<Scalars['String']>;
  subsection?: InputMaybe<Diagnostics_Subsections_Obj_Rel_Insert_Input>;
  subsection_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Diagnostics_Questions_Max_Fields = {
  __typename?: 'diagnostics_questions_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  question?: Maybe<Scalars['String']>;
  subsection_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  subsection_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Questions_Min_Fields = {
  __typename?: 'diagnostics_questions_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  question?: Maybe<Scalars['String']>;
  subsection_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  subsection_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_questions" */
export type Diagnostics_Questions_Mutation_Response = {
  __typename?: 'diagnostics_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Questions>;
};

/** input type for inserting object relation for remote table "diagnostics_questions" */
export type Diagnostics_Questions_Obj_Rel_Insert_Input = {
  data: Diagnostics_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Questions_On_Conflict>;
};

/** on_conflict condition type for table "diagnostics_questions" */
export type Diagnostics_Questions_On_Conflict = {
  constraint: Diagnostics_Questions_Constraint;
  update_columns?: Array<Diagnostics_Questions_Update_Column>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_questions". */
export type Diagnostics_Questions_Order_By = {
  answers_aggregate?: InputMaybe<Diagnostics_Questions_Answers_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  subsection?: InputMaybe<Diagnostics_Subsections_Order_By>;
  subsection_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_questions */
export type Diagnostics_Questions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "diagnostics_questions" */
export enum Diagnostics_Questions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Question = 'question',
  /** column name */
  SubsectionId = 'subsection_id'
}

/** input type for updating data in table "diagnostics_questions" */
export type Diagnostics_Questions_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  question?: InputMaybe<Scalars['String']>;
  subsection_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Diagnostics_Questions_Stddev_Fields = {
  __typename?: 'diagnostics_questions_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Diagnostics_Questions_Stddev_Pop_Fields = {
  __typename?: 'diagnostics_questions_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Diagnostics_Questions_Stddev_Samp_Fields = {
  __typename?: 'diagnostics_questions_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Diagnostics_Questions_Sum_Fields = {
  __typename?: 'diagnostics_questions_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics_questions" */
export enum Diagnostics_Questions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Question = 'question',
  /** column name */
  SubsectionId = 'subsection_id'
}

/** aggregate var_pop on columns */
export type Diagnostics_Questions_Var_Pop_Fields = {
  __typename?: 'diagnostics_questions_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Diagnostics_Questions_Var_Samp_Fields = {
  __typename?: 'diagnostics_questions_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Diagnostics_Questions_Variance_Fields = {
  __typename?: 'diagnostics_questions_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "diagnostics_sections" */
export type Diagnostics_Sections = {
  __typename?: 'diagnostics_sections';
  id: Scalars['uuid'];
  /** An array relationship */
  subsections: Array<Diagnostics_Subsections>;
  /** An aggregate relationship */
  subsections_aggregate: Diagnostics_Subsections_Aggregate;
  /** An array relationship */
  tabs: Array<Diagnostics_Tabs_Sections>;
  /** An aggregate relationship */
  tabs_aggregate: Diagnostics_Tabs_Sections_Aggregate;
  title: Scalars['String'];
};


/** columns and relationships of "diagnostics_sections" */
export type Diagnostics_SectionsSubsectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


/** columns and relationships of "diagnostics_sections" */
export type Diagnostics_SectionsSubsections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


/** columns and relationships of "diagnostics_sections" */
export type Diagnostics_SectionsTabsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


/** columns and relationships of "diagnostics_sections" */
export type Diagnostics_SectionsTabs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};

/** aggregated selection of "diagnostics_sections" */
export type Diagnostics_Sections_Aggregate = {
  __typename?: 'diagnostics_sections_aggregate';
  aggregate?: Maybe<Diagnostics_Sections_Aggregate_Fields>;
  nodes: Array<Diagnostics_Sections>;
};

/** aggregate fields of "diagnostics_sections" */
export type Diagnostics_Sections_Aggregate_Fields = {
  __typename?: 'diagnostics_sections_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Sections_Max_Fields>;
  min?: Maybe<Diagnostics_Sections_Min_Fields>;
};


/** aggregate fields of "diagnostics_sections" */
export type Diagnostics_Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "diagnostics_sections". All fields are combined with a logical 'AND'. */
export type Diagnostics_Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Sections_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Sections_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  subsections?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
  tabs?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_sections" */
export enum Diagnostics_Sections_Constraint {
  /** unique or primary key constraint */
  DiagnosticsSectionsPkey = 'diagnostics_sections_pkey'
}

/** input type for inserting data into table "diagnostics_sections" */
export type Diagnostics_Sections_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  subsections?: InputMaybe<Diagnostics_Subsections_Arr_Rel_Insert_Input>;
  tabs?: InputMaybe<Diagnostics_Tabs_Sections_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Diagnostics_Sections_Max_Fields = {
  __typename?: 'diagnostics_sections_max_fields';
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Diagnostics_Sections_Min_Fields = {
  __typename?: 'diagnostics_sections_min_fields';
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "diagnostics_sections" */
export type Diagnostics_Sections_Mutation_Response = {
  __typename?: 'diagnostics_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Sections>;
};

/** input type for inserting object relation for remote table "diagnostics_sections" */
export type Diagnostics_Sections_Obj_Rel_Insert_Input = {
  data: Diagnostics_Sections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Sections_On_Conflict>;
};

/** on_conflict condition type for table "diagnostics_sections" */
export type Diagnostics_Sections_On_Conflict = {
  constraint: Diagnostics_Sections_Constraint;
  update_columns?: Array<Diagnostics_Sections_Update_Column>;
  where?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_sections". */
export type Diagnostics_Sections_Order_By = {
  id?: InputMaybe<Order_By>;
  subsections_aggregate?: InputMaybe<Diagnostics_Subsections_Aggregate_Order_By>;
  tabs_aggregate?: InputMaybe<Diagnostics_Tabs_Sections_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_sections */
export type Diagnostics_Sections_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "diagnostics_sections" */
export enum Diagnostics_Sections_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "diagnostics_sections" */
export type Diagnostics_Sections_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** update columns of table "diagnostics_sections" */
export enum Diagnostics_Sections_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** select columns of table "diagnostics" */
export enum Diagnostics_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "diagnostics" */
export type Diagnostics_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "diagnostics_subsections" */
export type Diagnostics_Subsections = {
  __typename?: 'diagnostics_subsections';
  id: Scalars['uuid'];
  order: Scalars['smallint'];
  /** An array relationship */
  questions: Array<Diagnostics_Questions>;
  /** An aggregate relationship */
  questions_aggregate: Diagnostics_Questions_Aggregate;
  /** An object relationship */
  section: Diagnostics_Sections;
  section_id: Scalars['uuid'];
  title: Scalars['String'];
};


/** columns and relationships of "diagnostics_subsections" */
export type Diagnostics_SubsectionsQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};


/** columns and relationships of "diagnostics_subsections" */
export type Diagnostics_SubsectionsQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};

/** aggregated selection of "diagnostics_subsections" */
export type Diagnostics_Subsections_Aggregate = {
  __typename?: 'diagnostics_subsections_aggregate';
  aggregate?: Maybe<Diagnostics_Subsections_Aggregate_Fields>;
  nodes: Array<Diagnostics_Subsections>;
};

/** aggregate fields of "diagnostics_subsections" */
export type Diagnostics_Subsections_Aggregate_Fields = {
  __typename?: 'diagnostics_subsections_aggregate_fields';
  avg?: Maybe<Diagnostics_Subsections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Subsections_Max_Fields>;
  min?: Maybe<Diagnostics_Subsections_Min_Fields>;
  stddev?: Maybe<Diagnostics_Subsections_Stddev_Fields>;
  stddev_pop?: Maybe<Diagnostics_Subsections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Diagnostics_Subsections_Stddev_Samp_Fields>;
  sum?: Maybe<Diagnostics_Subsections_Sum_Fields>;
  var_pop?: Maybe<Diagnostics_Subsections_Var_Pop_Fields>;
  var_samp?: Maybe<Diagnostics_Subsections_Var_Samp_Fields>;
  variance?: Maybe<Diagnostics_Subsections_Variance_Fields>;
};


/** aggregate fields of "diagnostics_subsections" */
export type Diagnostics_Subsections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Aggregate_Order_By = {
  avg?: InputMaybe<Diagnostics_Subsections_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Subsections_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Subsections_Min_Order_By>;
  stddev?: InputMaybe<Diagnostics_Subsections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Diagnostics_Subsections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Diagnostics_Subsections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Diagnostics_Subsections_Sum_Order_By>;
  var_pop?: InputMaybe<Diagnostics_Subsections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Diagnostics_Subsections_Var_Samp_Order_By>;
  variance?: InputMaybe<Diagnostics_Subsections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_subsections" */
export type Diagnostics_Subsections_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Subsections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Subsections_On_Conflict>;
};

/** aggregate avg on columns */
export type Diagnostics_Subsections_Avg_Fields = {
  __typename?: 'diagnostics_subsections_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "diagnostics_subsections". All fields are combined with a logical 'AND'. */
export type Diagnostics_Subsections_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Subsections_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Subsections_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  questions?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
  section?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
  section_id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_subsections" */
export enum Diagnostics_Subsections_Constraint {
  /** unique or primary key constraint */
  DiagnosticsSubsectionsPkey = 'diagnostics_subsections_pkey',
  /** unique or primary key constraint */
  DiagnosticsSubsectionsSectionIdOrderKey = 'diagnostics_subsections_section_id_order_key'
}

/** input type for incrementing numeric columns in table "diagnostics_subsections" */
export type Diagnostics_Subsections_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "diagnostics_subsections" */
export type Diagnostics_Subsections_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  questions?: InputMaybe<Diagnostics_Questions_Arr_Rel_Insert_Input>;
  section?: InputMaybe<Diagnostics_Sections_Obj_Rel_Insert_Input>;
  section_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Diagnostics_Subsections_Max_Fields = {
  __typename?: 'diagnostics_subsections_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Subsections_Min_Fields = {
  __typename?: 'diagnostics_subsections_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_subsections" */
export type Diagnostics_Subsections_Mutation_Response = {
  __typename?: 'diagnostics_subsections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Subsections>;
};

/** input type for inserting object relation for remote table "diagnostics_subsections" */
export type Diagnostics_Subsections_Obj_Rel_Insert_Input = {
  data: Diagnostics_Subsections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Subsections_On_Conflict>;
};

/** on_conflict condition type for table "diagnostics_subsections" */
export type Diagnostics_Subsections_On_Conflict = {
  constraint: Diagnostics_Subsections_Constraint;
  update_columns?: Array<Diagnostics_Subsections_Update_Column>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_subsections". */
export type Diagnostics_Subsections_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questions_aggregate?: InputMaybe<Diagnostics_Questions_Aggregate_Order_By>;
  section?: InputMaybe<Diagnostics_Sections_Order_By>;
  section_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_subsections */
export type Diagnostics_Subsections_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "diagnostics_subsections" */
export enum Diagnostics_Subsections_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "diagnostics_subsections" */
export type Diagnostics_Subsections_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  section_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Diagnostics_Subsections_Stddev_Fields = {
  __typename?: 'diagnostics_subsections_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Diagnostics_Subsections_Stddev_Pop_Fields = {
  __typename?: 'diagnostics_subsections_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Diagnostics_Subsections_Stddev_Samp_Fields = {
  __typename?: 'diagnostics_subsections_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Diagnostics_Subsections_Sum_Fields = {
  __typename?: 'diagnostics_subsections_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics_subsections" */
export enum Diagnostics_Subsections_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Diagnostics_Subsections_Var_Pop_Fields = {
  __typename?: 'diagnostics_subsections_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Diagnostics_Subsections_Var_Samp_Fields = {
  __typename?: 'diagnostics_subsections_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Diagnostics_Subsections_Variance_Fields = {
  __typename?: 'diagnostics_subsections_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "diagnostics_tabs" */
export type Diagnostics_Tabs = {
  __typename?: 'diagnostics_tabs';
  /** An array relationship */
  comments: Array<Diagnostics_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Diagnostics_Comments_Aggregate;
  /** An object relationship */
  diagnostic: Diagnostics;
  diagnostic_id: Scalars['uuid'];
  id: Scalars['uuid'];
  order: Scalars['smallint'];
  /** An array relationship */
  sections: Array<Diagnostics_Tabs_Sections>;
  /** An aggregate relationship */
  sections_aggregate: Diagnostics_Tabs_Sections_Aggregate;
  title: Scalars['String'];
};


/** columns and relationships of "diagnostics_tabs" */
export type Diagnostics_TabsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


/** columns and relationships of "diagnostics_tabs" */
export type Diagnostics_TabsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


/** columns and relationships of "diagnostics_tabs" */
export type Diagnostics_TabsSectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


/** columns and relationships of "diagnostics_tabs" */
export type Diagnostics_TabsSections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};

/** aggregated selection of "diagnostics_tabs" */
export type Diagnostics_Tabs_Aggregate = {
  __typename?: 'diagnostics_tabs_aggregate';
  aggregate?: Maybe<Diagnostics_Tabs_Aggregate_Fields>;
  nodes: Array<Diagnostics_Tabs>;
};

/** aggregate fields of "diagnostics_tabs" */
export type Diagnostics_Tabs_Aggregate_Fields = {
  __typename?: 'diagnostics_tabs_aggregate_fields';
  avg?: Maybe<Diagnostics_Tabs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Tabs_Max_Fields>;
  min?: Maybe<Diagnostics_Tabs_Min_Fields>;
  stddev?: Maybe<Diagnostics_Tabs_Stddev_Fields>;
  stddev_pop?: Maybe<Diagnostics_Tabs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Diagnostics_Tabs_Stddev_Samp_Fields>;
  sum?: Maybe<Diagnostics_Tabs_Sum_Fields>;
  var_pop?: Maybe<Diagnostics_Tabs_Var_Pop_Fields>;
  var_samp?: Maybe<Diagnostics_Tabs_Var_Samp_Fields>;
  variance?: Maybe<Diagnostics_Tabs_Variance_Fields>;
};


/** aggregate fields of "diagnostics_tabs" */
export type Diagnostics_Tabs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Aggregate_Order_By = {
  avg?: InputMaybe<Diagnostics_Tabs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Tabs_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Tabs_Min_Order_By>;
  stddev?: InputMaybe<Diagnostics_Tabs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Diagnostics_Tabs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Diagnostics_Tabs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Diagnostics_Tabs_Sum_Order_By>;
  var_pop?: InputMaybe<Diagnostics_Tabs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Diagnostics_Tabs_Var_Samp_Order_By>;
  variance?: InputMaybe<Diagnostics_Tabs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_tabs" */
export type Diagnostics_Tabs_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Tabs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Tabs_On_Conflict>;
};

/** aggregate avg on columns */
export type Diagnostics_Tabs_Avg_Fields = {
  __typename?: 'diagnostics_tabs_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "diagnostics_tabs". All fields are combined with a logical 'AND'. */
export type Diagnostics_Tabs_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Tabs_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Tabs_Bool_Exp>>;
  comments?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
  diagnostic?: InputMaybe<Diagnostics_Bool_Exp>;
  diagnostic_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  sections?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_tabs" */
export enum Diagnostics_Tabs_Constraint {
  /** unique or primary key constraint */
  DiagnosticsTabsOrderDiagnosticIdKey = 'diagnostics_tabs_order_diagnostic_id_key',
  /** unique or primary key constraint */
  DiagnosticsTabsPkey = 'diagnostics_tabs_pkey'
}

/** input type for incrementing numeric columns in table "diagnostics_tabs" */
export type Diagnostics_Tabs_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "diagnostics_tabs" */
export type Diagnostics_Tabs_Insert_Input = {
  comments?: InputMaybe<Diagnostics_Comments_Arr_Rel_Insert_Input>;
  diagnostic?: InputMaybe<Diagnostics_Obj_Rel_Insert_Input>;
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  sections?: InputMaybe<Diagnostics_Tabs_Sections_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Diagnostics_Tabs_Max_Fields = {
  __typename?: 'diagnostics_tabs_max_fields';
  diagnostic_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Max_Order_By = {
  diagnostic_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Tabs_Min_Fields = {
  __typename?: 'diagnostics_tabs_min_fields';
  diagnostic_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Min_Order_By = {
  diagnostic_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_tabs" */
export type Diagnostics_Tabs_Mutation_Response = {
  __typename?: 'diagnostics_tabs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Tabs>;
};

/** input type for inserting object relation for remote table "diagnostics_tabs" */
export type Diagnostics_Tabs_Obj_Rel_Insert_Input = {
  data: Diagnostics_Tabs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Tabs_On_Conflict>;
};

/** on_conflict condition type for table "diagnostics_tabs" */
export type Diagnostics_Tabs_On_Conflict = {
  constraint: Diagnostics_Tabs_Constraint;
  update_columns?: Array<Diagnostics_Tabs_Update_Column>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_tabs". */
export type Diagnostics_Tabs_Order_By = {
  comments_aggregate?: InputMaybe<Diagnostics_Comments_Aggregate_Order_By>;
  diagnostic?: InputMaybe<Diagnostics_Order_By>;
  diagnostic_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  sections_aggregate?: InputMaybe<Diagnostics_Tabs_Sections_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_tabs */
export type Diagnostics_Tabs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections = {
  __typename?: 'diagnostics_tabs_sections';
  id: Scalars['uuid'];
  order: Scalars['smallint'];
  /** An object relationship */
  section: Diagnostics_Sections;
  section_id: Scalars['uuid'];
  /** An object relationship */
  tab: Diagnostics_Tabs;
  tab_id: Scalars['uuid'];
};

/** aggregated selection of "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Aggregate = {
  __typename?: 'diagnostics_tabs_sections_aggregate';
  aggregate?: Maybe<Diagnostics_Tabs_Sections_Aggregate_Fields>;
  nodes: Array<Diagnostics_Tabs_Sections>;
};

/** aggregate fields of "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Aggregate_Fields = {
  __typename?: 'diagnostics_tabs_sections_aggregate_fields';
  avg?: Maybe<Diagnostics_Tabs_Sections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Tabs_Sections_Max_Fields>;
  min?: Maybe<Diagnostics_Tabs_Sections_Min_Fields>;
  stddev?: Maybe<Diagnostics_Tabs_Sections_Stddev_Fields>;
  stddev_pop?: Maybe<Diagnostics_Tabs_Sections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Diagnostics_Tabs_Sections_Stddev_Samp_Fields>;
  sum?: Maybe<Diagnostics_Tabs_Sections_Sum_Fields>;
  var_pop?: Maybe<Diagnostics_Tabs_Sections_Var_Pop_Fields>;
  var_samp?: Maybe<Diagnostics_Tabs_Sections_Var_Samp_Fields>;
  variance?: Maybe<Diagnostics_Tabs_Sections_Variance_Fields>;
};


/** aggregate fields of "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Aggregate_Order_By = {
  avg?: InputMaybe<Diagnostics_Tabs_Sections_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Tabs_Sections_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Tabs_Sections_Min_Order_By>;
  stddev?: InputMaybe<Diagnostics_Tabs_Sections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Diagnostics_Tabs_Sections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Diagnostics_Tabs_Sections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Diagnostics_Tabs_Sections_Sum_Order_By>;
  var_pop?: InputMaybe<Diagnostics_Tabs_Sections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Diagnostics_Tabs_Sections_Var_Samp_Order_By>;
  variance?: InputMaybe<Diagnostics_Tabs_Sections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Tabs_Sections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Tabs_Sections_On_Conflict>;
};

/** aggregate avg on columns */
export type Diagnostics_Tabs_Sections_Avg_Fields = {
  __typename?: 'diagnostics_tabs_sections_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "diagnostics_tabs_sections". All fields are combined with a logical 'AND'. */
export type Diagnostics_Tabs_Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Tabs_Sections_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Tabs_Sections_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  section?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
  section_id?: InputMaybe<Uuid_Comparison_Exp>;
  tab?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
  tab_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_tabs_sections" */
export enum Diagnostics_Tabs_Sections_Constraint {
  /** unique or primary key constraint */
  DiagnosticsTabsSectionsPkey = 'diagnostics_tabs_sections_pkey',
  /** unique or primary key constraint */
  DiagnosticsTabsSectionsTabIdSectionIdKey = 'diagnostics_tabs_sections_tab_id_section_id_key',
  /** unique or primary key constraint */
  DiagnosticsTabsSectionsTabIdSectionIdOrderKey = 'diagnostics_tabs_sections_tab_id_section_id_order_key'
}

/** input type for incrementing numeric columns in table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  section?: InputMaybe<Diagnostics_Sections_Obj_Rel_Insert_Input>;
  section_id?: InputMaybe<Scalars['uuid']>;
  tab?: InputMaybe<Diagnostics_Tabs_Obj_Rel_Insert_Input>;
  tab_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Diagnostics_Tabs_Sections_Max_Fields = {
  __typename?: 'diagnostics_tabs_sections_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
  tab_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
  tab_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Tabs_Sections_Min_Fields = {
  __typename?: 'diagnostics_tabs_sections_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
  tab_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
  tab_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Mutation_Response = {
  __typename?: 'diagnostics_tabs_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Tabs_Sections>;
};

/** on_conflict condition type for table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_On_Conflict = {
  constraint: Diagnostics_Tabs_Sections_Constraint;
  update_columns?: Array<Diagnostics_Tabs_Sections_Update_Column>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_tabs_sections". */
export type Diagnostics_Tabs_Sections_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  section?: InputMaybe<Diagnostics_Sections_Order_By>;
  section_id?: InputMaybe<Order_By>;
  tab?: InputMaybe<Diagnostics_Tabs_Order_By>;
  tab_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_tabs_sections */
export type Diagnostics_Tabs_Sections_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "diagnostics_tabs_sections" */
export enum Diagnostics_Tabs_Sections_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  TabId = 'tab_id'
}

/** input type for updating data in table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  section_id?: InputMaybe<Scalars['uuid']>;
  tab_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Diagnostics_Tabs_Sections_Stddev_Fields = {
  __typename?: 'diagnostics_tabs_sections_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Diagnostics_Tabs_Sections_Stddev_Pop_Fields = {
  __typename?: 'diagnostics_tabs_sections_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Diagnostics_Tabs_Sections_Stddev_Samp_Fields = {
  __typename?: 'diagnostics_tabs_sections_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Diagnostics_Tabs_Sections_Sum_Fields = {
  __typename?: 'diagnostics_tabs_sections_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics_tabs_sections" */
export enum Diagnostics_Tabs_Sections_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  TabId = 'tab_id'
}

/** aggregate var_pop on columns */
export type Diagnostics_Tabs_Sections_Var_Pop_Fields = {
  __typename?: 'diagnostics_tabs_sections_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Diagnostics_Tabs_Sections_Var_Samp_Fields = {
  __typename?: 'diagnostics_tabs_sections_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Diagnostics_Tabs_Sections_Variance_Fields = {
  __typename?: 'diagnostics_tabs_sections_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** select columns of table "diagnostics_tabs" */
export enum Diagnostics_Tabs_Select_Column {
  /** column name */
  DiagnosticId = 'diagnostic_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "diagnostics_tabs" */
export type Diagnostics_Tabs_Set_Input = {
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Diagnostics_Tabs_Stddev_Fields = {
  __typename?: 'diagnostics_tabs_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Diagnostics_Tabs_Stddev_Pop_Fields = {
  __typename?: 'diagnostics_tabs_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Diagnostics_Tabs_Stddev_Samp_Fields = {
  __typename?: 'diagnostics_tabs_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Diagnostics_Tabs_Sum_Fields = {
  __typename?: 'diagnostics_tabs_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics_tabs" */
export enum Diagnostics_Tabs_Update_Column {
  /** column name */
  DiagnosticId = 'diagnostic_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Diagnostics_Tabs_Var_Pop_Fields = {
  __typename?: 'diagnostics_tabs_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Diagnostics_Tabs_Var_Samp_Fields = {
  __typename?: 'diagnostics_tabs_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Diagnostics_Tabs_Variance_Fields = {
  __typename?: 'diagnostics_tabs_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics" */
export enum Diagnostics_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  path: Scalars['String'];
};

/** aggregated selection of "files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

/** aggregate fields of "files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
};


/** aggregate fields of "files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "files" */
export enum Files_Constraint {
  /** unique or primary key constraint */
  FilesPkey = 'files_pkey'
}

/** input type for inserting data into table "files" */
export type Files_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "files". */
export type Files_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
};

/** primary key columns input for table: files */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "files" */
export enum Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path'
}

/** input type for updating data in table "files" */
export type Files_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['String']>;
};

/** update columns of table "files" */
export enum Files_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path'
}

/** columns and relationships of "groups" */
export type Groups = {
  __typename?: 'groups';
  /** An array relationship */
  contents: Array<Groups_Contents>;
  /** An aggregate relationship */
  contents_aggregate: Groups_Contents_Aggregate;
  email: Scalars['String'];
  end_date: Scalars['date'];
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  /** An array relationship */
  names: Array<Groups_Names>;
  /** An aggregate relationship */
  names_aggregate: Groups_Names_Aggregate;
  start_date: Scalars['date'];
  /** An array relationship */
  teams: Array<Groups_Teams>;
  /** An aggregate relationship */
  teams_aggregate: Groups_Teams_Aggregate;
};


/** columns and relationships of "groups" */
export type GroupsContentsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsNamesArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsNames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsTeamsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};

/** aggregated selection of "groups" */
export type Groups_Aggregate = {
  __typename?: 'groups_aggregate';
  aggregate?: Maybe<Groups_Aggregate_Fields>;
  nodes: Array<Groups>;
};

/** aggregate fields of "groups" */
export type Groups_Aggregate_Fields = {
  __typename?: 'groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Groups_Max_Fields>;
  min?: Maybe<Groups_Min_Fields>;
};


/** aggregate fields of "groups" */
export type Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups" */
export type Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Max_Order_By>;
  min?: InputMaybe<Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "groups" */
export type Groups_Arr_Rel_Insert_Input = {
  data: Array<Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "groups". All fields are combined with a logical 'AND'. */
export type Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Bool_Exp>>;
  _not?: InputMaybe<Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Bool_Exp>>;
  contents?: InputMaybe<Groups_Contents_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Locations_Bool_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  names?: InputMaybe<Groups_Names_Bool_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  teams?: InputMaybe<Groups_Teams_Bool_Exp>;
};

/** unique or primary key constraints on table "groups" */
export enum Groups_Constraint {
  /** unique or primary key constraint */
  GroupsPkey = 'groups_pkey'
}

/** columns and relationships of "groups_contents" */
export type Groups_Contents = {
  __typename?: 'groups_contents';
  active: Scalars['Boolean'];
  /** An object relationship */
  content: Contents;
  content_id: Scalars['uuid'];
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid'];
};

/** aggregated selection of "groups_contents" */
export type Groups_Contents_Aggregate = {
  __typename?: 'groups_contents_aggregate';
  aggregate?: Maybe<Groups_Contents_Aggregate_Fields>;
  nodes: Array<Groups_Contents>;
};

/** aggregate fields of "groups_contents" */
export type Groups_Contents_Aggregate_Fields = {
  __typename?: 'groups_contents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Groups_Contents_Max_Fields>;
  min?: Maybe<Groups_Contents_Min_Fields>;
};


/** aggregate fields of "groups_contents" */
export type Groups_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups_contents" */
export type Groups_Contents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Contents_Max_Order_By>;
  min?: InputMaybe<Groups_Contents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "groups_contents" */
export type Groups_Contents_Arr_Rel_Insert_Input = {
  data: Array<Groups_Contents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_Contents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "groups_contents". All fields are combined with a logical 'AND'. */
export type Groups_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Contents_Bool_Exp>>;
  _not?: InputMaybe<Groups_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Contents_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Uuid_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups_contents" */
export enum Groups_Contents_Constraint {
  /** unique or primary key constraint */
  GroupsContentsGroupIdActiveIdx = 'groups_contents_group_id_active_idx',
  /** unique or primary key constraint */
  GroupsContentsGroupIdContentIdKey = 'groups_contents_group_id_content_id_key',
  /** unique or primary key constraint */
  GroupsContentsPkey = 'groups_contents_pkey'
}

/** input type for inserting data into table "groups_contents" */
export type Groups_Contents_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['uuid']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Groups_Contents_Max_Fields = {
  __typename?: 'groups_contents_max_fields';
  content_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "groups_contents" */
export type Groups_Contents_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Contents_Min_Fields = {
  __typename?: 'groups_contents_min_fields';
  content_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "groups_contents" */
export type Groups_Contents_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups_contents" */
export type Groups_Contents_Mutation_Response = {
  __typename?: 'groups_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups_Contents>;
};

/** on_conflict condition type for table "groups_contents" */
export type Groups_Contents_On_Conflict = {
  constraint: Groups_Contents_Constraint;
  update_columns?: Array<Groups_Contents_Update_Column>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "groups_contents". */
export type Groups_Contents_Order_By = {
  active?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups_contents */
export type Groups_Contents_Pk_Columns_Input = {
  content_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};

/** select columns of table "groups_contents" */
export enum Groups_Contents_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ContentId = 'content_id',
  /** column name */
  GroupId = 'group_id'
}

/** input type for updating data in table "groups_contents" */
export type Groups_Contents_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  content_id?: InputMaybe<Scalars['uuid']>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "groups_contents" */
export enum Groups_Contents_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ContentId = 'content_id',
  /** column name */
  GroupId = 'group_id'
}

/** input type for inserting data into table "groups" */
export type Groups_Insert_Input = {
  contents?: InputMaybe<Groups_Contents_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['uuid']>;
  names?: InputMaybe<Groups_Names_Arr_Rel_Insert_Input>;
  start_date?: InputMaybe<Scalars['date']>;
  teams?: InputMaybe<Groups_Teams_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Groups_Max_Fields = {
  __typename?: 'groups_max_fields';
  email?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "groups" */
export type Groups_Max_Order_By = {
  email?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Min_Fields = {
  __typename?: 'groups_min_fields';
  email?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
};

/** order by min() on columns of table "groups" */
export type Groups_Min_Order_By = {
  email?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups" */
export type Groups_Mutation_Response = {
  __typename?: 'groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups>;
};

/** columns and relationships of "groups_names" */
export type Groups_Names = {
  __typename?: 'groups_names';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "groups_names" */
export type Groups_Names_Aggregate = {
  __typename?: 'groups_names_aggregate';
  aggregate?: Maybe<Groups_Names_Aggregate_Fields>;
  nodes: Array<Groups_Names>;
};

/** aggregate fields of "groups_names" */
export type Groups_Names_Aggregate_Fields = {
  __typename?: 'groups_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Groups_Names_Max_Fields>;
  min?: Maybe<Groups_Names_Min_Fields>;
};


/** aggregate fields of "groups_names" */
export type Groups_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups_names" */
export type Groups_Names_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Names_Max_Order_By>;
  min?: InputMaybe<Groups_Names_Min_Order_By>;
};

/** input type for inserting array relation for remote table "groups_names" */
export type Groups_Names_Arr_Rel_Insert_Input = {
  data: Array<Groups_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_Names_On_Conflict>;
};

/** Boolean expression to filter rows from the table "groups_names". All fields are combined with a logical 'AND'. */
export type Groups_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Names_Bool_Exp>>;
  _not?: InputMaybe<Groups_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups_names" */
export enum Groups_Names_Constraint {
  /** unique or primary key constraint */
  GroupsNamesPkey = 'groups_names_pkey'
}

/** input type for inserting data into table "groups_names" */
export type Groups_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Groups_Names_Max_Fields = {
  __typename?: 'groups_names_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "groups_names" */
export type Groups_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Names_Min_Fields = {
  __typename?: 'groups_names_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "groups_names" */
export type Groups_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups_names" */
export type Groups_Names_Mutation_Response = {
  __typename?: 'groups_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups_Names>;
};

/** on_conflict condition type for table "groups_names" */
export type Groups_Names_On_Conflict = {
  constraint: Groups_Names_Constraint;
  update_columns?: Array<Groups_Names_Update_Column>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "groups_names". */
export type Groups_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups_names */
export type Groups_Names_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "groups_names" */
export enum Groups_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "groups_names" */
export type Groups_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "groups_names" */
export enum Groups_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for inserting object relation for remote table "groups" */
export type Groups_Obj_Rel_Insert_Input = {
  data: Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** on_conflict condition type for table "groups" */
export type Groups_On_Conflict = {
  constraint: Groups_Constraint;
  update_columns?: Array<Groups_Update_Column>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "groups". */
export type Groups_Order_By = {
  contents_aggregate?: InputMaybe<Groups_Contents_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Locations_Order_By>;
  location_id?: InputMaybe<Order_By>;
  names_aggregate?: InputMaybe<Groups_Names_Aggregate_Order_By>;
  start_date?: InputMaybe<Order_By>;
  teams_aggregate?: InputMaybe<Groups_Teams_Aggregate_Order_By>;
};

/** primary key columns input for table: groups */
export type Groups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "groups" */
export enum Groups_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  StartDate = 'start_date'
}

/** input type for updating data in table "groups" */
export type Groups_Set_Input = {
  email?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_id?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['date']>;
};

/** columns and relationships of "groups_teams" */
export type Groups_Teams = {
  __typename?: 'groups_teams';
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};

/** aggregated selection of "groups_teams" */
export type Groups_Teams_Aggregate = {
  __typename?: 'groups_teams_aggregate';
  aggregate?: Maybe<Groups_Teams_Aggregate_Fields>;
  nodes: Array<Groups_Teams>;
};

/** aggregate fields of "groups_teams" */
export type Groups_Teams_Aggregate_Fields = {
  __typename?: 'groups_teams_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Groups_Teams_Max_Fields>;
  min?: Maybe<Groups_Teams_Min_Fields>;
};


/** aggregate fields of "groups_teams" */
export type Groups_Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups_teams" */
export type Groups_Teams_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Teams_Max_Order_By>;
  min?: InputMaybe<Groups_Teams_Min_Order_By>;
};

/** input type for inserting array relation for remote table "groups_teams" */
export type Groups_Teams_Arr_Rel_Insert_Input = {
  data: Array<Groups_Teams_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_Teams_On_Conflict>;
};

/** Boolean expression to filter rows from the table "groups_teams". All fields are combined with a logical 'AND'. */
export type Groups_Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Teams_Bool_Exp>>;
  _not?: InputMaybe<Groups_Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Teams_Bool_Exp>>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups_teams" */
export enum Groups_Teams_Constraint {
  /** unique or primary key constraint */
  GroupsTeamsGroupIdTeamIdKey = 'groups_teams_group_id_team_id_key',
  /** unique or primary key constraint */
  GroupsTeamsPkey = 'groups_teams_pkey'
}

/** input type for inserting data into table "groups_teams" */
export type Groups_Teams_Insert_Input = {
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Groups_Teams_Max_Fields = {
  __typename?: 'groups_teams_max_fields';
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "groups_teams" */
export type Groups_Teams_Max_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Teams_Min_Fields = {
  __typename?: 'groups_teams_min_fields';
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "groups_teams" */
export type Groups_Teams_Min_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups_teams" */
export type Groups_Teams_Mutation_Response = {
  __typename?: 'groups_teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups_Teams>;
};

/** on_conflict condition type for table "groups_teams" */
export type Groups_Teams_On_Conflict = {
  constraint: Groups_Teams_Constraint;
  update_columns?: Array<Groups_Teams_Update_Column>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "groups_teams". */
export type Groups_Teams_Order_By = {
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups_teams */
export type Groups_Teams_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "groups_teams" */
export enum Groups_Teams_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "groups_teams" */
export type Groups_Teams_Set_Input = {
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "groups_teams" */
export enum Groups_Teams_Update_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id'
}

/** update columns of table "groups" */
export enum Groups_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  StartDate = 'start_date'
}

/** columns and relationships of "icons_types" */
export type Icons_Types = {
  __typename?: 'icons_types';
  type: Scalars['String'];
};

/** aggregated selection of "icons_types" */
export type Icons_Types_Aggregate = {
  __typename?: 'icons_types_aggregate';
  aggregate?: Maybe<Icons_Types_Aggregate_Fields>;
  nodes: Array<Icons_Types>;
};

/** aggregate fields of "icons_types" */
export type Icons_Types_Aggregate_Fields = {
  __typename?: 'icons_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Icons_Types_Max_Fields>;
  min?: Maybe<Icons_Types_Min_Fields>;
};


/** aggregate fields of "icons_types" */
export type Icons_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icons_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "icons_types". All fields are combined with a logical 'AND'. */
export type Icons_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Icons_Types_Bool_Exp>>;
  _not?: InputMaybe<Icons_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Icons_Types_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icons_types" */
export enum Icons_Types_Constraint {
  /** unique or primary key constraint */
  IconsTypesPkey = 'icons_types_pkey'
}

export enum Icons_Types_Enum {
  Big = 'big',
  Medium = 'medium',
  Small = 'small'
}

/** Boolean expression to compare columns of type "icons_types_enum". All fields are combined with logical 'AND'. */
export type Icons_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Icons_Types_Enum>;
  _in?: InputMaybe<Array<Icons_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Icons_Types_Enum>;
  _nin?: InputMaybe<Array<Icons_Types_Enum>>;
};

/** input type for inserting data into table "icons_types" */
export type Icons_Types_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icons_Types_Max_Fields = {
  __typename?: 'icons_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icons_Types_Min_Fields = {
  __typename?: 'icons_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icons_types" */
export type Icons_Types_Mutation_Response = {
  __typename?: 'icons_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icons_Types>;
};

/** on_conflict condition type for table "icons_types" */
export type Icons_Types_On_Conflict = {
  constraint: Icons_Types_Constraint;
  update_columns?: Array<Icons_Types_Update_Column>;
  where?: InputMaybe<Icons_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "icons_types". */
export type Icons_Types_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icons_types */
export type Icons_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "icons_types" */
export enum Icons_Types_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "icons_types" */
export type Icons_Types_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "icons_types" */
export enum Icons_Types_Update_Column {
  /** column name */
  Type = 'type'
}

/** columns and relationships of "invite_states" */
export type Invite_States = {
  __typename?: 'invite_states';
  state: Scalars['String'];
};

/** aggregated selection of "invite_states" */
export type Invite_States_Aggregate = {
  __typename?: 'invite_states_aggregate';
  aggregate?: Maybe<Invite_States_Aggregate_Fields>;
  nodes: Array<Invite_States>;
};

/** aggregate fields of "invite_states" */
export type Invite_States_Aggregate_Fields = {
  __typename?: 'invite_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Invite_States_Max_Fields>;
  min?: Maybe<Invite_States_Min_Fields>;
};


/** aggregate fields of "invite_states" */
export type Invite_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invite_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "invite_states". All fields are combined with a logical 'AND'. */
export type Invite_States_Bool_Exp = {
  _and?: InputMaybe<Array<Invite_States_Bool_Exp>>;
  _not?: InputMaybe<Invite_States_Bool_Exp>;
  _or?: InputMaybe<Array<Invite_States_Bool_Exp>>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "invite_states" */
export enum Invite_States_Constraint {
  /** unique or primary key constraint */
  InviteStatesPkey = 'invite_states_pkey'
}

export enum Invite_States_Enum {
  Accepted = 'accepted',
  Pending = 'pending',
  Refused = 'refused'
}

/** Boolean expression to compare columns of type "invite_states_enum". All fields are combined with logical 'AND'. */
export type Invite_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Invite_States_Enum>;
  _in?: InputMaybe<Array<Invite_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Invite_States_Enum>;
  _nin?: InputMaybe<Array<Invite_States_Enum>>;
};

/** input type for inserting data into table "invite_states" */
export type Invite_States_Insert_Input = {
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Invite_States_Max_Fields = {
  __typename?: 'invite_states_max_fields';
  state?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Invite_States_Min_Fields = {
  __typename?: 'invite_states_min_fields';
  state?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "invite_states" */
export type Invite_States_Mutation_Response = {
  __typename?: 'invite_states_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invite_States>;
};

/** on_conflict condition type for table "invite_states" */
export type Invite_States_On_Conflict = {
  constraint: Invite_States_Constraint;
  update_columns?: Array<Invite_States_Update_Column>;
  where?: InputMaybe<Invite_States_Bool_Exp>;
};

/** Ordering options when selecting data from "invite_states". */
export type Invite_States_Order_By = {
  state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invite_states */
export type Invite_States_Pk_Columns_Input = {
  state: Scalars['String'];
};

/** select columns of table "invite_states" */
export enum Invite_States_Select_Column {
  /** column name */
  State = 'state'
}

/** input type for updating data in table "invite_states" */
export type Invite_States_Set_Input = {
  state?: InputMaybe<Scalars['String']>;
};

/** update columns of table "invite_states" */
export enum Invite_States_Update_Column {
  /** column name */
  State = 'state'
}

/** columns and relationships of "kanban_columns" */
export type Kanban_Columns = {
  __typename?: 'kanban_columns';
  column: Scalars['String'];
};

/** aggregated selection of "kanban_columns" */
export type Kanban_Columns_Aggregate = {
  __typename?: 'kanban_columns_aggregate';
  aggregate?: Maybe<Kanban_Columns_Aggregate_Fields>;
  nodes: Array<Kanban_Columns>;
};

/** aggregate fields of "kanban_columns" */
export type Kanban_Columns_Aggregate_Fields = {
  __typename?: 'kanban_columns_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanban_Columns_Max_Fields>;
  min?: Maybe<Kanban_Columns_Min_Fields>;
};


/** aggregate fields of "kanban_columns" */
export type Kanban_Columns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanban_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "kanban_columns". All fields are combined with a logical 'AND'. */
export type Kanban_Columns_Bool_Exp = {
  _and?: InputMaybe<Array<Kanban_Columns_Bool_Exp>>;
  _not?: InputMaybe<Kanban_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Kanban_Columns_Bool_Exp>>;
  column?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "kanban_columns" */
export enum Kanban_Columns_Constraint {
  /** unique or primary key constraint */
  KanbanColumnsPkey = 'kanban_columns_pkey'
}

export enum Kanban_Columns_Enum {
  Backlog = 'backlog',
  Done = 'done',
  Impediments = 'impediments',
  Ongoing = 'ongoing',
  Todo = 'todo'
}

/** Boolean expression to compare columns of type "kanban_columns_enum". All fields are combined with logical 'AND'. */
export type Kanban_Columns_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Kanban_Columns_Enum>;
  _in?: InputMaybe<Array<Kanban_Columns_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Kanban_Columns_Enum>;
  _nin?: InputMaybe<Array<Kanban_Columns_Enum>>;
};

/** input type for inserting data into table "kanban_columns" */
export type Kanban_Columns_Insert_Input = {
  column?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Kanban_Columns_Max_Fields = {
  __typename?: 'kanban_columns_max_fields';
  column?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Kanban_Columns_Min_Fields = {
  __typename?: 'kanban_columns_min_fields';
  column?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "kanban_columns" */
export type Kanban_Columns_Mutation_Response = {
  __typename?: 'kanban_columns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanban_Columns>;
};

/** on_conflict condition type for table "kanban_columns" */
export type Kanban_Columns_On_Conflict = {
  constraint: Kanban_Columns_Constraint;
  update_columns?: Array<Kanban_Columns_Update_Column>;
  where?: InputMaybe<Kanban_Columns_Bool_Exp>;
};

/** Ordering options when selecting data from "kanban_columns". */
export type Kanban_Columns_Order_By = {
  column?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanban_columns */
export type Kanban_Columns_Pk_Columns_Input = {
  column: Scalars['String'];
};

/** select columns of table "kanban_columns" */
export enum Kanban_Columns_Select_Column {
  /** column name */
  Column = 'column'
}

/** input type for updating data in table "kanban_columns" */
export type Kanban_Columns_Set_Input = {
  column?: InputMaybe<Scalars['String']>;
};

/** update columns of table "kanban_columns" */
export enum Kanban_Columns_Update_Column {
  /** column name */
  Column = 'column'
}

/** columns and relationships of "kanbans" */
export type Kanbans = {
  __typename?: 'kanbans';
  /** An array relationship */
  cards: Array<Kanbans_Cards>;
  /** An aggregate relationship */
  cards_aggregate: Kanbans_Cards_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};


/** columns and relationships of "kanbans" */
export type KanbansCardsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};


/** columns and relationships of "kanbans" */
export type KanbansCards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};

/** aggregated selection of "kanbans" */
export type Kanbans_Aggregate = {
  __typename?: 'kanbans_aggregate';
  aggregate?: Maybe<Kanbans_Aggregate_Fields>;
  nodes: Array<Kanbans>;
};

/** aggregate fields of "kanbans" */
export type Kanbans_Aggregate_Fields = {
  __typename?: 'kanbans_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanbans_Max_Fields>;
  min?: Maybe<Kanbans_Min_Fields>;
};


/** aggregate fields of "kanbans" */
export type Kanbans_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanbans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "kanbans". All fields are combined with a logical 'AND'. */
export type Kanbans_Bool_Exp = {
  _and?: InputMaybe<Array<Kanbans_Bool_Exp>>;
  _not?: InputMaybe<Kanbans_Bool_Exp>;
  _or?: InputMaybe<Array<Kanbans_Bool_Exp>>;
  cards?: InputMaybe<Kanbans_Cards_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** columns and relationships of "kanbans_cards" */
export type Kanbans_Cards = {
  __typename?: 'kanbans_cards';
  /** An array relationship */
  assignations: Array<Kanbans_Cards_Assignations>;
  /** An aggregate relationship */
  assignations_aggregate: Kanbans_Cards_Assignations_Aggregate;
  /** An array relationship */
  attachments: Array<Kanbans_Cards_Attachments>;
  /** An aggregate relationship */
  attachments_aggregate: Kanbans_Cards_Attachments_Aggregate;
  column: Kanban_Columns_Enum;
  /** An array relationship */
  comments: Array<Kanbans_Cards_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Kanbans_Cards_Comments_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by: Users;
  creator_id: Scalars['String'];
  due_date?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  kanban: Kanbans;
  kanban_id: Scalars['uuid'];
  /** An object relationship */
  link_output?: Maybe<Outputs>;
  link_output_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  link_section?: Maybe<Sections>;
  link_section_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  link_tool?: Maybe<Tools>;
  link_tool_id?: Maybe<Scalars['uuid']>;
  title: Scalars['String'];
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsAssignationsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsAssignations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsAttachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};

/** aggregated selection of "kanbans_cards" */
export type Kanbans_Cards_Aggregate = {
  __typename?: 'kanbans_cards_aggregate';
  aggregate?: Maybe<Kanbans_Cards_Aggregate_Fields>;
  nodes: Array<Kanbans_Cards>;
};

/** aggregate fields of "kanbans_cards" */
export type Kanbans_Cards_Aggregate_Fields = {
  __typename?: 'kanbans_cards_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanbans_Cards_Max_Fields>;
  min?: Maybe<Kanbans_Cards_Min_Fields>;
};


/** aggregate fields of "kanbans_cards" */
export type Kanbans_Cards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kanbans_cards" */
export type Kanbans_Cards_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kanbans_Cards_Max_Order_By>;
  min?: InputMaybe<Kanbans_Cards_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kanbans_cards" */
export type Kanbans_Cards_Arr_Rel_Insert_Input = {
  data: Array<Kanbans_Cards_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_Cards_On_Conflict>;
};

/** columns and relationships of "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations = {
  __typename?: 'kanbans_cards_assignations';
  /** An object relationship */
  card: Kanbans_Cards;
  card_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Aggregate = {
  __typename?: 'kanbans_cards_assignations_aggregate';
  aggregate?: Maybe<Kanbans_Cards_Assignations_Aggregate_Fields>;
  nodes: Array<Kanbans_Cards_Assignations>;
};

/** aggregate fields of "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Aggregate_Fields = {
  __typename?: 'kanbans_cards_assignations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanbans_Cards_Assignations_Max_Fields>;
  min?: Maybe<Kanbans_Cards_Assignations_Min_Fields>;
};


/** aggregate fields of "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kanbans_Cards_Assignations_Max_Order_By>;
  min?: InputMaybe<Kanbans_Cards_Assignations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Arr_Rel_Insert_Input = {
  data: Array<Kanbans_Cards_Assignations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_Cards_Assignations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kanbans_cards_assignations". All fields are combined with a logical 'AND'. */
export type Kanbans_Cards_Assignations_Bool_Exp = {
  _and?: InputMaybe<Array<Kanbans_Cards_Assignations_Bool_Exp>>;
  _not?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
  _or?: InputMaybe<Array<Kanbans_Cards_Assignations_Bool_Exp>>;
  card?: InputMaybe<Kanbans_Cards_Bool_Exp>;
  card_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "kanbans_cards_assignations" */
export enum Kanbans_Cards_Assignations_Constraint {
  /** unique or primary key constraint */
  KanbansCardsAssignationsPkey = 'kanbans_cards_assignations_pkey'
}

/** input type for inserting data into table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Insert_Input = {
  card?: InputMaybe<Kanbans_Cards_Obj_Rel_Insert_Input>;
  card_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Kanbans_Cards_Assignations_Max_Fields = {
  __typename?: 'kanbans_cards_assignations_max_fields';
  card_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Max_Order_By = {
  card_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kanbans_Cards_Assignations_Min_Fields = {
  __typename?: 'kanbans_cards_assignations_min_fields';
  card_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Min_Order_By = {
  card_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Mutation_Response = {
  __typename?: 'kanbans_cards_assignations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanbans_Cards_Assignations>;
};

/** on_conflict condition type for table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_On_Conflict = {
  constraint: Kanbans_Cards_Assignations_Constraint;
  update_columns?: Array<Kanbans_Cards_Assignations_Update_Column>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};

/** Ordering options when selecting data from "kanbans_cards_assignations". */
export type Kanbans_Cards_Assignations_Order_By = {
  card?: InputMaybe<Kanbans_Cards_Order_By>;
  card_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanbans_cards_assignations */
export type Kanbans_Cards_Assignations_Pk_Columns_Input = {
  card_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** select columns of table "kanbans_cards_assignations" */
export enum Kanbans_Cards_Assignations_Select_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Set_Input = {
  card_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "kanbans_cards_assignations" */
export enum Kanbans_Cards_Assignations_Update_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments = {
  __typename?: 'kanbans_cards_attachments';
  /** An object relationship */
  card: Kanbans_Cards;
  card_id: Scalars['uuid'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
};

/** aggregated selection of "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Aggregate = {
  __typename?: 'kanbans_cards_attachments_aggregate';
  aggregate?: Maybe<Kanbans_Cards_Attachments_Aggregate_Fields>;
  nodes: Array<Kanbans_Cards_Attachments>;
};

/** aggregate fields of "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Aggregate_Fields = {
  __typename?: 'kanbans_cards_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanbans_Cards_Attachments_Max_Fields>;
  min?: Maybe<Kanbans_Cards_Attachments_Min_Fields>;
};


/** aggregate fields of "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kanbans_Cards_Attachments_Max_Order_By>;
  min?: InputMaybe<Kanbans_Cards_Attachments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Arr_Rel_Insert_Input = {
  data: Array<Kanbans_Cards_Attachments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_Cards_Attachments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kanbans_cards_attachments". All fields are combined with a logical 'AND'. */
export type Kanbans_Cards_Attachments_Bool_Exp = {
  _and?: InputMaybe<Array<Kanbans_Cards_Attachments_Bool_Exp>>;
  _not?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
  _or?: InputMaybe<Array<Kanbans_Cards_Attachments_Bool_Exp>>;
  card?: InputMaybe<Kanbans_Cards_Bool_Exp>;
  card_id?: InputMaybe<Uuid_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "kanbans_cards_attachments" */
export enum Kanbans_Cards_Attachments_Constraint {
  /** unique or primary key constraint */
  KanbansCardsAttachmentsPkey = 'kanbans_cards_attachments_pkey'
}

/** input type for inserting data into table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Insert_Input = {
  card?: InputMaybe<Kanbans_Cards_Obj_Rel_Insert_Input>;
  card_id?: InputMaybe<Scalars['uuid']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Kanbans_Cards_Attachments_Max_Fields = {
  __typename?: 'kanbans_cards_attachments_max_fields';
  card_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Max_Order_By = {
  card_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kanbans_Cards_Attachments_Min_Fields = {
  __typename?: 'kanbans_cards_attachments_min_fields';
  card_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Min_Order_By = {
  card_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Mutation_Response = {
  __typename?: 'kanbans_cards_attachments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanbans_Cards_Attachments>;
};

/** on_conflict condition type for table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_On_Conflict = {
  constraint: Kanbans_Cards_Attachments_Constraint;
  update_columns?: Array<Kanbans_Cards_Attachments_Update_Column>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "kanbans_cards_attachments". */
export type Kanbans_Cards_Attachments_Order_By = {
  card?: InputMaybe<Kanbans_Cards_Order_By>;
  card_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanbans_cards_attachments */
export type Kanbans_Cards_Attachments_Pk_Columns_Input = {
  card_id: Scalars['uuid'];
  file_id: Scalars['uuid'];
};

/** select columns of table "kanbans_cards_attachments" */
export enum Kanbans_Cards_Attachments_Select_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  FileId = 'file_id'
}

/** input type for updating data in table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Set_Input = {
  card_id?: InputMaybe<Scalars['uuid']>;
  file_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "kanbans_cards_attachments" */
export enum Kanbans_Cards_Attachments_Update_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  FileId = 'file_id'
}

/** Boolean expression to filter rows from the table "kanbans_cards". All fields are combined with a logical 'AND'. */
export type Kanbans_Cards_Bool_Exp = {
  _and?: InputMaybe<Array<Kanbans_Cards_Bool_Exp>>;
  _not?: InputMaybe<Kanbans_Cards_Bool_Exp>;
  _or?: InputMaybe<Array<Kanbans_Cards_Bool_Exp>>;
  assignations?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
  attachments?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
  column?: InputMaybe<Kanban_Columns_Enum_Comparison_Exp>;
  comments?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Users_Bool_Exp>;
  creator_id?: InputMaybe<String_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kanban?: InputMaybe<Kanbans_Bool_Exp>;
  kanban_id?: InputMaybe<Uuid_Comparison_Exp>;
  link_output?: InputMaybe<Outputs_Bool_Exp>;
  link_output_id?: InputMaybe<Uuid_Comparison_Exp>;
  link_section?: InputMaybe<Sections_Bool_Exp>;
  link_section_id?: InputMaybe<Uuid_Comparison_Exp>;
  link_tool?: InputMaybe<Tools_Bool_Exp>;
  link_tool_id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "kanbans_cards_comments" */
export type Kanbans_Cards_Comments = {
  __typename?: 'kanbans_cards_comments';
  /** An object relationship */
  card: Kanbans_Cards;
  card_id: Scalars['uuid'];
  content?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by: Users;
  creator_id: Scalars['String'];
  id: Scalars['uuid'];
};

/** aggregated selection of "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Aggregate = {
  __typename?: 'kanbans_cards_comments_aggregate';
  aggregate?: Maybe<Kanbans_Cards_Comments_Aggregate_Fields>;
  nodes: Array<Kanbans_Cards_Comments>;
};

/** aggregate fields of "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Aggregate_Fields = {
  __typename?: 'kanbans_cards_comments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanbans_Cards_Comments_Max_Fields>;
  min?: Maybe<Kanbans_Cards_Comments_Min_Fields>;
};


/** aggregate fields of "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kanbans_Cards_Comments_Max_Order_By>;
  min?: InputMaybe<Kanbans_Cards_Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Arr_Rel_Insert_Input = {
  data: Array<Kanbans_Cards_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_Cards_Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kanbans_cards_comments". All fields are combined with a logical 'AND'. */
export type Kanbans_Cards_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Kanbans_Cards_Comments_Bool_Exp>>;
  _not?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Kanbans_Cards_Comments_Bool_Exp>>;
  card?: InputMaybe<Kanbans_Cards_Bool_Exp>;
  card_id?: InputMaybe<Uuid_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Users_Bool_Exp>;
  creator_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "kanbans_cards_comments" */
export enum Kanbans_Cards_Comments_Constraint {
  /** unique or primary key constraint */
  KanbansCardsCommentsPkey = 'kanbans_cards_comments_pkey'
}

/** input type for inserting data into table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Insert_Input = {
  card?: InputMaybe<Kanbans_Cards_Obj_Rel_Insert_Input>;
  card_id?: InputMaybe<Scalars['uuid']>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  creator_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Kanbans_Cards_Comments_Max_Fields = {
  __typename?: 'kanbans_cards_comments_max_fields';
  card_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Max_Order_By = {
  card_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kanbans_Cards_Comments_Min_Fields = {
  __typename?: 'kanbans_cards_comments_min_fields';
  card_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Min_Order_By = {
  card_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Mutation_Response = {
  __typename?: 'kanbans_cards_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanbans_Cards_Comments>;
};

/** on_conflict condition type for table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_On_Conflict = {
  constraint: Kanbans_Cards_Comments_Constraint;
  update_columns?: Array<Kanbans_Cards_Comments_Update_Column>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "kanbans_cards_comments". */
export type Kanbans_Cards_Comments_Order_By = {
  card?: InputMaybe<Kanbans_Cards_Order_By>;
  card_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Users_Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanbans_cards_comments */
export type Kanbans_Cards_Comments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "kanbans_cards_comments" */
export enum Kanbans_Cards_Comments_Select_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Set_Input = {
  card_id?: InputMaybe<Scalars['uuid']>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "kanbans_cards_comments" */
export enum Kanbans_Cards_Comments_Update_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id'
}

/** unique or primary key constraints on table "kanbans_cards" */
export enum Kanbans_Cards_Constraint {
  /** unique or primary key constraint */
  KanbansCardsPkey = 'kanbans_cards_pkey'
}

/** input type for inserting data into table "kanbans_cards" */
export type Kanbans_Cards_Insert_Input = {
  assignations?: InputMaybe<Kanbans_Cards_Assignations_Arr_Rel_Insert_Input>;
  attachments?: InputMaybe<Kanbans_Cards_Attachments_Arr_Rel_Insert_Input>;
  column?: InputMaybe<Kanban_Columns_Enum>;
  comments?: InputMaybe<Kanbans_Cards_Comments_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  creator_id?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  kanban?: InputMaybe<Kanbans_Obj_Rel_Insert_Input>;
  kanban_id?: InputMaybe<Scalars['uuid']>;
  link_output?: InputMaybe<Outputs_Obj_Rel_Insert_Input>;
  link_output_id?: InputMaybe<Scalars['uuid']>;
  link_section?: InputMaybe<Sections_Obj_Rel_Insert_Input>;
  link_section_id?: InputMaybe<Scalars['uuid']>;
  link_tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  link_tool_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Kanbans_Cards_Max_Fields = {
  __typename?: 'kanbans_cards_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kanban_id?: Maybe<Scalars['uuid']>;
  link_output_id?: Maybe<Scalars['uuid']>;
  link_section_id?: Maybe<Scalars['uuid']>;
  link_tool_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "kanbans_cards" */
export type Kanbans_Cards_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kanban_id?: InputMaybe<Order_By>;
  link_output_id?: InputMaybe<Order_By>;
  link_section_id?: InputMaybe<Order_By>;
  link_tool_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kanbans_Cards_Min_Fields = {
  __typename?: 'kanbans_cards_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kanban_id?: Maybe<Scalars['uuid']>;
  link_output_id?: Maybe<Scalars['uuid']>;
  link_section_id?: Maybe<Scalars['uuid']>;
  link_tool_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "kanbans_cards" */
export type Kanbans_Cards_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kanban_id?: InputMaybe<Order_By>;
  link_output_id?: InputMaybe<Order_By>;
  link_section_id?: InputMaybe<Order_By>;
  link_tool_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kanbans_cards" */
export type Kanbans_Cards_Mutation_Response = {
  __typename?: 'kanbans_cards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanbans_Cards>;
};

/** input type for inserting object relation for remote table "kanbans_cards" */
export type Kanbans_Cards_Obj_Rel_Insert_Input = {
  data: Kanbans_Cards_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_Cards_On_Conflict>;
};

/** on_conflict condition type for table "kanbans_cards" */
export type Kanbans_Cards_On_Conflict = {
  constraint: Kanbans_Cards_Constraint;
  update_columns?: Array<Kanbans_Cards_Update_Column>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};

/** Ordering options when selecting data from "kanbans_cards". */
export type Kanbans_Cards_Order_By = {
  assignations_aggregate?: InputMaybe<Kanbans_Cards_Assignations_Aggregate_Order_By>;
  attachments_aggregate?: InputMaybe<Kanbans_Cards_Attachments_Aggregate_Order_By>;
  column?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<Kanbans_Cards_Comments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Users_Order_By>;
  creator_id?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kanban?: InputMaybe<Kanbans_Order_By>;
  kanban_id?: InputMaybe<Order_By>;
  link_output?: InputMaybe<Outputs_Order_By>;
  link_output_id?: InputMaybe<Order_By>;
  link_section?: InputMaybe<Sections_Order_By>;
  link_section_id?: InputMaybe<Order_By>;
  link_tool?: InputMaybe<Tools_Order_By>;
  link_tool_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanbans_cards */
export type Kanbans_Cards_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "kanbans_cards" */
export enum Kanbans_Cards_Select_Column {
  /** column name */
  Column = 'column',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  KanbanId = 'kanban_id',
  /** column name */
  LinkOutputId = 'link_output_id',
  /** column name */
  LinkSectionId = 'link_section_id',
  /** column name */
  LinkToolId = 'link_tool_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "kanbans_cards" */
export type Kanbans_Cards_Set_Input = {
  column?: InputMaybe<Kanban_Columns_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  kanban_id?: InputMaybe<Scalars['uuid']>;
  link_output_id?: InputMaybe<Scalars['uuid']>;
  link_section_id?: InputMaybe<Scalars['uuid']>;
  link_tool_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** update columns of table "kanbans_cards" */
export enum Kanbans_Cards_Update_Column {
  /** column name */
  Column = 'column',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  KanbanId = 'kanban_id',
  /** column name */
  LinkOutputId = 'link_output_id',
  /** column name */
  LinkSectionId = 'link_section_id',
  /** column name */
  LinkToolId = 'link_tool_id',
  /** column name */
  Title = 'title'
}

/** unique or primary key constraints on table "kanbans" */
export enum Kanbans_Constraint {
  /** unique or primary key constraint */
  KanbansPkey = 'kanbans_pkey'
}

/** input type for inserting data into table "kanbans" */
export type Kanbans_Insert_Input = {
  cards?: InputMaybe<Kanbans_Cards_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Kanbans_Max_Fields = {
  __typename?: 'kanbans_max_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Kanbans_Min_Fields = {
  __typename?: 'kanbans_min_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "kanbans" */
export type Kanbans_Mutation_Response = {
  __typename?: 'kanbans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanbans>;
};

/** input type for inserting object relation for remote table "kanbans" */
export type Kanbans_Obj_Rel_Insert_Input = {
  data: Kanbans_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_On_Conflict>;
};

/** on_conflict condition type for table "kanbans" */
export type Kanbans_On_Conflict = {
  constraint: Kanbans_Constraint;
  update_columns?: Array<Kanbans_Update_Column>;
  where?: InputMaybe<Kanbans_Bool_Exp>;
};

/** Ordering options when selecting data from "kanbans". */
export type Kanbans_Order_By = {
  cards_aggregate?: InputMaybe<Kanbans_Cards_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanbans */
export type Kanbans_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "kanbans" */
export enum Kanbans_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "kanbans" */
export type Kanbans_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "kanbans" */
export enum Kanbans_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id'
}

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  address: Scalars['String'];
  calendar_id?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  names: Array<Locations_Names>;
  /** An aggregate relationship */
  names_aggregate: Locations_Names_Aggregate;
  state: Scalars['String'];
};


/** columns and relationships of "locations" */
export type LocationsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsNamesArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsNames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type Locations_Aggregate = {
  __typename?: 'locations_aggregate';
  aggregate?: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_Fields = {
  __typename?: 'locations_aggregate_fields';
  avg?: Maybe<Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Locations_Max_Fields>;
  min?: Maybe<Locations_Min_Fields>;
  stddev?: Maybe<Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Locations_Sum_Fields>;
  var_pop?: Maybe<Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Locations_Var_Samp_Fields>;
  variance?: Maybe<Locations_Variance_Fields>;
};


/** aggregate fields of "locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Locations_Avg_Fields = {
  __typename?: 'locations_avg_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Locations_Bool_Exp>>;
  _not?: InputMaybe<Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Locations_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  calendar_id?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  geo_lat?: InputMaybe<Float_Comparison_Exp>;
  geo_lon?: InputMaybe<Float_Comparison_Exp>;
  groups?: InputMaybe<Groups_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  names?: InputMaybe<Locations_Names_Bool_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum Locations_Constraint {
  /** unique or primary key constraint */
  LocationsPkey = 'locations_pkey'
}

/** input type for incrementing numeric columns in table "locations" */
export type Locations_Inc_Input = {
  geo_lat?: InputMaybe<Scalars['Float']>;
  geo_lon?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  calendar_id?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  geo_lat?: InputMaybe<Scalars['Float']>;
  geo_lon?: InputMaybe<Scalars['Float']>;
  groups?: InputMaybe<Groups_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  names?: InputMaybe<Locations_Names_Arr_Rel_Insert_Input>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: 'locations_max_fields';
  address?: Maybe<Scalars['String']>;
  calendar_id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: 'locations_min_fields';
  address?: Maybe<Scalars['String']>;
  calendar_id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
  __typename?: 'locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** columns and relationships of "locations_names" */
export type Locations_Names = {
  __typename?: 'locations_names';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "locations_names" */
export type Locations_Names_Aggregate = {
  __typename?: 'locations_names_aggregate';
  aggregate?: Maybe<Locations_Names_Aggregate_Fields>;
  nodes: Array<Locations_Names>;
};

/** aggregate fields of "locations_names" */
export type Locations_Names_Aggregate_Fields = {
  __typename?: 'locations_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Locations_Names_Max_Fields>;
  min?: Maybe<Locations_Names_Min_Fields>;
};


/** aggregate fields of "locations_names" */
export type Locations_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Locations_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locations_names" */
export type Locations_Names_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Locations_Names_Max_Order_By>;
  min?: InputMaybe<Locations_Names_Min_Order_By>;
};

/** input type for inserting array relation for remote table "locations_names" */
export type Locations_Names_Arr_Rel_Insert_Input = {
  data: Array<Locations_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Locations_Names_On_Conflict>;
};

/** Boolean expression to filter rows from the table "locations_names". All fields are combined with a logical 'AND'. */
export type Locations_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Locations_Names_Bool_Exp>>;
  _not?: InputMaybe<Locations_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Locations_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Locations_Bool_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations_names" */
export enum Locations_Names_Constraint {
  /** unique or primary key constraint */
  LocationsNamesPkey = 'locations_names_pkey'
}

/** input type for inserting data into table "locations_names" */
export type Locations_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Locations_Names_Max_Fields = {
  __typename?: 'locations_names_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "locations_names" */
export type Locations_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Locations_Names_Min_Fields = {
  __typename?: 'locations_names_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "locations_names" */
export type Locations_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "locations_names" */
export type Locations_Names_Mutation_Response = {
  __typename?: 'locations_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations_Names>;
};

/** on_conflict condition type for table "locations_names" */
export type Locations_Names_On_Conflict = {
  constraint: Locations_Names_Constraint;
  update_columns?: Array<Locations_Names_Update_Column>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "locations_names". */
export type Locations_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Locations_Order_By>;
  location_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locations_names */
export type Locations_Names_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locations_names" */
export enum Locations_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "locations_names" */
export type Locations_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "locations_names" */
export enum Locations_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name'
}

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns?: Array<Locations_Update_Column>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  address?: InputMaybe<Order_By>;
  calendar_id?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  geo_lat?: InputMaybe<Order_By>;
  geo_lon?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Groups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  names_aggregate?: InputMaybe<Locations_Names_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Description = 'description',
  /** column name */
  GeoLat = 'geo_lat',
  /** column name */
  GeoLon = 'geo_lon',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state'
}

/** input type for updating data in table "locations" */
export type Locations_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  calendar_id?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  geo_lat?: InputMaybe<Scalars['Float']>;
  geo_lon?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Locations_Stddev_Fields = {
  __typename?: 'locations_stddev_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Locations_Stddev_Pop_Fields = {
  __typename?: 'locations_stddev_pop_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Locations_Stddev_Samp_Fields = {
  __typename?: 'locations_stddev_samp_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Locations_Sum_Fields = {
  __typename?: 'locations_sum_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** update columns of table "locations" */
export enum Locations_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Description = 'description',
  /** column name */
  GeoLat = 'geo_lat',
  /** column name */
  GeoLon = 'geo_lon',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state'
}

/** aggregate var_pop on columns */
export type Locations_Var_Pop_Fields = {
  __typename?: 'locations_var_pop_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Locations_Var_Samp_Fields = {
  __typename?: 'locations_var_samp_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Locations_Variance_Fields = {
  __typename?: 'locations_variance_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mentoring_records" */
export type Mentoring_Records = {
  __typename?: 'mentoring_records';
  date: Scalars['timestamptz'];
  duration: Scalars['smallint'];
  id: Scalars['uuid'];
  /** An array relationship */
  members: Array<Mentoring_Records_Members>;
  /** An aggregate relationship */
  members_aggregate: Mentoring_Records_Members_Aggregate;
  record: Scalars['String'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};


/** columns and relationships of "mentoring_records" */
export type Mentoring_RecordsMembersArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


/** columns and relationships of "mentoring_records" */
export type Mentoring_RecordsMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};

/** aggregated selection of "mentoring_records" */
export type Mentoring_Records_Aggregate = {
  __typename?: 'mentoring_records_aggregate';
  aggregate?: Maybe<Mentoring_Records_Aggregate_Fields>;
  nodes: Array<Mentoring_Records>;
};

/** aggregate fields of "mentoring_records" */
export type Mentoring_Records_Aggregate_Fields = {
  __typename?: 'mentoring_records_aggregate_fields';
  avg?: Maybe<Mentoring_Records_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mentoring_Records_Max_Fields>;
  min?: Maybe<Mentoring_Records_Min_Fields>;
  stddev?: Maybe<Mentoring_Records_Stddev_Fields>;
  stddev_pop?: Maybe<Mentoring_Records_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mentoring_Records_Stddev_Samp_Fields>;
  sum?: Maybe<Mentoring_Records_Sum_Fields>;
  var_pop?: Maybe<Mentoring_Records_Var_Pop_Fields>;
  var_samp?: Maybe<Mentoring_Records_Var_Samp_Fields>;
  variance?: Maybe<Mentoring_Records_Variance_Fields>;
};


/** aggregate fields of "mentoring_records" */
export type Mentoring_Records_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentoring_Records_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Mentoring_Records_Avg_Fields = {
  __typename?: 'mentoring_records_avg_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mentoring_records". All fields are combined with a logical 'AND'. */
export type Mentoring_Records_Bool_Exp = {
  _and?: InputMaybe<Array<Mentoring_Records_Bool_Exp>>;
  _not?: InputMaybe<Mentoring_Records_Bool_Exp>;
  _or?: InputMaybe<Array<Mentoring_Records_Bool_Exp>>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  duration?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  members?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
  record?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentoring_records" */
export enum Mentoring_Records_Constraint {
  /** unique or primary key constraint */
  MentoringRecordsPkey = 'mentoring_records_pkey'
}

/** input type for incrementing numeric columns in table "mentoring_records" */
export type Mentoring_Records_Inc_Input = {
  duration?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "mentoring_records" */
export type Mentoring_Records_Insert_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  members?: InputMaybe<Mentoring_Records_Members_Arr_Rel_Insert_Input>;
  record?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mentoring_Records_Max_Fields = {
  __typename?: 'mentoring_records_max_fields';
  date?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  record?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "mentoring_records_members" */
export type Mentoring_Records_Members = {
  __typename?: 'mentoring_records_members';
  /** An object relationship */
  member: Users;
  member_id: Scalars['String'];
  /** An object relationship */
  mentoring_record: Mentoring_Records;
  mentoring_record_id: Scalars['uuid'];
};

/** aggregated selection of "mentoring_records_members" */
export type Mentoring_Records_Members_Aggregate = {
  __typename?: 'mentoring_records_members_aggregate';
  aggregate?: Maybe<Mentoring_Records_Members_Aggregate_Fields>;
  nodes: Array<Mentoring_Records_Members>;
};

/** aggregate fields of "mentoring_records_members" */
export type Mentoring_Records_Members_Aggregate_Fields = {
  __typename?: 'mentoring_records_members_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentoring_Records_Members_Max_Fields>;
  min?: Maybe<Mentoring_Records_Members_Min_Fields>;
};


/** aggregate fields of "mentoring_records_members" */
export type Mentoring_Records_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mentoring_records_members" */
export type Mentoring_Records_Members_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mentoring_Records_Members_Max_Order_By>;
  min?: InputMaybe<Mentoring_Records_Members_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mentoring_records_members" */
export type Mentoring_Records_Members_Arr_Rel_Insert_Input = {
  data: Array<Mentoring_Records_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentoring_Records_Members_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mentoring_records_members". All fields are combined with a logical 'AND'. */
export type Mentoring_Records_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Mentoring_Records_Members_Bool_Exp>>;
  _not?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Mentoring_Records_Members_Bool_Exp>>;
  member?: InputMaybe<Users_Bool_Exp>;
  member_id?: InputMaybe<String_Comparison_Exp>;
  mentoring_record?: InputMaybe<Mentoring_Records_Bool_Exp>;
  mentoring_record_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentoring_records_members" */
export enum Mentoring_Records_Members_Constraint {
  /** unique or primary key constraint */
  MentoringRecordsMembersPkey = 'mentoring_records_members_pkey'
}

/** input type for inserting data into table "mentoring_records_members" */
export type Mentoring_Records_Members_Insert_Input = {
  member?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['String']>;
  mentoring_record?: InputMaybe<Mentoring_Records_Obj_Rel_Insert_Input>;
  mentoring_record_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mentoring_Records_Members_Max_Fields = {
  __typename?: 'mentoring_records_members_max_fields';
  member_id?: Maybe<Scalars['String']>;
  mentoring_record_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "mentoring_records_members" */
export type Mentoring_Records_Members_Max_Order_By = {
  member_id?: InputMaybe<Order_By>;
  mentoring_record_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mentoring_Records_Members_Min_Fields = {
  __typename?: 'mentoring_records_members_min_fields';
  member_id?: Maybe<Scalars['String']>;
  mentoring_record_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "mentoring_records_members" */
export type Mentoring_Records_Members_Min_Order_By = {
  member_id?: InputMaybe<Order_By>;
  mentoring_record_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mentoring_records_members" */
export type Mentoring_Records_Members_Mutation_Response = {
  __typename?: 'mentoring_records_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentoring_Records_Members>;
};

/** on_conflict condition type for table "mentoring_records_members" */
export type Mentoring_Records_Members_On_Conflict = {
  constraint: Mentoring_Records_Members_Constraint;
  update_columns?: Array<Mentoring_Records_Members_Update_Column>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "mentoring_records_members". */
export type Mentoring_Records_Members_Order_By = {
  member?: InputMaybe<Users_Order_By>;
  member_id?: InputMaybe<Order_By>;
  mentoring_record?: InputMaybe<Mentoring_Records_Order_By>;
  mentoring_record_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentoring_records_members */
export type Mentoring_Records_Members_Pk_Columns_Input = {
  member_id: Scalars['String'];
  mentoring_record_id: Scalars['uuid'];
};

/** select columns of table "mentoring_records_members" */
export enum Mentoring_Records_Members_Select_Column {
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MentoringRecordId = 'mentoring_record_id'
}

/** input type for updating data in table "mentoring_records_members" */
export type Mentoring_Records_Members_Set_Input = {
  member_id?: InputMaybe<Scalars['String']>;
  mentoring_record_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "mentoring_records_members" */
export enum Mentoring_Records_Members_Update_Column {
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MentoringRecordId = 'mentoring_record_id'
}

/** aggregate min on columns */
export type Mentoring_Records_Min_Fields = {
  __typename?: 'mentoring_records_min_fields';
  date?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  record?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "mentoring_records" */
export type Mentoring_Records_Mutation_Response = {
  __typename?: 'mentoring_records_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentoring_Records>;
};

/** input type for inserting object relation for remote table "mentoring_records" */
export type Mentoring_Records_Obj_Rel_Insert_Input = {
  data: Mentoring_Records_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentoring_Records_On_Conflict>;
};

/** on_conflict condition type for table "mentoring_records" */
export type Mentoring_Records_On_Conflict = {
  constraint: Mentoring_Records_Constraint;
  update_columns?: Array<Mentoring_Records_Update_Column>;
  where?: InputMaybe<Mentoring_Records_Bool_Exp>;
};

/** Ordering options when selecting data from "mentoring_records". */
export type Mentoring_Records_Order_By = {
  date?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<Mentoring_Records_Members_Aggregate_Order_By>;
  record?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentoring_records */
export type Mentoring_Records_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mentoring_records" */
export enum Mentoring_Records_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Record = 'record',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "mentoring_records" */
export type Mentoring_Records_Set_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  record?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Mentoring_Records_Stddev_Fields = {
  __typename?: 'mentoring_records_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mentoring_Records_Stddev_Pop_Fields = {
  __typename?: 'mentoring_records_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mentoring_Records_Stddev_Samp_Fields = {
  __typename?: 'mentoring_records_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Mentoring_Records_Sum_Fields = {
  __typename?: 'mentoring_records_sum_fields';
  duration?: Maybe<Scalars['smallint']>;
};

/** update columns of table "mentoring_records" */
export enum Mentoring_Records_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Record = 'record',
  /** column name */
  TeamId = 'team_id'
}

/** aggregate var_pop on columns */
export type Mentoring_Records_Var_Pop_Fields = {
  __typename?: 'mentoring_records_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mentoring_Records_Var_Samp_Fields = {
  __typename?: 'mentoring_records_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mentoring_Records_Variance_Fields = {
  __typename?: 'mentoring_records_variance_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mentors_blablabla" */
export type Mentors_Blablabla = {
  __typename?: 'mentors_blablabla';
  id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
  /** An object relationship */
  tool: Tools;
  tool_id: Scalars['uuid'];
  value: Scalars['String'];
};

/** aggregated selection of "mentors_blablabla" */
export type Mentors_Blablabla_Aggregate = {
  __typename?: 'mentors_blablabla_aggregate';
  aggregate?: Maybe<Mentors_Blablabla_Aggregate_Fields>;
  nodes: Array<Mentors_Blablabla>;
};

/** aggregate fields of "mentors_blablabla" */
export type Mentors_Blablabla_Aggregate_Fields = {
  __typename?: 'mentors_blablabla_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentors_Blablabla_Max_Fields>;
  min?: Maybe<Mentors_Blablabla_Min_Fields>;
};


/** aggregate fields of "mentors_blablabla" */
export type Mentors_Blablabla_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mentors_blablabla" */
export type Mentors_Blablabla_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mentors_Blablabla_Max_Order_By>;
  min?: InputMaybe<Mentors_Blablabla_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mentors_blablabla" */
export type Mentors_Blablabla_Arr_Rel_Insert_Input = {
  data: Array<Mentors_Blablabla_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentors_Blablabla_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mentors_blablabla". All fields are combined with a logical 'AND'. */
export type Mentors_Blablabla_Bool_Exp = {
  _and?: InputMaybe<Array<Mentors_Blablabla_Bool_Exp>>;
  _not?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
  _or?: InputMaybe<Array<Mentors_Blablabla_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  tool?: InputMaybe<Tools_Bool_Exp>;
  tool_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentors_blablabla" */
export enum Mentors_Blablabla_Constraint {
  /** unique or primary key constraint */
  MentorsBlablablaPkey = 'mentors_blablabla_pkey',
  /** unique or primary key constraint */
  MentorsBlablablaTeamIdToolIdKey = 'mentors_blablabla_team_id_tool_id_key'
}

/** input type for inserting data into table "mentors_blablabla" */
export type Mentors_Blablabla_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Mentors_Blablabla_Max_Fields = {
  __typename?: 'mentors_blablabla_max_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "mentors_blablabla" */
export type Mentors_Blablabla_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mentors_Blablabla_Min_Fields = {
  __typename?: 'mentors_blablabla_min_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "mentors_blablabla" */
export type Mentors_Blablabla_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mentors_blablabla" */
export type Mentors_Blablabla_Mutation_Response = {
  __typename?: 'mentors_blablabla_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentors_Blablabla>;
};

/** on_conflict condition type for table "mentors_blablabla" */
export type Mentors_Blablabla_On_Conflict = {
  constraint: Mentors_Blablabla_Constraint;
  update_columns?: Array<Mentors_Blablabla_Update_Column>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};

/** Ordering options when selecting data from "mentors_blablabla". */
export type Mentors_Blablabla_Order_By = {
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  tool?: InputMaybe<Tools_Order_By>;
  tool_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentors_blablabla */
export type Mentors_Blablabla_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mentors_blablabla" */
export enum Mentors_Blablabla_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "mentors_blablabla" */
export type Mentors_Blablabla_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "mentors_blablabla" */
export enum Mentors_Blablabla_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "multimedia_contents" */
export type Multimedia_Contents = {
  __typename?: 'multimedia_contents';
  created_at: Scalars['timestamptz'];
  ebooks_link?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  resources?: Maybe<Scalars['String']>;
  /** An array relationship */
  video_lessons: Array<Multimedia_Contents_Links>;
  /** An aggregate relationship */
  video_lessons_aggregate: Multimedia_Contents_Links_Aggregate;
};


/** columns and relationships of "multimedia_contents" */
export type Multimedia_ContentsVideo_LessonsArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};


/** columns and relationships of "multimedia_contents" */
export type Multimedia_ContentsVideo_Lessons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};

/** aggregated selection of "multimedia_contents" */
export type Multimedia_Contents_Aggregate = {
  __typename?: 'multimedia_contents_aggregate';
  aggregate?: Maybe<Multimedia_Contents_Aggregate_Fields>;
  nodes: Array<Multimedia_Contents>;
};

/** aggregate fields of "multimedia_contents" */
export type Multimedia_Contents_Aggregate_Fields = {
  __typename?: 'multimedia_contents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Multimedia_Contents_Max_Fields>;
  min?: Maybe<Multimedia_Contents_Min_Fields>;
};


/** aggregate fields of "multimedia_contents" */
export type Multimedia_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Multimedia_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "multimedia_contents". All fields are combined with a logical 'AND'. */
export type Multimedia_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Multimedia_Contents_Bool_Exp>>;
  _not?: InputMaybe<Multimedia_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Multimedia_Contents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ebooks_link?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  resources?: InputMaybe<String_Comparison_Exp>;
  video_lessons?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};

/** unique or primary key constraints on table "multimedia_contents" */
export enum Multimedia_Contents_Constraint {
  /** unique or primary key constraint */
  MultimediaContentsPkey = 'multimedia_contents_pkey'
}

/** input type for inserting data into table "multimedia_contents" */
export type Multimedia_Contents_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ebooks_link?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  resources?: InputMaybe<Scalars['String']>;
  video_lessons?: InputMaybe<Multimedia_Contents_Links_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "multimedia_contents_links" */
export type Multimedia_Contents_Links = {
  __typename?: 'multimedia_contents_links';
  id: Scalars['uuid'];
  link: Scalars['String'];
  /** An object relationship */
  multimedia_content: Multimedia_Contents;
  multimedia_content_id: Scalars['uuid'];
};

/** aggregated selection of "multimedia_contents_links" */
export type Multimedia_Contents_Links_Aggregate = {
  __typename?: 'multimedia_contents_links_aggregate';
  aggregate?: Maybe<Multimedia_Contents_Links_Aggregate_Fields>;
  nodes: Array<Multimedia_Contents_Links>;
};

/** aggregate fields of "multimedia_contents_links" */
export type Multimedia_Contents_Links_Aggregate_Fields = {
  __typename?: 'multimedia_contents_links_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Multimedia_Contents_Links_Max_Fields>;
  min?: Maybe<Multimedia_Contents_Links_Min_Fields>;
};


/** aggregate fields of "multimedia_contents_links" */
export type Multimedia_Contents_Links_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Multimedia_Contents_Links_Max_Order_By>;
  min?: InputMaybe<Multimedia_Contents_Links_Min_Order_By>;
};

/** input type for inserting array relation for remote table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Arr_Rel_Insert_Input = {
  data: Array<Multimedia_Contents_Links_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Multimedia_Contents_Links_On_Conflict>;
};

/** Boolean expression to filter rows from the table "multimedia_contents_links". All fields are combined with a logical 'AND'. */
export type Multimedia_Contents_Links_Bool_Exp = {
  _and?: InputMaybe<Array<Multimedia_Contents_Links_Bool_Exp>>;
  _not?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
  _or?: InputMaybe<Array<Multimedia_Contents_Links_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Bool_Exp>;
  multimedia_content_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "multimedia_contents_links" */
export enum Multimedia_Contents_Links_Constraint {
  /** unique or primary key constraint */
  MultimediaContentsLinksPkey = 'multimedia_contents_links_pkey'
}

/** input type for inserting data into table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Obj_Rel_Insert_Input>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Multimedia_Contents_Links_Max_Fields = {
  __typename?: 'multimedia_contents_links_max_fields';
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Multimedia_Contents_Links_Min_Fields = {
  __typename?: 'multimedia_contents_links_min_fields';
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Mutation_Response = {
  __typename?: 'multimedia_contents_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Multimedia_Contents_Links>;
};

/** on_conflict condition type for table "multimedia_contents_links" */
export type Multimedia_Contents_Links_On_Conflict = {
  constraint: Multimedia_Contents_Links_Constraint;
  update_columns?: Array<Multimedia_Contents_Links_Update_Column>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "multimedia_contents_links". */
export type Multimedia_Contents_Links_Order_By = {
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: multimedia_contents_links */
export type Multimedia_Contents_Links_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "multimedia_contents_links" */
export enum Multimedia_Contents_Links_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  MultimediaContentId = 'multimedia_content_id'
}

/** input type for updating data in table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "multimedia_contents_links" */
export enum Multimedia_Contents_Links_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  MultimediaContentId = 'multimedia_content_id'
}

/** aggregate max on columns */
export type Multimedia_Contents_Max_Fields = {
  __typename?: 'multimedia_contents_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ebooks_link?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  resources?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Multimedia_Contents_Min_Fields = {
  __typename?: 'multimedia_contents_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ebooks_link?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  resources?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "multimedia_contents" */
export type Multimedia_Contents_Mutation_Response = {
  __typename?: 'multimedia_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Multimedia_Contents>;
};

/** input type for inserting object relation for remote table "multimedia_contents" */
export type Multimedia_Contents_Obj_Rel_Insert_Input = {
  data: Multimedia_Contents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Multimedia_Contents_On_Conflict>;
};

/** on_conflict condition type for table "multimedia_contents" */
export type Multimedia_Contents_On_Conflict = {
  constraint: Multimedia_Contents_Constraint;
  update_columns?: Array<Multimedia_Contents_Update_Column>;
  where?: InputMaybe<Multimedia_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "multimedia_contents". */
export type Multimedia_Contents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ebooks_link?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resources?: InputMaybe<Order_By>;
  video_lessons_aggregate?: InputMaybe<Multimedia_Contents_Links_Aggregate_Order_By>;
};

/** primary key columns input for table: multimedia_contents */
export type Multimedia_Contents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "multimedia_contents" */
export enum Multimedia_Contents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EbooksLink = 'ebooks_link',
  /** column name */
  Id = 'id',
  /** column name */
  Resources = 'resources'
}

/** input type for updating data in table "multimedia_contents" */
export type Multimedia_Contents_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ebooks_link?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  resources?: InputMaybe<Scalars['String']>;
};

/** update columns of table "multimedia_contents" */
export enum Multimedia_Contents_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EbooksLink = 'ebooks_link',
  /** column name */
  Id = 'id',
  /** column name */
  Resources = 'resources'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  createUser: Scalars['uuid'];
  /** delete data from the table: "contents" */
  delete_contents?: Maybe<Contents_Mutation_Response>;
  /** delete single row from the table: "contents" */
  delete_contents_by_pk?: Maybe<Contents>;
  /** delete data from the table: "contents_sections" */
  delete_contents_sections?: Maybe<Contents_Sections_Mutation_Response>;
  /** delete single row from the table: "contents_sections" */
  delete_contents_sections_by_pk?: Maybe<Contents_Sections>;
  /** delete data from the table: "diagnostics" */
  delete_diagnostics?: Maybe<Diagnostics_Mutation_Response>;
  /** delete single row from the table: "diagnostics" */
  delete_diagnostics_by_pk?: Maybe<Diagnostics>;
  /** delete data from the table: "diagnostics_comments" */
  delete_diagnostics_comments?: Maybe<Diagnostics_Comments_Mutation_Response>;
  /** delete single row from the table: "diagnostics_comments" */
  delete_diagnostics_comments_by_pk?: Maybe<Diagnostics_Comments>;
  /** delete data from the table: "diagnostics_groups" */
  delete_diagnostics_groups?: Maybe<Diagnostics_Groups_Mutation_Response>;
  /** delete single row from the table: "diagnostics_groups" */
  delete_diagnostics_groups_by_pk?: Maybe<Diagnostics_Groups>;
  /** delete data from the table: "diagnostics_questions" */
  delete_diagnostics_questions?: Maybe<Diagnostics_Questions_Mutation_Response>;
  /** delete data from the table: "diagnostics_questions_answers" */
  delete_diagnostics_questions_answers?: Maybe<Diagnostics_Questions_Answers_Mutation_Response>;
  /** delete single row from the table: "diagnostics_questions_answers" */
  delete_diagnostics_questions_answers_by_pk?: Maybe<Diagnostics_Questions_Answers>;
  /** delete single row from the table: "diagnostics_questions" */
  delete_diagnostics_questions_by_pk?: Maybe<Diagnostics_Questions>;
  /** delete data from the table: "diagnostics_sections" */
  delete_diagnostics_sections?: Maybe<Diagnostics_Sections_Mutation_Response>;
  /** delete single row from the table: "diagnostics_sections" */
  delete_diagnostics_sections_by_pk?: Maybe<Diagnostics_Sections>;
  /** delete data from the table: "diagnostics_subsections" */
  delete_diagnostics_subsections?: Maybe<Diagnostics_Subsections_Mutation_Response>;
  /** delete single row from the table: "diagnostics_subsections" */
  delete_diagnostics_subsections_by_pk?: Maybe<Diagnostics_Subsections>;
  /** delete data from the table: "diagnostics_tabs" */
  delete_diagnostics_tabs?: Maybe<Diagnostics_Tabs_Mutation_Response>;
  /** delete single row from the table: "diagnostics_tabs" */
  delete_diagnostics_tabs_by_pk?: Maybe<Diagnostics_Tabs>;
  /** delete data from the table: "diagnostics_tabs_sections" */
  delete_diagnostics_tabs_sections?: Maybe<Diagnostics_Tabs_Sections_Mutation_Response>;
  /** delete single row from the table: "diagnostics_tabs_sections" */
  delete_diagnostics_tabs_sections_by_pk?: Maybe<Diagnostics_Tabs_Sections>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "groups" */
  delete_groups?: Maybe<Groups_Mutation_Response>;
  /** delete single row from the table: "groups" */
  delete_groups_by_pk?: Maybe<Groups>;
  /** delete data from the table: "groups_contents" */
  delete_groups_contents?: Maybe<Groups_Contents_Mutation_Response>;
  /** delete single row from the table: "groups_contents" */
  delete_groups_contents_by_pk?: Maybe<Groups_Contents>;
  /** delete data from the table: "groups_names" */
  delete_groups_names?: Maybe<Groups_Names_Mutation_Response>;
  /** delete single row from the table: "groups_names" */
  delete_groups_names_by_pk?: Maybe<Groups_Names>;
  /** delete data from the table: "groups_teams" */
  delete_groups_teams?: Maybe<Groups_Teams_Mutation_Response>;
  /** delete single row from the table: "groups_teams" */
  delete_groups_teams_by_pk?: Maybe<Groups_Teams>;
  /** delete data from the table: "icons_types" */
  delete_icons_types?: Maybe<Icons_Types_Mutation_Response>;
  /** delete single row from the table: "icons_types" */
  delete_icons_types_by_pk?: Maybe<Icons_Types>;
  /** delete data from the table: "invite_states" */
  delete_invite_states?: Maybe<Invite_States_Mutation_Response>;
  /** delete single row from the table: "invite_states" */
  delete_invite_states_by_pk?: Maybe<Invite_States>;
  /** delete data from the table: "kanban_columns" */
  delete_kanban_columns?: Maybe<Kanban_Columns_Mutation_Response>;
  /** delete single row from the table: "kanban_columns" */
  delete_kanban_columns_by_pk?: Maybe<Kanban_Columns>;
  /** delete data from the table: "kanbans" */
  delete_kanbans?: Maybe<Kanbans_Mutation_Response>;
  /** delete single row from the table: "kanbans" */
  delete_kanbans_by_pk?: Maybe<Kanbans>;
  /** delete data from the table: "kanbans_cards" */
  delete_kanbans_cards?: Maybe<Kanbans_Cards_Mutation_Response>;
  /** delete data from the table: "kanbans_cards_assignations" */
  delete_kanbans_cards_assignations?: Maybe<Kanbans_Cards_Assignations_Mutation_Response>;
  /** delete single row from the table: "kanbans_cards_assignations" */
  delete_kanbans_cards_assignations_by_pk?: Maybe<Kanbans_Cards_Assignations>;
  /** delete data from the table: "kanbans_cards_attachments" */
  delete_kanbans_cards_attachments?: Maybe<Kanbans_Cards_Attachments_Mutation_Response>;
  /** delete single row from the table: "kanbans_cards_attachments" */
  delete_kanbans_cards_attachments_by_pk?: Maybe<Kanbans_Cards_Attachments>;
  /** delete single row from the table: "kanbans_cards" */
  delete_kanbans_cards_by_pk?: Maybe<Kanbans_Cards>;
  /** delete data from the table: "kanbans_cards_comments" */
  delete_kanbans_cards_comments?: Maybe<Kanbans_Cards_Comments_Mutation_Response>;
  /** delete single row from the table: "kanbans_cards_comments" */
  delete_kanbans_cards_comments_by_pk?: Maybe<Kanbans_Cards_Comments>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "locations_names" */
  delete_locations_names?: Maybe<Locations_Names_Mutation_Response>;
  /** delete single row from the table: "locations_names" */
  delete_locations_names_by_pk?: Maybe<Locations_Names>;
  /** delete data from the table: "mentoring_records" */
  delete_mentoring_records?: Maybe<Mentoring_Records_Mutation_Response>;
  /** delete single row from the table: "mentoring_records" */
  delete_mentoring_records_by_pk?: Maybe<Mentoring_Records>;
  /** delete data from the table: "mentoring_records_members" */
  delete_mentoring_records_members?: Maybe<Mentoring_Records_Members_Mutation_Response>;
  /** delete single row from the table: "mentoring_records_members" */
  delete_mentoring_records_members_by_pk?: Maybe<Mentoring_Records_Members>;
  /** delete data from the table: "mentors_blablabla" */
  delete_mentors_blablabla?: Maybe<Mentors_Blablabla_Mutation_Response>;
  /** delete single row from the table: "mentors_blablabla" */
  delete_mentors_blablabla_by_pk?: Maybe<Mentors_Blablabla>;
  /** delete data from the table: "multimedia_contents" */
  delete_multimedia_contents?: Maybe<Multimedia_Contents_Mutation_Response>;
  /** delete single row from the table: "multimedia_contents" */
  delete_multimedia_contents_by_pk?: Maybe<Multimedia_Contents>;
  /** delete data from the table: "multimedia_contents_links" */
  delete_multimedia_contents_links?: Maybe<Multimedia_Contents_Links_Mutation_Response>;
  /** delete single row from the table: "multimedia_contents_links" */
  delete_multimedia_contents_links_by_pk?: Maybe<Multimedia_Contents_Links>;
  /** delete data from the table: "outputs" */
  delete_outputs?: Maybe<Outputs_Mutation_Response>;
  /** delete single row from the table: "outputs" */
  delete_outputs_by_pk?: Maybe<Outputs>;
  /** delete data from the table: "outputs_icons" */
  delete_outputs_icons?: Maybe<Outputs_Icons_Mutation_Response>;
  /** delete single row from the table: "outputs_icons" */
  delete_outputs_icons_by_pk?: Maybe<Outputs_Icons>;
  /** delete data from the table: "outputs_tools" */
  delete_outputs_tools?: Maybe<Outputs_Tools_Mutation_Response>;
  /** delete single row from the table: "outputs_tools" */
  delete_outputs_tools_by_pk?: Maybe<Outputs_Tools>;
  /** delete data from the table: "questionnaires" */
  delete_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** delete single row from the table: "questionnaires" */
  delete_questionnaires_by_pk?: Maybe<Questionnaires>;
  /** delete data from the table: "questionnaires_teams_states" */
  delete_questionnaires_teams_states?: Maybe<Questionnaires_Teams_States_Mutation_Response>;
  /** delete single row from the table: "questionnaires_teams_states" */
  delete_questionnaires_teams_states_by_pk?: Maybe<Questionnaires_Teams_States>;
  /** delete data from the table: "questions" */
  delete_questions?: Maybe<Questions_Mutation_Response>;
  /** delete data from the table: "questions_answers" */
  delete_questions_answers?: Maybe<Questions_Answers_Mutation_Response>;
  /** delete single row from the table: "questions_answers" */
  delete_questions_answers_by_pk?: Maybe<Questions_Answers>;
  /** delete single row from the table: "questions" */
  delete_questions_by_pk?: Maybe<Questions>;
  /** delete data from the table: "questions_types" */
  delete_questions_types?: Maybe<Questions_Types_Mutation_Response>;
  /** delete single row from the table: "questions_types" */
  delete_questions_types_by_pk?: Maybe<Questions_Types>;
  /** delete data from the table: "repositories" */
  delete_repositories?: Maybe<Repositories_Mutation_Response>;
  /** delete single row from the table: "repositories" */
  delete_repositories_by_pk?: Maybe<Repositories>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "sections" */
  delete_sections?: Maybe<Sections_Mutation_Response>;
  /** delete single row from the table: "sections" */
  delete_sections_by_pk?: Maybe<Sections>;
  /** delete data from the table: "sections_icons" */
  delete_sections_icons?: Maybe<Sections_Icons_Mutation_Response>;
  /** delete single row from the table: "sections_icons" */
  delete_sections_icons_by_pk?: Maybe<Sections_Icons>;
  /** delete data from the table: "social_medias" */
  delete_social_medias?: Maybe<Social_Medias_Mutation_Response>;
  /** delete single row from the table: "social_medias" */
  delete_social_medias_by_pk?: Maybe<Social_Medias>;
  /** delete data from the table: "tags" */
  delete_tags?: Maybe<Tags_Mutation_Response>;
  /** delete single row from the table: "tags" */
  delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "team_invites" */
  delete_team_invites?: Maybe<Team_Invites_Mutation_Response>;
  /** delete single row from the table: "team_invites" */
  delete_team_invites_by_pk?: Maybe<Team_Invites>;
  /** delete data from the table: "teams" */
  delete_teams?: Maybe<Teams_Mutation_Response>;
  /** delete single row from the table: "teams" */
  delete_teams_by_pk?: Maybe<Teams>;
  /** delete data from the table: "teams_names" */
  delete_teams_names?: Maybe<Teams_Names_Mutation_Response>;
  /** delete single row from the table: "teams_names" */
  delete_teams_names_by_pk?: Maybe<Teams_Names>;
  /** delete data from the table: "teams_tags" */
  delete_teams_tags?: Maybe<Teams_Tags_Mutation_Response>;
  /** delete single row from the table: "teams_tags" */
  delete_teams_tags_by_pk?: Maybe<Teams_Tags>;
  /** delete data from the table: "teams_users" */
  delete_teams_users?: Maybe<Teams_Users_Mutation_Response>;
  /** delete single row from the table: "teams_users" */
  delete_teams_users_by_pk?: Maybe<Teams_Users>;
  /** delete data from the table: "tools" */
  delete_tools?: Maybe<Tools_Mutation_Response>;
  /** delete single row from the table: "tools" */
  delete_tools_by_pk?: Maybe<Tools>;
  /** delete data from the table: "tools_icons" */
  delete_tools_icons?: Maybe<Tools_Icons_Mutation_Response>;
  /** delete single row from the table: "tools_icons" */
  delete_tools_icons_by_pk?: Maybe<Tools_Icons>;
  /** delete data from the table: "user_info" */
  delete_user_info?: Maybe<User_Info_Mutation_Response>;
  /** delete single row from the table: "user_info" */
  delete_user_info_by_pk?: Maybe<User_Info>;
  /** delete data from the table: "user_invites" */
  delete_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** delete single row from the table: "user_invites" */
  delete_user_invites_by_pk?: Maybe<User_Invites>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete data from the table: "users_actions" */
  delete_users_actions?: Maybe<Users_Actions_Mutation_Response>;
  /** delete single row from the table: "users_actions" */
  delete_users_actions_by_pk?: Maybe<Users_Actions>;
  /** delete data from the table: "users_actions_types" */
  delete_users_actions_types?: Maybe<Users_Actions_Types_Mutation_Response>;
  /** delete single row from the table: "users_actions_types" */
  delete_users_actions_types_by_pk?: Maybe<Users_Actions_Types>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "users_documents" */
  delete_users_documents?: Maybe<Users_Documents_Mutation_Response>;
  /** delete single row from the table: "users_documents" */
  delete_users_documents_by_pk?: Maybe<Users_Documents>;
  /** delete data from the table: "users_documents_types" */
  delete_users_documents_types?: Maybe<Users_Documents_Types_Mutation_Response>;
  /** delete single row from the table: "users_documents_types" */
  delete_users_documents_types_by_pk?: Maybe<Users_Documents_Types>;
  /** delete data from the table: "users_names" */
  delete_users_names?: Maybe<Users_Names_Mutation_Response>;
  /** delete single row from the table: "users_names" */
  delete_users_names_by_pk?: Maybe<Users_Names>;
  /** delete data from the table: "video_repositories" */
  delete_video_repositories?: Maybe<Video_Repositories_Mutation_Response>;
  /** delete single row from the table: "video_repositories" */
  delete_video_repositories_by_pk?: Maybe<Video_Repositories>;
  /** delete data from the table: "videos" */
  delete_videos?: Maybe<Videos_Mutation_Response>;
  /** delete single row from the table: "videos" */
  delete_videos_by_pk?: Maybe<Videos>;
  /** delete data from the table: "videos_youtube_tags" */
  delete_videos_youtube_tags?: Maybe<Videos_Youtube_Tags_Mutation_Response>;
  /** delete single row from the table: "videos_youtube_tags" */
  delete_videos_youtube_tags_by_pk?: Maybe<Videos_Youtube_Tags>;
  /** delete data from the table: "youtube_tags" */
  delete_youtube_tags?: Maybe<Youtube_Tags_Mutation_Response>;
  /** delete single row from the table: "youtube_tags" */
  delete_youtube_tags_by_pk?: Maybe<Youtube_Tags>;
  /** insert data into the table: "contents" */
  insert_contents?: Maybe<Contents_Mutation_Response>;
  /** insert a single row into the table: "contents" */
  insert_contents_one?: Maybe<Contents>;
  /** insert data into the table: "contents_sections" */
  insert_contents_sections?: Maybe<Contents_Sections_Mutation_Response>;
  /** insert a single row into the table: "contents_sections" */
  insert_contents_sections_one?: Maybe<Contents_Sections>;
  /** insert data into the table: "diagnostics" */
  insert_diagnostics?: Maybe<Diagnostics_Mutation_Response>;
  /** insert data into the table: "diagnostics_comments" */
  insert_diagnostics_comments?: Maybe<Diagnostics_Comments_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_comments" */
  insert_diagnostics_comments_one?: Maybe<Diagnostics_Comments>;
  /** insert data into the table: "diagnostics_groups" */
  insert_diagnostics_groups?: Maybe<Diagnostics_Groups_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_groups" */
  insert_diagnostics_groups_one?: Maybe<Diagnostics_Groups>;
  /** insert a single row into the table: "diagnostics" */
  insert_diagnostics_one?: Maybe<Diagnostics>;
  /** insert data into the table: "diagnostics_questions" */
  insert_diagnostics_questions?: Maybe<Diagnostics_Questions_Mutation_Response>;
  /** insert data into the table: "diagnostics_questions_answers" */
  insert_diagnostics_questions_answers?: Maybe<Diagnostics_Questions_Answers_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_questions_answers" */
  insert_diagnostics_questions_answers_one?: Maybe<Diagnostics_Questions_Answers>;
  /** insert a single row into the table: "diagnostics_questions" */
  insert_diagnostics_questions_one?: Maybe<Diagnostics_Questions>;
  /** insert data into the table: "diagnostics_sections" */
  insert_diagnostics_sections?: Maybe<Diagnostics_Sections_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_sections" */
  insert_diagnostics_sections_one?: Maybe<Diagnostics_Sections>;
  /** insert data into the table: "diagnostics_subsections" */
  insert_diagnostics_subsections?: Maybe<Diagnostics_Subsections_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_subsections" */
  insert_diagnostics_subsections_one?: Maybe<Diagnostics_Subsections>;
  /** insert data into the table: "diagnostics_tabs" */
  insert_diagnostics_tabs?: Maybe<Diagnostics_Tabs_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_tabs" */
  insert_diagnostics_tabs_one?: Maybe<Diagnostics_Tabs>;
  /** insert data into the table: "diagnostics_tabs_sections" */
  insert_diagnostics_tabs_sections?: Maybe<Diagnostics_Tabs_Sections_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_tabs_sections" */
  insert_diagnostics_tabs_sections_one?: Maybe<Diagnostics_Tabs_Sections>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "groups" */
  insert_groups?: Maybe<Groups_Mutation_Response>;
  /** insert data into the table: "groups_contents" */
  insert_groups_contents?: Maybe<Groups_Contents_Mutation_Response>;
  /** insert a single row into the table: "groups_contents" */
  insert_groups_contents_one?: Maybe<Groups_Contents>;
  /** insert data into the table: "groups_names" */
  insert_groups_names?: Maybe<Groups_Names_Mutation_Response>;
  /** insert a single row into the table: "groups_names" */
  insert_groups_names_one?: Maybe<Groups_Names>;
  /** insert a single row into the table: "groups" */
  insert_groups_one?: Maybe<Groups>;
  /** insert data into the table: "groups_teams" */
  insert_groups_teams?: Maybe<Groups_Teams_Mutation_Response>;
  /** insert a single row into the table: "groups_teams" */
  insert_groups_teams_one?: Maybe<Groups_Teams>;
  /** insert data into the table: "icons_types" */
  insert_icons_types?: Maybe<Icons_Types_Mutation_Response>;
  /** insert a single row into the table: "icons_types" */
  insert_icons_types_one?: Maybe<Icons_Types>;
  /** insert data into the table: "invite_states" */
  insert_invite_states?: Maybe<Invite_States_Mutation_Response>;
  /** insert a single row into the table: "invite_states" */
  insert_invite_states_one?: Maybe<Invite_States>;
  /** insert data into the table: "kanban_columns" */
  insert_kanban_columns?: Maybe<Kanban_Columns_Mutation_Response>;
  /** insert a single row into the table: "kanban_columns" */
  insert_kanban_columns_one?: Maybe<Kanban_Columns>;
  /** insert data into the table: "kanbans" */
  insert_kanbans?: Maybe<Kanbans_Mutation_Response>;
  /** insert data into the table: "kanbans_cards" */
  insert_kanbans_cards?: Maybe<Kanbans_Cards_Mutation_Response>;
  /** insert data into the table: "kanbans_cards_assignations" */
  insert_kanbans_cards_assignations?: Maybe<Kanbans_Cards_Assignations_Mutation_Response>;
  /** insert a single row into the table: "kanbans_cards_assignations" */
  insert_kanbans_cards_assignations_one?: Maybe<Kanbans_Cards_Assignations>;
  /** insert data into the table: "kanbans_cards_attachments" */
  insert_kanbans_cards_attachments?: Maybe<Kanbans_Cards_Attachments_Mutation_Response>;
  /** insert a single row into the table: "kanbans_cards_attachments" */
  insert_kanbans_cards_attachments_one?: Maybe<Kanbans_Cards_Attachments>;
  /** insert data into the table: "kanbans_cards_comments" */
  insert_kanbans_cards_comments?: Maybe<Kanbans_Cards_Comments_Mutation_Response>;
  /** insert a single row into the table: "kanbans_cards_comments" */
  insert_kanbans_cards_comments_one?: Maybe<Kanbans_Cards_Comments>;
  /** insert a single row into the table: "kanbans_cards" */
  insert_kanbans_cards_one?: Maybe<Kanbans_Cards>;
  /** insert a single row into the table: "kanbans" */
  insert_kanbans_one?: Maybe<Kanbans>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<Locations_Mutation_Response>;
  /** insert data into the table: "locations_names" */
  insert_locations_names?: Maybe<Locations_Names_Mutation_Response>;
  /** insert a single row into the table: "locations_names" */
  insert_locations_names_one?: Maybe<Locations_Names>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<Locations>;
  /** insert data into the table: "mentoring_records" */
  insert_mentoring_records?: Maybe<Mentoring_Records_Mutation_Response>;
  /** insert data into the table: "mentoring_records_members" */
  insert_mentoring_records_members?: Maybe<Mentoring_Records_Members_Mutation_Response>;
  /** insert a single row into the table: "mentoring_records_members" */
  insert_mentoring_records_members_one?: Maybe<Mentoring_Records_Members>;
  /** insert a single row into the table: "mentoring_records" */
  insert_mentoring_records_one?: Maybe<Mentoring_Records>;
  /** insert data into the table: "mentors_blablabla" */
  insert_mentors_blablabla?: Maybe<Mentors_Blablabla_Mutation_Response>;
  /** insert a single row into the table: "mentors_blablabla" */
  insert_mentors_blablabla_one?: Maybe<Mentors_Blablabla>;
  /** insert data into the table: "multimedia_contents" */
  insert_multimedia_contents?: Maybe<Multimedia_Contents_Mutation_Response>;
  /** insert data into the table: "multimedia_contents_links" */
  insert_multimedia_contents_links?: Maybe<Multimedia_Contents_Links_Mutation_Response>;
  /** insert a single row into the table: "multimedia_contents_links" */
  insert_multimedia_contents_links_one?: Maybe<Multimedia_Contents_Links>;
  /** insert a single row into the table: "multimedia_contents" */
  insert_multimedia_contents_one?: Maybe<Multimedia_Contents>;
  /** insert data into the table: "outputs" */
  insert_outputs?: Maybe<Outputs_Mutation_Response>;
  /** insert data into the table: "outputs_icons" */
  insert_outputs_icons?: Maybe<Outputs_Icons_Mutation_Response>;
  /** insert a single row into the table: "outputs_icons" */
  insert_outputs_icons_one?: Maybe<Outputs_Icons>;
  /** insert a single row into the table: "outputs" */
  insert_outputs_one?: Maybe<Outputs>;
  /** insert data into the table: "outputs_tools" */
  insert_outputs_tools?: Maybe<Outputs_Tools_Mutation_Response>;
  /** insert a single row into the table: "outputs_tools" */
  insert_outputs_tools_one?: Maybe<Outputs_Tools>;
  /** insert data into the table: "questionnaires" */
  insert_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** insert a single row into the table: "questionnaires" */
  insert_questionnaires_one?: Maybe<Questionnaires>;
  /** insert data into the table: "questionnaires_teams_states" */
  insert_questionnaires_teams_states?: Maybe<Questionnaires_Teams_States_Mutation_Response>;
  /** insert a single row into the table: "questionnaires_teams_states" */
  insert_questionnaires_teams_states_one?: Maybe<Questionnaires_Teams_States>;
  /** insert data into the table: "questions" */
  insert_questions?: Maybe<Questions_Mutation_Response>;
  /** insert data into the table: "questions_answers" */
  insert_questions_answers?: Maybe<Questions_Answers_Mutation_Response>;
  /** insert a single row into the table: "questions_answers" */
  insert_questions_answers_one?: Maybe<Questions_Answers>;
  /** insert a single row into the table: "questions" */
  insert_questions_one?: Maybe<Questions>;
  /** insert data into the table: "questions_types" */
  insert_questions_types?: Maybe<Questions_Types_Mutation_Response>;
  /** insert a single row into the table: "questions_types" */
  insert_questions_types_one?: Maybe<Questions_Types>;
  /** insert data into the table: "repositories" */
  insert_repositories?: Maybe<Repositories_Mutation_Response>;
  /** insert a single row into the table: "repositories" */
  insert_repositories_one?: Maybe<Repositories>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "sections" */
  insert_sections?: Maybe<Sections_Mutation_Response>;
  /** insert data into the table: "sections_icons" */
  insert_sections_icons?: Maybe<Sections_Icons_Mutation_Response>;
  /** insert a single row into the table: "sections_icons" */
  insert_sections_icons_one?: Maybe<Sections_Icons>;
  /** insert a single row into the table: "sections" */
  insert_sections_one?: Maybe<Sections>;
  /** insert data into the table: "social_medias" */
  insert_social_medias?: Maybe<Social_Medias_Mutation_Response>;
  /** insert a single row into the table: "social_medias" */
  insert_social_medias_one?: Maybe<Social_Medias>;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "team_invites" */
  insert_team_invites?: Maybe<Team_Invites_Mutation_Response>;
  /** insert a single row into the table: "team_invites" */
  insert_team_invites_one?: Maybe<Team_Invites>;
  /** insert data into the table: "teams" */
  insert_teams?: Maybe<Teams_Mutation_Response>;
  /** insert data into the table: "teams_names" */
  insert_teams_names?: Maybe<Teams_Names_Mutation_Response>;
  /** insert a single row into the table: "teams_names" */
  insert_teams_names_one?: Maybe<Teams_Names>;
  /** insert a single row into the table: "teams" */
  insert_teams_one?: Maybe<Teams>;
  /** insert data into the table: "teams_tags" */
  insert_teams_tags?: Maybe<Teams_Tags_Mutation_Response>;
  /** insert a single row into the table: "teams_tags" */
  insert_teams_tags_one?: Maybe<Teams_Tags>;
  /** insert data into the table: "teams_users" */
  insert_teams_users?: Maybe<Teams_Users_Mutation_Response>;
  /** insert a single row into the table: "teams_users" */
  insert_teams_users_one?: Maybe<Teams_Users>;
  /** insert data into the table: "tools" */
  insert_tools?: Maybe<Tools_Mutation_Response>;
  /** insert data into the table: "tools_icons" */
  insert_tools_icons?: Maybe<Tools_Icons_Mutation_Response>;
  /** insert a single row into the table: "tools_icons" */
  insert_tools_icons_one?: Maybe<Tools_Icons>;
  /** insert a single row into the table: "tools" */
  insert_tools_one?: Maybe<Tools>;
  /** insert data into the table: "user_info" */
  insert_user_info?: Maybe<User_Info_Mutation_Response>;
  /** insert a single row into the table: "user_info" */
  insert_user_info_one?: Maybe<User_Info>;
  /** insert data into the table: "user_invites" */
  insert_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** insert a single row into the table: "user_invites" */
  insert_user_invites_one?: Maybe<User_Invites>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert data into the table: "users_actions" */
  insert_users_actions?: Maybe<Users_Actions_Mutation_Response>;
  /** insert a single row into the table: "users_actions" */
  insert_users_actions_one?: Maybe<Users_Actions>;
  /** insert data into the table: "users_actions_types" */
  insert_users_actions_types?: Maybe<Users_Actions_Types_Mutation_Response>;
  /** insert a single row into the table: "users_actions_types" */
  insert_users_actions_types_one?: Maybe<Users_Actions_Types>;
  /** insert data into the table: "users_documents" */
  insert_users_documents?: Maybe<Users_Documents_Mutation_Response>;
  /** insert a single row into the table: "users_documents" */
  insert_users_documents_one?: Maybe<Users_Documents>;
  /** insert data into the table: "users_documents_types" */
  insert_users_documents_types?: Maybe<Users_Documents_Types_Mutation_Response>;
  /** insert a single row into the table: "users_documents_types" */
  insert_users_documents_types_one?: Maybe<Users_Documents_Types>;
  /** insert data into the table: "users_names" */
  insert_users_names?: Maybe<Users_Names_Mutation_Response>;
  /** insert a single row into the table: "users_names" */
  insert_users_names_one?: Maybe<Users_Names>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "video_repositories" */
  insert_video_repositories?: Maybe<Video_Repositories_Mutation_Response>;
  /** insert a single row into the table: "video_repositories" */
  insert_video_repositories_one?: Maybe<Video_Repositories>;
  /** insert data into the table: "videos" */
  insert_videos?: Maybe<Videos_Mutation_Response>;
  /** insert a single row into the table: "videos" */
  insert_videos_one?: Maybe<Videos>;
  /** insert data into the table: "videos_youtube_tags" */
  insert_videos_youtube_tags?: Maybe<Videos_Youtube_Tags_Mutation_Response>;
  /** insert a single row into the table: "videos_youtube_tags" */
  insert_videos_youtube_tags_one?: Maybe<Videos_Youtube_Tags>;
  /** insert data into the table: "youtube_tags" */
  insert_youtube_tags?: Maybe<Youtube_Tags_Mutation_Response>;
  /** insert a single row into the table: "youtube_tags" */
  insert_youtube_tags_one?: Maybe<Youtube_Tags>;
  inviteNewUser?: Maybe<InviteNewUserOutput>;
  inviteUserToTeam?: Maybe<InviteUserToTeamOutput>;
  populateVideos: Scalars['Boolean'];
  respondTeamInvitation?: Maybe<RespondTeamInvitationOutput>;
  updateUserRole?: Maybe<UpdateUserRoleOutput>;
  /** update data of the table: "contents" */
  update_contents?: Maybe<Contents_Mutation_Response>;
  /** update single row of the table: "contents" */
  update_contents_by_pk?: Maybe<Contents>;
  /** update data of the table: "contents_sections" */
  update_contents_sections?: Maybe<Contents_Sections_Mutation_Response>;
  /** update single row of the table: "contents_sections" */
  update_contents_sections_by_pk?: Maybe<Contents_Sections>;
  /** update data of the table: "diagnostics" */
  update_diagnostics?: Maybe<Diagnostics_Mutation_Response>;
  /** update single row of the table: "diagnostics" */
  update_diagnostics_by_pk?: Maybe<Diagnostics>;
  /** update data of the table: "diagnostics_comments" */
  update_diagnostics_comments?: Maybe<Diagnostics_Comments_Mutation_Response>;
  /** update single row of the table: "diagnostics_comments" */
  update_diagnostics_comments_by_pk?: Maybe<Diagnostics_Comments>;
  /** update data of the table: "diagnostics_groups" */
  update_diagnostics_groups?: Maybe<Diagnostics_Groups_Mutation_Response>;
  /** update single row of the table: "diagnostics_groups" */
  update_diagnostics_groups_by_pk?: Maybe<Diagnostics_Groups>;
  /** update data of the table: "diagnostics_questions" */
  update_diagnostics_questions?: Maybe<Diagnostics_Questions_Mutation_Response>;
  /** update data of the table: "diagnostics_questions_answers" */
  update_diagnostics_questions_answers?: Maybe<Diagnostics_Questions_Answers_Mutation_Response>;
  /** update single row of the table: "diagnostics_questions_answers" */
  update_diagnostics_questions_answers_by_pk?: Maybe<Diagnostics_Questions_Answers>;
  /** update single row of the table: "diagnostics_questions" */
  update_diagnostics_questions_by_pk?: Maybe<Diagnostics_Questions>;
  /** update data of the table: "diagnostics_sections" */
  update_diagnostics_sections?: Maybe<Diagnostics_Sections_Mutation_Response>;
  /** update single row of the table: "diagnostics_sections" */
  update_diagnostics_sections_by_pk?: Maybe<Diagnostics_Sections>;
  /** update data of the table: "diagnostics_subsections" */
  update_diagnostics_subsections?: Maybe<Diagnostics_Subsections_Mutation_Response>;
  /** update single row of the table: "diagnostics_subsections" */
  update_diagnostics_subsections_by_pk?: Maybe<Diagnostics_Subsections>;
  /** update data of the table: "diagnostics_tabs" */
  update_diagnostics_tabs?: Maybe<Diagnostics_Tabs_Mutation_Response>;
  /** update single row of the table: "diagnostics_tabs" */
  update_diagnostics_tabs_by_pk?: Maybe<Diagnostics_Tabs>;
  /** update data of the table: "diagnostics_tabs_sections" */
  update_diagnostics_tabs_sections?: Maybe<Diagnostics_Tabs_Sections_Mutation_Response>;
  /** update single row of the table: "diagnostics_tabs_sections" */
  update_diagnostics_tabs_sections_by_pk?: Maybe<Diagnostics_Tabs_Sections>;
  /** update data of the table: "files" */
  update_files?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update data of the table: "groups" */
  update_groups?: Maybe<Groups_Mutation_Response>;
  /** update single row of the table: "groups" */
  update_groups_by_pk?: Maybe<Groups>;
  /** update data of the table: "groups_contents" */
  update_groups_contents?: Maybe<Groups_Contents_Mutation_Response>;
  /** update single row of the table: "groups_contents" */
  update_groups_contents_by_pk?: Maybe<Groups_Contents>;
  /** update data of the table: "groups_names" */
  update_groups_names?: Maybe<Groups_Names_Mutation_Response>;
  /** update single row of the table: "groups_names" */
  update_groups_names_by_pk?: Maybe<Groups_Names>;
  /** update data of the table: "groups_teams" */
  update_groups_teams?: Maybe<Groups_Teams_Mutation_Response>;
  /** update single row of the table: "groups_teams" */
  update_groups_teams_by_pk?: Maybe<Groups_Teams>;
  /** update data of the table: "icons_types" */
  update_icons_types?: Maybe<Icons_Types_Mutation_Response>;
  /** update single row of the table: "icons_types" */
  update_icons_types_by_pk?: Maybe<Icons_Types>;
  /** update data of the table: "invite_states" */
  update_invite_states?: Maybe<Invite_States_Mutation_Response>;
  /** update single row of the table: "invite_states" */
  update_invite_states_by_pk?: Maybe<Invite_States>;
  /** update data of the table: "kanban_columns" */
  update_kanban_columns?: Maybe<Kanban_Columns_Mutation_Response>;
  /** update single row of the table: "kanban_columns" */
  update_kanban_columns_by_pk?: Maybe<Kanban_Columns>;
  /** update data of the table: "kanbans" */
  update_kanbans?: Maybe<Kanbans_Mutation_Response>;
  /** update single row of the table: "kanbans" */
  update_kanbans_by_pk?: Maybe<Kanbans>;
  /** update data of the table: "kanbans_cards" */
  update_kanbans_cards?: Maybe<Kanbans_Cards_Mutation_Response>;
  /** update data of the table: "kanbans_cards_assignations" */
  update_kanbans_cards_assignations?: Maybe<Kanbans_Cards_Assignations_Mutation_Response>;
  /** update single row of the table: "kanbans_cards_assignations" */
  update_kanbans_cards_assignations_by_pk?: Maybe<Kanbans_Cards_Assignations>;
  /** update data of the table: "kanbans_cards_attachments" */
  update_kanbans_cards_attachments?: Maybe<Kanbans_Cards_Attachments_Mutation_Response>;
  /** update single row of the table: "kanbans_cards_attachments" */
  update_kanbans_cards_attachments_by_pk?: Maybe<Kanbans_Cards_Attachments>;
  /** update single row of the table: "kanbans_cards" */
  update_kanbans_cards_by_pk?: Maybe<Kanbans_Cards>;
  /** update data of the table: "kanbans_cards_comments" */
  update_kanbans_cards_comments?: Maybe<Kanbans_Cards_Comments_Mutation_Response>;
  /** update single row of the table: "kanbans_cards_comments" */
  update_kanbans_cards_comments_by_pk?: Maybe<Kanbans_Cards_Comments>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<Locations>;
  /** update data of the table: "locations_names" */
  update_locations_names?: Maybe<Locations_Names_Mutation_Response>;
  /** update single row of the table: "locations_names" */
  update_locations_names_by_pk?: Maybe<Locations_Names>;
  /** update data of the table: "mentoring_records" */
  update_mentoring_records?: Maybe<Mentoring_Records_Mutation_Response>;
  /** update single row of the table: "mentoring_records" */
  update_mentoring_records_by_pk?: Maybe<Mentoring_Records>;
  /** update data of the table: "mentoring_records_members" */
  update_mentoring_records_members?: Maybe<Mentoring_Records_Members_Mutation_Response>;
  /** update single row of the table: "mentoring_records_members" */
  update_mentoring_records_members_by_pk?: Maybe<Mentoring_Records_Members>;
  /** update data of the table: "mentors_blablabla" */
  update_mentors_blablabla?: Maybe<Mentors_Blablabla_Mutation_Response>;
  /** update single row of the table: "mentors_blablabla" */
  update_mentors_blablabla_by_pk?: Maybe<Mentors_Blablabla>;
  /** update data of the table: "multimedia_contents" */
  update_multimedia_contents?: Maybe<Multimedia_Contents_Mutation_Response>;
  /** update single row of the table: "multimedia_contents" */
  update_multimedia_contents_by_pk?: Maybe<Multimedia_Contents>;
  /** update data of the table: "multimedia_contents_links" */
  update_multimedia_contents_links?: Maybe<Multimedia_Contents_Links_Mutation_Response>;
  /** update single row of the table: "multimedia_contents_links" */
  update_multimedia_contents_links_by_pk?: Maybe<Multimedia_Contents_Links>;
  /** update data of the table: "outputs" */
  update_outputs?: Maybe<Outputs_Mutation_Response>;
  /** update single row of the table: "outputs" */
  update_outputs_by_pk?: Maybe<Outputs>;
  /** update data of the table: "outputs_icons" */
  update_outputs_icons?: Maybe<Outputs_Icons_Mutation_Response>;
  /** update single row of the table: "outputs_icons" */
  update_outputs_icons_by_pk?: Maybe<Outputs_Icons>;
  /** update data of the table: "outputs_tools" */
  update_outputs_tools?: Maybe<Outputs_Tools_Mutation_Response>;
  /** update single row of the table: "outputs_tools" */
  update_outputs_tools_by_pk?: Maybe<Outputs_Tools>;
  /** update data of the table: "questionnaires" */
  update_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** update single row of the table: "questionnaires" */
  update_questionnaires_by_pk?: Maybe<Questionnaires>;
  /** update data of the table: "questionnaires_teams_states" */
  update_questionnaires_teams_states?: Maybe<Questionnaires_Teams_States_Mutation_Response>;
  /** update single row of the table: "questionnaires_teams_states" */
  update_questionnaires_teams_states_by_pk?: Maybe<Questionnaires_Teams_States>;
  /** update data of the table: "questions" */
  update_questions?: Maybe<Questions_Mutation_Response>;
  /** update data of the table: "questions_answers" */
  update_questions_answers?: Maybe<Questions_Answers_Mutation_Response>;
  /** update single row of the table: "questions_answers" */
  update_questions_answers_by_pk?: Maybe<Questions_Answers>;
  /** update single row of the table: "questions" */
  update_questions_by_pk?: Maybe<Questions>;
  /** update data of the table: "questions_types" */
  update_questions_types?: Maybe<Questions_Types_Mutation_Response>;
  /** update single row of the table: "questions_types" */
  update_questions_types_by_pk?: Maybe<Questions_Types>;
  /** update data of the table: "repositories" */
  update_repositories?: Maybe<Repositories_Mutation_Response>;
  /** update single row of the table: "repositories" */
  update_repositories_by_pk?: Maybe<Repositories>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update data of the table: "sections" */
  update_sections?: Maybe<Sections_Mutation_Response>;
  /** update single row of the table: "sections" */
  update_sections_by_pk?: Maybe<Sections>;
  /** update data of the table: "sections_icons" */
  update_sections_icons?: Maybe<Sections_Icons_Mutation_Response>;
  /** update single row of the table: "sections_icons" */
  update_sections_icons_by_pk?: Maybe<Sections_Icons>;
  /** update data of the table: "social_medias" */
  update_social_medias?: Maybe<Social_Medias_Mutation_Response>;
  /** update single row of the table: "social_medias" */
  update_social_medias_by_pk?: Maybe<Social_Medias>;
  /** update data of the table: "tags" */
  update_tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update data of the table: "team_invites" */
  update_team_invites?: Maybe<Team_Invites_Mutation_Response>;
  /** update single row of the table: "team_invites" */
  update_team_invites_by_pk?: Maybe<Team_Invites>;
  /** update data of the table: "teams" */
  update_teams?: Maybe<Teams_Mutation_Response>;
  /** update single row of the table: "teams" */
  update_teams_by_pk?: Maybe<Teams>;
  /** update data of the table: "teams_names" */
  update_teams_names?: Maybe<Teams_Names_Mutation_Response>;
  /** update single row of the table: "teams_names" */
  update_teams_names_by_pk?: Maybe<Teams_Names>;
  /** update data of the table: "teams_tags" */
  update_teams_tags?: Maybe<Teams_Tags_Mutation_Response>;
  /** update single row of the table: "teams_tags" */
  update_teams_tags_by_pk?: Maybe<Teams_Tags>;
  /** update data of the table: "teams_users" */
  update_teams_users?: Maybe<Teams_Users_Mutation_Response>;
  /** update single row of the table: "teams_users" */
  update_teams_users_by_pk?: Maybe<Teams_Users>;
  /** update data of the table: "tools" */
  update_tools?: Maybe<Tools_Mutation_Response>;
  /** update single row of the table: "tools" */
  update_tools_by_pk?: Maybe<Tools>;
  /** update data of the table: "tools_icons" */
  update_tools_icons?: Maybe<Tools_Icons_Mutation_Response>;
  /** update single row of the table: "tools_icons" */
  update_tools_icons_by_pk?: Maybe<Tools_Icons>;
  /** update data of the table: "user_info" */
  update_user_info?: Maybe<User_Info_Mutation_Response>;
  /** update single row of the table: "user_info" */
  update_user_info_by_pk?: Maybe<User_Info>;
  /** update data of the table: "user_invites" */
  update_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** update single row of the table: "user_invites" */
  update_user_invites_by_pk?: Maybe<User_Invites>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update data of the table: "users_actions" */
  update_users_actions?: Maybe<Users_Actions_Mutation_Response>;
  /** update single row of the table: "users_actions" */
  update_users_actions_by_pk?: Maybe<Users_Actions>;
  /** update data of the table: "users_actions_types" */
  update_users_actions_types?: Maybe<Users_Actions_Types_Mutation_Response>;
  /** update single row of the table: "users_actions_types" */
  update_users_actions_types_by_pk?: Maybe<Users_Actions_Types>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "users_documents" */
  update_users_documents?: Maybe<Users_Documents_Mutation_Response>;
  /** update single row of the table: "users_documents" */
  update_users_documents_by_pk?: Maybe<Users_Documents>;
  /** update data of the table: "users_documents_types" */
  update_users_documents_types?: Maybe<Users_Documents_Types_Mutation_Response>;
  /** update single row of the table: "users_documents_types" */
  update_users_documents_types_by_pk?: Maybe<Users_Documents_Types>;
  /** update data of the table: "users_names" */
  update_users_names?: Maybe<Users_Names_Mutation_Response>;
  /** update single row of the table: "users_names" */
  update_users_names_by_pk?: Maybe<Users_Names>;
  /** update data of the table: "video_repositories" */
  update_video_repositories?: Maybe<Video_Repositories_Mutation_Response>;
  /** update single row of the table: "video_repositories" */
  update_video_repositories_by_pk?: Maybe<Video_Repositories>;
  /** update data of the table: "videos" */
  update_videos?: Maybe<Videos_Mutation_Response>;
  /** update single row of the table: "videos" */
  update_videos_by_pk?: Maybe<Videos>;
  /** update data of the table: "videos_youtube_tags" */
  update_videos_youtube_tags?: Maybe<Videos_Youtube_Tags_Mutation_Response>;
  /** update single row of the table: "videos_youtube_tags" */
  update_videos_youtube_tags_by_pk?: Maybe<Videos_Youtube_Tags>;
  /** update data of the table: "youtube_tags" */
  update_youtube_tags?: Maybe<Youtube_Tags_Mutation_Response>;
  /** update single row of the table: "youtube_tags" */
  update_youtube_tags_by_pk?: Maybe<Youtube_Tags>;
  uploadFile?: Maybe<UploadOutput>;
  uploadProfilePicture?: Maybe<UploadProfilePictureOutput>;
};


/** mutation root */
export type Mutation_RootCreateUserArgs = {
  address: Scalars['String'];
  address_doc_b64str: Scalars['String'];
  cep: Scalars['String'];
  city: Scalars['String'];
  cpf: Scalars['String'];
  dob: Scalars['date'];
  email: Scalars['String'];
  id_doc_b64str: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  secondLine?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  team_id?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ContentsArgs = {
  where: Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Contents_SectionsArgs = {
  where: Contents_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contents_Sections_By_PkArgs = {
  content_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DiagnosticsArgs = {
  where: Diagnostics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_CommentsArgs = {
  where: Diagnostics_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Comments_By_PkArgs = {
  diagnostic_tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_GroupsArgs = {
  where: Diagnostics_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Groups_By_PkArgs = {
  diagnostic_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_QuestionsArgs = {
  where: Diagnostics_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Questions_AnswersArgs = {
  where: Diagnostics_Questions_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Questions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_SectionsArgs = {
  where: Diagnostics_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_SubsectionsArgs = {
  where: Diagnostics_Subsections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Subsections_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_TabsArgs = {
  where: Diagnostics_Tabs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Tabs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Tabs_SectionsArgs = {
  where: Diagnostics_Tabs_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Tabs_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GroupsArgs = {
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Groups_ContentsArgs = {
  where: Groups_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_Contents_By_PkArgs = {
  content_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Groups_NamesArgs = {
  where: Groups_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Groups_TeamsArgs = {
  where: Groups_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_Teams_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Icons_TypesArgs = {
  where: Icons_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Icons_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Invite_StatesArgs = {
  where: Invite_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invite_States_By_PkArgs = {
  state: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Kanban_ColumnsArgs = {
  where: Kanban_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanban_Columns_By_PkArgs = {
  column: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_KanbansArgs = {
  where: Kanbans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_CardsArgs = {
  where: Kanbans_Cards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_AssignationsArgs = {
  where: Kanbans_Cards_Assignations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_Assignations_By_PkArgs = {
  card_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_AttachmentsArgs = {
  where: Kanbans_Cards_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_Attachments_By_PkArgs = {
  card_id: Scalars['uuid'];
  file_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_CommentsArgs = {
  where: Kanbans_Cards_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Locations_NamesArgs = {
  where: Locations_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locations_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_RecordsArgs = {
  where: Mentoring_Records_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Records_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Records_MembersArgs = {
  where: Mentoring_Records_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Records_Members_By_PkArgs = {
  member_id: Scalars['String'];
  mentoring_record_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentors_BlablablaArgs = {
  where: Mentors_Blablabla_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentors_Blablabla_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Multimedia_ContentsArgs = {
  where: Multimedia_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Multimedia_Contents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Multimedia_Contents_LinksArgs = {
  where: Multimedia_Contents_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Multimedia_Contents_Links_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OutputsArgs = {
  where: Outputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outputs_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Outputs_IconsArgs = {
  where: Outputs_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outputs_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Outputs_ToolsArgs = {
  where: Outputs_Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outputs_Tools_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionnairesArgs = {
  where: Questionnaires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaires_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Questionnaires_Teams_StatesArgs = {
  where: Questionnaires_Teams_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaires_Teams_States_By_PkArgs = {
  questionnaire_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionsArgs = {
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_AnswersArgs = {
  where: Questions_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Questions_TypesArgs = {
  where: Questions_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_RepositoriesArgs = {
  where: Repositories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Repositories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SectionsArgs = {
  where: Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sections_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Sections_IconsArgs = {
  where: Sections_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sections_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Social_MediasArgs = {
  where: Social_Medias_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Social_Medias_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars['uuid'];
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Team_InvitesArgs = {
  where: Team_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_Invites_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TeamsArgs = {
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Teams_NamesArgs = {
  where: Teams_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Teams_TagsArgs = {
  where: Teams_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Teams_UsersArgs = {
  where: Teams_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ToolsArgs = {
  where: Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tools_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Tools_IconsArgs = {
  where: Tools_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tools_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_InfoArgs = {
  where: User_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_InvitesArgs = {
  where: User_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Invites_By_PkArgs = {
  id: Scalars['uuid'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_ActionsArgs = {
  where: Users_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Users_Actions_TypesArgs = {
  where: Users_Actions_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Actions_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  email: Scalars['String'];
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Users_DocumentsArgs = {
  where: Users_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Documents_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Users_Documents_TypesArgs = {
  where: Users_Documents_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Documents_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Users_NamesArgs = {
  where: Users_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Video_RepositoriesArgs = {
  where: Video_Repositories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Video_Repositories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VideosArgs = {
  where: Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Videos_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Videos_Youtube_TagsArgs = {
  where: Videos_Youtube_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Videos_Youtube_Tags_By_PkArgs = {
  tag: Scalars['String'];
  video_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Youtube_TagsArgs = {
  where: Youtube_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Youtube_Tags_By_PkArgs = {
  tag: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_ContentsArgs = {
  objects: Array<Contents_Insert_Input>;
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contents_OneArgs = {
  object: Contents_Insert_Input;
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contents_SectionsArgs = {
  objects: Array<Contents_Sections_Insert_Input>;
  on_conflict?: InputMaybe<Contents_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contents_Sections_OneArgs = {
  object: Contents_Sections_Insert_Input;
  on_conflict?: InputMaybe<Contents_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DiagnosticsArgs = {
  objects: Array<Diagnostics_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_CommentsArgs = {
  objects: Array<Diagnostics_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Comments_OneArgs = {
  object: Diagnostics_Comments_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_GroupsArgs = {
  objects: Array<Diagnostics_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Groups_OneArgs = {
  object: Diagnostics_Groups_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_OneArgs = {
  object: Diagnostics_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_QuestionsArgs = {
  objects: Array<Diagnostics_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Questions_AnswersArgs = {
  objects: Array<Diagnostics_Questions_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Questions_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Questions_Answers_OneArgs = {
  object: Diagnostics_Questions_Answers_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Questions_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Questions_OneArgs = {
  object: Diagnostics_Questions_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_SectionsArgs = {
  objects: Array<Diagnostics_Sections_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Sections_OneArgs = {
  object: Diagnostics_Sections_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_SubsectionsArgs = {
  objects: Array<Diagnostics_Subsections_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Subsections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Subsections_OneArgs = {
  object: Diagnostics_Subsections_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Subsections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_TabsArgs = {
  objects: Array<Diagnostics_Tabs_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Tabs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Tabs_OneArgs = {
  object: Diagnostics_Tabs_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Tabs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Tabs_SectionsArgs = {
  objects: Array<Diagnostics_Tabs_Sections_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Tabs_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Tabs_Sections_OneArgs = {
  object: Diagnostics_Tabs_Sections_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Tabs_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_OneArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GroupsArgs = {
  objects: Array<Groups_Insert_Input>;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_ContentsArgs = {
  objects: Array<Groups_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Groups_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_Contents_OneArgs = {
  object: Groups_Contents_Insert_Input;
  on_conflict?: InputMaybe<Groups_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_NamesArgs = {
  objects: Array<Groups_Names_Insert_Input>;
  on_conflict?: InputMaybe<Groups_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_Names_OneArgs = {
  object: Groups_Names_Insert_Input;
  on_conflict?: InputMaybe<Groups_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_OneArgs = {
  object: Groups_Insert_Input;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_TeamsArgs = {
  objects: Array<Groups_Teams_Insert_Input>;
  on_conflict?: InputMaybe<Groups_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_Teams_OneArgs = {
  object: Groups_Teams_Insert_Input;
  on_conflict?: InputMaybe<Groups_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Icons_TypesArgs = {
  objects: Array<Icons_Types_Insert_Input>;
  on_conflict?: InputMaybe<Icons_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Icons_Types_OneArgs = {
  object: Icons_Types_Insert_Input;
  on_conflict?: InputMaybe<Icons_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invite_StatesArgs = {
  objects: Array<Invite_States_Insert_Input>;
  on_conflict?: InputMaybe<Invite_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invite_States_OneArgs = {
  object: Invite_States_Insert_Input;
  on_conflict?: InputMaybe<Invite_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanban_ColumnsArgs = {
  objects: Array<Kanban_Columns_Insert_Input>;
  on_conflict?: InputMaybe<Kanban_Columns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanban_Columns_OneArgs = {
  object: Kanban_Columns_Insert_Input;
  on_conflict?: InputMaybe<Kanban_Columns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_KanbansArgs = {
  objects: Array<Kanbans_Insert_Input>;
  on_conflict?: InputMaybe<Kanbans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_CardsArgs = {
  objects: Array<Kanbans_Cards_Insert_Input>;
  on_conflict?: InputMaybe<Kanbans_Cards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_AssignationsArgs = {
  objects: Array<Kanbans_Cards_Assignations_Insert_Input>;
  on_conflict?: InputMaybe<Kanbans_Cards_Assignations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_Assignations_OneArgs = {
  object: Kanbans_Cards_Assignations_Insert_Input;
  on_conflict?: InputMaybe<Kanbans_Cards_Assignations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_AttachmentsArgs = {
  objects: Array<Kanbans_Cards_Attachments_Insert_Input>;
  on_conflict?: InputMaybe<Kanbans_Cards_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_Attachments_OneArgs = {
  object: Kanbans_Cards_Attachments_Insert_Input;
  on_conflict?: InputMaybe<Kanbans_Cards_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_CommentsArgs = {
  objects: Array<Kanbans_Cards_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Kanbans_Cards_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_Comments_OneArgs = {
  object: Kanbans_Cards_Comments_Insert_Input;
  on_conflict?: InputMaybe<Kanbans_Cards_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_OneArgs = {
  object: Kanbans_Cards_Insert_Input;
  on_conflict?: InputMaybe<Kanbans_Cards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_OneArgs = {
  object: Kanbans_Insert_Input;
  on_conflict?: InputMaybe<Kanbans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_NamesArgs = {
  objects: Array<Locations_Names_Insert_Input>;
  on_conflict?: InputMaybe<Locations_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_Names_OneArgs = {
  object: Locations_Names_Insert_Input;
  on_conflict?: InputMaybe<Locations_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_RecordsArgs = {
  objects: Array<Mentoring_Records_Insert_Input>;
  on_conflict?: InputMaybe<Mentoring_Records_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Records_MembersArgs = {
  objects: Array<Mentoring_Records_Members_Insert_Input>;
  on_conflict?: InputMaybe<Mentoring_Records_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Records_Members_OneArgs = {
  object: Mentoring_Records_Members_Insert_Input;
  on_conflict?: InputMaybe<Mentoring_Records_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Records_OneArgs = {
  object: Mentoring_Records_Insert_Input;
  on_conflict?: InputMaybe<Mentoring_Records_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentors_BlablablaArgs = {
  objects: Array<Mentors_Blablabla_Insert_Input>;
  on_conflict?: InputMaybe<Mentors_Blablabla_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentors_Blablabla_OneArgs = {
  object: Mentors_Blablabla_Insert_Input;
  on_conflict?: InputMaybe<Mentors_Blablabla_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Multimedia_ContentsArgs = {
  objects: Array<Multimedia_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Multimedia_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Multimedia_Contents_LinksArgs = {
  objects: Array<Multimedia_Contents_Links_Insert_Input>;
  on_conflict?: InputMaybe<Multimedia_Contents_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Multimedia_Contents_Links_OneArgs = {
  object: Multimedia_Contents_Links_Insert_Input;
  on_conflict?: InputMaybe<Multimedia_Contents_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Multimedia_Contents_OneArgs = {
  object: Multimedia_Contents_Insert_Input;
  on_conflict?: InputMaybe<Multimedia_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OutputsArgs = {
  objects: Array<Outputs_Insert_Input>;
  on_conflict?: InputMaybe<Outputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outputs_IconsArgs = {
  objects: Array<Outputs_Icons_Insert_Input>;
  on_conflict?: InputMaybe<Outputs_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outputs_Icons_OneArgs = {
  object: Outputs_Icons_Insert_Input;
  on_conflict?: InputMaybe<Outputs_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outputs_OneArgs = {
  object: Outputs_Insert_Input;
  on_conflict?: InputMaybe<Outputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outputs_ToolsArgs = {
  objects: Array<Outputs_Tools_Insert_Input>;
  on_conflict?: InputMaybe<Outputs_Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outputs_Tools_OneArgs = {
  object: Outputs_Tools_Insert_Input;
  on_conflict?: InputMaybe<Outputs_Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionnairesArgs = {
  objects: Array<Questionnaires_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaires_OneArgs = {
  object: Questionnaires_Insert_Input;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaires_Teams_StatesArgs = {
  objects: Array<Questionnaires_Teams_States_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaires_Teams_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaires_Teams_States_OneArgs = {
  object: Questionnaires_Teams_States_Insert_Input;
  on_conflict?: InputMaybe<Questionnaires_Teams_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionsArgs = {
  objects: Array<Questions_Insert_Input>;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_AnswersArgs = {
  objects: Array<Questions_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Questions_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_Answers_OneArgs = {
  object: Questions_Answers_Insert_Input;
  on_conflict?: InputMaybe<Questions_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_OneArgs = {
  object: Questions_Insert_Input;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_TypesArgs = {
  objects: Array<Questions_Types_Insert_Input>;
  on_conflict?: InputMaybe<Questions_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_Types_OneArgs = {
  object: Questions_Types_Insert_Input;
  on_conflict?: InputMaybe<Questions_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RepositoriesArgs = {
  objects: Array<Repositories_Insert_Input>;
  on_conflict?: InputMaybe<Repositories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Repositories_OneArgs = {
  object: Repositories_Insert_Input;
  on_conflict?: InputMaybe<Repositories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SectionsArgs = {
  objects: Array<Sections_Insert_Input>;
  on_conflict?: InputMaybe<Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sections_IconsArgs = {
  objects: Array<Sections_Icons_Insert_Input>;
  on_conflict?: InputMaybe<Sections_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sections_Icons_OneArgs = {
  object: Sections_Icons_Insert_Input;
  on_conflict?: InputMaybe<Sections_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sections_OneArgs = {
  object: Sections_Insert_Input;
  on_conflict?: InputMaybe<Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Social_MediasArgs = {
  objects: Array<Social_Medias_Insert_Input>;
  on_conflict?: InputMaybe<Social_Medias_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Social_Medias_OneArgs = {
  object: Social_Medias_Insert_Input;
  on_conflict?: InputMaybe<Social_Medias_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_InvitesArgs = {
  objects: Array<Team_Invites_Insert_Input>;
  on_conflict?: InputMaybe<Team_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Invites_OneArgs = {
  object: Team_Invites_Insert_Input;
  on_conflict?: InputMaybe<Team_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamsArgs = {
  objects: Array<Teams_Insert_Input>;
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_NamesArgs = {
  objects: Array<Teams_Names_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_Names_OneArgs = {
  object: Teams_Names_Insert_Input;
  on_conflict?: InputMaybe<Teams_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_OneArgs = {
  object: Teams_Insert_Input;
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_TagsArgs = {
  objects: Array<Teams_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_Tags_OneArgs = {
  object: Teams_Tags_Insert_Input;
  on_conflict?: InputMaybe<Teams_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_UsersArgs = {
  objects: Array<Teams_Users_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_Users_OneArgs = {
  object: Teams_Users_Insert_Input;
  on_conflict?: InputMaybe<Teams_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ToolsArgs = {
  objects: Array<Tools_Insert_Input>;
  on_conflict?: InputMaybe<Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tools_IconsArgs = {
  objects: Array<Tools_Icons_Insert_Input>;
  on_conflict?: InputMaybe<Tools_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tools_Icons_OneArgs = {
  object: Tools_Icons_Insert_Input;
  on_conflict?: InputMaybe<Tools_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tools_OneArgs = {
  object: Tools_Insert_Input;
  on_conflict?: InputMaybe<Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InfoArgs = {
  objects: Array<User_Info_Insert_Input>;
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Info_OneArgs = {
  object: User_Info_Insert_Input;
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InvitesArgs = {
  objects: Array<User_Invites_Insert_Input>;
  on_conflict?: InputMaybe<User_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Invites_OneArgs = {
  object: User_Invites_Insert_Input;
  on_conflict?: InputMaybe<User_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_ActionsArgs = {
  objects: Array<Users_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Users_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Actions_OneArgs = {
  object: Users_Actions_Insert_Input;
  on_conflict?: InputMaybe<Users_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Actions_TypesArgs = {
  objects: Array<Users_Actions_Types_Insert_Input>;
  on_conflict?: InputMaybe<Users_Actions_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Actions_Types_OneArgs = {
  object: Users_Actions_Types_Insert_Input;
  on_conflict?: InputMaybe<Users_Actions_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_DocumentsArgs = {
  objects: Array<Users_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Users_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Documents_OneArgs = {
  object: Users_Documents_Insert_Input;
  on_conflict?: InputMaybe<Users_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Documents_TypesArgs = {
  objects: Array<Users_Documents_Types_Insert_Input>;
  on_conflict?: InputMaybe<Users_Documents_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Documents_Types_OneArgs = {
  object: Users_Documents_Types_Insert_Input;
  on_conflict?: InputMaybe<Users_Documents_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_NamesArgs = {
  objects: Array<Users_Names_Insert_Input>;
  on_conflict?: InputMaybe<Users_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Names_OneArgs = {
  object: Users_Names_Insert_Input;
  on_conflict?: InputMaybe<Users_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Video_RepositoriesArgs = {
  objects: Array<Video_Repositories_Insert_Input>;
  on_conflict?: InputMaybe<Video_Repositories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Video_Repositories_OneArgs = {
  object: Video_Repositories_Insert_Input;
  on_conflict?: InputMaybe<Video_Repositories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VideosArgs = {
  objects: Array<Videos_Insert_Input>;
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Videos_OneArgs = {
  object: Videos_Insert_Input;
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Videos_Youtube_TagsArgs = {
  objects: Array<Videos_Youtube_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Videos_Youtube_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Videos_Youtube_Tags_OneArgs = {
  object: Videos_Youtube_Tags_Insert_Input;
  on_conflict?: InputMaybe<Videos_Youtube_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Youtube_TagsArgs = {
  objects: Array<Youtube_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Youtube_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Youtube_Tags_OneArgs = {
  object: Youtube_Tags_Insert_Input;
  on_conflict?: InputMaybe<Youtube_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInviteNewUserArgs = {
  email: Scalars['String'];
  role: RolesEnum;
  team_id?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootInviteUserToTeamArgs = {
  team_id: Scalars['String'];
  user_email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRespondTeamInvitationArgs = {
  accept: Scalars['Boolean'];
  invite_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateUserRoleArgs = {
  new_role: RolesEnum;
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_ContentsArgs = {
  _set?: InputMaybe<Contents_Set_Input>;
  where: Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_By_PkArgs = {
  _set?: InputMaybe<Contents_Set_Input>;
  pk_columns: Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_SectionsArgs = {
  _set?: InputMaybe<Contents_Sections_Set_Input>;
  where: Contents_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_Sections_By_PkArgs = {
  _set?: InputMaybe<Contents_Sections_Set_Input>;
  pk_columns: Contents_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DiagnosticsArgs = {
  _set?: InputMaybe<Diagnostics_Set_Input>;
  where: Diagnostics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_By_PkArgs = {
  _set?: InputMaybe<Diagnostics_Set_Input>;
  pk_columns: Diagnostics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_CommentsArgs = {
  _set?: InputMaybe<Diagnostics_Comments_Set_Input>;
  where: Diagnostics_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Comments_By_PkArgs = {
  _set?: InputMaybe<Diagnostics_Comments_Set_Input>;
  pk_columns: Diagnostics_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_GroupsArgs = {
  _set?: InputMaybe<Diagnostics_Groups_Set_Input>;
  where: Diagnostics_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Groups_By_PkArgs = {
  _set?: InputMaybe<Diagnostics_Groups_Set_Input>;
  pk_columns: Diagnostics_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_QuestionsArgs = {
  _inc?: InputMaybe<Diagnostics_Questions_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Questions_Set_Input>;
  where: Diagnostics_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Questions_AnswersArgs = {
  _inc?: InputMaybe<Diagnostics_Questions_Answers_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Questions_Answers_Set_Input>;
  where: Diagnostics_Questions_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Questions_Answers_By_PkArgs = {
  _inc?: InputMaybe<Diagnostics_Questions_Answers_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Questions_Answers_Set_Input>;
  pk_columns: Diagnostics_Questions_Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Questions_By_PkArgs = {
  _inc?: InputMaybe<Diagnostics_Questions_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Questions_Set_Input>;
  pk_columns: Diagnostics_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_SectionsArgs = {
  _set?: InputMaybe<Diagnostics_Sections_Set_Input>;
  where: Diagnostics_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Sections_By_PkArgs = {
  _set?: InputMaybe<Diagnostics_Sections_Set_Input>;
  pk_columns: Diagnostics_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_SubsectionsArgs = {
  _inc?: InputMaybe<Diagnostics_Subsections_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Subsections_Set_Input>;
  where: Diagnostics_Subsections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Subsections_By_PkArgs = {
  _inc?: InputMaybe<Diagnostics_Subsections_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Subsections_Set_Input>;
  pk_columns: Diagnostics_Subsections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_TabsArgs = {
  _inc?: InputMaybe<Diagnostics_Tabs_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Tabs_Set_Input>;
  where: Diagnostics_Tabs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Tabs_By_PkArgs = {
  _inc?: InputMaybe<Diagnostics_Tabs_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Tabs_Set_Input>;
  pk_columns: Diagnostics_Tabs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Tabs_SectionsArgs = {
  _inc?: InputMaybe<Diagnostics_Tabs_Sections_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Tabs_Sections_Set_Input>;
  where: Diagnostics_Tabs_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Tabs_Sections_By_PkArgs = {
  _inc?: InputMaybe<Diagnostics_Tabs_Sections_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Tabs_Sections_Set_Input>;
  pk_columns: Diagnostics_Tabs_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FilesArgs = {
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_By_PkArgs = {
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GroupsArgs = {
  _set?: InputMaybe<Groups_Set_Input>;
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_By_PkArgs = {
  _set?: InputMaybe<Groups_Set_Input>;
  pk_columns: Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_ContentsArgs = {
  _set?: InputMaybe<Groups_Contents_Set_Input>;
  where: Groups_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Contents_By_PkArgs = {
  _set?: InputMaybe<Groups_Contents_Set_Input>;
  pk_columns: Groups_Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_NamesArgs = {
  _set?: InputMaybe<Groups_Names_Set_Input>;
  where: Groups_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Names_By_PkArgs = {
  _set?: InputMaybe<Groups_Names_Set_Input>;
  pk_columns: Groups_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_TeamsArgs = {
  _set?: InputMaybe<Groups_Teams_Set_Input>;
  where: Groups_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Teams_By_PkArgs = {
  _set?: InputMaybe<Groups_Teams_Set_Input>;
  pk_columns: Groups_Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Icons_TypesArgs = {
  _set?: InputMaybe<Icons_Types_Set_Input>;
  where: Icons_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Icons_Types_By_PkArgs = {
  _set?: InputMaybe<Icons_Types_Set_Input>;
  pk_columns: Icons_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invite_StatesArgs = {
  _set?: InputMaybe<Invite_States_Set_Input>;
  where: Invite_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invite_States_By_PkArgs = {
  _set?: InputMaybe<Invite_States_Set_Input>;
  pk_columns: Invite_States_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kanban_ColumnsArgs = {
  _set?: InputMaybe<Kanban_Columns_Set_Input>;
  where: Kanban_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanban_Columns_By_PkArgs = {
  _set?: InputMaybe<Kanban_Columns_Set_Input>;
  pk_columns: Kanban_Columns_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_KanbansArgs = {
  _set?: InputMaybe<Kanbans_Set_Input>;
  where: Kanbans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_By_PkArgs = {
  _set?: InputMaybe<Kanbans_Set_Input>;
  pk_columns: Kanbans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_CardsArgs = {
  _set?: InputMaybe<Kanbans_Cards_Set_Input>;
  where: Kanbans_Cards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_AssignationsArgs = {
  _set?: InputMaybe<Kanbans_Cards_Assignations_Set_Input>;
  where: Kanbans_Cards_Assignations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_Assignations_By_PkArgs = {
  _set?: InputMaybe<Kanbans_Cards_Assignations_Set_Input>;
  pk_columns: Kanbans_Cards_Assignations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_AttachmentsArgs = {
  _set?: InputMaybe<Kanbans_Cards_Attachments_Set_Input>;
  where: Kanbans_Cards_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_Attachments_By_PkArgs = {
  _set?: InputMaybe<Kanbans_Cards_Attachments_Set_Input>;
  pk_columns: Kanbans_Cards_Attachments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_By_PkArgs = {
  _set?: InputMaybe<Kanbans_Cards_Set_Input>;
  pk_columns: Kanbans_Cards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_CommentsArgs = {
  _set?: InputMaybe<Kanbans_Cards_Comments_Set_Input>;
  where: Kanbans_Cards_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_Comments_By_PkArgs = {
  _set?: InputMaybe<Kanbans_Cards_Comments_Set_Input>;
  pk_columns: Kanbans_Cards_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_NamesArgs = {
  _set?: InputMaybe<Locations_Names_Set_Input>;
  where: Locations_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_Names_By_PkArgs = {
  _set?: InputMaybe<Locations_Names_Set_Input>;
  pk_columns: Locations_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_RecordsArgs = {
  _inc?: InputMaybe<Mentoring_Records_Inc_Input>;
  _set?: InputMaybe<Mentoring_Records_Set_Input>;
  where: Mentoring_Records_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Records_By_PkArgs = {
  _inc?: InputMaybe<Mentoring_Records_Inc_Input>;
  _set?: InputMaybe<Mentoring_Records_Set_Input>;
  pk_columns: Mentoring_Records_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Records_MembersArgs = {
  _set?: InputMaybe<Mentoring_Records_Members_Set_Input>;
  where: Mentoring_Records_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Records_Members_By_PkArgs = {
  _set?: InputMaybe<Mentoring_Records_Members_Set_Input>;
  pk_columns: Mentoring_Records_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentors_BlablablaArgs = {
  _set?: InputMaybe<Mentors_Blablabla_Set_Input>;
  where: Mentors_Blablabla_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentors_Blablabla_By_PkArgs = {
  _set?: InputMaybe<Mentors_Blablabla_Set_Input>;
  pk_columns: Mentors_Blablabla_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Multimedia_ContentsArgs = {
  _set?: InputMaybe<Multimedia_Contents_Set_Input>;
  where: Multimedia_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Multimedia_Contents_By_PkArgs = {
  _set?: InputMaybe<Multimedia_Contents_Set_Input>;
  pk_columns: Multimedia_Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Multimedia_Contents_LinksArgs = {
  _set?: InputMaybe<Multimedia_Contents_Links_Set_Input>;
  where: Multimedia_Contents_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Multimedia_Contents_Links_By_PkArgs = {
  _set?: InputMaybe<Multimedia_Contents_Links_Set_Input>;
  pk_columns: Multimedia_Contents_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OutputsArgs = {
  _inc?: InputMaybe<Outputs_Inc_Input>;
  _set?: InputMaybe<Outputs_Set_Input>;
  where: Outputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_By_PkArgs = {
  _inc?: InputMaybe<Outputs_Inc_Input>;
  _set?: InputMaybe<Outputs_Set_Input>;
  pk_columns: Outputs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_IconsArgs = {
  _set?: InputMaybe<Outputs_Icons_Set_Input>;
  where: Outputs_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_Icons_By_PkArgs = {
  _set?: InputMaybe<Outputs_Icons_Set_Input>;
  pk_columns: Outputs_Icons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_ToolsArgs = {
  _inc?: InputMaybe<Outputs_Tools_Inc_Input>;
  _set?: InputMaybe<Outputs_Tools_Set_Input>;
  where: Outputs_Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_Tools_By_PkArgs = {
  _inc?: InputMaybe<Outputs_Tools_Inc_Input>;
  _set?: InputMaybe<Outputs_Tools_Set_Input>;
  pk_columns: Outputs_Tools_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnairesArgs = {
  _set?: InputMaybe<Questionnaires_Set_Input>;
  where: Questionnaires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_By_PkArgs = {
  _set?: InputMaybe<Questionnaires_Set_Input>;
  pk_columns: Questionnaires_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_Teams_StatesArgs = {
  _set?: InputMaybe<Questionnaires_Teams_States_Set_Input>;
  where: Questionnaires_Teams_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_Teams_States_By_PkArgs = {
  _set?: InputMaybe<Questionnaires_Teams_States_Set_Input>;
  pk_columns: Questionnaires_Teams_States_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionsArgs = {
  _inc?: InputMaybe<Questions_Inc_Input>;
  _set?: InputMaybe<Questions_Set_Input>;
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_AnswersArgs = {
  _set?: InputMaybe<Questions_Answers_Set_Input>;
  where: Questions_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_Answers_By_PkArgs = {
  _set?: InputMaybe<Questions_Answers_Set_Input>;
  pk_columns: Questions_Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_By_PkArgs = {
  _inc?: InputMaybe<Questions_Inc_Input>;
  _set?: InputMaybe<Questions_Set_Input>;
  pk_columns: Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_TypesArgs = {
  _set?: InputMaybe<Questions_Types_Set_Input>;
  where: Questions_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_Types_By_PkArgs = {
  _set?: InputMaybe<Questions_Types_Set_Input>;
  pk_columns: Questions_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RepositoriesArgs = {
  _set?: InputMaybe<Repositories_Set_Input>;
  where: Repositories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Repositories_By_PkArgs = {
  _set?: InputMaybe<Repositories_Set_Input>;
  pk_columns: Repositories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SectionsArgs = {
  _set?: InputMaybe<Sections_Set_Input>;
  where: Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sections_By_PkArgs = {
  _set?: InputMaybe<Sections_Set_Input>;
  pk_columns: Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sections_IconsArgs = {
  _set?: InputMaybe<Sections_Icons_Set_Input>;
  where: Sections_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sections_Icons_By_PkArgs = {
  _set?: InputMaybe<Sections_Icons_Set_Input>;
  pk_columns: Sections_Icons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Social_MediasArgs = {
  _set?: InputMaybe<Social_Medias_Set_Input>;
  where: Social_Medias_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Social_Medias_By_PkArgs = {
  _set?: InputMaybe<Social_Medias_Set_Input>;
  pk_columns: Social_Medias_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_InvitesArgs = {
  _set?: InputMaybe<Team_Invites_Set_Input>;
  where: Team_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Invites_By_PkArgs = {
  _set?: InputMaybe<Team_Invites_Set_Input>;
  pk_columns: Team_Invites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TeamsArgs = {
  _set?: InputMaybe<Teams_Set_Input>;
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_By_PkArgs = {
  _set?: InputMaybe<Teams_Set_Input>;
  pk_columns: Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_NamesArgs = {
  _set?: InputMaybe<Teams_Names_Set_Input>;
  where: Teams_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_Names_By_PkArgs = {
  _set?: InputMaybe<Teams_Names_Set_Input>;
  pk_columns: Teams_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_TagsArgs = {
  _set?: InputMaybe<Teams_Tags_Set_Input>;
  where: Teams_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_Tags_By_PkArgs = {
  _set?: InputMaybe<Teams_Tags_Set_Input>;
  pk_columns: Teams_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_UsersArgs = {
  _set?: InputMaybe<Teams_Users_Set_Input>;
  where: Teams_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_Users_By_PkArgs = {
  _set?: InputMaybe<Teams_Users_Set_Input>;
  pk_columns: Teams_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ToolsArgs = {
  _inc?: InputMaybe<Tools_Inc_Input>;
  _set?: InputMaybe<Tools_Set_Input>;
  where: Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_By_PkArgs = {
  _inc?: InputMaybe<Tools_Inc_Input>;
  _set?: InputMaybe<Tools_Set_Input>;
  pk_columns: Tools_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_IconsArgs = {
  _set?: InputMaybe<Tools_Icons_Set_Input>;
  where: Tools_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_Icons_By_PkArgs = {
  _set?: InputMaybe<Tools_Icons_Set_Input>;
  pk_columns: Tools_Icons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_InfoArgs = {
  _set?: InputMaybe<User_Info_Set_Input>;
  where: User_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Info_By_PkArgs = {
  _set?: InputMaybe<User_Info_Set_Input>;
  pk_columns: User_Info_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_InvitesArgs = {
  _set?: InputMaybe<User_Invites_Set_Input>;
  where: User_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Invites_By_PkArgs = {
  _set?: InputMaybe<User_Invites_Set_Input>;
  pk_columns: User_Invites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ActionsArgs = {
  _set?: InputMaybe<Users_Actions_Set_Input>;
  where: Users_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_By_PkArgs = {
  _set?: InputMaybe<Users_Actions_Set_Input>;
  pk_columns: Users_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_TypesArgs = {
  _set?: InputMaybe<Users_Actions_Types_Set_Input>;
  where: Users_Actions_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_Types_By_PkArgs = {
  _set?: InputMaybe<Users_Actions_Types_Set_Input>;
  pk_columns: Users_Actions_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_DocumentsArgs = {
  _set?: InputMaybe<Users_Documents_Set_Input>;
  where: Users_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Documents_By_PkArgs = {
  _set?: InputMaybe<Users_Documents_Set_Input>;
  pk_columns: Users_Documents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Documents_TypesArgs = {
  _set?: InputMaybe<Users_Documents_Types_Set_Input>;
  where: Users_Documents_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Documents_Types_By_PkArgs = {
  _set?: InputMaybe<Users_Documents_Types_Set_Input>;
  pk_columns: Users_Documents_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_NamesArgs = {
  _set?: InputMaybe<Users_Names_Set_Input>;
  where: Users_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Names_By_PkArgs = {
  _set?: InputMaybe<Users_Names_Set_Input>;
  pk_columns: Users_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Video_RepositoriesArgs = {
  _set?: InputMaybe<Video_Repositories_Set_Input>;
  where: Video_Repositories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Video_Repositories_By_PkArgs = {
  _set?: InputMaybe<Video_Repositories_Set_Input>;
  pk_columns: Video_Repositories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VideosArgs = {
  _set?: InputMaybe<Videos_Set_Input>;
  where: Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_By_PkArgs = {
  _set?: InputMaybe<Videos_Set_Input>;
  pk_columns: Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_Youtube_TagsArgs = {
  _set?: InputMaybe<Videos_Youtube_Tags_Set_Input>;
  where: Videos_Youtube_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_Youtube_Tags_By_PkArgs = {
  _set?: InputMaybe<Videos_Youtube_Tags_Set_Input>;
  pk_columns: Videos_Youtube_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Youtube_TagsArgs = {
  _set?: InputMaybe<Youtube_Tags_Set_Input>;
  where: Youtube_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Youtube_Tags_By_PkArgs = {
  _set?: InputMaybe<Youtube_Tags_Set_Input>;
  pk_columns: Youtube_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUploadFileArgs = {
  base64str: Scalars['String'];
  container: Scalars['String'];
  filename: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadProfilePictureArgs = {
  b64_img: Scalars['String'];
  user_id: Scalars['String'];
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "outputs" */
export type Outputs = {
  __typename?: 'outputs';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  icons: Array<Outputs_Icons>;
  /** An aggregate relationship */
  icons_aggregate: Outputs_Icons_Aggregate;
  id: Scalars['uuid'];
  intro: Scalars['String'];
  /** An object relationship */
  multimedia_content: Multimedia_Contents;
  multimedia_content_id: Scalars['uuid'];
  name: Scalars['String'];
  order?: Maybe<Scalars['smallint']>;
  parent_section_id: Scalars['uuid'];
  /** An object relationship */
  section: Sections;
  slug: Scalars['String'];
  /** An array relationship */
  tools: Array<Outputs_Tools>;
  /** An aggregate relationship */
  tools_aggregate: Outputs_Tools_Aggregate;
};


/** columns and relationships of "outputs" */
export type OutputsIconsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


/** columns and relationships of "outputs" */
export type OutputsIcons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


/** columns and relationships of "outputs" */
export type OutputsToolsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


/** columns and relationships of "outputs" */
export type OutputsTools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};

/** aggregated selection of "outputs" */
export type Outputs_Aggregate = {
  __typename?: 'outputs_aggregate';
  aggregate?: Maybe<Outputs_Aggregate_Fields>;
  nodes: Array<Outputs>;
};

/** aggregate fields of "outputs" */
export type Outputs_Aggregate_Fields = {
  __typename?: 'outputs_aggregate_fields';
  avg?: Maybe<Outputs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Outputs_Max_Fields>;
  min?: Maybe<Outputs_Min_Fields>;
  stddev?: Maybe<Outputs_Stddev_Fields>;
  stddev_pop?: Maybe<Outputs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outputs_Stddev_Samp_Fields>;
  sum?: Maybe<Outputs_Sum_Fields>;
  var_pop?: Maybe<Outputs_Var_Pop_Fields>;
  var_samp?: Maybe<Outputs_Var_Samp_Fields>;
  variance?: Maybe<Outputs_Variance_Fields>;
};


/** aggregate fields of "outputs" */
export type Outputs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Outputs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outputs" */
export type Outputs_Aggregate_Order_By = {
  avg?: InputMaybe<Outputs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Outputs_Max_Order_By>;
  min?: InputMaybe<Outputs_Min_Order_By>;
  stddev?: InputMaybe<Outputs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Outputs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Outputs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Outputs_Sum_Order_By>;
  var_pop?: InputMaybe<Outputs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Outputs_Var_Samp_Order_By>;
  variance?: InputMaybe<Outputs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outputs" */
export type Outputs_Arr_Rel_Insert_Input = {
  data: Array<Outputs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Outputs_On_Conflict>;
};

/** aggregate avg on columns */
export type Outputs_Avg_Fields = {
  __typename?: 'outputs_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "outputs" */
export type Outputs_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outputs". All fields are combined with a logical 'AND'. */
export type Outputs_Bool_Exp = {
  _and?: InputMaybe<Array<Outputs_Bool_Exp>>;
  _not?: InputMaybe<Outputs_Bool_Exp>;
  _or?: InputMaybe<Array<Outputs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  icons?: InputMaybe<Outputs_Icons_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  intro?: InputMaybe<String_Comparison_Exp>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Bool_Exp>;
  multimedia_content_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  parent_section_id?: InputMaybe<Uuid_Comparison_Exp>;
  section?: InputMaybe<Sections_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  tools?: InputMaybe<Outputs_Tools_Bool_Exp>;
};

/** unique or primary key constraints on table "outputs" */
export enum Outputs_Constraint {
  /** unique or primary key constraint */
  OutputsIdKey = 'outputs_id_key',
  /** unique or primary key constraint */
  OutputsOrderParentSectionIdKey = 'outputs_order_parent_section_id_key',
  /** unique or primary key constraint */
  OutputsPkey = 'outputs_pkey',
  /** unique or primary key constraint */
  OutputsSlugKey = 'outputs_slug_key'
}

/** columns and relationships of "outputs_icons" */
export type Outputs_Icons = {
  __typename?: 'outputs_icons';
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  output: Outputs;
  output_id: Scalars['uuid'];
  type: Icons_Types_Enum;
};

/** aggregated selection of "outputs_icons" */
export type Outputs_Icons_Aggregate = {
  __typename?: 'outputs_icons_aggregate';
  aggregate?: Maybe<Outputs_Icons_Aggregate_Fields>;
  nodes: Array<Outputs_Icons>;
};

/** aggregate fields of "outputs_icons" */
export type Outputs_Icons_Aggregate_Fields = {
  __typename?: 'outputs_icons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Outputs_Icons_Max_Fields>;
  min?: Maybe<Outputs_Icons_Min_Fields>;
};


/** aggregate fields of "outputs_icons" */
export type Outputs_Icons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outputs_icons" */
export type Outputs_Icons_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Outputs_Icons_Max_Order_By>;
  min?: InputMaybe<Outputs_Icons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "outputs_icons" */
export type Outputs_Icons_Arr_Rel_Insert_Input = {
  data: Array<Outputs_Icons_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Outputs_Icons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "outputs_icons". All fields are combined with a logical 'AND'. */
export type Outputs_Icons_Bool_Exp = {
  _and?: InputMaybe<Array<Outputs_Icons_Bool_Exp>>;
  _not?: InputMaybe<Outputs_Icons_Bool_Exp>;
  _or?: InputMaybe<Array<Outputs_Icons_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  output?: InputMaybe<Outputs_Bool_Exp>;
  output_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Icons_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "outputs_icons" */
export enum Outputs_Icons_Constraint {
  /** unique or primary key constraint */
  OutputsIconsOutputIdFileIdKey = 'outputs_icons_output_id_file_id_key',
  /** unique or primary key constraint */
  OutputsIconsOutputIdTypeKey = 'outputs_icons_output_id_type_key',
  /** unique or primary key constraint */
  OutputsIconsPkey = 'outputs_icons_pkey'
}

/** input type for inserting data into table "outputs_icons" */
export type Outputs_Icons_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  output?: InputMaybe<Outputs_Obj_Rel_Insert_Input>;
  output_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** aggregate max on columns */
export type Outputs_Icons_Max_Fields = {
  __typename?: 'outputs_icons_max_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  output_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "outputs_icons" */
export type Outputs_Icons_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  output_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Outputs_Icons_Min_Fields = {
  __typename?: 'outputs_icons_min_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  output_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "outputs_icons" */
export type Outputs_Icons_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  output_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "outputs_icons" */
export type Outputs_Icons_Mutation_Response = {
  __typename?: 'outputs_icons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outputs_Icons>;
};

/** on_conflict condition type for table "outputs_icons" */
export type Outputs_Icons_On_Conflict = {
  constraint: Outputs_Icons_Constraint;
  update_columns?: Array<Outputs_Icons_Update_Column>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};

/** Ordering options when selecting data from "outputs_icons". */
export type Outputs_Icons_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  output?: InputMaybe<Outputs_Order_By>;
  output_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: outputs_icons */
export type Outputs_Icons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "outputs_icons" */
export enum Outputs_Icons_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  OutputId = 'output_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "outputs_icons" */
export type Outputs_Icons_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  output_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** update columns of table "outputs_icons" */
export enum Outputs_Icons_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  OutputId = 'output_id',
  /** column name */
  Type = 'type'
}

/** input type for incrementing numeric columns in table "outputs" */
export type Outputs_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "outputs" */
export type Outputs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  icons?: InputMaybe<Outputs_Icons_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Obj_Rel_Insert_Input>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['smallint']>;
  parent_section_id?: InputMaybe<Scalars['uuid']>;
  section?: InputMaybe<Sections_Obj_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  tools?: InputMaybe<Outputs_Tools_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Outputs_Max_Fields = {
  __typename?: 'outputs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['smallint']>;
  parent_section_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "outputs" */
export type Outputs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intro?: InputMaybe<Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parent_section_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Outputs_Min_Fields = {
  __typename?: 'outputs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['smallint']>;
  parent_section_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "outputs" */
export type Outputs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intro?: InputMaybe<Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parent_section_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "outputs" */
export type Outputs_Mutation_Response = {
  __typename?: 'outputs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outputs>;
};

/** input type for inserting object relation for remote table "outputs" */
export type Outputs_Obj_Rel_Insert_Input = {
  data: Outputs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Outputs_On_Conflict>;
};

/** on_conflict condition type for table "outputs" */
export type Outputs_On_Conflict = {
  constraint: Outputs_Constraint;
  update_columns?: Array<Outputs_Update_Column>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};

/** Ordering options when selecting data from "outputs". */
export type Outputs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  icons_aggregate?: InputMaybe<Outputs_Icons_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  intro?: InputMaybe<Order_By>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parent_section_id?: InputMaybe<Order_By>;
  section?: InputMaybe<Sections_Order_By>;
  slug?: InputMaybe<Order_By>;
  tools_aggregate?: InputMaybe<Outputs_Tools_Aggregate_Order_By>;
};

/** primary key columns input for table: outputs */
export type Outputs_Pk_Columns_Input = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};

/** select columns of table "outputs" */
export enum Outputs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  MultimediaContentId = 'multimedia_content_id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  ParentSectionId = 'parent_section_id',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "outputs" */
export type Outputs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['smallint']>;
  parent_section_id?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Outputs_Stddev_Fields = {
  __typename?: 'outputs_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "outputs" */
export type Outputs_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outputs_Stddev_Pop_Fields = {
  __typename?: 'outputs_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "outputs" */
export type Outputs_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outputs_Stddev_Samp_Fields = {
  __typename?: 'outputs_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "outputs" */
export type Outputs_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Outputs_Sum_Fields = {
  __typename?: 'outputs_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "outputs" */
export type Outputs_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "outputs_tools" */
export type Outputs_Tools = {
  __typename?: 'outputs_tools';
  id: Scalars['uuid'];
  order?: Maybe<Scalars['smallint']>;
  /** An object relationship */
  output: Outputs;
  output_id: Scalars['uuid'];
  /** An object relationship */
  tool: Tools;
  tool_id: Scalars['uuid'];
};

/** aggregated selection of "outputs_tools" */
export type Outputs_Tools_Aggregate = {
  __typename?: 'outputs_tools_aggregate';
  aggregate?: Maybe<Outputs_Tools_Aggregate_Fields>;
  nodes: Array<Outputs_Tools>;
};

/** aggregate fields of "outputs_tools" */
export type Outputs_Tools_Aggregate_Fields = {
  __typename?: 'outputs_tools_aggregate_fields';
  avg?: Maybe<Outputs_Tools_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Outputs_Tools_Max_Fields>;
  min?: Maybe<Outputs_Tools_Min_Fields>;
  stddev?: Maybe<Outputs_Tools_Stddev_Fields>;
  stddev_pop?: Maybe<Outputs_Tools_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outputs_Tools_Stddev_Samp_Fields>;
  sum?: Maybe<Outputs_Tools_Sum_Fields>;
  var_pop?: Maybe<Outputs_Tools_Var_Pop_Fields>;
  var_samp?: Maybe<Outputs_Tools_Var_Samp_Fields>;
  variance?: Maybe<Outputs_Tools_Variance_Fields>;
};


/** aggregate fields of "outputs_tools" */
export type Outputs_Tools_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outputs_tools" */
export type Outputs_Tools_Aggregate_Order_By = {
  avg?: InputMaybe<Outputs_Tools_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Outputs_Tools_Max_Order_By>;
  min?: InputMaybe<Outputs_Tools_Min_Order_By>;
  stddev?: InputMaybe<Outputs_Tools_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Outputs_Tools_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Outputs_Tools_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Outputs_Tools_Sum_Order_By>;
  var_pop?: InputMaybe<Outputs_Tools_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Outputs_Tools_Var_Samp_Order_By>;
  variance?: InputMaybe<Outputs_Tools_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outputs_tools" */
export type Outputs_Tools_Arr_Rel_Insert_Input = {
  data: Array<Outputs_Tools_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Outputs_Tools_On_Conflict>;
};

/** aggregate avg on columns */
export type Outputs_Tools_Avg_Fields = {
  __typename?: 'outputs_tools_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "outputs_tools" */
export type Outputs_Tools_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outputs_tools". All fields are combined with a logical 'AND'. */
export type Outputs_Tools_Bool_Exp = {
  _and?: InputMaybe<Array<Outputs_Tools_Bool_Exp>>;
  _not?: InputMaybe<Outputs_Tools_Bool_Exp>;
  _or?: InputMaybe<Array<Outputs_Tools_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  output?: InputMaybe<Outputs_Bool_Exp>;
  output_id?: InputMaybe<Uuid_Comparison_Exp>;
  tool?: InputMaybe<Tools_Bool_Exp>;
  tool_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "outputs_tools" */
export enum Outputs_Tools_Constraint {
  /** unique or primary key constraint */
  OutputsToolsOutputIdToolIdKey = 'outputs_tools_output_id_tool_id_key',
  /** unique or primary key constraint */
  OutputsToolsOutputIdToolIdOrderKey = 'outputs_tools_output_id_tool_id_order_key',
  /** unique or primary key constraint */
  OutputsToolsPkey = 'outputs_tools_pkey'
}

/** input type for incrementing numeric columns in table "outputs_tools" */
export type Outputs_Tools_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "outputs_tools" */
export type Outputs_Tools_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  output?: InputMaybe<Outputs_Obj_Rel_Insert_Input>;
  output_id?: InputMaybe<Scalars['uuid']>;
  tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  tool_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Outputs_Tools_Max_Fields = {
  __typename?: 'outputs_tools_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  output_id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "outputs_tools" */
export type Outputs_Tools_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  output_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Outputs_Tools_Min_Fields = {
  __typename?: 'outputs_tools_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  output_id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "outputs_tools" */
export type Outputs_Tools_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  output_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "outputs_tools" */
export type Outputs_Tools_Mutation_Response = {
  __typename?: 'outputs_tools_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outputs_Tools>;
};

/** on_conflict condition type for table "outputs_tools" */
export type Outputs_Tools_On_Conflict = {
  constraint: Outputs_Tools_Constraint;
  update_columns?: Array<Outputs_Tools_Update_Column>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};

/** Ordering options when selecting data from "outputs_tools". */
export type Outputs_Tools_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  output?: InputMaybe<Outputs_Order_By>;
  output_id?: InputMaybe<Order_By>;
  tool?: InputMaybe<Tools_Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: outputs_tools */
export type Outputs_Tools_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "outputs_tools" */
export enum Outputs_Tools_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  OutputId = 'output_id',
  /** column name */
  ToolId = 'tool_id'
}

/** input type for updating data in table "outputs_tools" */
export type Outputs_Tools_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  output_id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Outputs_Tools_Stddev_Fields = {
  __typename?: 'outputs_tools_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "outputs_tools" */
export type Outputs_Tools_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outputs_Tools_Stddev_Pop_Fields = {
  __typename?: 'outputs_tools_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "outputs_tools" */
export type Outputs_Tools_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outputs_Tools_Stddev_Samp_Fields = {
  __typename?: 'outputs_tools_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "outputs_tools" */
export type Outputs_Tools_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Outputs_Tools_Sum_Fields = {
  __typename?: 'outputs_tools_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "outputs_tools" */
export type Outputs_Tools_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "outputs_tools" */
export enum Outputs_Tools_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  OutputId = 'output_id',
  /** column name */
  ToolId = 'tool_id'
}

/** aggregate var_pop on columns */
export type Outputs_Tools_Var_Pop_Fields = {
  __typename?: 'outputs_tools_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "outputs_tools" */
export type Outputs_Tools_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outputs_Tools_Var_Samp_Fields = {
  __typename?: 'outputs_tools_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "outputs_tools" */
export type Outputs_Tools_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Outputs_Tools_Variance_Fields = {
  __typename?: 'outputs_tools_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "outputs_tools" */
export type Outputs_Tools_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "outputs" */
export enum Outputs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  MultimediaContentId = 'multimedia_content_id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  ParentSectionId = 'parent_section_id',
  /** column name */
  Slug = 'slug'
}

/** aggregate var_pop on columns */
export type Outputs_Var_Pop_Fields = {
  __typename?: 'outputs_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "outputs" */
export type Outputs_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outputs_Var_Samp_Fields = {
  __typename?: 'outputs_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "outputs" */
export type Outputs_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Outputs_Variance_Fields = {
  __typename?: 'outputs_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "outputs" */
export type Outputs_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** Checks invite status */
  checkInviteStatus?: Maybe<CheckInviteStatusOutput>;
  /** fetch data from the table: "contents" */
  contents: Array<Contents>;
  /** fetch aggregated fields from the table: "contents" */
  contents_aggregate: Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  contents_by_pk?: Maybe<Contents>;
  /** An array relationship */
  contents_sections: Array<Contents_Sections>;
  /** An aggregate relationship */
  contents_sections_aggregate: Contents_Sections_Aggregate;
  /** fetch data from the table: "contents_sections" using primary key columns */
  contents_sections_by_pk?: Maybe<Contents_Sections>;
  createUser?: Maybe<CreateUser>;
  /** fetch data from the table: "diagnostics" */
  diagnostics: Array<Diagnostics>;
  /** fetch aggregated fields from the table: "diagnostics" */
  diagnostics_aggregate: Diagnostics_Aggregate;
  /** fetch data from the table: "diagnostics" using primary key columns */
  diagnostics_by_pk?: Maybe<Diagnostics>;
  /** fetch data from the table: "diagnostics_comments" */
  diagnostics_comments: Array<Diagnostics_Comments>;
  /** fetch aggregated fields from the table: "diagnostics_comments" */
  diagnostics_comments_aggregate: Diagnostics_Comments_Aggregate;
  /** fetch data from the table: "diagnostics_comments" using primary key columns */
  diagnostics_comments_by_pk?: Maybe<Diagnostics_Comments>;
  /** fetch data from the table: "diagnostics_groups" */
  diagnostics_groups: Array<Diagnostics_Groups>;
  /** fetch aggregated fields from the table: "diagnostics_groups" */
  diagnostics_groups_aggregate: Diagnostics_Groups_Aggregate;
  /** fetch data from the table: "diagnostics_groups" using primary key columns */
  diagnostics_groups_by_pk?: Maybe<Diagnostics_Groups>;
  /** fetch data from the table: "diagnostics_questions" */
  diagnostics_questions: Array<Diagnostics_Questions>;
  /** fetch aggregated fields from the table: "diagnostics_questions" */
  diagnostics_questions_aggregate: Diagnostics_Questions_Aggregate;
  /** fetch data from the table: "diagnostics_questions_answers" */
  diagnostics_questions_answers: Array<Diagnostics_Questions_Answers>;
  /** fetch aggregated fields from the table: "diagnostics_questions_answers" */
  diagnostics_questions_answers_aggregate: Diagnostics_Questions_Answers_Aggregate;
  /** fetch data from the table: "diagnostics_questions_answers" using primary key columns */
  diagnostics_questions_answers_by_pk?: Maybe<Diagnostics_Questions_Answers>;
  /** fetch data from the table: "diagnostics_questions" using primary key columns */
  diagnostics_questions_by_pk?: Maybe<Diagnostics_Questions>;
  /** fetch data from the table: "diagnostics_sections" */
  diagnostics_sections: Array<Diagnostics_Sections>;
  /** fetch aggregated fields from the table: "diagnostics_sections" */
  diagnostics_sections_aggregate: Diagnostics_Sections_Aggregate;
  /** fetch data from the table: "diagnostics_sections" using primary key columns */
  diagnostics_sections_by_pk?: Maybe<Diagnostics_Sections>;
  /** fetch data from the table: "diagnostics_subsections" */
  diagnostics_subsections: Array<Diagnostics_Subsections>;
  /** fetch aggregated fields from the table: "diagnostics_subsections" */
  diagnostics_subsections_aggregate: Diagnostics_Subsections_Aggregate;
  /** fetch data from the table: "diagnostics_subsections" using primary key columns */
  diagnostics_subsections_by_pk?: Maybe<Diagnostics_Subsections>;
  /** fetch data from the table: "diagnostics_tabs" */
  diagnostics_tabs: Array<Diagnostics_Tabs>;
  /** fetch aggregated fields from the table: "diagnostics_tabs" */
  diagnostics_tabs_aggregate: Diagnostics_Tabs_Aggregate;
  /** fetch data from the table: "diagnostics_tabs" using primary key columns */
  diagnostics_tabs_by_pk?: Maybe<Diagnostics_Tabs>;
  /** fetch data from the table: "diagnostics_tabs_sections" */
  diagnostics_tabs_sections: Array<Diagnostics_Tabs_Sections>;
  /** fetch aggregated fields from the table: "diagnostics_tabs_sections" */
  diagnostics_tabs_sections_aggregate: Diagnostics_Tabs_Sections_Aggregate;
  /** fetch data from the table: "diagnostics_tabs_sections" using primary key columns */
  diagnostics_tabs_sections_by_pk?: Maybe<Diagnostics_Tabs_Sections>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  getStorageSASUri: Scalars['String'];
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table: "groups_contents" */
  groups_contents: Array<Groups_Contents>;
  /** fetch aggregated fields from the table: "groups_contents" */
  groups_contents_aggregate: Groups_Contents_Aggregate;
  /** fetch data from the table: "groups_contents" using primary key columns */
  groups_contents_by_pk?: Maybe<Groups_Contents>;
  /** fetch data from the table: "groups_names" */
  groups_names: Array<Groups_Names>;
  /** fetch aggregated fields from the table: "groups_names" */
  groups_names_aggregate: Groups_Names_Aggregate;
  /** fetch data from the table: "groups_names" using primary key columns */
  groups_names_by_pk?: Maybe<Groups_Names>;
  /** fetch data from the table: "groups_teams" */
  groups_teams: Array<Groups_Teams>;
  /** fetch aggregated fields from the table: "groups_teams" */
  groups_teams_aggregate: Groups_Teams_Aggregate;
  /** fetch data from the table: "groups_teams" using primary key columns */
  groups_teams_by_pk?: Maybe<Groups_Teams>;
  /** fetch data from the table: "icons_types" */
  icons_types: Array<Icons_Types>;
  /** fetch aggregated fields from the table: "icons_types" */
  icons_types_aggregate: Icons_Types_Aggregate;
  /** fetch data from the table: "icons_types" using primary key columns */
  icons_types_by_pk?: Maybe<Icons_Types>;
  /** fetch data from the table: "invite_states" */
  invite_states: Array<Invite_States>;
  /** fetch aggregated fields from the table: "invite_states" */
  invite_states_aggregate: Invite_States_Aggregate;
  /** fetch data from the table: "invite_states" using primary key columns */
  invite_states_by_pk?: Maybe<Invite_States>;
  /** fetch data from the table: "kanban_columns" */
  kanban_columns: Array<Kanban_Columns>;
  /** fetch aggregated fields from the table: "kanban_columns" */
  kanban_columns_aggregate: Kanban_Columns_Aggregate;
  /** fetch data from the table: "kanban_columns" using primary key columns */
  kanban_columns_by_pk?: Maybe<Kanban_Columns>;
  /** fetch data from the table: "kanbans" */
  kanbans: Array<Kanbans>;
  /** fetch aggregated fields from the table: "kanbans" */
  kanbans_aggregate: Kanbans_Aggregate;
  /** fetch data from the table: "kanbans" using primary key columns */
  kanbans_by_pk?: Maybe<Kanbans>;
  /** fetch data from the table: "kanbans_cards" */
  kanbans_cards: Array<Kanbans_Cards>;
  /** fetch aggregated fields from the table: "kanbans_cards" */
  kanbans_cards_aggregate: Kanbans_Cards_Aggregate;
  /** fetch data from the table: "kanbans_cards_assignations" */
  kanbans_cards_assignations: Array<Kanbans_Cards_Assignations>;
  /** fetch aggregated fields from the table: "kanbans_cards_assignations" */
  kanbans_cards_assignations_aggregate: Kanbans_Cards_Assignations_Aggregate;
  /** fetch data from the table: "kanbans_cards_assignations" using primary key columns */
  kanbans_cards_assignations_by_pk?: Maybe<Kanbans_Cards_Assignations>;
  /** fetch data from the table: "kanbans_cards_attachments" */
  kanbans_cards_attachments: Array<Kanbans_Cards_Attachments>;
  /** fetch aggregated fields from the table: "kanbans_cards_attachments" */
  kanbans_cards_attachments_aggregate: Kanbans_Cards_Attachments_Aggregate;
  /** fetch data from the table: "kanbans_cards_attachments" using primary key columns */
  kanbans_cards_attachments_by_pk?: Maybe<Kanbans_Cards_Attachments>;
  /** fetch data from the table: "kanbans_cards" using primary key columns */
  kanbans_cards_by_pk?: Maybe<Kanbans_Cards>;
  /** fetch data from the table: "kanbans_cards_comments" */
  kanbans_cards_comments: Array<Kanbans_Cards_Comments>;
  /** fetch aggregated fields from the table: "kanbans_cards_comments" */
  kanbans_cards_comments_aggregate: Kanbans_Cards_Comments_Aggregate;
  /** fetch data from the table: "kanbans_cards_comments" using primary key columns */
  kanbans_cards_comments_by_pk?: Maybe<Kanbans_Cards_Comments>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table: "locations_names" */
  locations_names: Array<Locations_Names>;
  /** fetch aggregated fields from the table: "locations_names" */
  locations_names_aggregate: Locations_Names_Aggregate;
  /** fetch data from the table: "locations_names" using primary key columns */
  locations_names_by_pk?: Maybe<Locations_Names>;
  /** fetch data from the table: "mentoring_records" */
  mentoring_records: Array<Mentoring_Records>;
  /** fetch aggregated fields from the table: "mentoring_records" */
  mentoring_records_aggregate: Mentoring_Records_Aggregate;
  /** fetch data from the table: "mentoring_records" using primary key columns */
  mentoring_records_by_pk?: Maybe<Mentoring_Records>;
  /** fetch data from the table: "mentoring_records_members" */
  mentoring_records_members: Array<Mentoring_Records_Members>;
  /** fetch aggregated fields from the table: "mentoring_records_members" */
  mentoring_records_members_aggregate: Mentoring_Records_Members_Aggregate;
  /** fetch data from the table: "mentoring_records_members" using primary key columns */
  mentoring_records_members_by_pk?: Maybe<Mentoring_Records_Members>;
  /** fetch data from the table: "mentors_blablabla" */
  mentors_blablabla: Array<Mentors_Blablabla>;
  /** fetch aggregated fields from the table: "mentors_blablabla" */
  mentors_blablabla_aggregate: Mentors_Blablabla_Aggregate;
  /** fetch data from the table: "mentors_blablabla" using primary key columns */
  mentors_blablabla_by_pk?: Maybe<Mentors_Blablabla>;
  /** fetch data from the table: "multimedia_contents" */
  multimedia_contents: Array<Multimedia_Contents>;
  /** fetch aggregated fields from the table: "multimedia_contents" */
  multimedia_contents_aggregate: Multimedia_Contents_Aggregate;
  /** fetch data from the table: "multimedia_contents" using primary key columns */
  multimedia_contents_by_pk?: Maybe<Multimedia_Contents>;
  /** fetch data from the table: "multimedia_contents_links" */
  multimedia_contents_links: Array<Multimedia_Contents_Links>;
  /** fetch aggregated fields from the table: "multimedia_contents_links" */
  multimedia_contents_links_aggregate: Multimedia_Contents_Links_Aggregate;
  /** fetch data from the table: "multimedia_contents_links" using primary key columns */
  multimedia_contents_links_by_pk?: Maybe<Multimedia_Contents_Links>;
  /** An array relationship */
  outputs: Array<Outputs>;
  /** An aggregate relationship */
  outputs_aggregate: Outputs_Aggregate;
  /** fetch data from the table: "outputs" using primary key columns */
  outputs_by_pk?: Maybe<Outputs>;
  /** fetch data from the table: "outputs_icons" */
  outputs_icons: Array<Outputs_Icons>;
  /** fetch aggregated fields from the table: "outputs_icons" */
  outputs_icons_aggregate: Outputs_Icons_Aggregate;
  /** fetch data from the table: "outputs_icons" using primary key columns */
  outputs_icons_by_pk?: Maybe<Outputs_Icons>;
  /** fetch data from the table: "outputs_tools" */
  outputs_tools: Array<Outputs_Tools>;
  /** fetch aggregated fields from the table: "outputs_tools" */
  outputs_tools_aggregate: Outputs_Tools_Aggregate;
  /** fetch data from the table: "outputs_tools" using primary key columns */
  outputs_tools_by_pk?: Maybe<Outputs_Tools>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An aggregate relationship */
  questionnaires_aggregate: Questionnaires_Aggregate;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table: "questionnaires_teams_states" */
  questionnaires_teams_states: Array<Questionnaires_Teams_States>;
  /** fetch aggregated fields from the table: "questionnaires_teams_states" */
  questionnaires_teams_states_aggregate: Questionnaires_Teams_States_Aggregate;
  /** fetch data from the table: "questionnaires_teams_states" using primary key columns */
  questionnaires_teams_states_by_pk?: Maybe<Questionnaires_Teams_States>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** An array relationship */
  questions_answers: Array<Questions_Answers>;
  /** An aggregate relationship */
  questions_answers_aggregate: Questions_Answers_Aggregate;
  /** fetch data from the table: "questions_answers" using primary key columns */
  questions_answers_by_pk?: Maybe<Questions_Answers>;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table: "questions_types" */
  questions_types: Array<Questions_Types>;
  /** fetch aggregated fields from the table: "questions_types" */
  questions_types_aggregate: Questions_Types_Aggregate;
  /** fetch data from the table: "questions_types" using primary key columns */
  questions_types_by_pk?: Maybe<Questions_Types>;
  /** An array relationship */
  repositories: Array<Repositories>;
  /** An aggregate relationship */
  repositories_aggregate: Repositories_Aggregate;
  /** fetch data from the table: "repositories" using primary key columns */
  repositories_by_pk?: Maybe<Repositories>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "sections" */
  sections: Array<Sections>;
  /** fetch aggregated fields from the table: "sections" */
  sections_aggregate: Sections_Aggregate;
  /** fetch data from the table: "sections" using primary key columns */
  sections_by_pk?: Maybe<Sections>;
  /** fetch data from the table: "sections_icons" */
  sections_icons: Array<Sections_Icons>;
  /** fetch aggregated fields from the table: "sections_icons" */
  sections_icons_aggregate: Sections_Icons_Aggregate;
  /** fetch data from the table: "sections_icons" using primary key columns */
  sections_icons_by_pk?: Maybe<Sections_Icons>;
  /** fetch data from the table: "social_medias" */
  social_medias: Array<Social_Medias>;
  /** fetch aggregated fields from the table: "social_medias" */
  social_medias_aggregate: Social_Medias_Aggregate;
  /** fetch data from the table: "social_medias" using primary key columns */
  social_medias_by_pk?: Maybe<Social_Medias>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table: "team_invites" */
  team_invites: Array<Team_Invites>;
  /** fetch aggregated fields from the table: "team_invites" */
  team_invites_aggregate: Team_Invites_Aggregate;
  /** fetch data from the table: "team_invites" using primary key columns */
  team_invites_by_pk?: Maybe<Team_Invites>;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table: "teams_names" */
  teams_names: Array<Teams_Names>;
  /** fetch aggregated fields from the table: "teams_names" */
  teams_names_aggregate: Teams_Names_Aggregate;
  /** fetch data from the table: "teams_names" using primary key columns */
  teams_names_by_pk?: Maybe<Teams_Names>;
  /** fetch data from the table: "teams_scores" */
  teams_scores: Array<Teams_Scores>;
  /** fetch aggregated fields from the table: "teams_scores" */
  teams_scores_aggregate: Teams_Scores_Aggregate;
  /** fetch data from the table: "teams_tags" */
  teams_tags: Array<Teams_Tags>;
  /** fetch aggregated fields from the table: "teams_tags" */
  teams_tags_aggregate: Teams_Tags_Aggregate;
  /** fetch data from the table: "teams_tags" using primary key columns */
  teams_tags_by_pk?: Maybe<Teams_Tags>;
  /** fetch data from the table: "teams_users" */
  teams_users: Array<Teams_Users>;
  /** fetch aggregated fields from the table: "teams_users" */
  teams_users_aggregate: Teams_Users_Aggregate;
  /** fetch data from the table: "teams_users" using primary key columns */
  teams_users_by_pk?: Maybe<Teams_Users>;
  /** fetch data from the table: "tools" */
  tools: Array<Tools>;
  /** fetch aggregated fields from the table: "tools" */
  tools_aggregate: Tools_Aggregate;
  /** fetch data from the table: "tools" using primary key columns */
  tools_by_pk?: Maybe<Tools>;
  /** fetch data from the table: "tools_icons" */
  tools_icons: Array<Tools_Icons>;
  /** fetch aggregated fields from the table: "tools_icons" */
  tools_icons_aggregate: Tools_Icons_Aggregate;
  /** fetch data from the table: "tools_icons" using primary key columns */
  tools_icons_by_pk?: Maybe<Tools_Icons>;
  /** fetch data from the table: "user_info" */
  user_info: Array<User_Info>;
  /** fetch aggregated fields from the table: "user_info" */
  user_info_aggregate: User_Info_Aggregate;
  /** fetch data from the table: "user_info" using primary key columns */
  user_info_by_pk?: Maybe<User_Info>;
  /** fetch data from the table: "user_invites" */
  user_invites: Array<User_Invites>;
  /** fetch aggregated fields from the table: "user_invites" */
  user_invites_aggregate: User_Invites_Aggregate;
  /** fetch data from the table: "user_invites" using primary key columns */
  user_invites_by_pk?: Maybe<User_Invites>;
  /** An array relationship */
  users: Array<Users>;
  /** fetch data from the table: "users_actions" */
  users_actions: Array<Users_Actions>;
  /** fetch aggregated fields from the table: "users_actions" */
  users_actions_aggregate: Users_Actions_Aggregate;
  /** fetch data from the table: "users_actions" using primary key columns */
  users_actions_by_pk?: Maybe<Users_Actions>;
  /** fetch data from the table: "users_actions_types" */
  users_actions_types: Array<Users_Actions_Types>;
  /** fetch aggregated fields from the table: "users_actions_types" */
  users_actions_types_aggregate: Users_Actions_Types_Aggregate;
  /** fetch data from the table: "users_actions_types" using primary key columns */
  users_actions_types_by_pk?: Maybe<Users_Actions_Types>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_documents" */
  users_documents: Array<Users_Documents>;
  /** fetch aggregated fields from the table: "users_documents" */
  users_documents_aggregate: Users_Documents_Aggregate;
  /** fetch data from the table: "users_documents" using primary key columns */
  users_documents_by_pk?: Maybe<Users_Documents>;
  /** fetch data from the table: "users_documents_types" */
  users_documents_types: Array<Users_Documents_Types>;
  /** fetch aggregated fields from the table: "users_documents_types" */
  users_documents_types_aggregate: Users_Documents_Types_Aggregate;
  /** fetch data from the table: "users_documents_types" using primary key columns */
  users_documents_types_by_pk?: Maybe<Users_Documents_Types>;
  /** fetch data from the table: "users_names" */
  users_names: Array<Users_Names>;
  /** fetch aggregated fields from the table: "users_names" */
  users_names_aggregate: Users_Names_Aggregate;
  /** fetch data from the table: "users_names" using primary key columns */
  users_names_by_pk?: Maybe<Users_Names>;
  /** An array relationship */
  video_repositories: Array<Video_Repositories>;
  /** An aggregate relationship */
  video_repositories_aggregate: Video_Repositories_Aggregate;
  /** fetch data from the table: "video_repositories" using primary key columns */
  video_repositories_by_pk?: Maybe<Video_Repositories>;
  /** An array relationship */
  videos: Array<Videos>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Aggregate;
  /** fetch data from the table: "videos" using primary key columns */
  videos_by_pk?: Maybe<Videos>;
  /** execute function "videos_by_tags" which returns "videos" */
  videos_by_tags: Array<Videos>;
  /** execute function "videos_by_tags" and query aggregates on result of table type "videos" */
  videos_by_tags_aggregate: Videos_Aggregate;
  /** fetch data from the table: "videos_youtube_tags" */
  videos_youtube_tags: Array<Videos_Youtube_Tags>;
  /** fetch aggregated fields from the table: "videos_youtube_tags" */
  videos_youtube_tags_aggregate: Videos_Youtube_Tags_Aggregate;
  /** fetch data from the table: "videos_youtube_tags" using primary key columns */
  videos_youtube_tags_by_pk?: Maybe<Videos_Youtube_Tags>;
  /** fetch data from the table: "youtube_tags" */
  youtube_tags: Array<Youtube_Tags>;
  /** fetch aggregated fields from the table: "youtube_tags" */
  youtube_tags_aggregate: Youtube_Tags_Aggregate;
  /** fetch data from the table: "youtube_tags" using primary key columns */
  youtube_tags_by_pk?: Maybe<Youtube_Tags>;
};


export type Query_RootCheckInviteStatusArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type Query_RootContentsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Query_RootContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Query_RootContents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContents_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


export type Query_RootContents_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


export type Query_RootContents_Sections_By_PkArgs = {
  content_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


export type Query_RootCreateUserArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Query_RootDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Query_RootDiagnostics_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


export type Query_RootDiagnostics_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


export type Query_RootDiagnostics_Comments_By_PkArgs = {
  diagnostic_tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


export type Query_RootDiagnostics_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


export type Query_RootDiagnostics_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


export type Query_RootDiagnostics_Groups_By_PkArgs = {
  diagnostic_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


export type Query_RootDiagnostics_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};


export type Query_RootDiagnostics_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};


export type Query_RootDiagnostics_Questions_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


export type Query_RootDiagnostics_Questions_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


export type Query_RootDiagnostics_Questions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
};


export type Query_RootDiagnostics_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
};


export type Query_RootDiagnostics_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_SubsectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


export type Query_RootDiagnostics_Subsections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


export type Query_RootDiagnostics_Subsections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_TabsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};


export type Query_RootDiagnostics_Tabs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};


export type Query_RootDiagnostics_Tabs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_Tabs_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


export type Query_RootDiagnostics_Tabs_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


export type Query_RootDiagnostics_Tabs_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetStorageSasUriArgs = {
  container: Scalars['String'];
  filename: Scalars['String'];
};


export type Query_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGroups_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


export type Query_RootGroups_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


export type Query_RootGroups_Contents_By_PkArgs = {
  content_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


export type Query_RootGroups_NamesArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


export type Query_RootGroups_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


export type Query_RootGroups_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGroups_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


export type Query_RootGroups_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


export type Query_RootGroups_Teams_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIcons_TypesArgs = {
  distinct_on?: InputMaybe<Array<Icons_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icons_Types_Order_By>>;
  where?: InputMaybe<Icons_Types_Bool_Exp>;
};


export type Query_RootIcons_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icons_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icons_Types_Order_By>>;
  where?: InputMaybe<Icons_Types_Bool_Exp>;
};


export type Query_RootIcons_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootInvite_StatesArgs = {
  distinct_on?: InputMaybe<Array<Invite_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invite_States_Order_By>>;
  where?: InputMaybe<Invite_States_Bool_Exp>;
};


export type Query_RootInvite_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invite_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invite_States_Order_By>>;
  where?: InputMaybe<Invite_States_Bool_Exp>;
};


export type Query_RootInvite_States_By_PkArgs = {
  state: Scalars['String'];
};


export type Query_RootKanban_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Kanban_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanban_Columns_Order_By>>;
  where?: InputMaybe<Kanban_Columns_Bool_Exp>;
};


export type Query_RootKanban_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanban_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanban_Columns_Order_By>>;
  where?: InputMaybe<Kanban_Columns_Bool_Exp>;
};


export type Query_RootKanban_Columns_By_PkArgs = {
  column: Scalars['String'];
};


export type Query_RootKanbansArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Order_By>>;
  where?: InputMaybe<Kanbans_Bool_Exp>;
};


export type Query_RootKanbans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Order_By>>;
  where?: InputMaybe<Kanbans_Bool_Exp>;
};


export type Query_RootKanbans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootKanbans_CardsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};


export type Query_RootKanbans_Cards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};


export type Query_RootKanbans_Cards_AssignationsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Assignations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Assignations_By_PkArgs = {
  card_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Query_RootKanbans_Cards_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Attachments_By_PkArgs = {
  card_id: Scalars['uuid'];
  file_id: Scalars['uuid'];
};


export type Query_RootKanbans_Cards_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootKanbans_Cards_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocations_NamesArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};


export type Query_RootLocations_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};


export type Query_RootLocations_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMentoring_RecordsArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Bool_Exp>;
};


export type Query_RootMentoring_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Bool_Exp>;
};


export type Query_RootMentoring_Records_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMentoring_Records_MembersArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


export type Query_RootMentoring_Records_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


export type Query_RootMentoring_Records_Members_By_PkArgs = {
  member_id: Scalars['String'];
  mentoring_record_id: Scalars['uuid'];
};


export type Query_RootMentors_BlablablaArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


export type Query_RootMentors_Blablabla_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


export type Query_RootMentors_Blablabla_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMultimedia_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Bool_Exp>;
};


export type Query_RootMultimedia_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Bool_Exp>;
};


export type Query_RootMultimedia_Contents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMultimedia_Contents_LinksArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};


export type Query_RootMultimedia_Contents_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};


export type Query_RootMultimedia_Contents_Links_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOutputsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


export type Query_RootOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


export type Query_RootOutputs_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


export type Query_RootOutputs_IconsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


export type Query_RootOutputs_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


export type Query_RootOutputs_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOutputs_ToolsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


export type Query_RootOutputs_Tools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


export type Query_RootOutputs_Tools_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Query_RootQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Query_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuestionnaires_Teams_StatesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


export type Query_RootQuestionnaires_Teams_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


export type Query_RootQuestionnaires_Teams_States_By_PkArgs = {
  questionnaire_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


export type Query_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


export type Query_RootQuestions_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


export type Query_RootQuestions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuestions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuestions_TypesArgs = {
  distinct_on?: InputMaybe<Array<Questions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Types_Order_By>>;
  where?: InputMaybe<Questions_Types_Bool_Exp>;
};


export type Query_RootQuestions_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Types_Order_By>>;
  where?: InputMaybe<Questions_Types_Bool_Exp>;
};


export type Query_RootQuestions_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootRepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


export type Query_RootRepositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


export type Query_RootRepositories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  role: Scalars['String'];
};


export type Query_RootSectionsArgs = {
  distinct_on?: InputMaybe<Array<Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Order_By>>;
  where?: InputMaybe<Sections_Bool_Exp>;
};


export type Query_RootSections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Order_By>>;
  where?: InputMaybe<Sections_Bool_Exp>;
};


export type Query_RootSections_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


export type Query_RootSections_IconsArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};


export type Query_RootSections_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};


export type Query_RootSections_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSocial_MediasArgs = {
  distinct_on?: InputMaybe<Array<Social_Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Social_Medias_Order_By>>;
  where?: InputMaybe<Social_Medias_Bool_Exp>;
};


export type Query_RootSocial_Medias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Social_Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Social_Medias_Order_By>>;
  where?: InputMaybe<Social_Medias_Bool_Exp>;
};


export type Query_RootSocial_Medias_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
  value: Scalars['String'];
};


export type Query_RootTeam_InvitesArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


export type Query_RootTeam_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


export type Query_RootTeam_Invites_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeams_NamesArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


export type Query_RootTeams_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


export type Query_RootTeams_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeams_ScoresArgs = {
  distinct_on?: InputMaybe<Array<Teams_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Scores_Order_By>>;
  where?: InputMaybe<Teams_Scores_Bool_Exp>;
};


export type Query_RootTeams_Scores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Scores_Order_By>>;
  where?: InputMaybe<Teams_Scores_Bool_Exp>;
};


export type Query_RootTeams_TagsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


export type Query_RootTeams_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


export type Query_RootTeams_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeams_UsersArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


export type Query_RootTeams_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


export type Query_RootTeams_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootToolsArgs = {
  distinct_on?: InputMaybe<Array<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Query_RootTools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Query_RootTools_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


export type Query_RootTools_IconsArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


export type Query_RootTools_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


export type Query_RootTools_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_InfoArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Query_RootUser_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Query_RootUser_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_InvitesArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Query_RootUser_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Query_RootUser_Invites_By_PkArgs = {
  id: Scalars['uuid'];
  token: Scalars['String'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Query_RootUsers_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Query_RootUsers_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUsers_Actions_TypesArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Types_Order_By>>;
  where?: InputMaybe<Users_Actions_Types_Bool_Exp>;
};


export type Query_RootUsers_Actions_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Types_Order_By>>;
  where?: InputMaybe<Users_Actions_Types_Bool_Exp>;
};


export type Query_RootUsers_Actions_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  email: Scalars['String'];
  id: Scalars['String'];
};


export type Query_RootUsers_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


export type Query_RootUsers_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


export type Query_RootUsers_Documents_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Query_RootUsers_Documents_TypesArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Types_Order_By>>;
  where?: InputMaybe<Users_Documents_Types_Bool_Exp>;
};


export type Query_RootUsers_Documents_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Types_Order_By>>;
  where?: InputMaybe<Users_Documents_Types_Bool_Exp>;
};


export type Query_RootUsers_Documents_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootUsers_NamesArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


export type Query_RootUsers_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


export type Query_RootUsers_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVideo_RepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};


export type Query_RootVideo_Repositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};


export type Query_RootVideo_Repositories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVideos_By_TagsArgs = {
  args: Videos_By_Tags_Args;
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_By_Tags_AggregateArgs = {
  args: Videos_By_Tags_Args;
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_Youtube_TagsArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


export type Query_RootVideos_Youtube_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


export type Query_RootVideos_Youtube_Tags_By_PkArgs = {
  tag: Scalars['String'];
  video_id: Scalars['uuid'];
};


export type Query_RootYoutube_TagsArgs = {
  distinct_on?: InputMaybe<Array<Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Youtube_Tags_Order_By>>;
  where?: InputMaybe<Youtube_Tags_Bool_Exp>;
};


export type Query_RootYoutube_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Youtube_Tags_Order_By>>;
  where?: InputMaybe<Youtube_Tags_Bool_Exp>;
};


export type Query_RootYoutube_Tags_By_PkArgs = {
  tag: Scalars['String'];
};

/** columns and relationships of "questionnaires" */
export type Questionnaires = {
  __typename?: 'questionnaires';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** An array relationship */
  teams_states: Array<Questionnaires_Teams_States>;
  /** An aggregate relationship */
  teams_states_aggregate: Questionnaires_Teams_States_Aggregate;
  /** An object relationship */
  tool: Tools;
  tool_id: Scalars['uuid'];
};


/** columns and relationships of "questionnaires" */
export type QuestionnairesQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "questionnaires" */
export type QuestionnairesQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "questionnaires" */
export type QuestionnairesTeams_StatesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


/** columns and relationships of "questionnaires" */
export type QuestionnairesTeams_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};

/** aggregated selection of "questionnaires" */
export type Questionnaires_Aggregate = {
  __typename?: 'questionnaires_aggregate';
  aggregate?: Maybe<Questionnaires_Aggregate_Fields>;
  nodes: Array<Questionnaires>;
};

/** aggregate fields of "questionnaires" */
export type Questionnaires_Aggregate_Fields = {
  __typename?: 'questionnaires_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Questionnaires_Max_Fields>;
  min?: Maybe<Questionnaires_Min_Fields>;
};


/** aggregate fields of "questionnaires" */
export type Questionnaires_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questionnaires" */
export type Questionnaires_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaires_Max_Order_By>;
  min?: InputMaybe<Questionnaires_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questionnaires" */
export type Questionnaires_Arr_Rel_Insert_Input = {
  data: Array<Questionnaires_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questionnaires". All fields are combined with a logical 'AND'. */
export type Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questions?: InputMaybe<Questions_Bool_Exp>;
  teams_states?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
  tool?: InputMaybe<Tools_Bool_Exp>;
  tool_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionnaires" */
export enum Questionnaires_Constraint {
  /** unique or primary key constraint */
  QuestionnairesPkey = 'questionnaires_pkey',
  /** unique or primary key constraint */
  QuestionnairesToolIdActiveKey = 'questionnaires_tool_id_active_key'
}

/** input type for inserting data into table "questionnaires" */
export type Questionnaires_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questions?: InputMaybe<Questions_Arr_Rel_Insert_Input>;
  teams_states?: InputMaybe<Questionnaires_Teams_States_Arr_Rel_Insert_Input>;
  tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  tool_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Questionnaires_Max_Fields = {
  __typename?: 'questionnaires_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "questionnaires" */
export type Questionnaires_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questionnaires_Min_Fields = {
  __typename?: 'questionnaires_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "questionnaires" */
export type Questionnaires_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionnaires" */
export type Questionnaires_Mutation_Response = {
  __typename?: 'questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaires>;
};

/** input type for inserting object relation for remote table "questionnaires" */
export type Questionnaires_Obj_Rel_Insert_Input = {
  data: Questionnaires_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** on_conflict condition type for table "questionnaires" */
export type Questionnaires_On_Conflict = {
  constraint: Questionnaires_Constraint;
  update_columns?: Array<Questionnaires_Update_Column>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** Ordering options when selecting data from "questionnaires". */
export type Questionnaires_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Order_By>;
  teams_states_aggregate?: InputMaybe<Questionnaires_Teams_States_Aggregate_Order_By>;
  tool?: InputMaybe<Tools_Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionnaires */
export type Questionnaires_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "questionnaires" */
export enum Questionnaires_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ToolId = 'tool_id'
}

/** input type for updating data in table "questionnaires" */
export type Questionnaires_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "questionnaires_teams_states" */
export type Questionnaires_Teams_States = {
  __typename?: 'questionnaires_teams_states';
  finished: Scalars['Boolean'];
  /** An object relationship */
  questionnaire: Questionnaires;
  questionnaire_id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Aggregate = {
  __typename?: 'questionnaires_teams_states_aggregate';
  aggregate?: Maybe<Questionnaires_Teams_States_Aggregate_Fields>;
  nodes: Array<Questionnaires_Teams_States>;
};

/** aggregate fields of "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Aggregate_Fields = {
  __typename?: 'questionnaires_teams_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Questionnaires_Teams_States_Max_Fields>;
  min?: Maybe<Questionnaires_Teams_States_Min_Fields>;
};


/** aggregate fields of "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaires_Teams_States_Max_Order_By>;
  min?: InputMaybe<Questionnaires_Teams_States_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Arr_Rel_Insert_Input = {
  data: Array<Questionnaires_Teams_States_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaires_Teams_States_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questionnaires_teams_states". All fields are combined with a logical 'AND'. */
export type Questionnaires_Teams_States_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaires_Teams_States_Bool_Exp>>;
  _not?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaires_Teams_States_Bool_Exp>>;
  finished?: InputMaybe<Boolean_Comparison_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionnaires_teams_states" */
export enum Questionnaires_Teams_States_Constraint {
  /** unique or primary key constraint */
  QuestionnairesTeamsStatesPkey = 'questionnaires_teams_states_pkey'
}

/** input type for inserting data into table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Insert_Input = {
  finished?: InputMaybe<Scalars['Boolean']>;
  questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Questionnaires_Teams_States_Max_Fields = {
  __typename?: 'questionnaires_teams_states_max_fields';
  questionnaire_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Max_Order_By = {
  questionnaire_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questionnaires_Teams_States_Min_Fields = {
  __typename?: 'questionnaires_teams_states_min_fields';
  questionnaire_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Min_Order_By = {
  questionnaire_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Mutation_Response = {
  __typename?: 'questionnaires_teams_states_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaires_Teams_States>;
};

/** on_conflict condition type for table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_On_Conflict = {
  constraint: Questionnaires_Teams_States_Constraint;
  update_columns?: Array<Questionnaires_Teams_States_Update_Column>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};

/** Ordering options when selecting data from "questionnaires_teams_states". */
export type Questionnaires_Teams_States_Order_By = {
  finished?: InputMaybe<Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionnaires_teams_states */
export type Questionnaires_Teams_States_Pk_Columns_Input = {
  questionnaire_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};

/** select columns of table "questionnaires_teams_states" */
export enum Questionnaires_Teams_States_Select_Column {
  /** column name */
  Finished = 'finished',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Set_Input = {
  finished?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "questionnaires_teams_states" */
export enum Questionnaires_Teams_States_Update_Column {
  /** column name */
  Finished = 'finished',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** update columns of table "questionnaires" */
export enum Questionnaires_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ToolId = 'tool_id'
}

/** columns and relationships of "questions" */
export type Questions = {
  __typename?: 'questions';
  id: Scalars['uuid'];
  order: Scalars['Int'];
  /** An array relationship */
  question_answers: Array<Questions_Answers>;
  /** An aggregate relationship */
  question_answers_aggregate: Questions_Answers_Aggregate;
  /** An object relationship */
  questionnaire: Questionnaires;
  questionnaire_id: Scalars['uuid'];
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: Questions_Types_Enum;
};


/** columns and relationships of "questions" */
export type QuestionsQuestion_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsQuestion_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};

/** aggregated selection of "questions" */
export type Questions_Aggregate = {
  __typename?: 'questions_aggregate';
  aggregate?: Maybe<Questions_Aggregate_Fields>;
  nodes: Array<Questions>;
};

/** aggregate fields of "questions" */
export type Questions_Aggregate_Fields = {
  __typename?: 'questions_aggregate_fields';
  avg?: Maybe<Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Questions_Max_Fields>;
  min?: Maybe<Questions_Min_Fields>;
  stddev?: Maybe<Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Questions_Sum_Fields>;
  var_pop?: Maybe<Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Questions_Var_Samp_Fields>;
  variance?: Maybe<Questions_Variance_Fields>;
};


/** aggregate fields of "questions" */
export type Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questions" */
export type Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questions_Max_Order_By>;
  min?: InputMaybe<Questions_Min_Order_By>;
  stddev?: InputMaybe<Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Questions_Variance_Order_By>;
};

/** columns and relationships of "questions_answers" */
export type Questions_Answers = {
  __typename?: 'questions_answers';
  answer: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  question: Questions;
  question_id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};

/** aggregated selection of "questions_answers" */
export type Questions_Answers_Aggregate = {
  __typename?: 'questions_answers_aggregate';
  aggregate?: Maybe<Questions_Answers_Aggregate_Fields>;
  nodes: Array<Questions_Answers>;
};

/** aggregate fields of "questions_answers" */
export type Questions_Answers_Aggregate_Fields = {
  __typename?: 'questions_answers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Questions_Answers_Max_Fields>;
  min?: Maybe<Questions_Answers_Min_Fields>;
};


/** aggregate fields of "questions_answers" */
export type Questions_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questions_answers" */
export type Questions_Answers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questions_Answers_Max_Order_By>;
  min?: InputMaybe<Questions_Answers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questions_answers" */
export type Questions_Answers_Arr_Rel_Insert_Input = {
  data: Array<Questions_Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_Answers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questions_answers". All fields are combined with a logical 'AND'. */
export type Questions_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Answers_Bool_Exp>>;
  _not?: InputMaybe<Questions_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Answers_Bool_Exp>>;
  answer?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "questions_answers" */
export enum Questions_Answers_Constraint {
  /** unique or primary key constraint */
  QuestionsAnswersPkey = 'questions_answers_pkey',
  /** unique or primary key constraint */
  QuestionsAnswersQuestionIdTeamIdKey = 'questions_answers_question_id_team_id_key'
}

/** input type for inserting data into table "questions_answers" */
export type Questions_Answers_Insert_Input = {
  answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Questions_Answers_Max_Fields = {
  __typename?: 'questions_answers_max_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "questions_answers" */
export type Questions_Answers_Max_Order_By = {
  answer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questions_Answers_Min_Fields = {
  __typename?: 'questions_answers_min_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "questions_answers" */
export type Questions_Answers_Min_Order_By = {
  answer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questions_answers" */
export type Questions_Answers_Mutation_Response = {
  __typename?: 'questions_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions_Answers>;
};

/** on_conflict condition type for table "questions_answers" */
export type Questions_Answers_On_Conflict = {
  constraint: Questions_Answers_Constraint;
  update_columns?: Array<Questions_Answers_Update_Column>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "questions_answers". */
export type Questions_Answers_Order_By = {
  answer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  question_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questions_answers */
export type Questions_Answers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "questions_answers" */
export enum Questions_Answers_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "questions_answers" */
export type Questions_Answers_Set_Input = {
  answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "questions_answers" */
export enum Questions_Answers_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for inserting array relation for remote table "questions" */
export type Questions_Arr_Rel_Insert_Input = {
  data: Array<Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Questions_Avg_Fields = {
  __typename?: 'questions_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "questions" */
export type Questions_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "questions". All fields are combined with a logical 'AND'. */
export type Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Bool_Exp>>;
  _not?: InputMaybe<Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  question_answers?: InputMaybe<Questions_Answers_Bool_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Questions_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "questions" */
export enum Questions_Constraint {
  /** unique or primary key constraint */
  QuestionsOrderQuestionnaireIdKey = 'questions_order_questionnaire_id_key',
  /** unique or primary key constraint */
  QuestionsPkey = 'questions_pkey'
}

/** input type for incrementing numeric columns in table "questions" */
export type Questions_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "questions" */
export type Questions_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  question_answers?: InputMaybe<Questions_Answers_Arr_Rel_Insert_Input>;
  questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Questions_Types_Enum>;
};

/** aggregate max on columns */
export type Questions_Max_Fields = {
  __typename?: 'questions_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "questions" */
export type Questions_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questions_Min_Fields = {
  __typename?: 'questions_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "questions" */
export type Questions_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questions" */
export type Questions_Mutation_Response = {
  __typename?: 'questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions>;
};

/** input type for inserting object relation for remote table "questions" */
export type Questions_Obj_Rel_Insert_Input = {
  data: Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** on_conflict condition type for table "questions" */
export type Questions_On_Conflict = {
  constraint: Questions_Constraint;
  update_columns?: Array<Questions_Update_Column>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "questions". */
export type Questions_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question_answers_aggregate?: InputMaybe<Questions_Answers_Aggregate_Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questions */
export type Questions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "questions" */
export enum Questions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "questions" */
export type Questions_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Questions_Types_Enum>;
};

/** aggregate stddev on columns */
export type Questions_Stddev_Fields = {
  __typename?: 'questions_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "questions" */
export type Questions_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Questions_Stddev_Pop_Fields = {
  __typename?: 'questions_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "questions" */
export type Questions_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Questions_Stddev_Samp_Fields = {
  __typename?: 'questions_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "questions" */
export type Questions_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Questions_Sum_Fields = {
  __typename?: 'questions_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "questions" */
export type Questions_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "questions_types" */
export type Questions_Types = {
  __typename?: 'questions_types';
  type: Scalars['String'];
};

/** aggregated selection of "questions_types" */
export type Questions_Types_Aggregate = {
  __typename?: 'questions_types_aggregate';
  aggregate?: Maybe<Questions_Types_Aggregate_Fields>;
  nodes: Array<Questions_Types>;
};

/** aggregate fields of "questions_types" */
export type Questions_Types_Aggregate_Fields = {
  __typename?: 'questions_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Questions_Types_Max_Fields>;
  min?: Maybe<Questions_Types_Min_Fields>;
};


/** aggregate fields of "questions_types" */
export type Questions_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "questions_types". All fields are combined with a logical 'AND'. */
export type Questions_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Types_Bool_Exp>>;
  _not?: InputMaybe<Questions_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Types_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "questions_types" */
export enum Questions_Types_Constraint {
  /** unique or primary key constraint */
  QuestionsTypesPkey = 'questions_types_pkey'
}

export enum Questions_Types_Enum {
  File = 'file',
  HorizontalRule = 'horizontal_rule',
  InputArea = 'input_area',
  InputShort = 'input_short',
  Miro = 'miro',
  Title = 'title'
}

/** Boolean expression to compare columns of type "questions_types_enum". All fields are combined with logical 'AND'. */
export type Questions_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Questions_Types_Enum>;
  _in?: InputMaybe<Array<Questions_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Questions_Types_Enum>;
  _nin?: InputMaybe<Array<Questions_Types_Enum>>;
};

/** input type for inserting data into table "questions_types" */
export type Questions_Types_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Questions_Types_Max_Fields = {
  __typename?: 'questions_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Questions_Types_Min_Fields = {
  __typename?: 'questions_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "questions_types" */
export type Questions_Types_Mutation_Response = {
  __typename?: 'questions_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions_Types>;
};

/** on_conflict condition type for table "questions_types" */
export type Questions_Types_On_Conflict = {
  constraint: Questions_Types_Constraint;
  update_columns?: Array<Questions_Types_Update_Column>;
  where?: InputMaybe<Questions_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "questions_types". */
export type Questions_Types_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questions_types */
export type Questions_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "questions_types" */
export enum Questions_Types_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "questions_types" */
export type Questions_Types_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "questions_types" */
export enum Questions_Types_Update_Column {
  /** column name */
  Type = 'type'
}

/** update columns of table "questions" */
export enum Questions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Questions_Var_Pop_Fields = {
  __typename?: 'questions_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "questions" */
export type Questions_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Questions_Var_Samp_Fields = {
  __typename?: 'questions_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "questions" */
export type Questions_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Questions_Variance_Fields = {
  __typename?: 'questions_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "questions" */
export type Questions_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "repositories" */
export type Repositories = {
  __typename?: 'repositories';
  app_key: Scalars['String'];
  /** An object relationship */
  content: Contents;
  content_id: Scalars['uuid'];
  id: Scalars['uuid'];
  link: Scalars['String'];
};

/** aggregated selection of "repositories" */
export type Repositories_Aggregate = {
  __typename?: 'repositories_aggregate';
  aggregate?: Maybe<Repositories_Aggregate_Fields>;
  nodes: Array<Repositories>;
};

/** aggregate fields of "repositories" */
export type Repositories_Aggregate_Fields = {
  __typename?: 'repositories_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Repositories_Max_Fields>;
  min?: Maybe<Repositories_Min_Fields>;
};


/** aggregate fields of "repositories" */
export type Repositories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Repositories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "repositories" */
export type Repositories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Repositories_Max_Order_By>;
  min?: InputMaybe<Repositories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "repositories" */
export type Repositories_Arr_Rel_Insert_Input = {
  data: Array<Repositories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Repositories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "repositories". All fields are combined with a logical 'AND'. */
export type Repositories_Bool_Exp = {
  _and?: InputMaybe<Array<Repositories_Bool_Exp>>;
  _not?: InputMaybe<Repositories_Bool_Exp>;
  _or?: InputMaybe<Array<Repositories_Bool_Exp>>;
  app_key?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "repositories" */
export enum Repositories_Constraint {
  /** unique or primary key constraint */
  RepositoriesPkey = 'repositories_pkey'
}

/** input type for inserting data into table "repositories" */
export type Repositories_Insert_Input = {
  app_key?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Repositories_Max_Fields = {
  __typename?: 'repositories_max_fields';
  app_key?: Maybe<Scalars['String']>;
  content_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "repositories" */
export type Repositories_Max_Order_By = {
  app_key?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Repositories_Min_Fields = {
  __typename?: 'repositories_min_fields';
  app_key?: Maybe<Scalars['String']>;
  content_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "repositories" */
export type Repositories_Min_Order_By = {
  app_key?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "repositories" */
export type Repositories_Mutation_Response = {
  __typename?: 'repositories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Repositories>;
};

/** on_conflict condition type for table "repositories" */
export type Repositories_On_Conflict = {
  constraint: Repositories_Constraint;
  update_columns?: Array<Repositories_Update_Column>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};

/** Ordering options when selecting data from "repositories". */
export type Repositories_Order_By = {
  app_key?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
};

/** primary key columns input for table: repositories */
export type Repositories_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "repositories" */
export enum Repositories_Select_Column {
  /** column name */
  AppKey = 'app_key',
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link'
}

/** input type for updating data in table "repositories" */
export type Repositories_Set_Input = {
  app_key?: InputMaybe<Scalars['String']>;
  content_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
};

/** update columns of table "repositories" */
export enum Repositories_Update_Column {
  /** column name */
  AppKey = 'app_key',
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link'
}

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  role: Scalars['String'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
};


/** columns and relationships of "roles" */
export type RolesUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  role?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

export enum Roles_Enum {
  Admin = 'admin',
  Anonymous = 'anonymous',
  Editor = 'editor',
  Manager = 'manager',
  Mentor = 'mentor',
  User = 'user'
}

/** Boolean expression to compare columns of type "roles_enum". All fields are combined with logical 'AND'. */
export type Roles_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Roles_Enum>;
  _in?: InputMaybe<Array<Roles_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Roles_Enum>;
  _nin?: InputMaybe<Array<Roles_Enum>>;
};

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  role?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  role?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  role?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  role: Scalars['String'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  role?: InputMaybe<Scalars['String']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  Role = 'role'
}

/** columns and relationships of "sections" */
export type Sections = {
  __typename?: 'sections';
  /** An array relationship */
  contents_sections: Array<Contents_Sections>;
  /** An aggregate relationship */
  contents_sections_aggregate: Contents_Sections_Aggregate;
  created_at: Scalars['timestamptz'];
  hex_color?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  intro: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  outputs: Array<Outputs>;
  /** An aggregate relationship */
  outputs_aggregate: Outputs_Aggregate;
  outputs_img?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  outputs_img_file?: Maybe<Files>;
  outputs_text: Scalars['String'];
  /** An array relationship */
  section_icons_files: Array<Sections_Icons>;
  /** An aggregate relationship */
  section_icons_files_aggregate: Sections_Icons_Aggregate;
  slug: Scalars['String'];
};


/** columns and relationships of "sections" */
export type SectionsContents_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


/** columns and relationships of "sections" */
export type SectionsContents_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


/** columns and relationships of "sections" */
export type SectionsOutputsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


/** columns and relationships of "sections" */
export type SectionsOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


/** columns and relationships of "sections" */
export type SectionsSection_Icons_FilesArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};


/** columns and relationships of "sections" */
export type SectionsSection_Icons_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};

/** aggregated selection of "sections" */
export type Sections_Aggregate = {
  __typename?: 'sections_aggregate';
  aggregate?: Maybe<Sections_Aggregate_Fields>;
  nodes: Array<Sections>;
};

/** aggregate fields of "sections" */
export type Sections_Aggregate_Fields = {
  __typename?: 'sections_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sections_Max_Fields>;
  min?: Maybe<Sections_Min_Fields>;
};


/** aggregate fields of "sections" */
export type Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sections". All fields are combined with a logical 'AND'. */
export type Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Sections_Bool_Exp>>;
  _not?: InputMaybe<Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Sections_Bool_Exp>>;
  contents_sections?: InputMaybe<Contents_Sections_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hex_color?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  intro?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  outputs?: InputMaybe<Outputs_Bool_Exp>;
  outputs_img?: InputMaybe<Uuid_Comparison_Exp>;
  outputs_img_file?: InputMaybe<Files_Bool_Exp>;
  outputs_text?: InputMaybe<String_Comparison_Exp>;
  section_icons_files?: InputMaybe<Sections_Icons_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sections" */
export enum Sections_Constraint {
  /** unique or primary key constraint */
  SectionsIdKey = 'sections_id_key',
  /** unique or primary key constraint */
  SectionsPkey = 'sections_pkey',
  /** unique or primary key constraint */
  SectionsSlugKey = 'sections_slug_key'
}

/** columns and relationships of "sections_icons" */
export type Sections_Icons = {
  __typename?: 'sections_icons';
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  section: Sections;
  section_id: Scalars['uuid'];
  type: Icons_Types_Enum;
};

/** aggregated selection of "sections_icons" */
export type Sections_Icons_Aggregate = {
  __typename?: 'sections_icons_aggregate';
  aggregate?: Maybe<Sections_Icons_Aggregate_Fields>;
  nodes: Array<Sections_Icons>;
};

/** aggregate fields of "sections_icons" */
export type Sections_Icons_Aggregate_Fields = {
  __typename?: 'sections_icons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sections_Icons_Max_Fields>;
  min?: Maybe<Sections_Icons_Min_Fields>;
};


/** aggregate fields of "sections_icons" */
export type Sections_Icons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sections_icons" */
export type Sections_Icons_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sections_Icons_Max_Order_By>;
  min?: InputMaybe<Sections_Icons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "sections_icons" */
export type Sections_Icons_Arr_Rel_Insert_Input = {
  data: Array<Sections_Icons_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sections_Icons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sections_icons". All fields are combined with a logical 'AND'. */
export type Sections_Icons_Bool_Exp = {
  _and?: InputMaybe<Array<Sections_Icons_Bool_Exp>>;
  _not?: InputMaybe<Sections_Icons_Bool_Exp>;
  _or?: InputMaybe<Array<Sections_Icons_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  section?: InputMaybe<Sections_Bool_Exp>;
  section_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Icons_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "sections_icons" */
export enum Sections_Icons_Constraint {
  /** unique or primary key constraint */
  SectionsIconsPkey = 'sections_icons_pkey',
  /** unique or primary key constraint */
  SectionsIconsSectionIdIconFileIdKey = 'sections_icons_section_id_icon_file_id_key',
  /** unique or primary key constraint */
  SectionsIconsSectionIdTypeKey = 'sections_icons_section_id_type_key'
}

/** input type for inserting data into table "sections_icons" */
export type Sections_Icons_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  section?: InputMaybe<Sections_Obj_Rel_Insert_Input>;
  section_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** aggregate max on columns */
export type Sections_Icons_Max_Fields = {
  __typename?: 'sections_icons_max_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "sections_icons" */
export type Sections_Icons_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sections_Icons_Min_Fields = {
  __typename?: 'sections_icons_min_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "sections_icons" */
export type Sections_Icons_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sections_icons" */
export type Sections_Icons_Mutation_Response = {
  __typename?: 'sections_icons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sections_Icons>;
};

/** on_conflict condition type for table "sections_icons" */
export type Sections_Icons_On_Conflict = {
  constraint: Sections_Icons_Constraint;
  update_columns?: Array<Sections_Icons_Update_Column>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};

/** Ordering options when selecting data from "sections_icons". */
export type Sections_Icons_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  section?: InputMaybe<Sections_Order_By>;
  section_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sections_icons */
export type Sections_Icons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "sections_icons" */
export enum Sections_Icons_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "sections_icons" */
export type Sections_Icons_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  section_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** update columns of table "sections_icons" */
export enum Sections_Icons_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  Type = 'type'
}

/** input type for inserting data into table "sections" */
export type Sections_Insert_Input = {
  contents_sections?: InputMaybe<Contents_Sections_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hex_color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  outputs?: InputMaybe<Outputs_Arr_Rel_Insert_Input>;
  outputs_img?: InputMaybe<Scalars['uuid']>;
  outputs_img_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  outputs_text?: InputMaybe<Scalars['String']>;
  section_icons_files?: InputMaybe<Sections_Icons_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sections_Max_Fields = {
  __typename?: 'sections_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  hex_color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outputs_img?: Maybe<Scalars['uuid']>;
  outputs_text?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Sections_Min_Fields = {
  __typename?: 'sections_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  hex_color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outputs_img?: Maybe<Scalars['uuid']>;
  outputs_text?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sections" */
export type Sections_Mutation_Response = {
  __typename?: 'sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sections>;
};

/** input type for inserting object relation for remote table "sections" */
export type Sections_Obj_Rel_Insert_Input = {
  data: Sections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sections_On_Conflict>;
};

/** on_conflict condition type for table "sections" */
export type Sections_On_Conflict = {
  constraint: Sections_Constraint;
  update_columns?: Array<Sections_Update_Column>;
  where?: InputMaybe<Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "sections". */
export type Sections_Order_By = {
  contents_sections_aggregate?: InputMaybe<Contents_Sections_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  hex_color?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intro?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  outputs_aggregate?: InputMaybe<Outputs_Aggregate_Order_By>;
  outputs_img?: InputMaybe<Order_By>;
  outputs_img_file?: InputMaybe<Files_Order_By>;
  outputs_text?: InputMaybe<Order_By>;
  section_icons_files_aggregate?: InputMaybe<Sections_Icons_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sections */
export type Sections_Pk_Columns_Input = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};

/** select columns of table "sections" */
export enum Sections_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HexColor = 'hex_color',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  Name = 'name',
  /** column name */
  OutputsImg = 'outputs_img',
  /** column name */
  OutputsText = 'outputs_text',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "sections" */
export type Sections_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hex_color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  outputs_img?: InputMaybe<Scalars['uuid']>;
  outputs_text?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** update columns of table "sections" */
export enum Sections_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HexColor = 'hex_color',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  Name = 'name',
  /** column name */
  OutputsImg = 'outputs_img',
  /** column name */
  OutputsText = 'outputs_text',
  /** column name */
  Slug = 'slug'
}

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "social_medias" */
export type Social_Medias = {
  __typename?: 'social_medias';
  created_at: Scalars['timestamptz'];
  facebook?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Aggregate;
  twitter?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  youtube?: Maybe<Scalars['String']>;
};


/** columns and relationships of "social_medias" */
export type Social_MediasTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


/** columns and relationships of "social_medias" */
export type Social_MediasTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


/** columns and relationships of "social_medias" */
export type Social_MediasUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "social_medias" */
export type Social_MediasUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "social_medias" */
export type Social_Medias_Aggregate = {
  __typename?: 'social_medias_aggregate';
  aggregate?: Maybe<Social_Medias_Aggregate_Fields>;
  nodes: Array<Social_Medias>;
};

/** aggregate fields of "social_medias" */
export type Social_Medias_Aggregate_Fields = {
  __typename?: 'social_medias_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Social_Medias_Max_Fields>;
  min?: Maybe<Social_Medias_Min_Fields>;
};


/** aggregate fields of "social_medias" */
export type Social_Medias_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Social_Medias_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "social_medias". All fields are combined with a logical 'AND'. */
export type Social_Medias_Bool_Exp = {
  _and?: InputMaybe<Array<Social_Medias_Bool_Exp>>;
  _not?: InputMaybe<Social_Medias_Bool_Exp>;
  _or?: InputMaybe<Array<Social_Medias_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  facebook?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  instagram?: InputMaybe<String_Comparison_Exp>;
  linkedin?: InputMaybe<String_Comparison_Exp>;
  other?: InputMaybe<String_Comparison_Exp>;
  teams?: InputMaybe<Teams_Bool_Exp>;
  twitter?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  youtube?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "social_medias" */
export enum Social_Medias_Constraint {
  /** unique or primary key constraint */
  SocialMediasPkey = 'social_medias_pkey'
}

/** input type for inserting data into table "social_medias" */
export type Social_Medias_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facebook?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  instagram?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Teams_Arr_Rel_Insert_Input>;
  twitter?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  youtube?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Social_Medias_Max_Fields = {
  __typename?: 'social_medias_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  facebook?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  youtube?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Social_Medias_Min_Fields = {
  __typename?: 'social_medias_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  facebook?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  youtube?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "social_medias" */
export type Social_Medias_Mutation_Response = {
  __typename?: 'social_medias_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Social_Medias>;
};

/** input type for inserting object relation for remote table "social_medias" */
export type Social_Medias_Obj_Rel_Insert_Input = {
  data: Social_Medias_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Social_Medias_On_Conflict>;
};

/** on_conflict condition type for table "social_medias" */
export type Social_Medias_On_Conflict = {
  constraint: Social_Medias_Constraint;
  update_columns?: Array<Social_Medias_Update_Column>;
  where?: InputMaybe<Social_Medias_Bool_Exp>;
};

/** Ordering options when selecting data from "social_medias". */
export type Social_Medias_Order_By = {
  created_at?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagram?: InputMaybe<Order_By>;
  linkedin?: InputMaybe<Order_By>;
  other?: InputMaybe<Order_By>;
  teams_aggregate?: InputMaybe<Teams_Aggregate_Order_By>;
  twitter?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  youtube?: InputMaybe<Order_By>;
};

/** primary key columns input for table: social_medias */
export type Social_Medias_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "social_medias" */
export enum Social_Medias_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Id = 'id',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Linkedin = 'linkedin',
  /** column name */
  Other = 'other',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Youtube = 'youtube'
}

/** input type for updating data in table "social_medias" */
export type Social_Medias_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facebook?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  instagram?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  youtube?: InputMaybe<Scalars['String']>;
};

/** update columns of table "social_medias" */
export enum Social_Medias_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Id = 'id',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Linkedin = 'linkedin',
  /** column name */
  Other = 'other',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Youtube = 'youtube'
}

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "contents" */
  contents: Array<Contents>;
  /** fetch aggregated fields from the table: "contents" */
  contents_aggregate: Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  contents_by_pk?: Maybe<Contents>;
  /** An array relationship */
  contents_sections: Array<Contents_Sections>;
  /** An aggregate relationship */
  contents_sections_aggregate: Contents_Sections_Aggregate;
  /** fetch data from the table: "contents_sections" using primary key columns */
  contents_sections_by_pk?: Maybe<Contents_Sections>;
  createUser?: Maybe<CreateUser>;
  /** fetch data from the table: "diagnostics" */
  diagnostics: Array<Diagnostics>;
  /** fetch aggregated fields from the table: "diagnostics" */
  diagnostics_aggregate: Diagnostics_Aggregate;
  /** fetch data from the table: "diagnostics" using primary key columns */
  diagnostics_by_pk?: Maybe<Diagnostics>;
  /** fetch data from the table: "diagnostics_comments" */
  diagnostics_comments: Array<Diagnostics_Comments>;
  /** fetch aggregated fields from the table: "diagnostics_comments" */
  diagnostics_comments_aggregate: Diagnostics_Comments_Aggregate;
  /** fetch data from the table: "diagnostics_comments" using primary key columns */
  diagnostics_comments_by_pk?: Maybe<Diagnostics_Comments>;
  /** fetch data from the table: "diagnostics_groups" */
  diagnostics_groups: Array<Diagnostics_Groups>;
  /** fetch aggregated fields from the table: "diagnostics_groups" */
  diagnostics_groups_aggregate: Diagnostics_Groups_Aggregate;
  /** fetch data from the table: "diagnostics_groups" using primary key columns */
  diagnostics_groups_by_pk?: Maybe<Diagnostics_Groups>;
  /** fetch data from the table: "diagnostics_questions" */
  diagnostics_questions: Array<Diagnostics_Questions>;
  /** fetch aggregated fields from the table: "diagnostics_questions" */
  diagnostics_questions_aggregate: Diagnostics_Questions_Aggregate;
  /** fetch data from the table: "diagnostics_questions_answers" */
  diagnostics_questions_answers: Array<Diagnostics_Questions_Answers>;
  /** fetch aggregated fields from the table: "diagnostics_questions_answers" */
  diagnostics_questions_answers_aggregate: Diagnostics_Questions_Answers_Aggregate;
  /** fetch data from the table: "diagnostics_questions_answers" using primary key columns */
  diagnostics_questions_answers_by_pk?: Maybe<Diagnostics_Questions_Answers>;
  /** fetch data from the table: "diagnostics_questions" using primary key columns */
  diagnostics_questions_by_pk?: Maybe<Diagnostics_Questions>;
  /** fetch data from the table: "diagnostics_sections" */
  diagnostics_sections: Array<Diagnostics_Sections>;
  /** fetch aggregated fields from the table: "diagnostics_sections" */
  diagnostics_sections_aggregate: Diagnostics_Sections_Aggregate;
  /** fetch data from the table: "diagnostics_sections" using primary key columns */
  diagnostics_sections_by_pk?: Maybe<Diagnostics_Sections>;
  /** fetch data from the table: "diagnostics_subsections" */
  diagnostics_subsections: Array<Diagnostics_Subsections>;
  /** fetch aggregated fields from the table: "diagnostics_subsections" */
  diagnostics_subsections_aggregate: Diagnostics_Subsections_Aggregate;
  /** fetch data from the table: "diagnostics_subsections" using primary key columns */
  diagnostics_subsections_by_pk?: Maybe<Diagnostics_Subsections>;
  /** fetch data from the table: "diagnostics_tabs" */
  diagnostics_tabs: Array<Diagnostics_Tabs>;
  /** fetch aggregated fields from the table: "diagnostics_tabs" */
  diagnostics_tabs_aggregate: Diagnostics_Tabs_Aggregate;
  /** fetch data from the table: "diagnostics_tabs" using primary key columns */
  diagnostics_tabs_by_pk?: Maybe<Diagnostics_Tabs>;
  /** fetch data from the table: "diagnostics_tabs_sections" */
  diagnostics_tabs_sections: Array<Diagnostics_Tabs_Sections>;
  /** fetch aggregated fields from the table: "diagnostics_tabs_sections" */
  diagnostics_tabs_sections_aggregate: Diagnostics_Tabs_Sections_Aggregate;
  /** fetch data from the table: "diagnostics_tabs_sections" using primary key columns */
  diagnostics_tabs_sections_by_pk?: Maybe<Diagnostics_Tabs_Sections>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table: "groups_contents" */
  groups_contents: Array<Groups_Contents>;
  /** fetch aggregated fields from the table: "groups_contents" */
  groups_contents_aggregate: Groups_Contents_Aggregate;
  /** fetch data from the table: "groups_contents" using primary key columns */
  groups_contents_by_pk?: Maybe<Groups_Contents>;
  /** fetch data from the table: "groups_names" */
  groups_names: Array<Groups_Names>;
  /** fetch aggregated fields from the table: "groups_names" */
  groups_names_aggregate: Groups_Names_Aggregate;
  /** fetch data from the table: "groups_names" using primary key columns */
  groups_names_by_pk?: Maybe<Groups_Names>;
  /** fetch data from the table: "groups_teams" */
  groups_teams: Array<Groups_Teams>;
  /** fetch aggregated fields from the table: "groups_teams" */
  groups_teams_aggregate: Groups_Teams_Aggregate;
  /** fetch data from the table: "groups_teams" using primary key columns */
  groups_teams_by_pk?: Maybe<Groups_Teams>;
  /** fetch data from the table: "icons_types" */
  icons_types: Array<Icons_Types>;
  /** fetch aggregated fields from the table: "icons_types" */
  icons_types_aggregate: Icons_Types_Aggregate;
  /** fetch data from the table: "icons_types" using primary key columns */
  icons_types_by_pk?: Maybe<Icons_Types>;
  /** fetch data from the table: "invite_states" */
  invite_states: Array<Invite_States>;
  /** fetch aggregated fields from the table: "invite_states" */
  invite_states_aggregate: Invite_States_Aggregate;
  /** fetch data from the table: "invite_states" using primary key columns */
  invite_states_by_pk?: Maybe<Invite_States>;
  /** fetch data from the table: "kanban_columns" */
  kanban_columns: Array<Kanban_Columns>;
  /** fetch aggregated fields from the table: "kanban_columns" */
  kanban_columns_aggregate: Kanban_Columns_Aggregate;
  /** fetch data from the table: "kanban_columns" using primary key columns */
  kanban_columns_by_pk?: Maybe<Kanban_Columns>;
  /** fetch data from the table: "kanbans" */
  kanbans: Array<Kanbans>;
  /** fetch aggregated fields from the table: "kanbans" */
  kanbans_aggregate: Kanbans_Aggregate;
  /** fetch data from the table: "kanbans" using primary key columns */
  kanbans_by_pk?: Maybe<Kanbans>;
  /** fetch data from the table: "kanbans_cards" */
  kanbans_cards: Array<Kanbans_Cards>;
  /** fetch aggregated fields from the table: "kanbans_cards" */
  kanbans_cards_aggregate: Kanbans_Cards_Aggregate;
  /** fetch data from the table: "kanbans_cards_assignations" */
  kanbans_cards_assignations: Array<Kanbans_Cards_Assignations>;
  /** fetch aggregated fields from the table: "kanbans_cards_assignations" */
  kanbans_cards_assignations_aggregate: Kanbans_Cards_Assignations_Aggregate;
  /** fetch data from the table: "kanbans_cards_assignations" using primary key columns */
  kanbans_cards_assignations_by_pk?: Maybe<Kanbans_Cards_Assignations>;
  /** fetch data from the table: "kanbans_cards_attachments" */
  kanbans_cards_attachments: Array<Kanbans_Cards_Attachments>;
  /** fetch aggregated fields from the table: "kanbans_cards_attachments" */
  kanbans_cards_attachments_aggregate: Kanbans_Cards_Attachments_Aggregate;
  /** fetch data from the table: "kanbans_cards_attachments" using primary key columns */
  kanbans_cards_attachments_by_pk?: Maybe<Kanbans_Cards_Attachments>;
  /** fetch data from the table: "kanbans_cards" using primary key columns */
  kanbans_cards_by_pk?: Maybe<Kanbans_Cards>;
  /** fetch data from the table: "kanbans_cards_comments" */
  kanbans_cards_comments: Array<Kanbans_Cards_Comments>;
  /** fetch aggregated fields from the table: "kanbans_cards_comments" */
  kanbans_cards_comments_aggregate: Kanbans_Cards_Comments_Aggregate;
  /** fetch data from the table: "kanbans_cards_comments" using primary key columns */
  kanbans_cards_comments_by_pk?: Maybe<Kanbans_Cards_Comments>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table: "locations_names" */
  locations_names: Array<Locations_Names>;
  /** fetch aggregated fields from the table: "locations_names" */
  locations_names_aggregate: Locations_Names_Aggregate;
  /** fetch data from the table: "locations_names" using primary key columns */
  locations_names_by_pk?: Maybe<Locations_Names>;
  /** fetch data from the table: "mentoring_records" */
  mentoring_records: Array<Mentoring_Records>;
  /** fetch aggregated fields from the table: "mentoring_records" */
  mentoring_records_aggregate: Mentoring_Records_Aggregate;
  /** fetch data from the table: "mentoring_records" using primary key columns */
  mentoring_records_by_pk?: Maybe<Mentoring_Records>;
  /** fetch data from the table: "mentoring_records_members" */
  mentoring_records_members: Array<Mentoring_Records_Members>;
  /** fetch aggregated fields from the table: "mentoring_records_members" */
  mentoring_records_members_aggregate: Mentoring_Records_Members_Aggregate;
  /** fetch data from the table: "mentoring_records_members" using primary key columns */
  mentoring_records_members_by_pk?: Maybe<Mentoring_Records_Members>;
  /** fetch data from the table: "mentors_blablabla" */
  mentors_blablabla: Array<Mentors_Blablabla>;
  /** fetch aggregated fields from the table: "mentors_blablabla" */
  mentors_blablabla_aggregate: Mentors_Blablabla_Aggregate;
  /** fetch data from the table: "mentors_blablabla" using primary key columns */
  mentors_blablabla_by_pk?: Maybe<Mentors_Blablabla>;
  /** fetch data from the table: "multimedia_contents" */
  multimedia_contents: Array<Multimedia_Contents>;
  /** fetch aggregated fields from the table: "multimedia_contents" */
  multimedia_contents_aggregate: Multimedia_Contents_Aggregate;
  /** fetch data from the table: "multimedia_contents" using primary key columns */
  multimedia_contents_by_pk?: Maybe<Multimedia_Contents>;
  /** fetch data from the table: "multimedia_contents_links" */
  multimedia_contents_links: Array<Multimedia_Contents_Links>;
  /** fetch aggregated fields from the table: "multimedia_contents_links" */
  multimedia_contents_links_aggregate: Multimedia_Contents_Links_Aggregate;
  /** fetch data from the table: "multimedia_contents_links" using primary key columns */
  multimedia_contents_links_by_pk?: Maybe<Multimedia_Contents_Links>;
  /** An array relationship */
  outputs: Array<Outputs>;
  /** An aggregate relationship */
  outputs_aggregate: Outputs_Aggregate;
  /** fetch data from the table: "outputs" using primary key columns */
  outputs_by_pk?: Maybe<Outputs>;
  /** fetch data from the table: "outputs_icons" */
  outputs_icons: Array<Outputs_Icons>;
  /** fetch aggregated fields from the table: "outputs_icons" */
  outputs_icons_aggregate: Outputs_Icons_Aggregate;
  /** fetch data from the table: "outputs_icons" using primary key columns */
  outputs_icons_by_pk?: Maybe<Outputs_Icons>;
  /** fetch data from the table: "outputs_tools" */
  outputs_tools: Array<Outputs_Tools>;
  /** fetch aggregated fields from the table: "outputs_tools" */
  outputs_tools_aggregate: Outputs_Tools_Aggregate;
  /** fetch data from the table: "outputs_tools" using primary key columns */
  outputs_tools_by_pk?: Maybe<Outputs_Tools>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An aggregate relationship */
  questionnaires_aggregate: Questionnaires_Aggregate;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table: "questionnaires_teams_states" */
  questionnaires_teams_states: Array<Questionnaires_Teams_States>;
  /** fetch aggregated fields from the table: "questionnaires_teams_states" */
  questionnaires_teams_states_aggregate: Questionnaires_Teams_States_Aggregate;
  /** fetch data from the table: "questionnaires_teams_states" using primary key columns */
  questionnaires_teams_states_by_pk?: Maybe<Questionnaires_Teams_States>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** An array relationship */
  questions_answers: Array<Questions_Answers>;
  /** An aggregate relationship */
  questions_answers_aggregate: Questions_Answers_Aggregate;
  /** fetch data from the table: "questions_answers" using primary key columns */
  questions_answers_by_pk?: Maybe<Questions_Answers>;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table: "questions_types" */
  questions_types: Array<Questions_Types>;
  /** fetch aggregated fields from the table: "questions_types" */
  questions_types_aggregate: Questions_Types_Aggregate;
  /** fetch data from the table: "questions_types" using primary key columns */
  questions_types_by_pk?: Maybe<Questions_Types>;
  /** An array relationship */
  repositories: Array<Repositories>;
  /** An aggregate relationship */
  repositories_aggregate: Repositories_Aggregate;
  /** fetch data from the table: "repositories" using primary key columns */
  repositories_by_pk?: Maybe<Repositories>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "sections" */
  sections: Array<Sections>;
  /** fetch aggregated fields from the table: "sections" */
  sections_aggregate: Sections_Aggregate;
  /** fetch data from the table: "sections" using primary key columns */
  sections_by_pk?: Maybe<Sections>;
  /** fetch data from the table: "sections_icons" */
  sections_icons: Array<Sections_Icons>;
  /** fetch aggregated fields from the table: "sections_icons" */
  sections_icons_aggregate: Sections_Icons_Aggregate;
  /** fetch data from the table: "sections_icons" using primary key columns */
  sections_icons_by_pk?: Maybe<Sections_Icons>;
  /** fetch data from the table: "social_medias" */
  social_medias: Array<Social_Medias>;
  /** fetch aggregated fields from the table: "social_medias" */
  social_medias_aggregate: Social_Medias_Aggregate;
  /** fetch data from the table: "social_medias" using primary key columns */
  social_medias_by_pk?: Maybe<Social_Medias>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table: "team_invites" */
  team_invites: Array<Team_Invites>;
  /** fetch aggregated fields from the table: "team_invites" */
  team_invites_aggregate: Team_Invites_Aggregate;
  /** fetch data from the table: "team_invites" using primary key columns */
  team_invites_by_pk?: Maybe<Team_Invites>;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table: "teams_names" */
  teams_names: Array<Teams_Names>;
  /** fetch aggregated fields from the table: "teams_names" */
  teams_names_aggregate: Teams_Names_Aggregate;
  /** fetch data from the table: "teams_names" using primary key columns */
  teams_names_by_pk?: Maybe<Teams_Names>;
  /** fetch data from the table: "teams_scores" */
  teams_scores: Array<Teams_Scores>;
  /** fetch aggregated fields from the table: "teams_scores" */
  teams_scores_aggregate: Teams_Scores_Aggregate;
  /** fetch data from the table: "teams_tags" */
  teams_tags: Array<Teams_Tags>;
  /** fetch aggregated fields from the table: "teams_tags" */
  teams_tags_aggregate: Teams_Tags_Aggregate;
  /** fetch data from the table: "teams_tags" using primary key columns */
  teams_tags_by_pk?: Maybe<Teams_Tags>;
  /** fetch data from the table: "teams_users" */
  teams_users: Array<Teams_Users>;
  /** fetch aggregated fields from the table: "teams_users" */
  teams_users_aggregate: Teams_Users_Aggregate;
  /** fetch data from the table: "teams_users" using primary key columns */
  teams_users_by_pk?: Maybe<Teams_Users>;
  /** fetch data from the table: "tools" */
  tools: Array<Tools>;
  /** fetch aggregated fields from the table: "tools" */
  tools_aggregate: Tools_Aggregate;
  /** fetch data from the table: "tools" using primary key columns */
  tools_by_pk?: Maybe<Tools>;
  /** fetch data from the table: "tools_icons" */
  tools_icons: Array<Tools_Icons>;
  /** fetch aggregated fields from the table: "tools_icons" */
  tools_icons_aggregate: Tools_Icons_Aggregate;
  /** fetch data from the table: "tools_icons" using primary key columns */
  tools_icons_by_pk?: Maybe<Tools_Icons>;
  /** fetch data from the table: "user_info" */
  user_info: Array<User_Info>;
  /** fetch aggregated fields from the table: "user_info" */
  user_info_aggregate: User_Info_Aggregate;
  /** fetch data from the table: "user_info" using primary key columns */
  user_info_by_pk?: Maybe<User_Info>;
  /** fetch data from the table: "user_invites" */
  user_invites: Array<User_Invites>;
  /** fetch aggregated fields from the table: "user_invites" */
  user_invites_aggregate: User_Invites_Aggregate;
  /** fetch data from the table: "user_invites" using primary key columns */
  user_invites_by_pk?: Maybe<User_Invites>;
  /** An array relationship */
  users: Array<Users>;
  /** fetch data from the table: "users_actions" */
  users_actions: Array<Users_Actions>;
  /** fetch aggregated fields from the table: "users_actions" */
  users_actions_aggregate: Users_Actions_Aggregate;
  /** fetch data from the table: "users_actions" using primary key columns */
  users_actions_by_pk?: Maybe<Users_Actions>;
  /** fetch data from the table: "users_actions_types" */
  users_actions_types: Array<Users_Actions_Types>;
  /** fetch aggregated fields from the table: "users_actions_types" */
  users_actions_types_aggregate: Users_Actions_Types_Aggregate;
  /** fetch data from the table: "users_actions_types" using primary key columns */
  users_actions_types_by_pk?: Maybe<Users_Actions_Types>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_documents" */
  users_documents: Array<Users_Documents>;
  /** fetch aggregated fields from the table: "users_documents" */
  users_documents_aggregate: Users_Documents_Aggregate;
  /** fetch data from the table: "users_documents" using primary key columns */
  users_documents_by_pk?: Maybe<Users_Documents>;
  /** fetch data from the table: "users_documents_types" */
  users_documents_types: Array<Users_Documents_Types>;
  /** fetch aggregated fields from the table: "users_documents_types" */
  users_documents_types_aggregate: Users_Documents_Types_Aggregate;
  /** fetch data from the table: "users_documents_types" using primary key columns */
  users_documents_types_by_pk?: Maybe<Users_Documents_Types>;
  /** fetch data from the table: "users_names" */
  users_names: Array<Users_Names>;
  /** fetch aggregated fields from the table: "users_names" */
  users_names_aggregate: Users_Names_Aggregate;
  /** fetch data from the table: "users_names" using primary key columns */
  users_names_by_pk?: Maybe<Users_Names>;
  /** An array relationship */
  video_repositories: Array<Video_Repositories>;
  /** An aggregate relationship */
  video_repositories_aggregate: Video_Repositories_Aggregate;
  /** fetch data from the table: "video_repositories" using primary key columns */
  video_repositories_by_pk?: Maybe<Video_Repositories>;
  /** An array relationship */
  videos: Array<Videos>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Aggregate;
  /** fetch data from the table: "videos" using primary key columns */
  videos_by_pk?: Maybe<Videos>;
  /** execute function "videos_by_tags" which returns "videos" */
  videos_by_tags: Array<Videos>;
  /** execute function "videos_by_tags" and query aggregates on result of table type "videos" */
  videos_by_tags_aggregate: Videos_Aggregate;
  /** fetch data from the table: "videos_youtube_tags" */
  videos_youtube_tags: Array<Videos_Youtube_Tags>;
  /** fetch aggregated fields from the table: "videos_youtube_tags" */
  videos_youtube_tags_aggregate: Videos_Youtube_Tags_Aggregate;
  /** fetch data from the table: "videos_youtube_tags" using primary key columns */
  videos_youtube_tags_by_pk?: Maybe<Videos_Youtube_Tags>;
  /** fetch data from the table: "youtube_tags" */
  youtube_tags: Array<Youtube_Tags>;
  /** fetch aggregated fields from the table: "youtube_tags" */
  youtube_tags_aggregate: Youtube_Tags_Aggregate;
  /** fetch data from the table: "youtube_tags" using primary key columns */
  youtube_tags_by_pk?: Maybe<Youtube_Tags>;
};


export type Subscription_RootContentsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Subscription_RootContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Subscription_RootContents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContents_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


export type Subscription_RootContents_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


export type Subscription_RootContents_Sections_By_PkArgs = {
  content_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


export type Subscription_RootCreateUserArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Subscription_RootDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Subscription_RootDiagnostics_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Comments_By_PkArgs = {
  diagnostic_tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Groups_By_PkArgs = {
  diagnostic_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Questions_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Questions_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Questions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_SubsectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Subsections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Subsections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_TabsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Tabs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Tabs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_Tabs_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Tabs_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Tabs_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroups_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


export type Subscription_RootGroups_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


export type Subscription_RootGroups_Contents_By_PkArgs = {
  content_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


export type Subscription_RootGroups_NamesArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


export type Subscription_RootGroups_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


export type Subscription_RootGroups_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroups_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


export type Subscription_RootGroups_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


export type Subscription_RootGroups_Teams_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootIcons_TypesArgs = {
  distinct_on?: InputMaybe<Array<Icons_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icons_Types_Order_By>>;
  where?: InputMaybe<Icons_Types_Bool_Exp>;
};


export type Subscription_RootIcons_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icons_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icons_Types_Order_By>>;
  where?: InputMaybe<Icons_Types_Bool_Exp>;
};


export type Subscription_RootIcons_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootInvite_StatesArgs = {
  distinct_on?: InputMaybe<Array<Invite_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invite_States_Order_By>>;
  where?: InputMaybe<Invite_States_Bool_Exp>;
};


export type Subscription_RootInvite_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invite_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invite_States_Order_By>>;
  where?: InputMaybe<Invite_States_Bool_Exp>;
};


export type Subscription_RootInvite_States_By_PkArgs = {
  state: Scalars['String'];
};


export type Subscription_RootKanban_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Kanban_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanban_Columns_Order_By>>;
  where?: InputMaybe<Kanban_Columns_Bool_Exp>;
};


export type Subscription_RootKanban_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanban_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanban_Columns_Order_By>>;
  where?: InputMaybe<Kanban_Columns_Bool_Exp>;
};


export type Subscription_RootKanban_Columns_By_PkArgs = {
  column: Scalars['String'];
};


export type Subscription_RootKanbansArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Order_By>>;
  where?: InputMaybe<Kanbans_Bool_Exp>;
};


export type Subscription_RootKanbans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Order_By>>;
  where?: InputMaybe<Kanbans_Bool_Exp>;
};


export type Subscription_RootKanbans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootKanbans_CardsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_AssignationsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Assignations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Assignations_By_PkArgs = {
  card_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Subscription_RootKanbans_Cards_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Attachments_By_PkArgs = {
  card_id: Scalars['uuid'];
  file_id: Scalars['uuid'];
};


export type Subscription_RootKanbans_Cards_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootKanbans_Cards_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocations_NamesArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};


export type Subscription_RootLocations_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};


export type Subscription_RootLocations_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMentoring_RecordsArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMentoring_Records_MembersArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_Members_By_PkArgs = {
  member_id: Scalars['String'];
  mentoring_record_id: Scalars['uuid'];
};


export type Subscription_RootMentors_BlablablaArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


export type Subscription_RootMentors_Blablabla_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


export type Subscription_RootMentors_Blablabla_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMultimedia_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Bool_Exp>;
};


export type Subscription_RootMultimedia_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Bool_Exp>;
};


export type Subscription_RootMultimedia_Contents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMultimedia_Contents_LinksArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};


export type Subscription_RootMultimedia_Contents_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};


export type Subscription_RootMultimedia_Contents_Links_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOutputsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


export type Subscription_RootOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


export type Subscription_RootOutputs_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


export type Subscription_RootOutputs_IconsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


export type Subscription_RootOutputs_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


export type Subscription_RootOutputs_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOutputs_ToolsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


export type Subscription_RootOutputs_Tools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


export type Subscription_RootOutputs_Tools_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuestionnaires_Teams_StatesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_Teams_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_Teams_States_By_PkArgs = {
  questionnaire_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


export type Subscription_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


export type Subscription_RootQuestions_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


export type Subscription_RootQuestions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuestions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuestions_TypesArgs = {
  distinct_on?: InputMaybe<Array<Questions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Types_Order_By>>;
  where?: InputMaybe<Questions_Types_Bool_Exp>;
};


export type Subscription_RootQuestions_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Types_Order_By>>;
  where?: InputMaybe<Questions_Types_Bool_Exp>;
};


export type Subscription_RootQuestions_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootRepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


export type Subscription_RootRepositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


export type Subscription_RootRepositories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  role: Scalars['String'];
};


export type Subscription_RootSectionsArgs = {
  distinct_on?: InputMaybe<Array<Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Order_By>>;
  where?: InputMaybe<Sections_Bool_Exp>;
};


export type Subscription_RootSections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Order_By>>;
  where?: InputMaybe<Sections_Bool_Exp>;
};


export type Subscription_RootSections_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


export type Subscription_RootSections_IconsArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};


export type Subscription_RootSections_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};


export type Subscription_RootSections_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSocial_MediasArgs = {
  distinct_on?: InputMaybe<Array<Social_Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Social_Medias_Order_By>>;
  where?: InputMaybe<Social_Medias_Bool_Exp>;
};


export type Subscription_RootSocial_Medias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Social_Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Social_Medias_Order_By>>;
  where?: InputMaybe<Social_Medias_Bool_Exp>;
};


export type Subscription_RootSocial_Medias_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
  value: Scalars['String'];
};


export type Subscription_RootTeam_InvitesArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


export type Subscription_RootTeam_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


export type Subscription_RootTeam_Invites_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeams_NamesArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


export type Subscription_RootTeams_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


export type Subscription_RootTeams_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeams_ScoresArgs = {
  distinct_on?: InputMaybe<Array<Teams_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Scores_Order_By>>;
  where?: InputMaybe<Teams_Scores_Bool_Exp>;
};


export type Subscription_RootTeams_Scores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Scores_Order_By>>;
  where?: InputMaybe<Teams_Scores_Bool_Exp>;
};


export type Subscription_RootTeams_TagsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


export type Subscription_RootTeams_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


export type Subscription_RootTeams_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeams_UsersArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


export type Subscription_RootTeams_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


export type Subscription_RootTeams_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootToolsArgs = {
  distinct_on?: InputMaybe<Array<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Subscription_RootTools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Subscription_RootTools_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


export type Subscription_RootTools_IconsArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


export type Subscription_RootTools_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


export type Subscription_RootTools_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_InfoArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Subscription_RootUser_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Subscription_RootUser_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_InvitesArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_Invites_By_PkArgs = {
  id: Scalars['uuid'];
  token: Scalars['String'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_Actions_TypesArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Types_Order_By>>;
  where?: InputMaybe<Users_Actions_Types_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Types_Order_By>>;
  where?: InputMaybe<Users_Actions_Types_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  email: Scalars['String'];
  id: Scalars['String'];
};


export type Subscription_RootUsers_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


export type Subscription_RootUsers_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


export type Subscription_RootUsers_Documents_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Subscription_RootUsers_Documents_TypesArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Types_Order_By>>;
  where?: InputMaybe<Users_Documents_Types_Bool_Exp>;
};


export type Subscription_RootUsers_Documents_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Types_Order_By>>;
  where?: InputMaybe<Users_Documents_Types_Bool_Exp>;
};


export type Subscription_RootUsers_Documents_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootUsers_NamesArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


export type Subscription_RootUsers_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


export type Subscription_RootUsers_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVideo_RepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};


export type Subscription_RootVideo_Repositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};


export type Subscription_RootVideo_Repositories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVideos_By_TagsArgs = {
  args: Videos_By_Tags_Args;
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_By_Tags_AggregateArgs = {
  args: Videos_By_Tags_Args;
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_Youtube_TagsArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


export type Subscription_RootVideos_Youtube_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


export type Subscription_RootVideos_Youtube_Tags_By_PkArgs = {
  tag: Scalars['String'];
  video_id: Scalars['uuid'];
};


export type Subscription_RootYoutube_TagsArgs = {
  distinct_on?: InputMaybe<Array<Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Youtube_Tags_Order_By>>;
  where?: InputMaybe<Youtube_Tags_Bool_Exp>;
};


export type Subscription_RootYoutube_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Youtube_Tags_Order_By>>;
  where?: InputMaybe<Youtube_Tags_Bool_Exp>;
};


export type Subscription_RootYoutube_Tags_By_PkArgs = {
  tag: Scalars['String'];
};

/** columns and relationships of "tags" */
export type Tags = {
  __typename?: 'tags';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  teams: Array<Teams_Tags>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Tags_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "tags" */
export type TagsTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};

/** aggregated selection of "tags" */
export type Tags_Aggregate = {
  __typename?: 'tags_aggregate';
  aggregate?: Maybe<Tags_Aggregate_Fields>;
  nodes: Array<Tags>;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_Fields = {
  __typename?: 'tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tags_Max_Fields>;
  min?: Maybe<Tags_Min_Fields>;
};


/** aggregate fields of "tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Tags_Bool_Exp>>;
  _not?: InputMaybe<Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  teams?: InputMaybe<Teams_Tags_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint */
  TagsIdKey = 'tags_id_key',
  /** unique or primary key constraint */
  TagsPkey = 'tags_pkey',
  /** unique or primary key constraint */
  TagsValueKey = 'tags_value_key'
}

/** input type for inserting data into table "tags" */
export type Tags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  teams?: InputMaybe<Teams_Tags_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  __typename?: 'tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  __typename?: 'tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tags" */
export type Tags_Mutation_Response = {
  __typename?: 'tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type Tags_Obj_Rel_Insert_Input = {
  data: Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** on_conflict condition type for table "tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns?: Array<Tags_Update_Column>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "tags". */
export type Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  teams_aggregate?: InputMaybe<Teams_Tags_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tags */
export type Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
  value: Scalars['String'];
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "tags" */
export type Tags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "tags" */
export enum Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "team_invites" */
export type Team_Invites = {
  __typename?: 'team_invites';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  status: Invite_States_Enum;
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "team_invites" */
export type Team_Invites_Aggregate = {
  __typename?: 'team_invites_aggregate';
  aggregate?: Maybe<Team_Invites_Aggregate_Fields>;
  nodes: Array<Team_Invites>;
};

/** aggregate fields of "team_invites" */
export type Team_Invites_Aggregate_Fields = {
  __typename?: 'team_invites_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_Invites_Max_Fields>;
  min?: Maybe<Team_Invites_Min_Fields>;
};


/** aggregate fields of "team_invites" */
export type Team_Invites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Invites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "team_invites" */
export type Team_Invites_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Team_Invites_Max_Order_By>;
  min?: InputMaybe<Team_Invites_Min_Order_By>;
};

/** input type for inserting array relation for remote table "team_invites" */
export type Team_Invites_Arr_Rel_Insert_Input = {
  data: Array<Team_Invites_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Team_Invites_On_Conflict>;
};

/** Boolean expression to filter rows from the table "team_invites". All fields are combined with a logical 'AND'. */
export type Team_Invites_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Invites_Bool_Exp>>;
  _not?: InputMaybe<Team_Invites_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Invites_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Invite_States_Enum_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_invites" */
export enum Team_Invites_Constraint {
  /** unique or primary key constraint */
  TeamInvitesPkey = 'team_invites_pkey'
}

/** input type for inserting data into table "team_invites" */
export type Team_Invites_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Invite_States_Enum>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Team_Invites_Max_Fields = {
  __typename?: 'team_invites_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "team_invites" */
export type Team_Invites_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Team_Invites_Min_Fields = {
  __typename?: 'team_invites_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "team_invites" */
export type Team_Invites_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "team_invites" */
export type Team_Invites_Mutation_Response = {
  __typename?: 'team_invites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_Invites>;
};

/** on_conflict condition type for table "team_invites" */
export type Team_Invites_On_Conflict = {
  constraint: Team_Invites_Constraint;
  update_columns?: Array<Team_Invites_Update_Column>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};

/** Ordering options when selecting data from "team_invites". */
export type Team_Invites_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_invites */
export type Team_Invites_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "team_invites" */
export enum Team_Invites_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "team_invites" */
export type Team_Invites_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Invite_States_Enum>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "team_invites" */
export enum Team_Invites_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "teams" */
export type Teams = {
  __typename?: 'teams';
  cnpj?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  groups: Array<Groups_Teams>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Teams_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  logo?: Maybe<Files>;
  logo_file_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  members: Array<Teams_Users>;
  /** An aggregate relationship */
  members_aggregate: Teams_Users_Aggregate;
  /** An array relationship */
  mentors_blablablas: Array<Mentors_Blablabla>;
  /** An aggregate relationship */
  mentors_blablablas_aggregate: Mentors_Blablabla_Aggregate;
  /** A computed field, executes function "team_name" */
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  names: Array<Teams_Names>;
  /** An aggregate relationship */
  names_aggregate: Teams_Names_Aggregate;
  /** An array relationship */
  questionnaires_states: Array<Questionnaires_Teams_States>;
  /** An aggregate relationship */
  questionnaires_states_aggregate: Questionnaires_Teams_States_Aggregate;
  /** An array relationship */
  questions_answers: Array<Questions_Answers>;
  /** An aggregate relationship */
  questions_answers_aggregate: Questions_Answers_Aggregate;
  /** An object relationship */
  social_media?: Maybe<Social_Medias>;
  social_media_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  tags: Array<Teams_Tags>;
  /** An aggregate relationship */
  tags_aggregate: Teams_Tags_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  user_invitations: Array<Team_Invites>;
  /** An aggregate relationship */
  user_invitations_aggregate: Team_Invites_Aggregate;
};


/** columns and relationships of "teams" */
export type TeamsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsMembersArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsMentors_BlablablasArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsMentors_Blablablas_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsNamesArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsNames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsQuestionnaires_StatesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsQuestionnaires_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsQuestions_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsQuestions_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsTagsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsUser_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsUser_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};

/** aggregated selection of "teams" */
export type Teams_Aggregate = {
  __typename?: 'teams_aggregate';
  aggregate?: Maybe<Teams_Aggregate_Fields>;
  nodes: Array<Teams>;
};

/** aggregate fields of "teams" */
export type Teams_Aggregate_Fields = {
  __typename?: 'teams_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teams_Max_Fields>;
  min?: Maybe<Teams_Min_Fields>;
};


/** aggregate fields of "teams" */
export type Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams" */
export type Teams_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Max_Order_By>;
  min?: InputMaybe<Teams_Min_Order_By>;
};

/** input type for inserting array relation for remote table "teams" */
export type Teams_Arr_Rel_Insert_Input = {
  data: Array<Teams_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teams". All fields are combined with a logical 'AND'. */
export type Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Bool_Exp>>;
  _not?: InputMaybe<Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Bool_Exp>>;
  cnpj?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  groups?: InputMaybe<Groups_Teams_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  logo?: InputMaybe<Files_Bool_Exp>;
  logo_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  members?: InputMaybe<Teams_Users_Bool_Exp>;
  mentors_blablablas?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  names?: InputMaybe<Teams_Names_Bool_Exp>;
  questionnaires_states?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
  questions_answers?: InputMaybe<Questions_Answers_Bool_Exp>;
  social_media?: InputMaybe<Social_Medias_Bool_Exp>;
  social_media_id?: InputMaybe<Uuid_Comparison_Exp>;
  tags?: InputMaybe<Teams_Tags_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_invitations?: InputMaybe<Team_Invites_Bool_Exp>;
};

/** unique or primary key constraints on table "teams" */
export enum Teams_Constraint {
  /** unique or primary key constraint */
  TeamsPkey = 'teams_pkey',
  /** unique or primary key constraint */
  TeamsSocialMediaIdKey = 'teams_social_media_id_key'
}

/** input type for inserting data into table "teams" */
export type Teams_Insert_Input = {
  cnpj?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Groups_Teams_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  logo?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  logo_file_id?: InputMaybe<Scalars['uuid']>;
  members?: InputMaybe<Teams_Users_Arr_Rel_Insert_Input>;
  mentors_blablablas?: InputMaybe<Mentors_Blablabla_Arr_Rel_Insert_Input>;
  names?: InputMaybe<Teams_Names_Arr_Rel_Insert_Input>;
  questionnaires_states?: InputMaybe<Questionnaires_Teams_States_Arr_Rel_Insert_Input>;
  questions_answers?: InputMaybe<Questions_Answers_Arr_Rel_Insert_Input>;
  social_media?: InputMaybe<Social_Medias_Obj_Rel_Insert_Input>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<Teams_Tags_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_invitations?: InputMaybe<Team_Invites_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Teams_Max_Fields = {
  __typename?: 'teams_max_fields';
  cnpj?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_file_id?: Maybe<Scalars['uuid']>;
  social_media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "teams" */
export type Teams_Max_Order_By = {
  cnpj?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_file_id?: InputMaybe<Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Min_Fields = {
  __typename?: 'teams_min_fields';
  cnpj?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_file_id?: Maybe<Scalars['uuid']>;
  social_media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "teams" */
export type Teams_Min_Order_By = {
  cnpj?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_file_id?: InputMaybe<Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams" */
export type Teams_Mutation_Response = {
  __typename?: 'teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams>;
};

/** columns and relationships of "teams_names" */
export type Teams_Names = {
  __typename?: 'teams_names';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};

/** aggregated selection of "teams_names" */
export type Teams_Names_Aggregate = {
  __typename?: 'teams_names_aggregate';
  aggregate?: Maybe<Teams_Names_Aggregate_Fields>;
  nodes: Array<Teams_Names>;
};

/** aggregate fields of "teams_names" */
export type Teams_Names_Aggregate_Fields = {
  __typename?: 'teams_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teams_Names_Max_Fields>;
  min?: Maybe<Teams_Names_Min_Fields>;
};


/** aggregate fields of "teams_names" */
export type Teams_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams_names" */
export type Teams_Names_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Names_Max_Order_By>;
  min?: InputMaybe<Teams_Names_Min_Order_By>;
};

/** input type for inserting array relation for remote table "teams_names" */
export type Teams_Names_Arr_Rel_Insert_Input = {
  data: Array<Teams_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Names_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teams_names". All fields are combined with a logical 'AND'. */
export type Teams_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Names_Bool_Exp>>;
  _not?: InputMaybe<Teams_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams_names" */
export enum Teams_Names_Constraint {
  /** unique or primary key constraint */
  TeamsNamesIdKey = 'teams_names_id_key',
  /** unique or primary key constraint */
  TeamsNamesPkey = 'teams_names_pkey'
}

/** input type for inserting data into table "teams_names" */
export type Teams_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Teams_Names_Max_Fields = {
  __typename?: 'teams_names_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "teams_names" */
export type Teams_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Names_Min_Fields = {
  __typename?: 'teams_names_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "teams_names" */
export type Teams_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams_names" */
export type Teams_Names_Mutation_Response = {
  __typename?: 'teams_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams_Names>;
};

/** on_conflict condition type for table "teams_names" */
export type Teams_Names_On_Conflict = {
  constraint: Teams_Names_Constraint;
  update_columns?: Array<Teams_Names_Update_Column>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "teams_names". */
export type Teams_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams_names */
export type Teams_Names_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "teams_names" */
export enum Teams_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "teams_names" */
export type Teams_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "teams_names" */
export enum Teams_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TeamId = 'team_id'
}

/** input type for inserting object relation for remote table "teams" */
export type Teams_Obj_Rel_Insert_Input = {
  data: Teams_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};

/** on_conflict condition type for table "teams" */
export type Teams_On_Conflict = {
  constraint: Teams_Constraint;
  update_columns?: Array<Teams_Update_Column>;
  where?: InputMaybe<Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "teams". */
export type Teams_Order_By = {
  cnpj?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Groups_Teams_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Files_Order_By>;
  logo_file_id?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<Teams_Users_Aggregate_Order_By>;
  mentors_blablablas_aggregate?: InputMaybe<Mentors_Blablabla_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  names_aggregate?: InputMaybe<Teams_Names_Aggregate_Order_By>;
  questionnaires_states_aggregate?: InputMaybe<Questionnaires_Teams_States_Aggregate_Order_By>;
  questions_answers_aggregate?: InputMaybe<Questions_Answers_Aggregate_Order_By>;
  social_media?: InputMaybe<Social_Medias_Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Teams_Tags_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_invitations_aggregate?: InputMaybe<Team_Invites_Aggregate_Order_By>;
};

/** primary key columns input for table: teams */
export type Teams_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "teams_scores" */
export type Teams_Scores = {
  __typename?: 'teams_scores';
  sum?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  team?: Maybe<Teams>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "teams_scores" */
export type Teams_Scores_Aggregate = {
  __typename?: 'teams_scores_aggregate';
  aggregate?: Maybe<Teams_Scores_Aggregate_Fields>;
  nodes: Array<Teams_Scores>;
};

/** aggregate fields of "teams_scores" */
export type Teams_Scores_Aggregate_Fields = {
  __typename?: 'teams_scores_aggregate_fields';
  avg?: Maybe<Teams_Scores_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teams_Scores_Max_Fields>;
  min?: Maybe<Teams_Scores_Min_Fields>;
  stddev?: Maybe<Teams_Scores_Stddev_Fields>;
  stddev_pop?: Maybe<Teams_Scores_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teams_Scores_Stddev_Samp_Fields>;
  sum?: Maybe<Teams_Scores_Sum_Fields>;
  var_pop?: Maybe<Teams_Scores_Var_Pop_Fields>;
  var_samp?: Maybe<Teams_Scores_Var_Samp_Fields>;
  variance?: Maybe<Teams_Scores_Variance_Fields>;
};


/** aggregate fields of "teams_scores" */
export type Teams_Scores_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Scores_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Teams_Scores_Avg_Fields = {
  __typename?: 'teams_scores_avg_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "teams_scores". All fields are combined with a logical 'AND'. */
export type Teams_Scores_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Scores_Bool_Exp>>;
  _not?: InputMaybe<Teams_Scores_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Scores_Bool_Exp>>;
  sum?: InputMaybe<Bigint_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Teams_Scores_Max_Fields = {
  __typename?: 'teams_scores_max_fields';
  sum?: Maybe<Scalars['bigint']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Teams_Scores_Min_Fields = {
  __typename?: 'teams_scores_min_fields';
  sum?: Maybe<Scalars['bigint']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "teams_scores". */
export type Teams_Scores_Order_By = {
  sum?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** select columns of table "teams_scores" */
export enum Teams_Scores_Select_Column {
  /** column name */
  Sum = 'sum',
  /** column name */
  TeamId = 'team_id'
}

/** aggregate stddev on columns */
export type Teams_Scores_Stddev_Fields = {
  __typename?: 'teams_scores_stddev_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Teams_Scores_Stddev_Pop_Fields = {
  __typename?: 'teams_scores_stddev_pop_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Teams_Scores_Stddev_Samp_Fields = {
  __typename?: 'teams_scores_stddev_samp_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Teams_Scores_Sum_Fields = {
  __typename?: 'teams_scores_sum_fields';
  sum?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Teams_Scores_Var_Pop_Fields = {
  __typename?: 'teams_scores_var_pop_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Teams_Scores_Var_Samp_Fields = {
  __typename?: 'teams_scores_var_samp_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Teams_Scores_Variance_Fields = {
  __typename?: 'teams_scores_variance_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** select columns of table "teams" */
export enum Teams_Select_Column {
  /** column name */
  Cnpj = 'cnpj',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LogoFileId = 'logo_file_id',
  /** column name */
  SocialMediaId = 'social_media_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "teams" */
export type Teams_Set_Input = {
  cnpj?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  logo_file_id?: InputMaybe<Scalars['uuid']>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "teams_tags" */
export type Teams_Tags = {
  __typename?: 'teams_tags';
  id: Scalars['uuid'];
  /** An object relationship */
  tag: Tags;
  tag_id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};

/** aggregated selection of "teams_tags" */
export type Teams_Tags_Aggregate = {
  __typename?: 'teams_tags_aggregate';
  aggregate?: Maybe<Teams_Tags_Aggregate_Fields>;
  nodes: Array<Teams_Tags>;
};

/** aggregate fields of "teams_tags" */
export type Teams_Tags_Aggregate_Fields = {
  __typename?: 'teams_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teams_Tags_Max_Fields>;
  min?: Maybe<Teams_Tags_Min_Fields>;
};


/** aggregate fields of "teams_tags" */
export type Teams_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams_tags" */
export type Teams_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Tags_Max_Order_By>;
  min?: InputMaybe<Teams_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "teams_tags" */
export type Teams_Tags_Arr_Rel_Insert_Input = {
  data: Array<Teams_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teams_tags". All fields are combined with a logical 'AND'. */
export type Teams_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Tags_Bool_Exp>>;
  _not?: InputMaybe<Teams_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Tags_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams_tags" */
export enum Teams_Tags_Constraint {
  /** unique or primary key constraint */
  TeamsTagsPkey = 'teams_tags_pkey',
  /** unique or primary key constraint */
  TeamsTagsTeamIdTagIdKey = 'teams_tags_team_id_tag_id_key'
}

/** input type for inserting data into table "teams_tags" */
export type Teams_Tags_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Teams_Tags_Max_Fields = {
  __typename?: 'teams_tags_max_fields';
  id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "teams_tags" */
export type Teams_Tags_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Tags_Min_Fields = {
  __typename?: 'teams_tags_min_fields';
  id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "teams_tags" */
export type Teams_Tags_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams_tags" */
export type Teams_Tags_Mutation_Response = {
  __typename?: 'teams_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams_Tags>;
};

/** on_conflict condition type for table "teams_tags" */
export type Teams_Tags_On_Conflict = {
  constraint: Teams_Tags_Constraint;
  update_columns?: Array<Teams_Tags_Update_Column>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "teams_tags". */
export type Teams_Tags_Order_By = {
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams_tags */
export type Teams_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "teams_tags" */
export enum Teams_Tags_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "teams_tags" */
export type Teams_Tags_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "teams_tags" */
export enum Teams_Tags_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TeamId = 'team_id'
}

/** update columns of table "teams" */
export enum Teams_Update_Column {
  /** column name */
  Cnpj = 'cnpj',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LogoFileId = 'logo_file_id',
  /** column name */
  SocialMediaId = 'social_media_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "teams_users" */
export type Teams_Users = {
  __typename?: 'teams_users';
  id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "teams_users" */
export type Teams_Users_Aggregate = {
  __typename?: 'teams_users_aggregate';
  aggregate?: Maybe<Teams_Users_Aggregate_Fields>;
  nodes: Array<Teams_Users>;
};

/** aggregate fields of "teams_users" */
export type Teams_Users_Aggregate_Fields = {
  __typename?: 'teams_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teams_Users_Max_Fields>;
  min?: Maybe<Teams_Users_Min_Fields>;
};


/** aggregate fields of "teams_users" */
export type Teams_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams_users" */
export type Teams_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Users_Max_Order_By>;
  min?: InputMaybe<Teams_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "teams_users" */
export type Teams_Users_Arr_Rel_Insert_Input = {
  data: Array<Teams_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teams_users". All fields are combined with a logical 'AND'. */
export type Teams_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Users_Bool_Exp>>;
  _not?: InputMaybe<Teams_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Users_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams_users" */
export enum Teams_Users_Constraint {
  /** unique or primary key constraint */
  TeamsUsersPkey = 'teams_users_pkey',
  /** unique or primary key constraint */
  TeamsUsersTeamIdUserIdKey = 'teams_users_team_id_user_id_key'
}

/** input type for inserting data into table "teams_users" */
export type Teams_Users_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Teams_Users_Max_Fields = {
  __typename?: 'teams_users_max_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "teams_users" */
export type Teams_Users_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Users_Min_Fields = {
  __typename?: 'teams_users_min_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "teams_users" */
export type Teams_Users_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams_users" */
export type Teams_Users_Mutation_Response = {
  __typename?: 'teams_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams_Users>;
};

/** on_conflict condition type for table "teams_users" */
export type Teams_Users_On_Conflict = {
  constraint: Teams_Users_Constraint;
  update_columns?: Array<Teams_Users_Update_Column>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "teams_users". */
export type Teams_Users_Order_By = {
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams_users */
export type Teams_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "teams_users" */
export enum Teams_Users_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "teams_users" */
export type Teams_Users_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "teams_users" */
export enum Teams_Users_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "tools" */
export type Tools = {
  __typename?: 'tools';
  explanation: Scalars['String'];
  /** An array relationship */
  icons: Array<Tools_Icons>;
  /** An aggregate relationship */
  icons_aggregate: Tools_Icons_Aggregate;
  id: Scalars['uuid'];
  intro: Scalars['String'];
  /** An array relationship */
  mentors_blablablas: Array<Mentors_Blablabla>;
  /** An aggregate relationship */
  mentors_blablablas_aggregate: Mentors_Blablabla_Aggregate;
  /** An object relationship */
  multimedia_content: Multimedia_Contents;
  multimedia_content_id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  outputs: Array<Outputs_Tools>;
  /** An aggregate relationship */
  outputs_aggregate: Outputs_Tools_Aggregate;
  points: Scalars['smallint'];
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An aggregate relationship */
  questionnaires_aggregate: Questionnaires_Aggregate;
  slug: Scalars['String'];
};


/** columns and relationships of "tools" */
export type ToolsIconsArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsIcons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsMentors_BlablablasArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsMentors_Blablablas_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsOutputsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** aggregated selection of "tools" */
export type Tools_Aggregate = {
  __typename?: 'tools_aggregate';
  aggregate?: Maybe<Tools_Aggregate_Fields>;
  nodes: Array<Tools>;
};

/** aggregate fields of "tools" */
export type Tools_Aggregate_Fields = {
  __typename?: 'tools_aggregate_fields';
  avg?: Maybe<Tools_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tools_Max_Fields>;
  min?: Maybe<Tools_Min_Fields>;
  stddev?: Maybe<Tools_Stddev_Fields>;
  stddev_pop?: Maybe<Tools_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tools_Stddev_Samp_Fields>;
  sum?: Maybe<Tools_Sum_Fields>;
  var_pop?: Maybe<Tools_Var_Pop_Fields>;
  var_samp?: Maybe<Tools_Var_Samp_Fields>;
  variance?: Maybe<Tools_Variance_Fields>;
};


/** aggregate fields of "tools" */
export type Tools_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tools_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tools_Avg_Fields = {
  __typename?: 'tools_avg_fields';
  points?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tools". All fields are combined with a logical 'AND'. */
export type Tools_Bool_Exp = {
  _and?: InputMaybe<Array<Tools_Bool_Exp>>;
  _not?: InputMaybe<Tools_Bool_Exp>;
  _or?: InputMaybe<Array<Tools_Bool_Exp>>;
  explanation?: InputMaybe<String_Comparison_Exp>;
  icons?: InputMaybe<Tools_Icons_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  intro?: InputMaybe<String_Comparison_Exp>;
  mentors_blablablas?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Bool_Exp>;
  multimedia_content_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  outputs?: InputMaybe<Outputs_Tools_Bool_Exp>;
  points?: InputMaybe<Smallint_Comparison_Exp>;
  questionnaires?: InputMaybe<Questionnaires_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tools" */
export enum Tools_Constraint {
  /** unique or primary key constraint */
  ToolsIdKey = 'tools_id_key',
  /** unique or primary key constraint */
  ToolsPkey = 'tools_pkey',
  /** unique or primary key constraint */
  ToolsSlugKey = 'tools_slug_key'
}

/** columns and relationships of "tools_icons" */
export type Tools_Icons = {
  __typename?: 'tools_icons';
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  tool: Tools;
  tool_id: Scalars['uuid'];
  type: Icons_Types_Enum;
};

/** aggregated selection of "tools_icons" */
export type Tools_Icons_Aggregate = {
  __typename?: 'tools_icons_aggregate';
  aggregate?: Maybe<Tools_Icons_Aggregate_Fields>;
  nodes: Array<Tools_Icons>;
};

/** aggregate fields of "tools_icons" */
export type Tools_Icons_Aggregate_Fields = {
  __typename?: 'tools_icons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tools_Icons_Max_Fields>;
  min?: Maybe<Tools_Icons_Min_Fields>;
};


/** aggregate fields of "tools_icons" */
export type Tools_Icons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tools_icons" */
export type Tools_Icons_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tools_Icons_Max_Order_By>;
  min?: InputMaybe<Tools_Icons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tools_icons" */
export type Tools_Icons_Arr_Rel_Insert_Input = {
  data: Array<Tools_Icons_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tools_Icons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tools_icons". All fields are combined with a logical 'AND'. */
export type Tools_Icons_Bool_Exp = {
  _and?: InputMaybe<Array<Tools_Icons_Bool_Exp>>;
  _not?: InputMaybe<Tools_Icons_Bool_Exp>;
  _or?: InputMaybe<Array<Tools_Icons_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tool?: InputMaybe<Tools_Bool_Exp>;
  tool_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Icons_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "tools_icons" */
export enum Tools_Icons_Constraint {
  /** unique or primary key constraint */
  ToolsIconsFileIdToolIdKey = 'tools_icons_file_id_tool_id_key',
  /** unique or primary key constraint */
  ToolsIconsIdKey = 'tools_icons_id_key',
  /** unique or primary key constraint */
  ToolsIconsPkey = 'tools_icons_pkey',
  /** unique or primary key constraint */
  ToolsIconsToolIdTypeKey = 'tools_icons_tool_id_type_key'
}

/** input type for inserting data into table "tools_icons" */
export type Tools_Icons_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** aggregate max on columns */
export type Tools_Icons_Max_Fields = {
  __typename?: 'tools_icons_max_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tools_icons" */
export type Tools_Icons_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tools_Icons_Min_Fields = {
  __typename?: 'tools_icons_min_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tools_icons" */
export type Tools_Icons_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tools_icons" */
export type Tools_Icons_Mutation_Response = {
  __typename?: 'tools_icons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tools_Icons>;
};

/** on_conflict condition type for table "tools_icons" */
export type Tools_Icons_On_Conflict = {
  constraint: Tools_Icons_Constraint;
  update_columns?: Array<Tools_Icons_Update_Column>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};

/** Ordering options when selecting data from "tools_icons". */
export type Tools_Icons_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tool?: InputMaybe<Tools_Order_By>;
  tool_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tools_icons */
export type Tools_Icons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tools_icons" */
export enum Tools_Icons_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "tools_icons" */
export type Tools_Icons_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** update columns of table "tools_icons" */
export enum Tools_Icons_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  Type = 'type'
}

/** input type for incrementing numeric columns in table "tools" */
export type Tools_Inc_Input = {
  points?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "tools" */
export type Tools_Insert_Input = {
  explanation?: InputMaybe<Scalars['String']>;
  icons?: InputMaybe<Tools_Icons_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  mentors_blablablas?: InputMaybe<Mentors_Blablabla_Arr_Rel_Insert_Input>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Obj_Rel_Insert_Input>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  outputs?: InputMaybe<Outputs_Tools_Arr_Rel_Insert_Input>;
  points?: InputMaybe<Scalars['smallint']>;
  questionnaires?: InputMaybe<Questionnaires_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tools_Max_Fields = {
  __typename?: 'tools_max_fields';
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['smallint']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tools_Min_Fields = {
  __typename?: 'tools_min_fields';
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['smallint']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tools" */
export type Tools_Mutation_Response = {
  __typename?: 'tools_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tools>;
};

/** input type for inserting object relation for remote table "tools" */
export type Tools_Obj_Rel_Insert_Input = {
  data: Tools_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tools_On_Conflict>;
};

/** on_conflict condition type for table "tools" */
export type Tools_On_Conflict = {
  constraint: Tools_Constraint;
  update_columns?: Array<Tools_Update_Column>;
  where?: InputMaybe<Tools_Bool_Exp>;
};

/** Ordering options when selecting data from "tools". */
export type Tools_Order_By = {
  explanation?: InputMaybe<Order_By>;
  icons_aggregate?: InputMaybe<Tools_Icons_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  intro?: InputMaybe<Order_By>;
  mentors_blablablas_aggregate?: InputMaybe<Mentors_Blablabla_Aggregate_Order_By>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  outputs_aggregate?: InputMaybe<Outputs_Tools_Aggregate_Order_By>;
  points?: InputMaybe<Order_By>;
  questionnaires_aggregate?: InputMaybe<Questionnaires_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tools */
export type Tools_Pk_Columns_Input = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};

/** select columns of table "tools" */
export enum Tools_Select_Column {
  /** column name */
  Explanation = 'explanation',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  MultimediaContentId = 'multimedia_content_id',
  /** column name */
  Name = 'name',
  /** column name */
  Points = 'points',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "tools" */
export type Tools_Set_Input = {
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<Scalars['smallint']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Tools_Stddev_Fields = {
  __typename?: 'tools_stddev_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tools_Stddev_Pop_Fields = {
  __typename?: 'tools_stddev_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tools_Stddev_Samp_Fields = {
  __typename?: 'tools_stddev_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Tools_Sum_Fields = {
  __typename?: 'tools_sum_fields';
  points?: Maybe<Scalars['smallint']>;
};

/** update columns of table "tools" */
export enum Tools_Update_Column {
  /** column name */
  Explanation = 'explanation',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  MultimediaContentId = 'multimedia_content_id',
  /** column name */
  Name = 'name',
  /** column name */
  Points = 'points',
  /** column name */
  Slug = 'slug'
}

/** aggregate var_pop on columns */
export type Tools_Var_Pop_Fields = {
  __typename?: 'tools_var_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tools_Var_Samp_Fields = {
  __typename?: 'tools_var_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tools_Variance_Fields = {
  __typename?: 'tools_variance_fields';
  points?: Maybe<Scalars['Float']>;
};

/** Additional personal user info */
export type User_Info = {
  __typename?: 'user_info';
  address?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  phone?: Maybe<Scalars['String']>;
  second_line?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "user_info" */
export type User_Info_Aggregate = {
  __typename?: 'user_info_aggregate';
  aggregate?: Maybe<User_Info_Aggregate_Fields>;
  nodes: Array<User_Info>;
};

/** aggregate fields of "user_info" */
export type User_Info_Aggregate_Fields = {
  __typename?: 'user_info_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Info_Max_Fields>;
  min?: Maybe<User_Info_Min_Fields>;
};


/** aggregate fields of "user_info" */
export type User_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_info". All fields are combined with a logical 'AND'. */
export type User_Info_Bool_Exp = {
  _and?: InputMaybe<Array<User_Info_Bool_Exp>>;
  _not?: InputMaybe<User_Info_Bool_Exp>;
  _or?: InputMaybe<Array<User_Info_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  cep?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  dob?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  second_line?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "user_info" */
export enum User_Info_Constraint {
  /** unique or primary key constraint */
  UserInfoPkey = 'user_info_pkey'
}

/** input type for inserting data into table "user_info" */
export type User_Info_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  cep?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  phone?: InputMaybe<Scalars['String']>;
  second_line?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Info_Max_Fields = {
  __typename?: 'user_info_max_fields';
  address?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  second_line?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Info_Min_Fields = {
  __typename?: 'user_info_min_fields';
  address?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  second_line?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_info" */
export type User_Info_Mutation_Response = {
  __typename?: 'user_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Info>;
};

/** input type for inserting object relation for remote table "user_info" */
export type User_Info_Obj_Rel_Insert_Input = {
  data: User_Info_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};

/** on_conflict condition type for table "user_info" */
export type User_Info_On_Conflict = {
  constraint: User_Info_Constraint;
  update_columns?: Array<User_Info_Update_Column>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "user_info". */
export type User_Info_Order_By = {
  address?: InputMaybe<Order_By>;
  cep?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  dob?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  second_line?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: user_info */
export type User_Info_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_info" */
export enum User_Info_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Cep = 'cep',
  /** column name */
  City = 'city',
  /** column name */
  Dob = 'dob',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  SecondLine = 'second_line',
  /** column name */
  State = 'state'
}

/** input type for updating data in table "user_info" */
export type User_Info_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  cep?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  phone?: InputMaybe<Scalars['String']>;
  second_line?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_info" */
export enum User_Info_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Cep = 'cep',
  /** column name */
  City = 'city',
  /** column name */
  Dob = 'dob',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  SecondLine = 'second_line',
  /** column name */
  State = 'state'
}

/** columns and relationships of "user_invites" */
export type User_Invites = {
  __typename?: 'user_invites';
  consumed?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  invited_by?: Maybe<Scalars['uuid']>;
  role: Roles_Enum;
  /** An object relationship */
  team?: Maybe<Teams>;
  token: Scalars['String'];
};

/** aggregated selection of "user_invites" */
export type User_Invites_Aggregate = {
  __typename?: 'user_invites_aggregate';
  aggregate?: Maybe<User_Invites_Aggregate_Fields>;
  nodes: Array<User_Invites>;
};

/** aggregate fields of "user_invites" */
export type User_Invites_Aggregate_Fields = {
  __typename?: 'user_invites_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Invites_Max_Fields>;
  min?: Maybe<User_Invites_Min_Fields>;
};


/** aggregate fields of "user_invites" */
export type User_Invites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Invites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_invites". All fields are combined with a logical 'AND'. */
export type User_Invites_Bool_Exp = {
  _and?: InputMaybe<Array<User_Invites_Bool_Exp>>;
  _not?: InputMaybe<User_Invites_Bool_Exp>;
  _or?: InputMaybe<Array<User_Invites_Bool_Exp>>;
  consumed?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invited_by?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Roles_Enum_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_invites" */
export enum User_Invites_Constraint {
  /** unique or primary key constraint */
  UserInvitesIdKey = 'user_invites_id_key',
  /** unique or primary key constraint */
  UserInvitesPkey = 'user_invites_pkey',
  /** unique or primary key constraint */
  UserInvitesTokenKey = 'user_invites_token_key'
}

/** input type for inserting data into table "user_invites" */
export type User_Invites_Insert_Input = {
  consumed?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Roles_Enum>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  token?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Invites_Max_Fields = {
  __typename?: 'user_invites_max_fields';
  consumed?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invited_by?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Invites_Min_Fields = {
  __typename?: 'user_invites_min_fields';
  consumed?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invited_by?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_invites" */
export type User_Invites_Mutation_Response = {
  __typename?: 'user_invites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Invites>;
};

/** on_conflict condition type for table "user_invites" */
export type User_Invites_On_Conflict = {
  constraint: User_Invites_Constraint;
  update_columns?: Array<User_Invites_Update_Column>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};

/** Ordering options when selecting data from "user_invites". */
export type User_Invites_Order_By = {
  consumed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  token?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_invites */
export type User_Invites_Pk_Columns_Input = {
  id: Scalars['uuid'];
  token: Scalars['String'];
};

/** select columns of table "user_invites" */
export enum User_Invites_Select_Column {
  /** column name */
  Consumed = 'consumed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  Role = 'role',
  /** column name */
  Token = 'token'
}

/** input type for updating data in table "user_invites" */
export type User_Invites_Set_Input = {
  consumed?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Roles_Enum>;
  token?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_invites" */
export enum User_Invites_Update_Column {
  /** column name */
  Consumed = 'consumed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  Role = 'role',
  /** column name */
  Token = 'token'
}

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  actions: Array<Users_Actions>;
  /** An aggregate relationship */
  actions_aggregate: Users_Actions_Aggregate;
  cpf: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deactivated_since?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  documents: Array<Users_Documents>;
  /** An aggregate relationship */
  documents_aggregate: Users_Documents_Aggregate;
  email: Scalars['String'];
  id: Scalars['String'];
  /** An array relationship */
  names: Array<Users_Names>;
  /** An aggregate relationship */
  names_aggregate: Users_Names_Aggregate;
  occupation?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile_picture?: Maybe<Files>;
  profile_picture_file_id?: Maybe<Scalars['uuid']>;
  role: Roles_Enum;
  /** An object relationship */
  social_media?: Maybe<Social_Medias>;
  social_media_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  team_invitations: Array<Team_Invites>;
  /** An aggregate relationship */
  team_invitations_aggregate: Team_Invites_Aggregate;
  /** An array relationship */
  teams: Array<Teams_Users>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Users_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user_info?: Maybe<User_Info>;
  user_info_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "users" */
export type UsersActionsArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNamesArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeam_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeam_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};

/** columns and relationships of "users_actions" */
export type Users_Actions = {
  __typename?: 'users_actions';
  at_page_slug: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  previous_page_slug?: Maybe<Scalars['String']>;
  type: Users_Actions_Types_Enum;
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "users_actions" */
export type Users_Actions_Aggregate = {
  __typename?: 'users_actions_aggregate';
  aggregate?: Maybe<Users_Actions_Aggregate_Fields>;
  nodes: Array<Users_Actions>;
};

/** aggregate fields of "users_actions" */
export type Users_Actions_Aggregate_Fields = {
  __typename?: 'users_actions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Actions_Max_Fields>;
  min?: Maybe<Users_Actions_Min_Fields>;
};


/** aggregate fields of "users_actions" */
export type Users_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_actions" */
export type Users_Actions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Actions_Max_Order_By>;
  min?: InputMaybe<Users_Actions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_actions" */
export type Users_Actions_Arr_Rel_Insert_Input = {
  data: Array<Users_Actions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Actions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_actions". All fields are combined with a logical 'AND'. */
export type Users_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Actions_Bool_Exp>>;
  _not?: InputMaybe<Users_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Actions_Bool_Exp>>;
  at_page_slug?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  previous_page_slug?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Users_Actions_Types_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_actions" */
export enum Users_Actions_Constraint {
  /** unique or primary key constraint */
  UsersActionsPkey = 'users_actions_pkey'
}

/** input type for inserting data into table "users_actions" */
export type Users_Actions_Insert_Input = {
  at_page_slug?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  previous_page_slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Users_Actions_Types_Enum>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Actions_Max_Fields = {
  __typename?: 'users_actions_max_fields';
  at_page_slug?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  previous_page_slug?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users_actions" */
export type Users_Actions_Max_Order_By = {
  at_page_slug?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previous_page_slug?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Actions_Min_Fields = {
  __typename?: 'users_actions_min_fields';
  at_page_slug?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  previous_page_slug?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users_actions" */
export type Users_Actions_Min_Order_By = {
  at_page_slug?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previous_page_slug?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_actions" */
export type Users_Actions_Mutation_Response = {
  __typename?: 'users_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Actions>;
};

/** on_conflict condition type for table "users_actions" */
export type Users_Actions_On_Conflict = {
  constraint: Users_Actions_Constraint;
  update_columns?: Array<Users_Actions_Update_Column>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "users_actions". */
export type Users_Actions_Order_By = {
  at_page_slug?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previous_page_slug?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_actions */
export type Users_Actions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users_actions" */
export enum Users_Actions_Select_Column {
  /** column name */
  AtPageSlug = 'at_page_slug',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousPageSlug = 'previous_page_slug',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_actions" */
export type Users_Actions_Set_Input = {
  at_page_slug?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  previous_page_slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Users_Actions_Types_Enum>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "users_actions_types" */
export type Users_Actions_Types = {
  __typename?: 'users_actions_types';
  type: Scalars['String'];
};

/** aggregated selection of "users_actions_types" */
export type Users_Actions_Types_Aggregate = {
  __typename?: 'users_actions_types_aggregate';
  aggregate?: Maybe<Users_Actions_Types_Aggregate_Fields>;
  nodes: Array<Users_Actions_Types>;
};

/** aggregate fields of "users_actions_types" */
export type Users_Actions_Types_Aggregate_Fields = {
  __typename?: 'users_actions_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Actions_Types_Max_Fields>;
  min?: Maybe<Users_Actions_Types_Min_Fields>;
};


/** aggregate fields of "users_actions_types" */
export type Users_Actions_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Actions_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users_actions_types". All fields are combined with a logical 'AND'. */
export type Users_Actions_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Actions_Types_Bool_Exp>>;
  _not?: InputMaybe<Users_Actions_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Actions_Types_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_actions_types" */
export enum Users_Actions_Types_Constraint {
  /** unique or primary key constraint */
  UsersActionsTypesPkey = 'users_actions_types_pkey'
}

export enum Users_Actions_Types_Enum {
  Delete = 'delete',
  Insert = 'insert',
  Login = 'login',
  Query = 'query',
  Update = 'update'
}

/** Boolean expression to compare columns of type "users_actions_types_enum". All fields are combined with logical 'AND'. */
export type Users_Actions_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Users_Actions_Types_Enum>;
  _in?: InputMaybe<Array<Users_Actions_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Users_Actions_Types_Enum>;
  _nin?: InputMaybe<Array<Users_Actions_Types_Enum>>;
};

/** input type for inserting data into table "users_actions_types" */
export type Users_Actions_Types_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Actions_Types_Max_Fields = {
  __typename?: 'users_actions_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Actions_Types_Min_Fields = {
  __typename?: 'users_actions_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users_actions_types" */
export type Users_Actions_Types_Mutation_Response = {
  __typename?: 'users_actions_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Actions_Types>;
};

/** on_conflict condition type for table "users_actions_types" */
export type Users_Actions_Types_On_Conflict = {
  constraint: Users_Actions_Types_Constraint;
  update_columns?: Array<Users_Actions_Types_Update_Column>;
  where?: InputMaybe<Users_Actions_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "users_actions_types". */
export type Users_Actions_Types_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_actions_types */
export type Users_Actions_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "users_actions_types" */
export enum Users_Actions_Types_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "users_actions_types" */
export type Users_Actions_Types_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users_actions_types" */
export enum Users_Actions_Types_Update_Column {
  /** column name */
  Type = 'type'
}

/** update columns of table "users_actions" */
export enum Users_Actions_Update_Column {
  /** column name */
  AtPageSlug = 'at_page_slug',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousPageSlug = 'previous_page_slug',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  actions?: InputMaybe<Users_Actions_Bool_Exp>;
  cpf?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deactivated_since?: InputMaybe<Timestamptz_Comparison_Exp>;
  documents?: InputMaybe<Users_Documents_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  names?: InputMaybe<Users_Names_Bool_Exp>;
  occupation?: InputMaybe<String_Comparison_Exp>;
  profile_picture?: InputMaybe<Files_Bool_Exp>;
  profile_picture_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Roles_Enum_Comparison_Exp>;
  social_media?: InputMaybe<Social_Medias_Bool_Exp>;
  social_media_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_invitations?: InputMaybe<Team_Invites_Bool_Exp>;
  teams?: InputMaybe<Teams_Users_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
  user_info_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersCpfKey = 'users_cpf_key',
  /** unique or primary key constraint */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint */
  UsersIdKey = 'users_id_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint */
  UsersUserInfoIdKey = 'users_user_info_id_key'
}

/** columns and relationships of "users_documents" */
export type Users_Documents = {
  __typename?: 'users_documents';
  document_type: Users_Documents_Types_Enum;
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "users_documents" */
export type Users_Documents_Aggregate = {
  __typename?: 'users_documents_aggregate';
  aggregate?: Maybe<Users_Documents_Aggregate_Fields>;
  nodes: Array<Users_Documents>;
};

/** aggregate fields of "users_documents" */
export type Users_Documents_Aggregate_Fields = {
  __typename?: 'users_documents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Documents_Max_Fields>;
  min?: Maybe<Users_Documents_Min_Fields>;
};


/** aggregate fields of "users_documents" */
export type Users_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_documents" */
export type Users_Documents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Documents_Max_Order_By>;
  min?: InputMaybe<Users_Documents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_documents" */
export type Users_Documents_Arr_Rel_Insert_Input = {
  data: Array<Users_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Documents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_documents". All fields are combined with a logical 'AND'. */
export type Users_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Documents_Bool_Exp>>;
  _not?: InputMaybe<Users_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Documents_Bool_Exp>>;
  document_type?: InputMaybe<Users_Documents_Types_Enum_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_documents" */
export enum Users_Documents_Constraint {
  /** unique or primary key constraint */
  UsersDocumentsPkey = 'users_documents_pkey'
}

/** input type for inserting data into table "users_documents" */
export type Users_Documents_Insert_Input = {
  document_type?: InputMaybe<Users_Documents_Types_Enum>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Documents_Max_Fields = {
  __typename?: 'users_documents_max_fields';
  file_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users_documents" */
export type Users_Documents_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Documents_Min_Fields = {
  __typename?: 'users_documents_min_fields';
  file_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users_documents" */
export type Users_Documents_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_documents" */
export type Users_Documents_Mutation_Response = {
  __typename?: 'users_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Documents>;
};

/** on_conflict condition type for table "users_documents" */
export type Users_Documents_On_Conflict = {
  constraint: Users_Documents_Constraint;
  update_columns?: Array<Users_Documents_Update_Column>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "users_documents". */
export type Users_Documents_Order_By = {
  document_type?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_documents */
export type Users_Documents_Pk_Columns_Input = {
  file_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** select columns of table "users_documents" */
export enum Users_Documents_Select_Column {
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  FileId = 'file_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_documents" */
export type Users_Documents_Set_Input = {
  document_type?: InputMaybe<Users_Documents_Types_Enum>;
  file_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "users_documents_types" */
export type Users_Documents_Types = {
  __typename?: 'users_documents_types';
  type: Scalars['String'];
};

/** aggregated selection of "users_documents_types" */
export type Users_Documents_Types_Aggregate = {
  __typename?: 'users_documents_types_aggregate';
  aggregate?: Maybe<Users_Documents_Types_Aggregate_Fields>;
  nodes: Array<Users_Documents_Types>;
};

/** aggregate fields of "users_documents_types" */
export type Users_Documents_Types_Aggregate_Fields = {
  __typename?: 'users_documents_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Documents_Types_Max_Fields>;
  min?: Maybe<Users_Documents_Types_Min_Fields>;
};


/** aggregate fields of "users_documents_types" */
export type Users_Documents_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Documents_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users_documents_types". All fields are combined with a logical 'AND'. */
export type Users_Documents_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Documents_Types_Bool_Exp>>;
  _not?: InputMaybe<Users_Documents_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Documents_Types_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_documents_types" */
export enum Users_Documents_Types_Constraint {
  /** unique or primary key constraint */
  UsersDocumentsTypesPkey = 'users_documents_types_pkey'
}

export enum Users_Documents_Types_Enum {
  Contract = 'contract',
  Identity = 'identity',
  Other = 'other'
}

/** Boolean expression to compare columns of type "users_documents_types_enum". All fields are combined with logical 'AND'. */
export type Users_Documents_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Users_Documents_Types_Enum>;
  _in?: InputMaybe<Array<Users_Documents_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Users_Documents_Types_Enum>;
  _nin?: InputMaybe<Array<Users_Documents_Types_Enum>>;
};

/** input type for inserting data into table "users_documents_types" */
export type Users_Documents_Types_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Documents_Types_Max_Fields = {
  __typename?: 'users_documents_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Documents_Types_Min_Fields = {
  __typename?: 'users_documents_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users_documents_types" */
export type Users_Documents_Types_Mutation_Response = {
  __typename?: 'users_documents_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Documents_Types>;
};

/** on_conflict condition type for table "users_documents_types" */
export type Users_Documents_Types_On_Conflict = {
  constraint: Users_Documents_Types_Constraint;
  update_columns?: Array<Users_Documents_Types_Update_Column>;
  where?: InputMaybe<Users_Documents_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "users_documents_types". */
export type Users_Documents_Types_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_documents_types */
export type Users_Documents_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "users_documents_types" */
export enum Users_Documents_Types_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "users_documents_types" */
export type Users_Documents_Types_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users_documents_types" */
export enum Users_Documents_Types_Update_Column {
  /** column name */
  Type = 'type'
}

/** update columns of table "users_documents" */
export enum Users_Documents_Update_Column {
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  FileId = 'file_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  actions?: InputMaybe<Users_Actions_Arr_Rel_Insert_Input>;
  cpf?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_since?: InputMaybe<Scalars['timestamptz']>;
  documents?: InputMaybe<Users_Documents_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Users_Names_Arr_Rel_Insert_Input>;
  occupation?: InputMaybe<Scalars['String']>;
  profile_picture?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  profile_picture_file_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Roles_Enum>;
  social_media?: InputMaybe<Social_Medias_Obj_Rel_Insert_Input>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  team_invitations?: InputMaybe<Team_Invites_Arr_Rel_Insert_Input>;
  teams?: InputMaybe<Teams_Users_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  user_info_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_since?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['uuid']>;
  social_media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_info_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_since?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occupation?: InputMaybe<Order_By>;
  profile_picture_file_id?: InputMaybe<Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_since?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['uuid']>;
  social_media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_info_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_since?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occupation?: InputMaybe<Order_By>;
  profile_picture_file_id?: InputMaybe<Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** columns and relationships of "users_names" */
export type Users_Names = {
  __typename?: 'users_names';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "users_names" */
export type Users_Names_Aggregate = {
  __typename?: 'users_names_aggregate';
  aggregate?: Maybe<Users_Names_Aggregate_Fields>;
  nodes: Array<Users_Names>;
};

/** aggregate fields of "users_names" */
export type Users_Names_Aggregate_Fields = {
  __typename?: 'users_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Names_Max_Fields>;
  min?: Maybe<Users_Names_Min_Fields>;
};


/** aggregate fields of "users_names" */
export type Users_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_names" */
export type Users_Names_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Names_Max_Order_By>;
  min?: InputMaybe<Users_Names_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_names" */
export type Users_Names_Arr_Rel_Insert_Input = {
  data: Array<Users_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Names_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_names". All fields are combined with a logical 'AND'. */
export type Users_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Names_Bool_Exp>>;
  _not?: InputMaybe<Users_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_names" */
export enum Users_Names_Constraint {
  /** unique or primary key constraint */
  UsersNamesPkey = 'users_names_pkey'
}

/** input type for inserting data into table "users_names" */
export type Users_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Names_Max_Fields = {
  __typename?: 'users_names_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users_names" */
export type Users_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Names_Min_Fields = {
  __typename?: 'users_names_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users_names" */
export type Users_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_names" */
export type Users_Names_Mutation_Response = {
  __typename?: 'users_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Names>;
};

/** on_conflict condition type for table "users_names" */
export type Users_Names_On_Conflict = {
  constraint: Users_Names_Constraint;
  update_columns?: Array<Users_Names_Update_Column>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "users_names". */
export type Users_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_names */
export type Users_Names_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users_names" */
export enum Users_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_names" */
export type Users_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users_names" */
export enum Users_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  actions_aggregate?: InputMaybe<Users_Actions_Aggregate_Order_By>;
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_since?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Users_Documents_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  names_aggregate?: InputMaybe<Users_Names_Aggregate_Order_By>;
  occupation?: InputMaybe<Order_By>;
  profile_picture?: InputMaybe<Files_Order_By>;
  profile_picture_file_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  social_media?: InputMaybe<Social_Medias_Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  team_invitations_aggregate?: InputMaybe<Team_Invites_Aggregate_Order_By>;
  teams_aggregate?: InputMaybe<Teams_Users_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  email: Scalars['String'];
  id: Scalars['String'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Cpf = 'cpf',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedSince = 'deactivated_since',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Role = 'role',
  /** column name */
  SocialMediaId = 'social_media_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  cpf?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_since?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  profile_picture_file_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Roles_Enum>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Cpf = 'cpf',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedSince = 'deactivated_since',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Role = 'role',
  /** column name */
  SocialMediaId = 'social_media_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id'
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "video_repositories" */
export type Video_Repositories = {
  __typename?: 'video_repositories';
  /** An object relationship */
  content: Contents;
  content_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An array relationship */
  videos: Array<Videos>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Aggregate;
  youtube_channel_id: Scalars['String'];
};


/** columns and relationships of "video_repositories" */
export type Video_RepositoriesVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


/** columns and relationships of "video_repositories" */
export type Video_RepositoriesVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};

/** aggregated selection of "video_repositories" */
export type Video_Repositories_Aggregate = {
  __typename?: 'video_repositories_aggregate';
  aggregate?: Maybe<Video_Repositories_Aggregate_Fields>;
  nodes: Array<Video_Repositories>;
};

/** aggregate fields of "video_repositories" */
export type Video_Repositories_Aggregate_Fields = {
  __typename?: 'video_repositories_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Video_Repositories_Max_Fields>;
  min?: Maybe<Video_Repositories_Min_Fields>;
};


/** aggregate fields of "video_repositories" */
export type Video_Repositories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "video_repositories" */
export type Video_Repositories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Video_Repositories_Max_Order_By>;
  min?: InputMaybe<Video_Repositories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "video_repositories" */
export type Video_Repositories_Arr_Rel_Insert_Input = {
  data: Array<Video_Repositories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Repositories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "video_repositories". All fields are combined with a logical 'AND'. */
export type Video_Repositories_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Repositories_Bool_Exp>>;
  _not?: InputMaybe<Video_Repositories_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Repositories_Bool_Exp>>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  videos?: InputMaybe<Videos_Bool_Exp>;
  youtube_channel_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_repositories" */
export enum Video_Repositories_Constraint {
  /** unique or primary key constraint */
  VideoRepositoriesPkey = 'video_repositories_pkey',
  /** unique or primary key constraint */
  VideoRepositoriesYoutubeChannelIdContentIdKey = 'video_repositories_youtube_channel_id_content_id_key'
}

/** input type for inserting data into table "video_repositories" */
export type Video_Repositories_Insert_Input = {
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  videos?: InputMaybe<Videos_Arr_Rel_Insert_Input>;
  youtube_channel_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Video_Repositories_Max_Fields = {
  __typename?: 'video_repositories_max_fields';
  content_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  youtube_channel_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "video_repositories" */
export type Video_Repositories_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  youtube_channel_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Video_Repositories_Min_Fields = {
  __typename?: 'video_repositories_min_fields';
  content_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  youtube_channel_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "video_repositories" */
export type Video_Repositories_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  youtube_channel_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "video_repositories" */
export type Video_Repositories_Mutation_Response = {
  __typename?: 'video_repositories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Repositories>;
};

/** input type for inserting object relation for remote table "video_repositories" */
export type Video_Repositories_Obj_Rel_Insert_Input = {
  data: Video_Repositories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Repositories_On_Conflict>;
};

/** on_conflict condition type for table "video_repositories" */
export type Video_Repositories_On_Conflict = {
  constraint: Video_Repositories_Constraint;
  update_columns?: Array<Video_Repositories_Update_Column>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};

/** Ordering options when selecting data from "video_repositories". */
export type Video_Repositories_Order_By = {
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  videos_aggregate?: InputMaybe<Videos_Aggregate_Order_By>;
  youtube_channel_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_repositories */
export type Video_Repositories_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "video_repositories" */
export enum Video_Repositories_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Id = 'id',
  /** column name */
  YoutubeChannelId = 'youtube_channel_id'
}

/** input type for updating data in table "video_repositories" */
export type Video_Repositories_Set_Input = {
  content_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  youtube_channel_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "video_repositories" */
export enum Video_Repositories_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Id = 'id',
  /** column name */
  YoutubeChannelId = 'youtube_channel_id'
}

/** columns and relationships of "videos" */
export type Videos = {
  __typename?: 'videos';
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  link: Scalars['String'];
  published_at: Scalars['timestamptz'];
  /** An object relationship */
  repository: Video_Repositories;
  repository_id: Scalars['uuid'];
  /** An array relationship */
  tags: Array<Videos_Youtube_Tags>;
  /** An aggregate relationship */
  tags_aggregate: Videos_Youtube_Tags_Aggregate;
  thumbnail: Scalars['String'];
  title: Scalars['String'];
};


/** columns and relationships of "videos" */
export type VideosTagsArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


/** columns and relationships of "videos" */
export type VideosTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};

/** aggregated selection of "videos" */
export type Videos_Aggregate = {
  __typename?: 'videos_aggregate';
  aggregate?: Maybe<Videos_Aggregate_Fields>;
  nodes: Array<Videos>;
};

/** aggregate fields of "videos" */
export type Videos_Aggregate_Fields = {
  __typename?: 'videos_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Videos_Max_Fields>;
  min?: Maybe<Videos_Min_Fields>;
};


/** aggregate fields of "videos" */
export type Videos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "videos" */
export type Videos_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Videos_Max_Order_By>;
  min?: InputMaybe<Videos_Min_Order_By>;
};

/** input type for inserting array relation for remote table "videos" */
export type Videos_Arr_Rel_Insert_Input = {
  data: Array<Videos_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};

/** Boolean expression to filter rows from the table "videos". All fields are combined with a logical 'AND'. */
export type Videos_Bool_Exp = {
  _and?: InputMaybe<Array<Videos_Bool_Exp>>;
  _not?: InputMaybe<Videos_Bool_Exp>;
  _or?: InputMaybe<Array<Videos_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  published_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  repository?: InputMaybe<Video_Repositories_Bool_Exp>;
  repository_id?: InputMaybe<Uuid_Comparison_Exp>;
  tags?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
  thumbnail?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

export type Videos_By_Tags_Args = {
  tags?: InputMaybe<Scalars['_text']>;
};

/** unique or primary key constraints on table "videos" */
export enum Videos_Constraint {
  /** unique or primary key constraint */
  VideosPkey = 'videos_pkey',
  /** unique or primary key constraint */
  VideosRepositoryIdLinkKey = 'videos_repository_id_link_key'
}

/** input type for inserting data into table "videos" */
export type Videos_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  published_at?: InputMaybe<Scalars['timestamptz']>;
  repository?: InputMaybe<Video_Repositories_Obj_Rel_Insert_Input>;
  repository_id?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<Videos_Youtube_Tags_Arr_Rel_Insert_Input>;
  thumbnail?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Videos_Max_Fields = {
  __typename?: 'videos_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  repository_id?: Maybe<Scalars['uuid']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "videos" */
export type Videos_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  repository_id?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Videos_Min_Fields = {
  __typename?: 'videos_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  repository_id?: Maybe<Scalars['uuid']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "videos" */
export type Videos_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  repository_id?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "videos" */
export type Videos_Mutation_Response = {
  __typename?: 'videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Videos>;
};

/** input type for inserting object relation for remote table "videos" */
export type Videos_Obj_Rel_Insert_Input = {
  data: Videos_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};

/** on_conflict condition type for table "videos" */
export type Videos_On_Conflict = {
  constraint: Videos_Constraint;
  update_columns?: Array<Videos_Update_Column>;
  where?: InputMaybe<Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "videos". */
export type Videos_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  repository?: InputMaybe<Video_Repositories_Order_By>;
  repository_id?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Videos_Youtube_Tags_Aggregate_Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: videos */
export type Videos_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "videos" */
export enum Videos_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  RepositoryId = 'repository_id',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "videos" */
export type Videos_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  published_at?: InputMaybe<Scalars['timestamptz']>;
  repository_id?: InputMaybe<Scalars['uuid']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** update columns of table "videos" */
export enum Videos_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  RepositoryId = 'repository_id',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title'
}

/** columns and relationships of "videos_youtube_tags" */
export type Videos_Youtube_Tags = {
  __typename?: 'videos_youtube_tags';
  tag: Scalars['String'];
  /** An object relationship */
  video: Videos;
  video_id: Scalars['uuid'];
  /** An object relationship */
  youtube_tag: Youtube_Tags;
};

/** aggregated selection of "videos_youtube_tags" */
export type Videos_Youtube_Tags_Aggregate = {
  __typename?: 'videos_youtube_tags_aggregate';
  aggregate?: Maybe<Videos_Youtube_Tags_Aggregate_Fields>;
  nodes: Array<Videos_Youtube_Tags>;
};

/** aggregate fields of "videos_youtube_tags" */
export type Videos_Youtube_Tags_Aggregate_Fields = {
  __typename?: 'videos_youtube_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Videos_Youtube_Tags_Max_Fields>;
  min?: Maybe<Videos_Youtube_Tags_Min_Fields>;
};


/** aggregate fields of "videos_youtube_tags" */
export type Videos_Youtube_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Videos_Youtube_Tags_Max_Order_By>;
  min?: InputMaybe<Videos_Youtube_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Arr_Rel_Insert_Input = {
  data: Array<Videos_Youtube_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Videos_Youtube_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "videos_youtube_tags". All fields are combined with a logical 'AND'. */
export type Videos_Youtube_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Videos_Youtube_Tags_Bool_Exp>>;
  _not?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Videos_Youtube_Tags_Bool_Exp>>;
  tag?: InputMaybe<String_Comparison_Exp>;
  video?: InputMaybe<Videos_Bool_Exp>;
  video_id?: InputMaybe<Uuid_Comparison_Exp>;
  youtube_tag?: InputMaybe<Youtube_Tags_Bool_Exp>;
};

/** unique or primary key constraints on table "videos_youtube_tags" */
export enum Videos_Youtube_Tags_Constraint {
  /** unique or primary key constraint */
  VideosYoutubeTagsPkey = 'videos_youtube_tags_pkey'
}

/** input type for inserting data into table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Insert_Input = {
  tag?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  video_id?: InputMaybe<Scalars['uuid']>;
  youtube_tag?: InputMaybe<Youtube_Tags_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Videos_Youtube_Tags_Max_Fields = {
  __typename?: 'videos_youtube_tags_max_fields';
  tag?: Maybe<Scalars['String']>;
  video_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Max_Order_By = {
  tag?: InputMaybe<Order_By>;
  video_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Videos_Youtube_Tags_Min_Fields = {
  __typename?: 'videos_youtube_tags_min_fields';
  tag?: Maybe<Scalars['String']>;
  video_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Min_Order_By = {
  tag?: InputMaybe<Order_By>;
  video_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Mutation_Response = {
  __typename?: 'videos_youtube_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Videos_Youtube_Tags>;
};

/** on_conflict condition type for table "videos_youtube_tags" */
export type Videos_Youtube_Tags_On_Conflict = {
  constraint: Videos_Youtube_Tags_Constraint;
  update_columns?: Array<Videos_Youtube_Tags_Update_Column>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "videos_youtube_tags". */
export type Videos_Youtube_Tags_Order_By = {
  tag?: InputMaybe<Order_By>;
  video?: InputMaybe<Videos_Order_By>;
  video_id?: InputMaybe<Order_By>;
  youtube_tag?: InputMaybe<Youtube_Tags_Order_By>;
};

/** primary key columns input for table: videos_youtube_tags */
export type Videos_Youtube_Tags_Pk_Columns_Input = {
  tag: Scalars['String'];
  video_id: Scalars['uuid'];
};

/** select columns of table "videos_youtube_tags" */
export enum Videos_Youtube_Tags_Select_Column {
  /** column name */
  Tag = 'tag',
  /** column name */
  VideoId = 'video_id'
}

/** input type for updating data in table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Set_Input = {
  tag?: InputMaybe<Scalars['String']>;
  video_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "videos_youtube_tags" */
export enum Videos_Youtube_Tags_Update_Column {
  /** column name */
  Tag = 'tag',
  /** column name */
  VideoId = 'video_id'
}

/** columns and relationships of "youtube_tags" */
export type Youtube_Tags = {
  __typename?: 'youtube_tags';
  tag: Scalars['String'];
  /** An array relationship */
  videos: Array<Videos_Youtube_Tags>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Youtube_Tags_Aggregate;
};


/** columns and relationships of "youtube_tags" */
export type Youtube_TagsVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


/** columns and relationships of "youtube_tags" */
export type Youtube_TagsVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};

/** aggregated selection of "youtube_tags" */
export type Youtube_Tags_Aggregate = {
  __typename?: 'youtube_tags_aggregate';
  aggregate?: Maybe<Youtube_Tags_Aggregate_Fields>;
  nodes: Array<Youtube_Tags>;
};

/** aggregate fields of "youtube_tags" */
export type Youtube_Tags_Aggregate_Fields = {
  __typename?: 'youtube_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Youtube_Tags_Max_Fields>;
  min?: Maybe<Youtube_Tags_Min_Fields>;
};


/** aggregate fields of "youtube_tags" */
export type Youtube_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Youtube_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "youtube_tags". All fields are combined with a logical 'AND'. */
export type Youtube_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Youtube_Tags_Bool_Exp>>;
  _not?: InputMaybe<Youtube_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Youtube_Tags_Bool_Exp>>;
  tag?: InputMaybe<String_Comparison_Exp>;
  videos?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};

/** unique or primary key constraints on table "youtube_tags" */
export enum Youtube_Tags_Constraint {
  /** unique or primary key constraint */
  YoutubeTagsPkey = 'youtube_tags_pkey'
}

/** input type for inserting data into table "youtube_tags" */
export type Youtube_Tags_Insert_Input = {
  tag?: InputMaybe<Scalars['String']>;
  videos?: InputMaybe<Videos_Youtube_Tags_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Youtube_Tags_Max_Fields = {
  __typename?: 'youtube_tags_max_fields';
  tag?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Youtube_Tags_Min_Fields = {
  __typename?: 'youtube_tags_min_fields';
  tag?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "youtube_tags" */
export type Youtube_Tags_Mutation_Response = {
  __typename?: 'youtube_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Youtube_Tags>;
};

/** input type for inserting object relation for remote table "youtube_tags" */
export type Youtube_Tags_Obj_Rel_Insert_Input = {
  data: Youtube_Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Youtube_Tags_On_Conflict>;
};

/** on_conflict condition type for table "youtube_tags" */
export type Youtube_Tags_On_Conflict = {
  constraint: Youtube_Tags_Constraint;
  update_columns?: Array<Youtube_Tags_Update_Column>;
  where?: InputMaybe<Youtube_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "youtube_tags". */
export type Youtube_Tags_Order_By = {
  tag?: InputMaybe<Order_By>;
  videos_aggregate?: InputMaybe<Videos_Youtube_Tags_Aggregate_Order_By>;
};

/** primary key columns input for table: youtube_tags */
export type Youtube_Tags_Pk_Columns_Input = {
  tag: Scalars['String'];
};

/** select columns of table "youtube_tags" */
export enum Youtube_Tags_Select_Column {
  /** column name */
  Tag = 'tag'
}

/** input type for updating data in table "youtube_tags" */
export type Youtube_Tags_Set_Input = {
  tag?: InputMaybe<Scalars['String']>;
};

/** update columns of table "youtube_tags" */
export enum Youtube_Tags_Update_Column {
  /** column name */
  Tag = 'tag'
}

export type SectionCoreFragment = { __typename?: 'sections', id: string, name: string, slug: string, hex_color?: string | null, intro: string, section_icons_files: Array<{ __typename?: 'sections_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> };

export type OutputCoreFragment = { __typename?: 'outputs', id: string, intro: string, name: string, slug: string, order?: number | null, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> };

export type ToolCoreFragment = { __typename?: 'tools', id: string, name: string, intro: string, explanation: string, slug: string, points: number, icons: Array<{ __typename?: 'tools_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> };

export type MultimediaContentFullFragment = { __typename?: 'multimedia_contents', ebooks_link?: string | null, resources?: string | null, video_lessons: Array<{ __typename?: 'multimedia_contents_links', link: string }> };

export type UserPrivateInfoFragment = { __typename?: 'users', cpf: string, created_at: string, updated_at?: string | null, deactivated_since?: string | null, role: Roles_Enum };

export type UserPublicInfoFragment = { __typename?: 'users', id: string, email: string, occupation?: string | null, names: Array<{ __typename?: 'users_names', name: string }>, profile_picture?: { __typename?: 'files', path: string } | null, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null };

export type TeamCoreFragment = { __typename?: 'teams', description?: string | null, id: string, cnpj?: string | null, logo?: { __typename?: 'files', path: string } | null, names: Array<{ __typename?: 'teams_names', name: string }>, tags: Array<{ __typename?: 'teams_tags', tag: { __typename?: 'tags', value: string } }>, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null, members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', id: string, names: Array<{ __typename?: 'users_names', name: string }> } }> };

export type GroupCoreFragment = { __typename?: 'groups', id: string, start_date: string, end_date: string, location_id: string, names: Array<{ __typename?: 'groups_names', name: string }> };

export type GetSectionsBasicQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetSectionsBasicQuery = { __typename?: 'query_root', sections: Array<{ __typename?: 'sections', id: string, name: string, slug: string, hex_color?: string | null, intro: string, section_icons_files: Array<{ __typename?: 'sections_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type SectionsBasicByGroupQueryVariables = Exact<{
  group_id: Scalars['uuid'];
}>;


export type SectionsBasicByGroupQuery = { __typename?: 'query_root', sections: Array<{ __typename?: 'sections', id: string, name: string, slug: string, hex_color?: string | null, intro: string, section_icons_files: Array<{ __typename?: 'sections_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetSectionsCoreQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetSectionsCoreQuery = { __typename?: 'query_root', sections: Array<{ __typename?: 'sections', id: string, name: string, slug: string, hex_color?: string | null, intro: string, section_icons_files: Array<{ __typename?: 'sections_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetOutputsCoresBySectionQueryVariables = Exact<{
  sectionId: Scalars['uuid'];
}>;


export type GetOutputsCoresBySectionQuery = { __typename?: 'query_root', outputs: Array<{ __typename?: 'outputs', id: string, intro: string, name: string, slug: string, order?: number | null, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetOutputCoreBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetOutputCoreBySlugQuery = { __typename?: 'query_root', outputs: Array<{ __typename?: 'outputs', id: string, intro: string, name: string, slug: string, order?: number | null, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetOutputCoreMultimediaBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetOutputCoreMultimediaBySlugQuery = { __typename?: 'query_root', outputs: Array<{ __typename?: 'outputs', id: string, intro: string, name: string, slug: string, order?: number | null, multimedia_content: { __typename?: 'multimedia_contents', ebooks_link?: string | null, resources?: string | null, video_lessons: Array<{ __typename?: 'multimedia_contents_links', link: string }> }, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetToolsCoreByOutputQueryVariables = Exact<{
  outputId: Scalars['uuid'];
}>;


export type GetToolsCoreByOutputQuery = { __typename?: 'query_root', outputs_tools: Array<{ __typename?: 'outputs_tools', order?: number | null, tool: { __typename?: 'tools', id: string, name: string, intro: string, explanation: string, slug: string, points: number, icons: Array<{ __typename?: 'tools_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> } }> };

export type GetToolCoreMultimediaBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetToolCoreMultimediaBySlugQuery = { __typename?: 'query_root', tools: Array<{ __typename?: 'tools', id: string, name: string, intro: string, explanation: string, slug: string, points: number, multimedia_content: { __typename?: 'multimedia_contents', ebooks_link?: string | null, resources?: string | null, video_lessons: Array<{ __typename?: 'multimedia_contents_links', link: string }> }, icons: Array<{ __typename?: 'tools_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type QuestionnaireActiveOfToolQueryVariables = Exact<{
  tool_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
}>;


export type QuestionnaireActiveOfToolQuery = { __typename?: 'query_root', questionnaires: Array<{ __typename?: 'questionnaires', id: string, questions: Array<{ __typename?: 'questions', id: string, order: number, title?: string | null, subtitle?: string | null, type: Questions_Types_Enum, question_answers: Array<{ __typename?: 'questions_answers', answer: string }> }>, teams_states: Array<{ __typename?: 'questionnaires_teams_states', updated_at: string, finished: boolean }> }> };

export type SignUpUserMutationVariables = Exact<{
  cpf: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  address_doc_b64str: Scalars['String'];
  id_doc_b64str: Scalars['String'];
  token: Scalars['String'];
  team_id?: InputMaybe<Scalars['String']>;
  dob: Scalars['date'];
  phone: Scalars['String'];
  cep: Scalars['String'];
  state: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  secondLine?: InputMaybe<Scalars['String']>;
}>;


export type SignUpUserMutation = { __typename?: 'mutation_root', createUser: string };

export type SignUpSubscriptionSubscriptionVariables = Exact<{
  action_id: Scalars['uuid'];
}>;


export type SignUpSubscriptionSubscription = { __typename?: 'subscription_root', createUser?: { __typename?: 'createUser', errors?: object | null, output?: { __typename?: 'CreateUserOutput', id?: string | null, name?: string | null, email?: string | null, cpf?: string | null } | null } | null };

export type GetUserCoreQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserCoreQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', cpf: string, created_at: string, updated_at?: string | null, deactivated_since?: string | null, role: Roles_Enum, id: string, email: string, occupation?: string | null, names: Array<{ __typename?: 'users_names', name: string }>, profile_picture?: { __typename?: 'files', path: string } | null, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null }> };

export type GetTeamCoreByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTeamCoreByIdQuery = { __typename?: 'query_root', teams_by_pk?: { __typename?: 'teams', description?: string | null, id: string, cnpj?: string | null, logo?: { __typename?: 'files', path: string } | null, names: Array<{ __typename?: 'teams_names', name: string }>, tags: Array<{ __typename?: 'teams_tags', tag: { __typename?: 'tags', value: string } }>, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null, members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', id: string, names: Array<{ __typename?: 'users_names', name: string }> } }> } | null };

export type GetTeamsOfUserQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GetTeamsOfUserQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', description?: string | null, id: string, cnpj?: string | null, logo?: { __typename?: 'files', path: string } | null, names: Array<{ __typename?: 'teams_names', name: string }>, tags: Array<{ __typename?: 'teams_tags', tag: { __typename?: 'tags', value: string } }>, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null, members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', id: string, names: Array<{ __typename?: 'users_names', name: string }> } }> }> };

export type GetGroupsOfTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetGroupsOfTeamQuery = { __typename?: 'query_root', groups: Array<{ __typename?: 'groups', id: string, start_date: string, end_date: string, location_id: string, names: Array<{ __typename?: 'groups_names', name: string }> }> };

export type GetTeamCoreQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GetTeamCoreQuery = { __typename?: 'query_root', team_invites: Array<{ __typename?: 'team_invites', id: string, team: { __typename?: 'teams', description?: string | null, id: string, cnpj?: string | null, logo?: { __typename?: 'files', path: string } | null, names: Array<{ __typename?: 'teams_names', name: string }>, tags: Array<{ __typename?: 'teams_tags', tag: { __typename?: 'tags', value: string } }>, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null, members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', id: string, names: Array<{ __typename?: 'users_names', name: string }> } }> } }> };

export type RespondTeamInvitationMutationVariables = Exact<{
  invite_id: Scalars['String'];
  accept: Scalars['Boolean'];
}>;


export type RespondTeamInvitationMutation = { __typename?: 'mutation_root', respondTeamInvitation?: { __typename?: 'RespondTeamInvitationOutput', status: string, team_id: string, user_id: string } | null };

export type GetTeamScoreQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetTeamScoreQuery = { __typename?: 'query_root', tools_aggregate: { __typename?: 'tools_aggregate', aggregate?: { __typename?: 'tools_aggregate_fields', sum?: { __typename?: 'tools_sum_fields', points?: number | null } | null } | null } };

export type GetTeamsScoresByGroupQueryVariables = Exact<{
  group_id: Scalars['uuid'];
}>;


export type GetTeamsScoresByGroupQuery = { __typename?: 'query_root', teams_scores: Array<{ __typename?: 'teams_scores', sum?: number | null, team?: { __typename?: 'teams', id: string, names: Array<{ __typename?: 'teams_names', name: string }> } | null }> };

export type CountToolsDoneOfSectionByTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
}>;


export type CountToolsDoneOfSectionByTeamQuery = { __typename?: 'query_root', done: { __typename?: 'tools_aggregate', aggregate?: { __typename?: 'tools_aggregate_fields', count: number } | null }, all: { __typename?: 'tools_aggregate', aggregate?: { __typename?: 'tools_aggregate_fields', count: number } | null } };

export type CountToolsDoneOfSectionByTeamGetOutputsNodesQueryVariables = Exact<{
  team_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
}>;


export type CountToolsDoneOfSectionByTeamGetOutputsNodesQuery = { __typename?: 'query_root', done: { __typename?: 'tools_aggregate', nodes: Array<{ __typename?: 'tools', outputs: Array<{ __typename?: 'outputs_tools', output_id: string }> }> }, all: { __typename?: 'tools_aggregate', nodes: Array<{ __typename?: 'tools', outputs: Array<{ __typename?: 'outputs_tools', output_id: string }> }> } };

export type GetOutputsDoneBySectionQueryVariables = Exact<{
  section_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
}>;


export type GetOutputsDoneBySectionQuery = { __typename?: 'query_root', outputs: Array<{ __typename?: 'outputs', id: string, intro: string, name: string, slug: string, order?: number | null, done: { __typename?: 'outputs_tools_aggregate', aggregate?: { __typename?: 'outputs_tools_aggregate_fields', count: number } | null }, all: { __typename?: 'outputs_tools_aggregate', aggregate?: { __typename?: 'outputs_tools_aggregate_fields', count: number } | null }, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetLocationsCalendarsQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetLocationsCalendarsQuery = { __typename?: 'query_root', default: Array<{ __typename?: 'locations', calendar_id?: string | null, names: Array<{ __typename?: 'locations_names', name: string }> }>, all: Array<{ __typename?: 'locations', calendar_id?: string | null, names: Array<{ __typename?: 'locations_names', name: string }> }> };

export type GetTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsQuery = { __typename?: 'query_root', tags: Array<{ __typename?: 'tags', id: string, value: string }> };

export type GetMembersOfTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetMembersOfTeamQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', role: Roles_Enum, id: string, email: string, occupation?: string | null, names: Array<{ __typename?: 'users_names', name: string }>, profile_picture?: { __typename?: 'files', path: string } | null, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null }> };

export type UploadProfilePictureMutationVariables = Exact<{
  user_id: Scalars['String'];
  b64_img: Scalars['String'];
}>;


export type UploadProfilePictureMutation = { __typename?: 'mutation_root', uploadProfilePicture?: { __typename?: 'UploadProfilePictureOutput', id: string, path: string } | null };

export type UpdateUserNameMutationVariables = Exact<{
  user_id: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpdateUserNameMutation = { __typename?: 'mutation_root', insert_users_names_one?: { __typename?: 'users_names', name: string, user_id: string } | null };

export type UpdateUserOccupationMutationVariables = Exact<{
  user_id: Scalars['String'];
  occupation: Scalars['String'];
}>;


export type UpdateUserOccupationMutation = { __typename?: 'mutation_root', update_users?: { __typename?: 'users_mutation_response', returning: Array<{ __typename?: 'users', id: string, occupation?: string | null }> } | null };

export type UpdateTeamMutationVariables = Exact<{
  team_id: Scalars['uuid'];
  cnpj?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateTeamMutation = { __typename?: 'mutation_root', update_teams_by_pk?: { __typename?: 'teams', id: string, cnpj?: string | null, description?: string | null } | null };

export type InsertTeamNameMutationVariables = Exact<{
  team_id: Scalars['uuid'];
  name: Scalars['String'];
}>;


export type InsertTeamNameMutation = { __typename?: 'mutation_root', insert_teams_names_one?: { __typename?: 'teams_names', team_id: string, name: string } | null };

export type DeleteTeamMemberMutationVariables = Exact<{
  team_id: Scalars['uuid'];
  user_id: Scalars['String'];
}>;


export type DeleteTeamMemberMutation = { __typename?: 'mutation_root', delete_teams_users?: { __typename?: 'teams_users_mutation_response', returning: Array<{ __typename?: 'teams_users', team_id: string }> } | null };

export type UpdateTeamSocialMediaMutationVariables = Exact<{
  team_id: Scalars['uuid'];
  facebook?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
}>;


export type UpdateTeamSocialMediaMutation = { __typename?: 'mutation_root', update_social_medias?: { __typename?: 'social_medias_mutation_response', returning: Array<{ __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, twitter?: string | null, youtube?: string | null }> } | null };

export type InsertTagsToTeamMutationVariables = Exact<{
  objects: Array<Teams_Tags_Insert_Input> | Teams_Tags_Insert_Input;
}>;


export type InsertTagsToTeamMutation = { __typename?: 'mutation_root', insert_teams_tags?: { __typename?: 'teams_tags_mutation_response', returning: Array<{ __typename?: 'teams_tags', tag_id: string, team_id: string }> } | null };

export type InsertNewTagMutationVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type InsertNewTagMutation = { __typename?: 'mutation_root', delete_teams_tags?: { __typename?: 'teams_tags_mutation_response', affected_rows: number } | null };

export type GetDiagnosticsTabsActiveByTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetDiagnosticsTabsActiveByTeamQuery = { __typename?: 'query_root', diagnostics_tabs: Array<{ __typename?: 'diagnostics_tabs', id: string, order: number, title: string }> };

export type GetDiagnosticsSectionsByTabQueryVariables = Exact<{
  tab_id: Scalars['uuid'];
}>;


export type GetDiagnosticsSectionsByTabQuery = { __typename?: 'query_root', diagnostics_tabs_sections: Array<{ __typename?: 'diagnostics_tabs_sections', order: number, section: { __typename?: 'diagnostics_sections', id: string, title: string } }> };

export type GetDiagnosticsSubsectionsBySectionAndTeamQueryVariables = Exact<{
  tab_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
}>;


export type GetDiagnosticsSubsectionsBySectionAndTeamQuery = { __typename?: 'query_root', diagnostics_subsections: Array<{ __typename?: 'diagnostics_subsections', id: string, order: number, title: string, questions: Array<{ __typename?: 'diagnostics_questions', id: string, order: number, question: string, answers: Array<{ __typename?: 'diagnostics_questions_answers', id: string, answer: number }> }> }> };

export type StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamMutationVariables = Exact<{
  objects: Array<Diagnostics_Questions_Answers_Insert_Input> | Diagnostics_Questions_Answers_Insert_Input;
}>;


export type StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamMutation = { __typename?: 'mutation_root', insert_diagnostics_questions_answers?: { __typename?: 'diagnostics_questions_answers_mutation_response', returning: Array<{ __typename?: 'diagnostics_questions_answers', id: string, answer: number }> } | null };

export type GetDiagnosticsCommentByTabAndTeamQueryVariables = Exact<{
  tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
}>;


export type GetDiagnosticsCommentByTabAndTeamQuery = { __typename?: 'query_root', diagnostics_comments_by_pk?: { __typename?: 'diagnostics_comments', comment?: string | null } | null };

export type StoreDiagnosticsCommentByTabAndTeamMutationVariables = Exact<{
  comment: Scalars['String'];
  tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
}>;


export type StoreDiagnosticsCommentByTabAndTeamMutation = { __typename?: 'mutation_root', insert_diagnostics_comments_one?: { __typename?: 'diagnostics_comments', comment?: string | null } | null };

export type GetVideosByTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order: Order_By;
  contains_text: Scalars['String'];
}>;


export type GetVideosByTeamQuery = { __typename?: 'query_root', videos: Array<{ __typename?: 'videos', description?: string | null, title: string, id: string, link: string, published_at: string, thumbnail: string, tags: Array<{ __typename?: 'videos_youtube_tags', tag: string }> }> };

export type CountVideosByTagsByTeamQueryVariables = Exact<{
  tags: Scalars['_text'];
  team_id: Scalars['uuid'];
  contains_text: Scalars['String'];
}>;


export type CountVideosByTagsByTeamQuery = { __typename?: 'query_root', videos_by_tags_aggregate: { __typename?: 'videos_aggregate', aggregate?: { __typename?: 'videos_aggregate_fields', count: number } | null } };

export type GetVideosByTagsByTeamQueryVariables = Exact<{
  tags: Scalars['_text'];
  team_id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order: Order_By;
  contains_text: Scalars['String'];
}>;


export type GetVideosByTagsByTeamQuery = { __typename?: 'query_root', videos_by_tags: Array<{ __typename?: 'videos', description?: string | null, title: string, id: string, link: string, published_at: string, thumbnail: string, tags: Array<{ __typename?: 'videos_youtube_tags', tag: string }> }> };

export type GetYoutubeTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetYoutubeTagsQuery = { __typename?: 'query_root', youtube_tags: Array<{ __typename?: 'youtube_tags', tag: string }> };

export type GetRepositoriesActiveByTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetRepositoriesActiveByTeamQuery = { __typename?: 'query_root', repositories: Array<{ __typename?: 'repositories', id: string, link: string, app_key: string }> };

export type StoreQuestionsAnswersByQuestionByTeamMutationVariables = Exact<{
  answers: Array<Questions_Answers_Insert_Input> | Questions_Answers_Insert_Input;
}>;


export type StoreQuestionsAnswersByQuestionByTeamMutation = { __typename?: 'mutation_root', insert_questions_answers?: { __typename?: 'questions_answers_mutation_response', affected_rows: number } | null };

export type StoreQuestionnaireTeamStateMutationVariables = Exact<{
  questionnaire_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
  finished: Scalars['Boolean'];
}>;


export type StoreQuestionnaireTeamStateMutation = { __typename?: 'mutation_root', insert_questionnaires_teams_states_one?: { __typename?: 'questionnaires_teams_states', finished: boolean } | null };

export type GetMentorsFeedbackByToolByTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
  tool_id: Scalars['uuid'];
}>;


export type GetMentorsFeedbackByToolByTeamQuery = { __typename?: 'query_root', mentors_blablabla: Array<{ __typename?: 'mentors_blablabla', id: string, value: string }> };

export type StoreMentorsFeedbackMutationVariables = Exact<{
  team_id: Scalars['uuid'];
  tool_id: Scalars['uuid'];
  value: Scalars['String'];
}>;


export type StoreMentorsFeedbackMutation = { __typename?: 'mutation_root', insert_mentors_blablabla_one?: { __typename?: 'mentors_blablabla', value: string } | null };

export type GetBlobStorageSasUriQueryVariables = Exact<{
  container: Scalars['String'];
  filename: Scalars['String'];
}>;


export type GetBlobStorageSasUriQuery = { __typename?: 'query_root', getStorageSASUri: string };

export type InsertMentoringRecordMutationVariables = Exact<{
  date: Scalars['timestamptz'];
  record: Scalars['String'];
  team_id: Scalars['uuid'];
  duration: Scalars['smallint'];
}>;


export type InsertMentoringRecordMutation = { __typename?: 'mutation_root', insert_mentoring_records_one?: { __typename?: 'mentoring_records', id: string } | null };

export type DeleteMentoringRecordByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteMentoringRecordByIdMutation = { __typename?: 'mutation_root', delete_mentoring_records_by_pk?: { __typename?: 'mentoring_records', id: string } | null };

export type GetMentoringRecordsByTeamByMonthQueryVariables = Exact<{
  team_id: Scalars['uuid'];
  first_day: Scalars['timestamptz'];
  last_day: Scalars['timestamptz'];
}>;


export type GetMentoringRecordsByTeamByMonthQuery = { __typename?: 'query_root', mentoring_records: Array<{ __typename?: 'mentoring_records', id: string, duration: number, date: string, record: string, members: Array<{ __typename?: 'mentoring_records_members', member_id: string, member: { __typename?: 'users', id: string, names: Array<{ __typename?: 'users_names', name: string }> } }> }> };

export type InviteEmailToTeamMutationVariables = Exact<{
  email: Scalars['String'];
  team_id: Scalars['String'];
}>;


export type InviteEmailToTeamMutation = { __typename?: 'mutation_root', inviteUserToTeam?: { __typename?: 'InviteUserToTeamOutput', invite_id: string, team_id: string, user_id: string } | null };

export type GetInvitesOfTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetInvitesOfTeamQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'team_invites', user: { __typename?: 'users', id: string, names: Array<{ __typename?: 'users_names', name: string }> } }>, emails: Array<{ __typename?: 'user_invites', email: string }> };

export type GetTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamsQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', id: string }> };

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = { __typename?: 'query_root', locations: Array<{ __typename?: 'locations', id: string, names: Array<{ __typename?: 'locations_names', name: string }> }> };

export type GetGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsQuery = { __typename?: 'query_root', groups: Array<{ __typename?: 'groups', id: string, names: Array<{ __typename?: 'groups_names', name: string }> }> };

export type GetTeamsByGroupQueryVariables = Exact<{
  groupId: Scalars['uuid'];
}>;


export type GetTeamsByGroupQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', name?: string | null, description?: string | null, id: string, cnpj?: string | null, logo?: { __typename?: 'files', path: string } | null, names: Array<{ __typename?: 'teams_names', name: string }>, tags: Array<{ __typename?: 'teams_tags', tag: { __typename?: 'tags', value: string } }>, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null, members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', id: string, names: Array<{ __typename?: 'users_names', name: string }> } }> }> };

export type GroupsByUserQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GroupsByUserQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', teams: Array<{ __typename?: 'teams_users', team: { __typename?: 'teams', groups: Array<{ __typename?: 'groups_teams', group: { __typename?: 'groups', id: string, names: Array<{ __typename?: 'groups_names', name: string }> } }> } }> }> };

export type GetInviteStateQueryVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
}>;


export type GetInviteStateQuery = { __typename?: 'query_root', checkInviteStatus?: { __typename?: 'CheckInviteStatusOutput', id: string, status: InviteState } | null };

export type ContentWithProgressQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type ContentWithProgressQuery = { __typename?: 'query_root', contents: Array<{ __typename?: 'contents', sections: Array<{ __typename?: 'contents_sections', section: { __typename?: 'sections', id: string, name: string, slug: string, section_icons_files: Array<{ __typename?: 'sections_icons', id: string, type: Icons_Types_Enum, file: { __typename?: 'files', id: string, path: string } }>, outputs: Array<{ __typename?: 'outputs', id: string, name: string, slug: string, icons: Array<{ __typename?: 'outputs_icons', id: string, type: Icons_Types_Enum, file: { __typename?: 'files', id: string, path: string } }>, tools: Array<{ __typename?: 'outputs_tools', tool: { __typename?: 'tools', id: string, name: string, slug: string, intro: string, explanation: string, points: number, icons: Array<{ __typename?: 'tools_icons', id: string, type: Icons_Types_Enum, file: { __typename?: 'files', id: string, path: string } }>, questionnaires: Array<{ __typename?: 'questionnaires', teams_states: Array<{ __typename?: 'questionnaires_teams_states', finished: boolean }> }> } }> }> } }> }> };

export const SectionCoreFragmentDoc = gql`
    fragment SectionCore on sections {
  id
  name
  slug
  hex_color
  intro
  section_icons_files {
    type
    file {
      path
    }
  }
}
    `;
export const OutputCoreFragmentDoc = gql`
    fragment OutputCore on outputs {
  id
  intro
  name
  slug
  order
  icons {
    type
    file {
      path
    }
  }
}
    `;
export const ToolCoreFragmentDoc = gql`
    fragment ToolCore on tools {
  id
  name
  intro
  explanation
  slug
  points
  icons {
    type
    file {
      path
    }
  }
}
    `;
export const MultimediaContentFullFragmentDoc = gql`
    fragment MultimediaContentFull on multimedia_contents {
  ebooks_link
  resources
  video_lessons {
    link
  }
}
    `;
export const UserPrivateInfoFragmentDoc = gql`
    fragment UserPrivateInfo on users {
  cpf
  created_at
  updated_at
  deactivated_since
  role
}
    `;
export const UserPublicInfoFragmentDoc = gql`
    fragment UserPublicInfo on users {
  id
  email
  names(order_by: {created_at: desc}) {
    name
  }
  profile_picture {
    path
  }
  social_media {
    facebook
    instagram
    linkedin
    other
    youtube
    twitter
  }
  occupation
}
    `;
export const TeamCoreFragmentDoc = gql`
    fragment TeamCore on teams {
  description
  id
  logo {
    path
  }
  names(order_by: {created_at: desc}) {
    name
  }
  tags {
    tag {
      value
    }
  }
  social_media {
    facebook
    instagram
    linkedin
    other
    youtube
    twitter
  }
  members {
    user {
      id
      names(order_by: {created_at: desc}) {
        name
      }
    }
  }
  cnpj
}
    `;
export const GroupCoreFragmentDoc = gql`
    fragment GroupCore on groups {
  id
  start_date
  end_date
  names(order_by: {created_at: desc}) {
    name
  }
  location_id
}
    `;
export const GetSectionsBasicDoc = gql`
    query GetSectionsBasic($team_id: uuid!) {
  sections(
    where: {contents_sections: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}}
  ) {
    id
    name
    slug
    hex_color
    intro
    section_icons_files {
      type
      file {
        path
      }
    }
  }
}
    `;
export const SectionsBasicByGroupDoc = gql`
    query SectionsBasicByGroup($group_id: uuid!) {
  sections(
    where: {contents_sections: {content: {groups: {_and: {active: {_eq: true}, group: {id: {_eq: $group_id}}}}}}}
  ) {
    id
    name
    slug
    hex_color
    intro
    section_icons_files {
      type
      file {
        path
      }
    }
  }
}
    `;
export const GetSectionsCoreDoc = gql`
    query GetSectionsCore($team_id: uuid!) {
  sections(
    where: {contents_sections: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}}
  ) {
    ...SectionCore
  }
}
    ${SectionCoreFragmentDoc}`;
export const GetOutputsCoresBySectionDoc = gql`
    query GetOutputsCoresBySection($sectionId: uuid!) {
  outputs(where: {parent_section_id: {_eq: $sectionId}}, order_by: {order: asc}) {
    ...OutputCore
  }
}
    ${OutputCoreFragmentDoc}`;
export const GetOutputCoreBySlugDoc = gql`
    query GetOutputCoreBySlug($slug: String!) {
  outputs(where: {slug: {_eq: $slug}}) {
    ...OutputCore
  }
}
    ${OutputCoreFragmentDoc}`;
export const GetOutputCoreMultimediaBySlugDoc = gql`
    query GetOutputCoreMultimediaBySlug($slug: String!) {
  outputs(where: {slug: {_eq: $slug}}) {
    ...OutputCore
    multimedia_content {
      ...MultimediaContentFull
    }
  }
}
    ${OutputCoreFragmentDoc}
${MultimediaContentFullFragmentDoc}`;
export const GetToolsCoreByOutputDoc = gql`
    query GetToolsCoreByOutput($outputId: uuid!) {
  outputs_tools(where: {output_id: {_eq: $outputId}}, order_by: {order: asc}) {
    order
    tool {
      ...ToolCore
    }
  }
}
    ${ToolCoreFragmentDoc}`;
export const GetToolCoreMultimediaBySlugDoc = gql`
    query GetToolCoreMultimediaBySlug($slug: String!) {
  tools(where: {slug: {_eq: $slug}}) {
    ...ToolCore
    multimedia_content {
      ...MultimediaContentFull
    }
  }
}
    ${ToolCoreFragmentDoc}
${MultimediaContentFullFragmentDoc}`;
export const QuestionnaireActiveOfToolDoc = gql`
    query QuestionnaireActiveOfTool($tool_id: uuid!, $team_id: uuid!) {
  questionnaires(where: {_and: {tool_id: {_eq: $tool_id}, active: {_eq: true}}}) {
    id
    questions(order_by: {order: asc}) {
      id
      order
      title
      subtitle
      type
      question_answers(where: {team_id: {_eq: $team_id}}) {
        answer
      }
    }
    teams_states(where: {team_id: {_eq: $team_id}}) {
      updated_at
      finished
    }
  }
}
    `;
export const SignUpUserDoc = gql`
    mutation SignUpUser($cpf: String!, $email: String!, $name: String!, $password: String!, $address_doc_b64str: String!, $id_doc_b64str: String!, $token: String!, $team_id: String, $dob: date!, $phone: String!, $cep: String!, $state: String!, $city: String!, $address: String!, $secondLine: String) {
  createUser(
    cpf: $cpf
    email: $email
    name: $name
    password: $password
    address_doc_b64str: $address_doc_b64str
    id_doc_b64str: $id_doc_b64str
    token: $token
    team_id: $team_id
    dob: $dob
    phone: $phone
    cep: $cep
    state: $state
    city: $city
    address: $address
    secondLine: $secondLine
  )
}
    `;
export const SignUpSubscriptionDoc = gql`
    subscription SignUpSubscription($action_id: uuid!) {
  createUser(id: $action_id) {
    errors
    output {
      id
      name
      email
      cpf
    }
  }
}
    `;
export const GetUserCoreDoc = gql`
    query GetUserCore($id: String!) {
  users(where: {id: {_eq: $id}}) {
    ...UserPrivateInfo
    ...UserPublicInfo
  }
}
    ${UserPrivateInfoFragmentDoc}
${UserPublicInfoFragmentDoc}`;
export const GetTeamCoreByIdDoc = gql`
    query GetTeamCoreById($id: uuid!) {
  teams_by_pk(id: $id) {
    ...TeamCore
  }
}
    ${TeamCoreFragmentDoc}`;
export const GetTeamsOfUserDoc = gql`
    query GetTeamsOfUser($user_id: String!) {
  teams(where: {members: {user_id: {_eq: $user_id}}}) {
    ...TeamCore
  }
}
    ${TeamCoreFragmentDoc}`;
export const GetGroupsOfTeamDoc = gql`
    query GetGroupsOfTeam($team_id: uuid!) {
  groups(where: {teams: {team_id: {_eq: $team_id}}}) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export const GetTeamCoreDoc = gql`
    query GetTeamCore($user_id: String!) {
  team_invites(where: {_and: {user_id: {_eq: $user_id}, status: {_eq: pending}}}) {
    id
    team {
      ...TeamCore
    }
  }
}
    ${TeamCoreFragmentDoc}`;
export const RespondTeamInvitationDoc = gql`
    mutation RespondTeamInvitation($invite_id: String!, $accept: Boolean!) {
  respondTeamInvitation(invite_id: $invite_id, accept: $accept) {
    status
    team_id
    user_id
  }
}
    `;
export const GetTeamScoreDoc = gql`
    query GetTeamScore($team_id: uuid!) {
  tools_aggregate(
    where: {questionnaires: {teams_states: {_and: {finished: {_eq: true}, team_id: {_eq: $team_id}}}}}
  ) {
    aggregate {
      sum {
        points
      }
    }
  }
}
    `;
export const GetTeamsScoresByGroupDoc = gql`
    query GetTeamsScoresByGroup($group_id: uuid!) {
  teams_scores(
    order_by: {sum: desc}
    where: {team: {groups: {group_id: {_eq: $group_id}}}}
  ) {
    sum
    team {
      id
      names(limit: 1, order_by: {created_at: desc}) {
        name
      }
    }
  }
}
    `;
export const CountToolsDoneOfSectionByTeamDoc = gql`
    query CountToolsDoneOfSectionByTeam($team_id: uuid!, $section_id: uuid!) {
  done: tools_aggregate(
    where: {_and: {questionnaires: {teams_states: {_and: {finished: {_eq: true}, team_id: {_eq: $team_id}}}}, outputs: {output: {parent_section_id: {_eq: $section_id}}}}}
  ) {
    aggregate {
      count
    }
  }
  all: tools_aggregate(
    where: {outputs: {output: {parent_section_id: {_eq: $section_id}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const CountToolsDoneOfSectionByTeamGetOutputsNodesDoc = gql`
    query CountToolsDoneOfSectionByTeamGetOutputsNodes($team_id: uuid!, $section_id: uuid!) {
  done: tools_aggregate(
    where: {_and: {questionnaires: {teams_states: {_and: {finished: {_eq: true}, team_id: {_eq: $team_id}}}}, outputs: {output: {parent_section_id: {_eq: $section_id}}}}}
  ) {
    nodes {
      outputs {
        output_id
      }
    }
  }
  all: tools_aggregate(
    where: {outputs: {output: {parent_section_id: {_eq: $section_id}}}}
  ) {
    nodes {
      outputs {
        output_id
      }
    }
  }
}
    `;
export const GetOutputsDoneBySectionDoc = gql`
    query GetOutputsDoneBySection($section_id: uuid!, $team_id: uuid!) {
  outputs(where: {parent_section_id: {_eq: $section_id}}) {
    ...OutputCore
    done: tools_aggregate(
      where: {tool: {questionnaires: {teams_states: {_and: {finished: {_eq: true}, team_id: {_eq: $team_id}}}}}}
    ) {
      aggregate {
        count
      }
    }
    all: tools_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${OutputCoreFragmentDoc}`;
export const GetLocationsCalendarsDoc = gql`
    query GetLocationsCalendars($team_id: uuid!) {
  default: locations(where: {groups: {teams: {team_id: {_eq: $team_id}}}}) {
    calendar_id
    names(order_by: {created_at: desc}) {
      name
    }
  }
  all: locations(order_by: {names_aggregate: {min: {name: asc}}}) {
    calendar_id
    names(order_by: {created_at: desc}) {
      name
    }
  }
}
    `;
export const GetTagsDoc = gql`
    query GetTags {
  tags {
    id
    value
  }
}
    `;
export const GetMembersOfTeamDoc = gql`
    query GetMembersOfTeam($team_id: uuid!) {
  users(where: {teams: {team_id: {_eq: $team_id}}}) {
    role
    ...UserPublicInfo
  }
}
    ${UserPublicInfoFragmentDoc}`;
export const UploadProfilePictureDoc = gql`
    mutation UploadProfilePicture($user_id: String!, $b64_img: String!) {
  uploadProfilePicture(user_id: $user_id, b64_img: $b64_img) {
    id
    path
  }
}
    `;
export const UpdateUserNameDoc = gql`
    mutation UpdateUserName($user_id: String!, $name: String!) {
  insert_users_names_one(object: {user_id: $user_id, name: $name}) {
    name
    user_id
  }
}
    `;
export const UpdateUserOccupationDoc = gql`
    mutation UpdateUserOccupation($user_id: String!, $occupation: String!) {
  update_users(where: {id: {_eq: $user_id}}, _set: {occupation: $occupation}) {
    returning {
      id
      occupation
    }
  }
}
    `;
export const UpdateTeamDoc = gql`
    mutation UpdateTeam($team_id: uuid!, $cnpj: String, $description: String) {
  update_teams_by_pk(
    pk_columns: {id: $team_id}
    _set: {cnpj: $cnpj, description: $description}
  ) {
    id
    cnpj
    description
  }
}
    `;
export const InsertTeamNameDoc = gql`
    mutation InsertTeamName($team_id: uuid!, $name: String!) {
  insert_teams_names_one(object: {team_id: $team_id, name: $name}) {
    team_id
    name
  }
}
    `;
export const DeleteTeamMemberDoc = gql`
    mutation DeleteTeamMember($team_id: uuid!, $user_id: String!) {
  delete_teams_users(
    where: {_and: {team_id: {_eq: $team_id}, user_id: {_eq: $user_id}}}
  ) {
    returning {
      team_id
    }
  }
}
    `;
export const UpdateTeamSocialMediaDoc = gql`
    mutation UpdateTeamSocialMedia($team_id: uuid!, $facebook: String, $twitter: String, $instagram: String, $youtube: String, $linkedin: String, $other: String) {
  update_social_medias(
    where: {teams: {id: {_eq: $team_id}}}
    _set: {youtube: $youtube, twitter: $twitter, other: $other, linkedin: $linkedin, instagram: $instagram, facebook: $facebook}
  ) {
    returning {
      facebook
      instagram
      linkedin
      other
      twitter
      youtube
    }
  }
}
    `;
export const InsertTagsToTeamDoc = gql`
    mutation InsertTagsToTeam($objects: [teams_tags_insert_input!]!) {
  insert_teams_tags(objects: $objects) {
    returning {
      tag_id
      team_id
    }
  }
}
    `;
export const InsertNewTagDoc = gql`
    mutation InsertNewTag($team_id: uuid!) {
  delete_teams_tags(where: {team_id: {_eq: $team_id}}) {
    affected_rows
  }
}
    `;
export const GetDiagnosticsTabsActiveByTeamDoc = gql`
    query GetDiagnosticsTabsActiveByTeam($team_id: uuid!) {
  diagnostics_tabs(
    order_by: {order: asc}
    where: {diagnostic: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}
  ) {
    id
    order
    title
  }
}
    `;
export const GetDiagnosticsSectionsByTabDoc = gql`
    query GetDiagnosticsSectionsByTab($tab_id: uuid!) {
  diagnostics_tabs_sections(
    order_by: {order: asc}
    where: {tab_id: {_eq: $tab_id}}
  ) {
    order
    section {
      id
      title
    }
  }
}
    `;
export const GetDiagnosticsSubsectionsBySectionAndTeamDoc = gql`
    query GetDiagnosticsSubsectionsBySectionAndTeam($tab_id: uuid!, $section_id: uuid!, $team_id: uuid!) {
  diagnostics_subsections(
    order_by: {order: asc}
    where: {section_id: {_eq: $section_id}}
  ) {
    id
    order
    title
    questions(order_by: {order: asc}) {
      id
      order
      question
      answers(where: {_and: {tab_id: {_eq: $tab_id}, team_id: {_eq: $team_id}}}) {
        id
        answer
      }
    }
  }
}
    `;
export const StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamDoc = gql`
    mutation StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeam($objects: [diagnostics_questions_answers_insert_input!]!) {
  insert_diagnostics_questions_answers(
    objects: $objects
    on_conflict: {constraint: diagnostics_questions_answers_group_id_question_id_tab_id_key, update_columns: answer}
  ) {
    returning {
      id
      answer
    }
  }
}
    `;
export const GetDiagnosticsCommentByTabAndTeamDoc = gql`
    query GetDiagnosticsCommentByTabAndTeam($tab_id: uuid!, $team_id: uuid!) {
  diagnostics_comments_by_pk(diagnostic_tab_id: $tab_id, team_id: $team_id) {
    comment
  }
}
    `;
export const StoreDiagnosticsCommentByTabAndTeamDoc = gql`
    mutation StoreDiagnosticsCommentByTabAndTeam($comment: String!, $tab_id: uuid!, $team_id: uuid!) {
  insert_diagnostics_comments_one(
    object: {comment: $comment, diagnostic_tab_id: $tab_id, team_id: $team_id}
    on_conflict: {constraint: diagnostics_comments_pkey, update_columns: comment}
  ) {
    comment
  }
}
    `;
export const GetVideosByTeamDoc = gql`
    query GetVideosByTeam($team_id: uuid!, $limit: Int!, $offset: Int!, $order: order_by!, $contains_text: String!) {
  videos(
    limit: $limit
    offset: $offset
    order_by: {published_at: $order}
    where: {_and: {repository: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}, _or: [{description: {_ilike: $contains_text}}, {title: {_ilike: $contains_text}}]}}
  ) {
    description
    title
    id
    link
    published_at
    tags {
      tag
    }
    thumbnail
  }
}
    `;
export const CountVideosByTagsByTeamDoc = gql`
    query CountVideosByTagsByTeam($tags: _text!, $team_id: uuid!, $contains_text: String!) {
  videos_by_tags_aggregate(
    args: {tags: $tags}
    where: {_and: {repository: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}, _or: [{description: {_ilike: $contains_text}}, {title: {_ilike: $contains_text}}]}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetVideosByTagsByTeamDoc = gql`
    query GetVideosByTagsByTeam($tags: _text!, $team_id: uuid!, $limit: Int!, $offset: Int!, $order: order_by!, $contains_text: String!) {
  videos_by_tags(
    args: {tags: $tags}
    limit: $limit
    offset: $offset
    order_by: {published_at: $order}
    where: {_and: {repository: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}, _or: [{description: {_ilike: $contains_text}}, {title: {_ilike: $contains_text}}]}}
  ) {
    description
    title
    id
    link
    published_at
    tags {
      tag
    }
    thumbnail
  }
}
    `;
export const GetYoutubeTagsDoc = gql`
    query GetYoutubeTags {
  youtube_tags {
    tag
  }
}
    `;
export const GetRepositoriesActiveByTeamDoc = gql`
    query GetRepositoriesActiveByTeam($team_id: uuid!) {
  repositories(
    where: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}
  ) {
    id
    link
    app_key
  }
}
    `;
export const StoreQuestionsAnswersByQuestionByTeamDoc = gql`
    mutation StoreQuestionsAnswersByQuestionByTeam($answers: [questions_answers_insert_input!]!) {
  insert_questions_answers(
    objects: $answers
    on_conflict: {constraint: questions_answers_question_id_team_id_key, update_columns: answer}
  ) {
    affected_rows
  }
}
    `;
export const StoreQuestionnaireTeamStateDoc = gql`
    mutation StoreQuestionnaireTeamState($questionnaire_id: uuid!, $team_id: uuid!, $finished: Boolean!) {
  insert_questionnaires_teams_states_one(
    object: {questionnaire_id: $questionnaire_id, team_id: $team_id, finished: $finished}
    on_conflict: {constraint: questionnaires_teams_states_pkey, update_columns: finished}
  ) {
    finished
  }
}
    `;
export const GetMentorsFeedbackByToolByTeamDoc = gql`
    query GetMentorsFeedbackByToolByTeam($team_id: uuid!, $tool_id: uuid!) {
  mentors_blablabla(
    where: {_and: {team_id: {_eq: $team_id}, tool_id: {_eq: $tool_id}}}
  ) {
    id
    value
  }
}
    `;
export const StoreMentorsFeedbackDoc = gql`
    mutation StoreMentorsFeedback($team_id: uuid!, $tool_id: uuid!, $value: String!) {
  insert_mentors_blablabla_one(
    object: {team_id: $team_id, tool_id: $tool_id, value: $value}
    on_conflict: {constraint: mentors_blablabla_team_id_tool_id_key, update_columns: value}
  ) {
    value
  }
}
    `;
export const GetBlobStorageSasUriDoc = gql`
    query GetBlobStorageSASUri($container: String!, $filename: String!) {
  getStorageSASUri(container: $container, filename: $filename)
}
    `;
export const InsertMentoringRecordDoc = gql`
    mutation InsertMentoringRecord($date: timestamptz!, $record: String!, $team_id: uuid!, $duration: smallint!) {
  insert_mentoring_records_one(
    object: {date: $date, record: $record, team_id: $team_id, duration: $duration}
  ) {
    id
  }
}
    `;
export const DeleteMentoringRecordByIdDoc = gql`
    mutation DeleteMentoringRecordById($id: uuid!) {
  delete_mentoring_records_by_pk(id: $id) {
    id
  }
}
    `;
export const GetMentoringRecordsByTeamByMonthDoc = gql`
    query GetMentoringRecordsByTeamByMonth($team_id: uuid!, $first_day: timestamptz!, $last_day: timestamptz!) {
  mentoring_records(
    where: {_and: {team_id: {_eq: $team_id}, date: {_gte: $first_day, _lte: $last_day}}}
  ) {
    id
    duration
    date
    record
    members {
      member_id
      member {
        id
        names(order_by: {created_at: desc}, limit: 1) {
          name
        }
      }
    }
  }
}
    `;
export const InviteEmailToTeamDoc = gql`
    mutation InviteEmailToTeam($email: String!, $team_id: String!) {
  inviteUserToTeam(user_email: $email, team_id: $team_id) {
    invite_id
    team_id
    user_id
  }
}
    `;
export const GetInvitesOfTeamDoc = gql`
    query GetInvitesOfTeam($team_id: uuid!) {
  users: team_invites(
    where: {_and: {team_id: {_eq: $team_id}}, status: {_eq: pending}}
  ) {
    user {
      id
      names(order_by: {created_at: desc}, limit: 1) {
        name
      }
    }
  }
  emails: user_invites(
    where: {_and: {invited_by: {_eq: $team_id}}, consumed: {_is_null: true}}
  ) {
    email
  }
}
    `;
export const GetTeamsDoc = gql`
    query GetTeams {
  teams {
    id
  }
}
    `;
export const GetLocationsDoc = gql`
    query GetLocations {
  locations {
    id
    names {
      name
    }
  }
}
    `;
export const GetGroupsDoc = gql`
    query GetGroups {
  groups {
    names {
      name
    }
    id
  }
}
    `;
export const GetTeamsByGroupDoc = gql`
    query GetTeamsByGroup($groupId: uuid!) {
  teams(where: {groups: {group: {id: {_eq: $groupId}}}}) {
    ...TeamCore
    name
  }
}
    ${TeamCoreFragmentDoc}`;
export const GroupsByUserDoc = gql`
    query GroupsByUser($user_id: String!) {
  users(where: {id: {_eq: $user_id}}) {
    teams {
      team {
        groups {
          group {
            id
            names {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export const GetInviteStateDoc = gql`
    query GetInviteState($email: String!, $token: String!) {
  checkInviteStatus(email: $email, token: $token) {
    id
    status
  }
}
    `;
export const ContentWithProgressDoc = gql`
    query ContentWithProgress($team_id: uuid!) {
  contents(where: {groups: {group: {teams: {team: {id: {_eq: $team_id}}}}}}) {
    sections {
      section {
        id
        name
        slug
        section_icons_files {
          id
          type
          file {
            id
            path
          }
        }
        outputs(order_by: {order: asc}) {
          id
          name
          slug
          icons {
            id
            type
            file {
              id
              path
            }
          }
          tools(order_by: {order: asc}) {
            tool {
              id
              name
              slug
              intro
              explanation
              slug
              points
              icons {
                id
                type
                file {
                  id
                  path
                }
              }
              questionnaires {
                teams_states(where: {team_id: {_eq: $team_id}}) {
                  finished
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GetSectionsBasic = (
            options: Omit<
              WatchQueryOptions<GetSectionsBasicQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetSectionsBasicQuery> & {
              query: ObservableQuery<
                GetSectionsBasicQuery,
                GetSectionsBasicQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetSectionsBasicDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetSectionsBasicQuery> & {
                query: ObservableQuery<
                  GetSectionsBasicQuery,
                  GetSectionsBasicQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const SectionsBasicByGroup = (
            options: Omit<
              WatchQueryOptions<SectionsBasicByGroupQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<SectionsBasicByGroupQuery> & {
              query: ObservableQuery<
                SectionsBasicByGroupQuery,
                SectionsBasicByGroupQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: SectionsBasicByGroupDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<SectionsBasicByGroupQuery> & {
                query: ObservableQuery<
                  SectionsBasicByGroupQuery,
                  SectionsBasicByGroupQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetSectionsCore = (
            options: Omit<
              WatchQueryOptions<GetSectionsCoreQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetSectionsCoreQuery> & {
              query: ObservableQuery<
                GetSectionsCoreQuery,
                GetSectionsCoreQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetSectionsCoreDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetSectionsCoreQuery> & {
                query: ObservableQuery<
                  GetSectionsCoreQuery,
                  GetSectionsCoreQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetOutputsCoresBySection = (
            options: Omit<
              WatchQueryOptions<GetOutputsCoresBySectionQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetOutputsCoresBySectionQuery> & {
              query: ObservableQuery<
                GetOutputsCoresBySectionQuery,
                GetOutputsCoresBySectionQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetOutputsCoresBySectionDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetOutputsCoresBySectionQuery> & {
                query: ObservableQuery<
                  GetOutputsCoresBySectionQuery,
                  GetOutputsCoresBySectionQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetOutputCoreBySlug = (
            options: Omit<
              WatchQueryOptions<GetOutputCoreBySlugQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetOutputCoreBySlugQuery> & {
              query: ObservableQuery<
                GetOutputCoreBySlugQuery,
                GetOutputCoreBySlugQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetOutputCoreBySlugDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetOutputCoreBySlugQuery> & {
                query: ObservableQuery<
                  GetOutputCoreBySlugQuery,
                  GetOutputCoreBySlugQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetOutputCoreMultimediaBySlug = (
            options: Omit<
              WatchQueryOptions<GetOutputCoreMultimediaBySlugQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetOutputCoreMultimediaBySlugQuery> & {
              query: ObservableQuery<
                GetOutputCoreMultimediaBySlugQuery,
                GetOutputCoreMultimediaBySlugQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetOutputCoreMultimediaBySlugDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetOutputCoreMultimediaBySlugQuery> & {
                query: ObservableQuery<
                  GetOutputCoreMultimediaBySlugQuery,
                  GetOutputCoreMultimediaBySlugQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetToolsCoreByOutput = (
            options: Omit<
              WatchQueryOptions<GetToolsCoreByOutputQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetToolsCoreByOutputQuery> & {
              query: ObservableQuery<
                GetToolsCoreByOutputQuery,
                GetToolsCoreByOutputQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetToolsCoreByOutputDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetToolsCoreByOutputQuery> & {
                query: ObservableQuery<
                  GetToolsCoreByOutputQuery,
                  GetToolsCoreByOutputQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetToolCoreMultimediaBySlug = (
            options: Omit<
              WatchQueryOptions<GetToolCoreMultimediaBySlugQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetToolCoreMultimediaBySlugQuery> & {
              query: ObservableQuery<
                GetToolCoreMultimediaBySlugQuery,
                GetToolCoreMultimediaBySlugQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetToolCoreMultimediaBySlugDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetToolCoreMultimediaBySlugQuery> & {
                query: ObservableQuery<
                  GetToolCoreMultimediaBySlugQuery,
                  GetToolCoreMultimediaBySlugQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const QuestionnaireActiveOfTool = (
            options: Omit<
              WatchQueryOptions<QuestionnaireActiveOfToolQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<QuestionnaireActiveOfToolQuery> & {
              query: ObservableQuery<
                QuestionnaireActiveOfToolQuery,
                QuestionnaireActiveOfToolQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: QuestionnaireActiveOfToolDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<QuestionnaireActiveOfToolQuery> & {
                query: ObservableQuery<
                  QuestionnaireActiveOfToolQuery,
                  QuestionnaireActiveOfToolQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const SignUpUser = (
            options: Omit<
              MutationOptions<any, SignUpUserMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<SignUpUserMutation, SignUpUserMutationVariables>({
              mutation: SignUpUserDoc,
              ...options,
            });
            return m;
          }
export const SignUpSubscription = (
            options: Omit<SubscriptionOptions<SignUpSubscriptionSubscriptionVariables>, "query">
          ) => {
            const q = client.subscribe<SignUpSubscriptionSubscription, SignUpSubscriptionSubscriptionVariables>(
              {
                query: SignUpSubscriptionDoc,
                ...options,
              }
            )
            return q;
          }
export const GetUserCore = (
            options: Omit<
              WatchQueryOptions<GetUserCoreQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetUserCoreQuery> & {
              query: ObservableQuery<
                GetUserCoreQuery,
                GetUserCoreQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetUserCoreDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetUserCoreQuery> & {
                query: ObservableQuery<
                  GetUserCoreQuery,
                  GetUserCoreQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamCoreById = (
            options: Omit<
              WatchQueryOptions<GetTeamCoreByIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamCoreByIdQuery> & {
              query: ObservableQuery<
                GetTeamCoreByIdQuery,
                GetTeamCoreByIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamCoreByIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamCoreByIdQuery> & {
                query: ObservableQuery<
                  GetTeamCoreByIdQuery,
                  GetTeamCoreByIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamsOfUser = (
            options: Omit<
              WatchQueryOptions<GetTeamsOfUserQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamsOfUserQuery> & {
              query: ObservableQuery<
                GetTeamsOfUserQuery,
                GetTeamsOfUserQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamsOfUserDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamsOfUserQuery> & {
                query: ObservableQuery<
                  GetTeamsOfUserQuery,
                  GetTeamsOfUserQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetGroupsOfTeam = (
            options: Omit<
              WatchQueryOptions<GetGroupsOfTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetGroupsOfTeamQuery> & {
              query: ObservableQuery<
                GetGroupsOfTeamQuery,
                GetGroupsOfTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetGroupsOfTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetGroupsOfTeamQuery> & {
                query: ObservableQuery<
                  GetGroupsOfTeamQuery,
                  GetGroupsOfTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamCore = (
            options: Omit<
              WatchQueryOptions<GetTeamCoreQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamCoreQuery> & {
              query: ObservableQuery<
                GetTeamCoreQuery,
                GetTeamCoreQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamCoreDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamCoreQuery> & {
                query: ObservableQuery<
                  GetTeamCoreQuery,
                  GetTeamCoreQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const RespondTeamInvitation = (
            options: Omit<
              MutationOptions<any, RespondTeamInvitationMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<RespondTeamInvitationMutation, RespondTeamInvitationMutationVariables>({
              mutation: RespondTeamInvitationDoc,
              ...options,
            });
            return m;
          }
export const GetTeamScore = (
            options: Omit<
              WatchQueryOptions<GetTeamScoreQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamScoreQuery> & {
              query: ObservableQuery<
                GetTeamScoreQuery,
                GetTeamScoreQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamScoreDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamScoreQuery> & {
                query: ObservableQuery<
                  GetTeamScoreQuery,
                  GetTeamScoreQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamsScoresByGroup = (
            options: Omit<
              WatchQueryOptions<GetTeamsScoresByGroupQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamsScoresByGroupQuery> & {
              query: ObservableQuery<
                GetTeamsScoresByGroupQuery,
                GetTeamsScoresByGroupQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamsScoresByGroupDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamsScoresByGroupQuery> & {
                query: ObservableQuery<
                  GetTeamsScoresByGroupQuery,
                  GetTeamsScoresByGroupQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const CountToolsDoneOfSectionByTeam = (
            options: Omit<
              WatchQueryOptions<CountToolsDoneOfSectionByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<CountToolsDoneOfSectionByTeamQuery> & {
              query: ObservableQuery<
                CountToolsDoneOfSectionByTeamQuery,
                CountToolsDoneOfSectionByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: CountToolsDoneOfSectionByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<CountToolsDoneOfSectionByTeamQuery> & {
                query: ObservableQuery<
                  CountToolsDoneOfSectionByTeamQuery,
                  CountToolsDoneOfSectionByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const CountToolsDoneOfSectionByTeamGetOutputsNodes = (
            options: Omit<
              WatchQueryOptions<CountToolsDoneOfSectionByTeamGetOutputsNodesQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<CountToolsDoneOfSectionByTeamGetOutputsNodesQuery> & {
              query: ObservableQuery<
                CountToolsDoneOfSectionByTeamGetOutputsNodesQuery,
                CountToolsDoneOfSectionByTeamGetOutputsNodesQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: CountToolsDoneOfSectionByTeamGetOutputsNodesDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<CountToolsDoneOfSectionByTeamGetOutputsNodesQuery> & {
                query: ObservableQuery<
                  CountToolsDoneOfSectionByTeamGetOutputsNodesQuery,
                  CountToolsDoneOfSectionByTeamGetOutputsNodesQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetOutputsDoneBySection = (
            options: Omit<
              WatchQueryOptions<GetOutputsDoneBySectionQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetOutputsDoneBySectionQuery> & {
              query: ObservableQuery<
                GetOutputsDoneBySectionQuery,
                GetOutputsDoneBySectionQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetOutputsDoneBySectionDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetOutputsDoneBySectionQuery> & {
                query: ObservableQuery<
                  GetOutputsDoneBySectionQuery,
                  GetOutputsDoneBySectionQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetLocationsCalendars = (
            options: Omit<
              WatchQueryOptions<GetLocationsCalendarsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetLocationsCalendarsQuery> & {
              query: ObservableQuery<
                GetLocationsCalendarsQuery,
                GetLocationsCalendarsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetLocationsCalendarsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetLocationsCalendarsQuery> & {
                query: ObservableQuery<
                  GetLocationsCalendarsQuery,
                  GetLocationsCalendarsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTags = (
            options: Omit<
              WatchQueryOptions<GetTagsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTagsQuery> & {
              query: ObservableQuery<
                GetTagsQuery,
                GetTagsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTagsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTagsQuery> & {
                query: ObservableQuery<
                  GetTagsQuery,
                  GetTagsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetMembersOfTeam = (
            options: Omit<
              WatchQueryOptions<GetMembersOfTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMembersOfTeamQuery> & {
              query: ObservableQuery<
                GetMembersOfTeamQuery,
                GetMembersOfTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMembersOfTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMembersOfTeamQuery> & {
                query: ObservableQuery<
                  GetMembersOfTeamQuery,
                  GetMembersOfTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const UploadProfilePicture = (
            options: Omit<
              MutationOptions<any, UploadProfilePictureMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>({
              mutation: UploadProfilePictureDoc,
              ...options,
            });
            return m;
          }
export const UpdateUserName = (
            options: Omit<
              MutationOptions<any, UpdateUserNameMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateUserNameMutation, UpdateUserNameMutationVariables>({
              mutation: UpdateUserNameDoc,
              ...options,
            });
            return m;
          }
export const UpdateUserOccupation = (
            options: Omit<
              MutationOptions<any, UpdateUserOccupationMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateUserOccupationMutation, UpdateUserOccupationMutationVariables>({
              mutation: UpdateUserOccupationDoc,
              ...options,
            });
            return m;
          }
export const UpdateTeam = (
            options: Omit<
              MutationOptions<any, UpdateTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateTeamMutation, UpdateTeamMutationVariables>({
              mutation: UpdateTeamDoc,
              ...options,
            });
            return m;
          }
export const InsertTeamName = (
            options: Omit<
              MutationOptions<any, InsertTeamNameMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertTeamNameMutation, InsertTeamNameMutationVariables>({
              mutation: InsertTeamNameDoc,
              ...options,
            });
            return m;
          }
export const DeleteTeamMember = (
            options: Omit<
              MutationOptions<any, DeleteTeamMemberMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>({
              mutation: DeleteTeamMemberDoc,
              ...options,
            });
            return m;
          }
export const UpdateTeamSocialMedia = (
            options: Omit<
              MutationOptions<any, UpdateTeamSocialMediaMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateTeamSocialMediaMutation, UpdateTeamSocialMediaMutationVariables>({
              mutation: UpdateTeamSocialMediaDoc,
              ...options,
            });
            return m;
          }
export const InsertTagsToTeam = (
            options: Omit<
              MutationOptions<any, InsertTagsToTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertTagsToTeamMutation, InsertTagsToTeamMutationVariables>({
              mutation: InsertTagsToTeamDoc,
              ...options,
            });
            return m;
          }
export const InsertNewTag = (
            options: Omit<
              MutationOptions<any, InsertNewTagMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertNewTagMutation, InsertNewTagMutationVariables>({
              mutation: InsertNewTagDoc,
              ...options,
            });
            return m;
          }
export const GetDiagnosticsTabsActiveByTeam = (
            options: Omit<
              WatchQueryOptions<GetDiagnosticsTabsActiveByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetDiagnosticsTabsActiveByTeamQuery> & {
              query: ObservableQuery<
                GetDiagnosticsTabsActiveByTeamQuery,
                GetDiagnosticsTabsActiveByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetDiagnosticsTabsActiveByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetDiagnosticsTabsActiveByTeamQuery> & {
                query: ObservableQuery<
                  GetDiagnosticsTabsActiveByTeamQuery,
                  GetDiagnosticsTabsActiveByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetDiagnosticsSectionsByTab = (
            options: Omit<
              WatchQueryOptions<GetDiagnosticsSectionsByTabQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetDiagnosticsSectionsByTabQuery> & {
              query: ObservableQuery<
                GetDiagnosticsSectionsByTabQuery,
                GetDiagnosticsSectionsByTabQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetDiagnosticsSectionsByTabDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetDiagnosticsSectionsByTabQuery> & {
                query: ObservableQuery<
                  GetDiagnosticsSectionsByTabQuery,
                  GetDiagnosticsSectionsByTabQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetDiagnosticsSubsectionsBySectionAndTeam = (
            options: Omit<
              WatchQueryOptions<GetDiagnosticsSubsectionsBySectionAndTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetDiagnosticsSubsectionsBySectionAndTeamQuery> & {
              query: ObservableQuery<
                GetDiagnosticsSubsectionsBySectionAndTeamQuery,
                GetDiagnosticsSubsectionsBySectionAndTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetDiagnosticsSubsectionsBySectionAndTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetDiagnosticsSubsectionsBySectionAndTeamQuery> & {
                query: ObservableQuery<
                  GetDiagnosticsSubsectionsBySectionAndTeamQuery,
                  GetDiagnosticsSubsectionsBySectionAndTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeam = (
            options: Omit<
              MutationOptions<any, StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamMutation, StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamMutationVariables>({
              mutation: StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamDoc,
              ...options,
            });
            return m;
          }
export const GetDiagnosticsCommentByTabAndTeam = (
            options: Omit<
              WatchQueryOptions<GetDiagnosticsCommentByTabAndTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetDiagnosticsCommentByTabAndTeamQuery> & {
              query: ObservableQuery<
                GetDiagnosticsCommentByTabAndTeamQuery,
                GetDiagnosticsCommentByTabAndTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetDiagnosticsCommentByTabAndTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetDiagnosticsCommentByTabAndTeamQuery> & {
                query: ObservableQuery<
                  GetDiagnosticsCommentByTabAndTeamQuery,
                  GetDiagnosticsCommentByTabAndTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const StoreDiagnosticsCommentByTabAndTeam = (
            options: Omit<
              MutationOptions<any, StoreDiagnosticsCommentByTabAndTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<StoreDiagnosticsCommentByTabAndTeamMutation, StoreDiagnosticsCommentByTabAndTeamMutationVariables>({
              mutation: StoreDiagnosticsCommentByTabAndTeamDoc,
              ...options,
            });
            return m;
          }
export const GetVideosByTeam = (
            options: Omit<
              WatchQueryOptions<GetVideosByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetVideosByTeamQuery> & {
              query: ObservableQuery<
                GetVideosByTeamQuery,
                GetVideosByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetVideosByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetVideosByTeamQuery> & {
                query: ObservableQuery<
                  GetVideosByTeamQuery,
                  GetVideosByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const CountVideosByTagsByTeam = (
            options: Omit<
              WatchQueryOptions<CountVideosByTagsByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<CountVideosByTagsByTeamQuery> & {
              query: ObservableQuery<
                CountVideosByTagsByTeamQuery,
                CountVideosByTagsByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: CountVideosByTagsByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<CountVideosByTagsByTeamQuery> & {
                query: ObservableQuery<
                  CountVideosByTagsByTeamQuery,
                  CountVideosByTagsByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetVideosByTagsByTeam = (
            options: Omit<
              WatchQueryOptions<GetVideosByTagsByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetVideosByTagsByTeamQuery> & {
              query: ObservableQuery<
                GetVideosByTagsByTeamQuery,
                GetVideosByTagsByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetVideosByTagsByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetVideosByTagsByTeamQuery> & {
                query: ObservableQuery<
                  GetVideosByTagsByTeamQuery,
                  GetVideosByTagsByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetYoutubeTags = (
            options: Omit<
              WatchQueryOptions<GetYoutubeTagsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetYoutubeTagsQuery> & {
              query: ObservableQuery<
                GetYoutubeTagsQuery,
                GetYoutubeTagsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetYoutubeTagsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetYoutubeTagsQuery> & {
                query: ObservableQuery<
                  GetYoutubeTagsQuery,
                  GetYoutubeTagsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetRepositoriesActiveByTeam = (
            options: Omit<
              WatchQueryOptions<GetRepositoriesActiveByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetRepositoriesActiveByTeamQuery> & {
              query: ObservableQuery<
                GetRepositoriesActiveByTeamQuery,
                GetRepositoriesActiveByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetRepositoriesActiveByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetRepositoriesActiveByTeamQuery> & {
                query: ObservableQuery<
                  GetRepositoriesActiveByTeamQuery,
                  GetRepositoriesActiveByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const StoreQuestionsAnswersByQuestionByTeam = (
            options: Omit<
              MutationOptions<any, StoreQuestionsAnswersByQuestionByTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<StoreQuestionsAnswersByQuestionByTeamMutation, StoreQuestionsAnswersByQuestionByTeamMutationVariables>({
              mutation: StoreQuestionsAnswersByQuestionByTeamDoc,
              ...options,
            });
            return m;
          }
export const StoreQuestionnaireTeamState = (
            options: Omit<
              MutationOptions<any, StoreQuestionnaireTeamStateMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<StoreQuestionnaireTeamStateMutation, StoreQuestionnaireTeamStateMutationVariables>({
              mutation: StoreQuestionnaireTeamStateDoc,
              ...options,
            });
            return m;
          }
export const GetMentorsFeedbackByToolByTeam = (
            options: Omit<
              WatchQueryOptions<GetMentorsFeedbackByToolByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentorsFeedbackByToolByTeamQuery> & {
              query: ObservableQuery<
                GetMentorsFeedbackByToolByTeamQuery,
                GetMentorsFeedbackByToolByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentorsFeedbackByToolByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentorsFeedbackByToolByTeamQuery> & {
                query: ObservableQuery<
                  GetMentorsFeedbackByToolByTeamQuery,
                  GetMentorsFeedbackByToolByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const StoreMentorsFeedback = (
            options: Omit<
              MutationOptions<any, StoreMentorsFeedbackMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<StoreMentorsFeedbackMutation, StoreMentorsFeedbackMutationVariables>({
              mutation: StoreMentorsFeedbackDoc,
              ...options,
            });
            return m;
          }
export const GetBlobStorageSASUri = (
            options: Omit<
              WatchQueryOptions<GetBlobStorageSasUriQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetBlobStorageSasUriQuery> & {
              query: ObservableQuery<
                GetBlobStorageSasUriQuery,
                GetBlobStorageSasUriQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetBlobStorageSasUriDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetBlobStorageSasUriQuery> & {
                query: ObservableQuery<
                  GetBlobStorageSasUriQuery,
                  GetBlobStorageSasUriQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const InsertMentoringRecord = (
            options: Omit<
              MutationOptions<any, InsertMentoringRecordMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMentoringRecordMutation, InsertMentoringRecordMutationVariables>({
              mutation: InsertMentoringRecordDoc,
              ...options,
            });
            return m;
          }
export const DeleteMentoringRecordById = (
            options: Omit<
              MutationOptions<any, DeleteMentoringRecordByIdMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteMentoringRecordByIdMutation, DeleteMentoringRecordByIdMutationVariables>({
              mutation: DeleteMentoringRecordByIdDoc,
              ...options,
            });
            return m;
          }
export const GetMentoringRecordsByTeamByMonth = (
            options: Omit<
              WatchQueryOptions<GetMentoringRecordsByTeamByMonthQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentoringRecordsByTeamByMonthQuery> & {
              query: ObservableQuery<
                GetMentoringRecordsByTeamByMonthQuery,
                GetMentoringRecordsByTeamByMonthQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentoringRecordsByTeamByMonthDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentoringRecordsByTeamByMonthQuery> & {
                query: ObservableQuery<
                  GetMentoringRecordsByTeamByMonthQuery,
                  GetMentoringRecordsByTeamByMonthQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const InviteEmailToTeam = (
            options: Omit<
              MutationOptions<any, InviteEmailToTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InviteEmailToTeamMutation, InviteEmailToTeamMutationVariables>({
              mutation: InviteEmailToTeamDoc,
              ...options,
            });
            return m;
          }
export const GetInvitesOfTeam = (
            options: Omit<
              WatchQueryOptions<GetInvitesOfTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetInvitesOfTeamQuery> & {
              query: ObservableQuery<
                GetInvitesOfTeamQuery,
                GetInvitesOfTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetInvitesOfTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetInvitesOfTeamQuery> & {
                query: ObservableQuery<
                  GetInvitesOfTeamQuery,
                  GetInvitesOfTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeams = (
            options: Omit<
              WatchQueryOptions<GetTeamsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamsQuery> & {
              query: ObservableQuery<
                GetTeamsQuery,
                GetTeamsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamsQuery> & {
                query: ObservableQuery<
                  GetTeamsQuery,
                  GetTeamsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetLocations = (
            options: Omit<
              WatchQueryOptions<GetLocationsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetLocationsQuery> & {
              query: ObservableQuery<
                GetLocationsQuery,
                GetLocationsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetLocationsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetLocationsQuery> & {
                query: ObservableQuery<
                  GetLocationsQuery,
                  GetLocationsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetGroups = (
            options: Omit<
              WatchQueryOptions<GetGroupsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetGroupsQuery> & {
              query: ObservableQuery<
                GetGroupsQuery,
                GetGroupsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetGroupsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetGroupsQuery> & {
                query: ObservableQuery<
                  GetGroupsQuery,
                  GetGroupsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamsByGroup = (
            options: Omit<
              WatchQueryOptions<GetTeamsByGroupQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamsByGroupQuery> & {
              query: ObservableQuery<
                GetTeamsByGroupQuery,
                GetTeamsByGroupQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamsByGroupDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamsByGroupQuery> & {
                query: ObservableQuery<
                  GetTeamsByGroupQuery,
                  GetTeamsByGroupQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GroupsByUser = (
            options: Omit<
              WatchQueryOptions<GroupsByUserQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GroupsByUserQuery> & {
              query: ObservableQuery<
                GroupsByUserQuery,
                GroupsByUserQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GroupsByUserDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GroupsByUserQuery> & {
                query: ObservableQuery<
                  GroupsByUserQuery,
                  GroupsByUserQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetInviteState = (
            options: Omit<
              WatchQueryOptions<GetInviteStateQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetInviteStateQuery> & {
              query: ObservableQuery<
                GetInviteStateQuery,
                GetInviteStateQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetInviteStateDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetInviteStateQuery> & {
                query: ObservableQuery<
                  GetInviteStateQuery,
                  GetInviteStateQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const ContentWithProgress = (
            options: Omit<
              WatchQueryOptions<ContentWithProgressQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<ContentWithProgressQuery> & {
              query: ObservableQuery<
                ContentWithProgressQuery,
                ContentWithProgressQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: ContentWithProgressDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<ContentWithProgressQuery> & {
                query: ObservableQuery<
                  ContentWithProgressQuery,
                  ContentWithProgressQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        